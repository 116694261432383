import React, { Component } from 'react';
import { CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Image, Progress, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import { getProgramStatus } from '../utils/ProgramHelper';
import { openNotification } from '../utils/General';
import '../styles/programs.less';
import WithSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';

class ProgramItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingItem: false,
    };
  }

  convertDate(date) {
    return moment(date).format('DD/MM/YYYY');
  }

  getProgramItem() {
    const { program, t, dashboard } = this.props;
    const quote = program.total_budget || 0;

    const lockedMoney = parseInt(program.locked);

    const totalBudget = parseInt(program.total_budget);
    const totalPaid = parseInt(program.total_paid);

    let budgetPercent =
      totalBudget == 0 ? 0 : ((totalPaid + lockedMoney) / totalBudget) * 100;
    budgetPercent = budgetPercent > 100 ? 100 : budgetPercent;

    const creationDate = program.created_at
      ? moment(program.created_at).format('DD/MM/YYYY')
      : null;
    const updateDate = program.updated_at
      ? moment(program.updated_at).format('DD/MM/YYYY')
      : null;
    let startDate;
    let endDate;
    if (program.is_ptaas) {
      startDate = this.convertDate(program.pentest_as_a_service.start_at);
      endDate = this.convertDate(program.pentest_as_a_service.end_at);
    }
    const { programState, program_class } = getProgramStatus(program, t);

    return (
      <Row id="program-item">
        <Col
          xs={{ span: 4 }}
          md={{ span: 2 }}
          lg={{ span: 2 }}
          xl={{ span: 2 }}
          xxl={{ span: 2 }}
        >
          <div className="program-item-avatar">
            <Image
              src={
                program.avatar_thumbnail_s_url || '/images/program-default.png'
              }
              preview={false}
              className="img-circle ml-1"
              width={77}
              height={77}
            />
            {program.is_ptaas ? (
              <div className="program-ptaas-label">
                <p className="text-center">PTAAS</p>
              </div>
            ) : (
              <div className="program-bb-label">
                <p className="text-center">BUG BOUNTY</p>
              </div>
            )}
          </div>
        </Col>
        <Col
          xs={{ span: 18, offset: 2 }}
          md={{ span: 13, offset: 1 }}
          lg={{ span: dashboard ? 19 : 13, offset: dashboard ? 3 : 2 }} // 24 17
          xl={{ span: 12, offset: dashboard ? 2 : 1 }}
          xxl={{ span: dashboard ? 12 : 14, offset: dashboard ? 2 : 0 }} // 16
        >
          <Row>
            <h2 className="program-title" style={{ fontSize: '14px' }}>
              {program.name ? program.name : '-'} - {program.company.name}
            </h2>
          </Row>
          <Row>
            <Col>
              <p className="program-description">
                {program.program_types.map((p, index) => (
                  <span key={p.code}>
                    {t(`programs.program-types.${p.code}`)}
                    {index < program.program_types.length - 1 ? ' - ' : ''}
                  </span>
                ))}
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24}>
              {this.props.companyMode
                ? program.is_ptaas === false && (
                    <div className="percentage-bar">
                      <Tooltip
                        placement="right"
                        title={`Utilizado ${Number(budgetPercent.toFixed(1))}%`}
                      >
                        <Progress
                          percent={Number(budgetPercent.toFixed(1))}
                          showInfo={false}
                        />
                      </Tooltip>
                      <div style={{ display: 'flex' }}>
                        <p>{t('programs.total-budget')}: </p>
                        &nbsp;
                        <p>
                          <NumberDisplay number={quote} showUSD={true} />
                        </p>
                      </div>
                    </div>
                  )
                : program.is_ptaas === false && (
                    <div className="rewards-range">
                      <p>
                        <NumberDisplay
                          number={program.rewards_info.bounty_low}
                        />{' '}
                        -{' '}
                        <NumberDisplay
                          number={program.rewards_info.bounty_critical}
                        />{' '}
                        USD
                      </p>
                    </div>
                  )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={24} className="range-dates">
              {program.is_ptaas ? (
                <div className="flex-inline">
                  <PlayCircleOutlined className="icon-date mr-1" />
                  <p className="mr-2">{startDate}</p>
                  <CloseCircleOutlined className="icon-date mr-1" />
                  <p>{endDate}</p>
                </div>
              ) : (
                <div className="flex-inline">
                  <Image
                    className="custom-icon mr-1"
                    preview={false}
                    src={`/images/creation-date.png`}
                  />
                  <p className="mr-2">{creationDate}</p>
                  <Image
                    className="custom-icon mr-1"
                    preview={false}
                    src={`/images/update-date.png`}
                  />
                  <p>{updateDate}</p>
                </div>
              )}
            </Col>
          </Row>
          {program.is_ptaas && (
            <Row>
              <Col span={24}>
                <p>
                  {t('programs.form.credits')}:{' '}
                  <NumberDisplay number={quote} showUSD={false} />
                </p>
                &nbsp;{' '}
              </Col>
            </Row>
          )}
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 8, offset: 0 }}
          lg={{ span: dashboard ? 24 : 7, offset: 0 }} // 24 8
          xl={{ span: dashboard ? 6 : 8, offset: dashboard ? 1 : 0 }}
          xxl={{ span: 8, offset: 0 }} // 24
        >
          {program.is_approved ? (
            <Row className={`mt-2 ${dashboard ? 'mb-5' : 'mb-3'}`}>
              <Col
                lg={{ span: 12 }}
                xl={{ span: dashboard ? 24 : 12 }}
                className={`${!this.props.isMobile && 'centered-content'}`}
              >
                <div className="program-info-label">
                  <p>
                    <span className="mr-1">
                      {program.number_of_reports
                        ? program.number_of_reports
                        : '0'}
                    </span>
                    <span>
                      {program.number_of_reports === 1
                        ? t('reports.verbose_name')
                        : t('reports.verbose_name_plural')}
                    </span>
                  </p>
                </div>
              </Col>
              <Col
                lg={{ span: 12 }}
                xl={{ span: dashboard ? 24 : 12 }}
                className={`${!this.props.isMobile && 'centered-content'}`}
              >
                <div className="program-info-label">
                  <p>
                    <span className="mr-1">
                      {program.number_of_hackers
                        ? program.number_of_hackers
                        : '0'}
                    </span>
                    <span>
                      {program.number_of_hackers === 1 ? 'Hacker' : 'Hackers'}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            <div style={{ marginBottom: '5vh' }}></div>
          )}
          <Row>
            <Col
              span={24}
              className={`${!this.props.isMobile && 'centered-content'}`}
            >
              <div className={`program-state-label-${program_class}`}>
                <p>{programState}</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  deniedAccess = () => {
    openNotification(
      'error',
      'Lo sentimos este programa se encuentra cerrado o pausado. Su descripción no esta disponible'
    );
  };

  render() {
    const { program } = this.props;
    return (
      <Card
        className="programItem"
        hoverable={true}
        loading={this.state.loadingItem}
        onClick={(e) => {
          if (this.props.onClick) {
            e.preventDefault();
            this.props.onClick(program.id);
          }
        }}
      >
        {this.getProgramItem()}
      </Card>
    );
  }
}

export default WithSizes(mapSizes)(withTranslation()(ProgramItem));
