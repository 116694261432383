import { Button, Card, Col, Form, Input, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import '../styles/profile.less';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import TwoFactorOtpForm from './TwoFactorOtpForm';
import { QRCodeSVG } from 'qrcode.react';
import { handleAxiosError } from '../utils/Http';
import axiosInstance from '../axiosInstance';

const TwoFactorSetup = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [qrData, setQrData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/two-factor/initiate/', {
          method: 'generator',
        });
        setQrData(response.data);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleOtpSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/two-factor/validate/', {
        method: 'generator',
        token: values['token'],
      });
      history.push('/two-factor/backup-codes', { method: 'generator' });
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || qrData === null) {
    return <Spin size="large" />;
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.qr.title')}
          </h4>
          <p className="card-content mb-3">
            {t('profile.security.2fa.setup.qr.description')}
          </p>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.qr.scan-title')}
          </h4>
          <p className="card-content mb-3">
            {t('profile.security.2fa.setup.qr.scan-description')}
          </p>
          <QRCodeSVG value={qrData.otpAuthUrl} />,
          <p className="card-content mt-2">
            {t('profile.security.2fa.setup.qr.alternative-code')}
          </p>
          <p className="card-contet mb-5">
            <Spin spinning={isLoading}>{qrData.secret}</Spin>
          </p>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.qr.app-title')}
          </h4>
          <p className="card-content">
            {t('profile.security.2fa.setup.qr.app-description')}
          </p>
        </Col>
      </Row>

      <TwoFactorOtpForm
        onSubmit={handleOtpSubmit}
        isLoading={isLoading}
        forceNumber={true}
      />
      <hr />
      <Row>
        <Col span={24}>
          <p className="mb-3">
            {t('profile.security.2fa.setup.email.alternative')}
          </p>
        </Col>
        <Col span={12}>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.email.title')}
          </h4>
          <p className="card-content">
            {t('profile.security.2fa.setup.email.description')}
          </p>
        </Col>

        <Col span={12} className="right-content">
          <Button
            onClick={() => props.history.push('/two-factor/setup-email')}
            className="button-primary size-l"
          >
            <p>{t('profile.security.2fa.select-button')}</p>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(TwoFactorSetup);
