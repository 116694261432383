import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Row, Col } from 'antd';
import * as _ from 'lodash';
import '../styles/program-view.less'
import { useTranslation } from 'react-i18next';

const ProgramServicesForm = (props) => {
  const { t } = useTranslation();

  const { program, services } = props;

  return (
    <Form id='program-services-form'>
      {services.map((p, index) => {
        const svc = program.services.find(
          (item) => item.service.code === p.code
        );

        return (
          <Row className='service service-bordered' key={index}>
            <Col xs={{ span: 12 }} sm={21} md={{ span: 12 }} lg={{ span: 12 }}>
              <p>{t(`services.verbose.${p.code}`)}</p>
            </Col>

            <Col
              xs={{ span: 2, offset: 10 }}
              sm={3}
              md={{ span: 2 }}
              lg={{ span: 2 }}
              className="text-center"
            >
              <Checkbox checked={svc !== undefined} disabled />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
};

export default ProgramServicesForm;
