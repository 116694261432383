import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, Alert, Checkbox } from 'antd';
import { signUpCompany } from '../redux/actions/auth';
import { PreferedLanguages, Publicidad, footerUrl } from '../constants/Data';
import * as _ from 'lodash';
import { fetchSectors } from '../redux/actions/currentUser';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;

class CompanySignUpForm extends Component {
  state = {
    signingUp: false,
    message: false,
    sectorList: [],
    confirmDirty: false,
    welcomeMessage: null,
    errorMessage: null,
    redirectLogin: false,
  };

  componentDidMount() {
    this.fetchSector();
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, isRequesting, hasError } = this.props;
    const { signingUp } = this.state;

    if (isRequesting && !nextProps.isRequesting) {
      if (signingUp) {
        this.showMessage(nextProps.hasError, nextProps.errorMessage);

        if (!nextProps.hasError) {
          this.setState({
            message: true,
            welcomeMessage: nextProps.errorMessage,
          });
        }

        window.scrollTo(0, 0);
      }
    }
  }

  showMessage = (hasError, message) => {
    this.setState({
      signingUp: !this.state.signingUp,
    });

    if (hasError) {
      this.setState({
        errorMessage: <span dangerouslySetInnerHTML={{ __html: message }} />,
      });
    }
  };

  closeMessage = () => {
    this.setState({ errorMessage: null });
  };

  welcomeMessage = () => {
    const { welcomeMessage } = this.state;

    return (
      <div
        style={{ border: '1px solid #4a4a4a', padding: '14px 14px 14px 14px' }}
      >
        <h2 className="text-center">¡Gracias por registrarte!</h2>
        <h5 className="text-center">Se te redirigirá al Inicio de Sesión</h5>
        <p
          className="text-center"
          style={{ color: '#000000', fontSize: '14px' }}
        >
          {welcomeMessage}
        </p>
      </div>
    );
  };

  handleConfirmPassword = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password1')) {
      callback(t('signup.hacker.pass-check2'));
    } else {
      callback();
    }
  };

  checkConfirmPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    const expression =
      '^(?:(?=.|\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&=+\\-_*]).*)$';
    const match = !value ? null : value.match(expression);

    if (value && match === null) {
      callback(t('signup.hacker.pass-check1'));
    } else if (value && this.state.confirmDirty) {
      form.validateFieldsAndScroll(['password2'], { force: true });
    }
    callback();
  };

  checkAgreementCheckBox = (rule, value, callback) => {
    const { t } = this.props;
    if (!value) {
      callback(t('signup.company.must-accept'));
    } else {
      callback();
    }
  };

  getLanguages = () => {
    return PreferedLanguages.map((p, index) => {
      return (
        <Option value={p.id} key={index.toString()}>
          {p.name}
        </Option>
      );
    });
  };

  fetchSector() {
    const { dispatch } = this.props;

    dispatch(fetchSectors());
  }

  getSectorList = () => {
    let sectors = [];

    if (this.props.sectors) {
      sectors = this.props.sectors.map((sector, index) => {
        return (
          <Option key={index} value={`${sector.code}`}>
            {sector.name}
          </Option>
        );
      });
    }

    return sectors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    this.setState({ signingUp: true });

    form.validateFields((err, values) => {
      if (!err) {
        dispatch(signUpCompany(values)).then(() => {
          if (!this.props.hasError) {
            setTimeout(() => {
              this.setState({ redirectLogin: true });
            }, 2000);
          }
        });
      } else {
        this.setState({ signingUp: false });
      }
    });
  };

  render() {
    const { t } = this.props;
    const sectors = this.getSectorList();
    const { getFieldDecorator } = this.props.form;

    const publicidad = Publicidad.map((p, index) => {
      return (
        <Option value={p.id} key={index}>
          {p.name}
        </Option>
      );
    });

    if (this.state.redirectLogin) {
      window.location.href = '/login';
    }

    return this.state.message ? (
      this.welcomeMessage()
    ) : (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {!_.isEmpty(this.state.errorMessage) ? (
          <Alert
            message=""
            description={this.state.errorMessage}
            type="error"
            closable
            onClose={this.closeMessage}
          />
        ) : (
          ''
        )}
        <Row gutter={16}>
          <Col sm={12}>
            <FormItem label={t('signup.company.name')}>
              {getFieldDecorator('first_name', {
                rules: [
                  {
                    required: true,
                    message: t('signup.hacker.name-placeholder'),
                  },
                ],
              })(<Input placeholder="John" className="col-sm-12" />)}
            </FormItem>
          </Col>

          <Col sm={12}>
            <FormItem label={t('signup.company.last-name')}>
              {getFieldDecorator('last_name', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.last-name-placeholder'),
                  },
                ],
              })(<Input placeholder="Smith" className="col-sm-12" />)}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={12}>
            <FormItem label={t('signup.company.email')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.email-message'),
                    type: 'email',
                  },
                ],
              })(<Input placeholder="johnsmith@cyscope.io" />)}
            </FormItem>
          </Col>

          <Col sm={12}>
            <FormItem label={t('signup.company.username')}>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.username-message'),
                  },
                  {
                    min: 5,
                    message: t('signup.company.username-message-check'),
                  },
                ],
              })(<Input placeholder="johnsmith" />)}
            </FormItem>
          </Col>
        </Row>

        <hr className="separator-form" />

        <Row gutter={16}>
          <Col sm={12}>
            <FormItem label={t('signup.company.company-name')}>
              {getFieldDecorator('company_name', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.company-name-message'),
                  },
                ],
              })(
                <Input
                  placeholder={t('signup.company.company-name-message')}
                  className="col-sm-12"
                />
              )}
            </FormItem>
          </Col>

          <Col sm={12}>
            <FormItem label={t('signup.company.job-title')}>
              {getFieldDecorator('position', {
                rules: [{ required: false }],
              })(<Input placeholder="CEO" className="col-sm-12" />)}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormItem label={t('signup.company.sector')}>
              {getFieldDecorator('sector', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.sector-message'),
                  },
                ],
              })(
                <Select
                  placeholder={t('signup.company.sector-message')}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sectors}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={12}>
            <FormItem label={t('signup.company.website')}>
              {getFieldDecorator('website', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.website-message'),
                  },
                ],
              })(
                <Input
                  placeholder="https://www.company.com"
                  className="col-sm-12"
                />
              )}
            </FormItem>
          </Col>
          <Col sm={12}>
            <FormItem label={t('signup.company.phone')}>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: false,
                    message: t('signup.company.phone-message'),
                  },
                ],
              })(<Input placeholder="2 1234 5678" className="col-sm-12" />)}
            </FormItem>
          </Col>
          <Col sm={12}>
            <FormItem label={t('signup.company.main-language')}>
              {getFieldDecorator('default_language', {
                rules: [
                  {
                    required: true,
                    message: t('signup.company.language-message'),
                  },
                ],
              })(
                <Select placeholder={t('signup.company.main-language-message')}>
                  {this.getLanguages()}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <hr className="separator-form" />

        <Row gutter={24}>
          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <FormItem label={t('signup.hacker.pass')}>
              {getFieldDecorator('password1', {
                rules: [
                  { required: true, message: t('signup.hacker.pass-message') },
                  { min: 8, message: t('signup.hacker.pass-check3') },
                  { validator: this.checkConfirmPassword },
                ],
              })(<Input type="password" autoComplete="new-password" />)}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <FormItem label={t('signup.hacker.new-pass2')}>
              {getFieldDecorator('password2', {
                rules: [
                  {
                    required: true,
                    message: t('signup.hacker.new-pass2-message'),
                  },
                  { validator: this.checkPassword },
                ],
              })(
                <Input
                  type="password"
                  onBlur={this.handleConfirmPassword}
                  autoComplete="new-password"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem style={{ marginBottom: 8 }}>
          {getFieldDecorator('agreement', {
            valuePropName: 'checked',
            rules: [{ validator: this.checkAgreementCheckBox }],
          })(
            <Checkbox style={{ color: '#757575' }}>
              {t('signup.company.accept')}{' '}
              <a
                style={{ color: '#304678' }}
                href={footerUrl.legal.TAC}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('signup.company.terms')}
              </a>
            </Checkbox>
          )}
        </FormItem>

        <hr className="separator-form separator-form-margin" />

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-sign-up btn-company pull-right"
            loading={this.state.signingUp}
          >
            {t('signup.company.save-button')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const CNormalSignUpForm = Form.create()(CompanySignUpForm);

const mapStateToProps = (state) => ({
  auth: state.auth,
  isRequesting: state.auth.requesting,
  hasError: state.auth.hasError,
  errorMessage: state.auth.errorMessage,
  sectors: state.currentUser.sectors,
});
export default withTranslation()(connect(mapStateToProps)(CNormalSignUpForm));
