
export const countryFlags = (countryCode) => {
    const flags = {
        // AMERICA
        AR: '🇦🇷', // Argentina
        BO: '🇧🇴', // Bolivia
        BR: '🇧🇷', // Brazil
        CL: '🇨🇱', // Chile
        CO: '🇨🇴', // Colombia
        EC: '🇪🇨', // Ecuador
        PY: '🇵🇾', // Paraguay
        PE: '🇵🇪', // Peru
        UY: '🇺🇾', // Uruguay
        VE: '🇻🇪', // Venezuela
        SR: '🇸🇷', // Suriname
        GY: '🇬🇾', // Guyana
        BZ: '🇧🇿', // Belize
        CR: '🇨🇷', // Costa Rica
        SV: '🇸🇻', // El Salvador
        GT: '🇬🇹', // Guatemala
        HN: '🇭🇳', // Honduras
        NI: '🇳🇮', // Nicaragua
        PA: '🇵🇦', // Panama
        CU: '🇨🇺', // Cuba
        DO: '🇩🇴', // Dominican Republic
        US: '🇺🇸', // United States
        CA: '🇨🇦', // Canada
        MX: '🇲🇽', // Mexico
        // EUROPA
        AL: '🇦🇱', // Albania
        AD: '🇦🇩', // Andorra
        AT: '🇦🇹', // Austria
        BY: '🇧🇾', // Belarus
        BE: '🇧🇪', // Belgium
        BA: '🇧🇦', // Bosnia and Herzegovina
        BG: '🇧🇬', // Bulgaria
        HR: '🇭🇷', // Croatia
        CY: '🇨🇾', // Cyprus
        CZ: '🇨🇿', // Czech Republic
        DK: '🇩🇰', // Denmark
        EE: '🇪🇪', // Estonia
        FI: '🇫🇮', // Finland
        FR: '🇫🇷', // France
        DE: '🇩🇪', // Germany
        GR: '🇬🇷', // Greece
        HU: '🇭🇺', // Hungary
        IS: '🇮🇸', // Iceland
        IE: '🇮🇪', // Ireland
        IT: '🇮🇹', // Italy
        LV: '🇱🇻', // Latvia
        LI: '🇱🇮', // Liechtenstein
        LT: '🇱🇹', // Lithuania
        LU: '🇱🇺', // Luxembourg
        MT: '🇲🇹', // Malta
        MD: '🇲🇩', // Moldova
        MC: '🇲🇨', // Monaco
        ME: '🇲🇪', // Montenegro
        NL: '🇳🇱', // Netherlands
        MK: '🇲🇰', // North Macedonia
        NO: '🇳🇴', // Norway
        PL: '🇵🇱', // Poland
        PT: '🇵🇹', // Portugal
        RO: '🇷🇴', // Romania
        RU: '🇷🇺', // Russia
        SM: '🇸🇲', // San Marino
        RS: '🇷🇸', // Serbia
        SK: '🇸🇰', // Slovakia
        SI: '🇸🇮', // Slovenia
        ES: '🇪🇸', // Spain
        SE: '🇸🇪', // Sweden
        CH: '🇨🇭', // Switzerland
        UA: '🇺🇦', // Ukraine
        GB: '🇬🇧', // United Kingdom
        VA: '🇻🇦', // Vatican City
        // AFRICA
        DZ: '🇩🇿', // Algeria
        AO: '🇦🇴', // Angola
        BJ: '🇧🇯', // Benin
        BW: '🇧🇼', // Botswana
        BF: '🇧🇫', // Burkina Faso
        BI: '🇧🇮', // Burundi
        CM: '🇨🇲', // Cameroon
        CV: '🇨🇻', // Cape Verde
        CF: '🇨🇫', // Central African Republic
        TD: '🇹🇩', // Chad
        KM: '🇰🇲', // Comoros
        CG: '🇨🇬', // Congo - Brazzaville
        CD: '🇨🇩', // Congo - Kinshasa
        DJ: '🇩🇯', // Djibouti
        EG: '🇪🇬', // Egypt
        GQ: '🇬🇶', // Equatorial Guinea
        ER: '🇪🇷', // Eritrea
        ET: '🇪🇹', // Ethiopia
        GA: '🇬🇦', // Gabon
        GM: '🇬🇲', // Gambia
        GH: '🇬🇭', // Ghana
        GN: '🇬🇳', // Guinea
        GW: '🇬🇼', // Guinea-Bissau
        CI: '🇨🇮', // Ivory Coast
        KE: '🇰🇪', // Kenya
        LS: '🇱🇸', // Lesotho
        LR: '🇱🇷', // Liberia
        LY: '🇱🇾', // Libya
        MG: '🇲🇬', // Madagascar
        MW: '🇲🇼', // Malawi
        ML: '🇲🇱', // Mali
        MR: '🇲🇷', // Mauritania
        MU: '🇲🇺', // Mauritius
        MA: '🇲🇦', // Morocco
        MZ: '🇲🇿', // Mozambique
        NA: '🇳🇦', // Namibia
        NE: '🇳🇪', // Niger
        NG: '🇳🇬', // Nigeria
        RW: '🇷🇼', // Rwanda
        ST: '🇸🇹', // Sao Tome and Principe
        SN: '🇸🇳', // Senegal
        SC: '🇸🇨', // Seychelles
        SL: '🇸🇱', // Sierra Leone
        SO: '🇸🇴', // Somalia
        ZA: '🇿🇦', // South Africa
        SS: '🇸🇸', // South Sudan
        SD: '🇸🇩', // Sudan
        SZ: '🇸🇿', // Eswatini
        TZ: '🇹🇿', // Tanzania
        TG: '🇹🇬', // Togo
        TN: '🇹🇳', // Tunisia
        UG: '🇺🇬', // Uganda
        ZM: '🇿🇲', // Zambia
        ZW: '🇿🇼', // Zimbabwe
        // ASIA
        AF: '🇦🇫', // Afghanistan
        AM: '🇦🇲', // Armenia
        AZ: '🇦🇿', // Azerbaijan
        BH: '🇧🇭', // Bahrain
        BD: '🇧🇩', // Bangladesh
        BT: '🇧🇹', // Bhutan
        BN: '🇧🇳', // Brunei
        KH: '🇰🇭', // Cambodia
        CN: '🇨🇳', // China
        CY: '🇨🇾', // Cyprus
        GE: '🇬🇪', // Georgia
        IN: '🇮🇳', // India
        ID: '🇮🇩', // Indonesia
        IR: '🇮🇷', // Iran
        IQ: '🇮🇶', // Iraq
        IL: '🇮🇱', // Israel
        JP: '🇯🇵', // Japan
        JO: '🇯🇴', // Jordan
        KZ: '🇰🇿', // Kazakhstan
        KW: '🇰🇼', // Kuwait
        KG: '🇰🇬', // Kyrgyzstan
        LA: '🇱🇦', // Laos
        LB: '🇱🇧', // Lebanon
        MY: '🇲🇾', // Malaysia
        MV: '🇲🇻', // Maldives
        MN: '🇲🇳', // Mongolia
        MM: '🇲🇲', // Myanmar (Burma)
        NP: '🇳🇵', // Nepal
        KP: '🇰🇵', // North Korea
        OM: '🇴🇲', // Oman
        PK: '🇵🇰', // Pakistan
        PS: '🇵🇸', // Palestine
        PH: '🇵🇭', // Philippines
        QA: '🇶🇦', // Qatar
        SA: '🇸🇦', // Saudi Arabia
        SG: '🇸🇬', // Singapore
        KR: '🇰🇷', // South Korea
        LK: '🇱🇰', // Sri Lanka
        SY: '🇸🇾', // Syria
        TW: '🇹🇼', // Taiwan
        TJ: '🇹🇯', // Tajikistan
        TH: '🇹🇭', // Thailand
        TR: '🇹🇷', // Turkey
        TM: '🇹🇲', // Turkmenistan
        AE: '🇦🇪', // United Arab Emirates
        UZ: '🇺🇿', // Uzbekistan
        VN: '🇻🇳', // Vietnam
        YE: '🇾🇪', // Yemen
        // OCEANIA
        AU: '🇦🇺', // Australia
        NZ: '🇳🇿', // New Zealand
    }
    return flags[countryCode.toUpperCase()]
  }
  