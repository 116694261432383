import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { PrivateRoute, PublicRoute } from './routes';
import {
  updateLanguage,
  getUserAndAbilities,
} from './redux/actions/currentUser';
import { getSignOut } from './redux/actions/auth';

import { getInitState } from './constants/Data';
import withSizes from 'react-sizes';

import Login from './containers/Login';
import EmployeeSignUp from './containers/EmployeeSignUp';
import PasswordResetRequest from './containers/PasswordResetRequest';
import PasswordReset from './containers/PasswordReset';
import AccountConfirmation from './components/AccountConfirmation';
import Overview from './containers/Overview';
import Programs from './containers/Programs';
import Rewards from './containers/Rewards';
import FinanceContainer from './containers/FinanceContainer';
import ProgramHistory from './containers/ProgramHistory';
import Report from './containers/Report';
import Program from './containers/Program';
import ProgramContainer from './containers/ProgramContainer';
import SignUp from './containers/SignUp';
import NotFound from './containers/NotFound';
import RankingProfile from './containers/RankingProfile';
import Logs from './containers/Logs';
import WalletHistory from './containers/WalletHistory';
import ReportViewDefault from './containers/ReportViewDefault';
import SecondFactor from './containers/SecondFactor';
import ReferCustomer from './containers/ReferCustomer';
import ConversionHistory from './containers/ConversionHistory';
import UserProfile from './containers/UserProfile';

import { mapSizes } from './mapSizes';

import ChangeLanguage from './components/ChangeLanguage';
import Reports from './containers/Reports';
import Ranking from './containers/Ranking';
import RankingGuide from './components/RankingGuide';

import AcademyIndex from './containers/AcademyIndex';
import AcademyDetail from './containers/AcademyDetail';
import AcademyHackRock from './components/AcademyHackRock';
import AcademyHackTheBox from './components/AcademyHackTheBox';
import AcademyStationX from './components/AcademyStationX';

import { isCompanyMode } from './utils/General';
import LoaderComponent from './components/LoaderComponent';
import LayoutContainer from './containers/LayoutContainer';
import TwoFactor from './containers/TwoFactor';
import TwoFactorBackupCodes from './components/TwoFactorBackupCodes';
import TwoFactorSetup from './components/TwoFactorSetup';
import TwoFactorOptions from './components/TwoFactorOptions';
import TwoFactorAuth from './containers/TwoFactorAuth';
import TwoFactorValidation from './components/TwoFactorValidation';

const { TwoFactorFinish, TwoFactorEmail, TwoFactorRecover, TwoFactorReminder } =
  TwoFactorOptions;

const history = createBrowserHistory();

class App extends Component {
  state = Object.assign(
    {
      firstPath: history.location.pathname,
    },
    getInitState()
  );

  componentDidMount() {
    const { hasSession, dispatch } = this.props;
    if (hasSession) {
      dispatch(getUserAndAbilities());
    }
  }

  componentDidUpdate(prevProps) {
    const { hasSession, dispatch, currentUser } = this.props;
    const { firstPath } = this.state;
    const {
      location: { pathname },
    } = history;
    const bodyElement = document.getElementsByTagName('body')[0];

    if (prevProps.isTinyMobile !== this.props.isTinyMobile) {
      this.props.isTinyMobile
        ? bodyElement.classList.add('tiny-smartphone')
        : bodyElement.classList.remove('tiny-smartphone');
    }

    if (prevProps.isMobile !== this.props.isMobile) {
      this.props.isMobile
        ? bodyElement.classList.add('smartphone')
        : bodyElement.classList.remove('smartphone');
    }

    if (prevProps.isTablet !== this.props.isTablet) {
      this.props.isTablet
        ? bodyElement.classList.add('tablet')
        : bodyElement.classList.remove('tablet');
    }

    if (prevProps.isDesktop !== this.props.isDesktop) {
      this.props.isDesktop
        ? bodyElement.classList.add('desktop')
        : bodyElement.classList.remove('desktop');
    }

    if (prevProps.isBigDesktop !== this.props.isBigDesktop) {
      this.props.isBigDesktop
        ? bodyElement.classList.add('big-desktop')
        : bodyElement.classList.remove('big-desktop');
    }

    if (prevProps.hasSession !== hasSession) {
      if (hasSession) {
        if (
          !this.props.secondFactorEnabled ||
          (this.props.secondFactorEnabled && this.props.secondValidated)
        ) {
          dispatch(getUserAndAbilities());
        }
      } else {
        dispatch(getSignOut());
      }
    }

    if (prevProps.currentUser.role !== currentUser.role) {
      if (!currentUser.role) {
        this.cleanState();
        bodyElement.id = '';
        this.setState(getInitState());
      } else {
        if (firstPath && firstPath !== '/login' && firstPath !== pathname) {
          this.setState({ firstPath: null });
          history.push(firstPath);
        }
        bodyElement.id = isCompanyMode(currentUser)
          ? 'companyLayout'
          : 'hackerLayout';
      }
    }
  }

  handleLanguageChange = (lang) => {
    const { changeLanguage, dispatch, hasSession } = this.props;
    changeLanguage(lang);
    if (hasSession) {
      dispatch(updateLanguage({ default_language: lang }));
    }
  };

  render() {
    const {
      isHydrated,
      hasSession,
      secondFactorEnabled,
      secondValidated,
      changeLanguage,
    } = this.props;

    if (!isHydrated) {
      return <LoaderComponent />;
    }

    const routeProps = {
      authed: hasSession,
      second: secondFactorEnabled,
      secondValidated: secondValidated,
    };

    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <PublicRoute path="/login" component={Login} {...routeProps} />
            <PublicRoute
              path="/signup/:type"
              component={SignUp}
              {...routeProps}
            />
            <PublicRoute
              path="/account/activated"
              component={AccountConfirmation}
              {...routeProps}
            />
            <PublicRoute
              path="/account/noactivated"
              component={AccountConfirmation}
              {...routeProps}
            />
            <PublicRoute
              path="/company/invitation"
              component={EmployeeSignUp}
              {...routeProps}
            />
            <PublicRoute
              path="/passwords/reset/request"
              component={PasswordResetRequest}
              {...routeProps}
            />
            <PublicRoute
              path="/users/password_reset"
              component={PasswordReset}
              {...routeProps}
            />
            <PrivateRoute
              path="/authenticator"
              component={SecondFactor}
              isAuthenticated={hasSession}
            />
            <PrivateRoute
              path="/two-factor"
              component={TwoFactor}
              isAuthenticated={hasSession}
            >
              <Switch>
                <PrivateRoute
                  path="/two-factor/setup"
                  component={TwoFactorSetup}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/two-factor/backup-codes"
                  component={TwoFactorBackupCodes}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/two-factor/setup-email"
                  component={TwoFactorEmail}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/two-factor/enabled"
                  component={TwoFactorFinish}
                  isAuthenticated={hasSession}
                />
              </Switch>
            </PrivateRoute>
            <PrivateRoute
              path="/auth"
              component={TwoFactorAuth}
              isAuthenticated={hasSession}
            >
              <Switch>
                <PrivateRoute
                  path="/auth/reminder"
                  component={TwoFactorReminder}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/auth/two-factor"
                  component={TwoFactorValidation}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/auth/recover"
                  component={TwoFactorRecover}
                  isAuthenticated={hasSession}
                />
              </Switch>
            </PrivateRoute>

            <PrivateRoute
              path={['/dashboard', '/user', '/company']}
              component={LayoutContainer}
              isAuthenticated={hasSession}
            >
              <Switch>
                <PrivateRoute
                  path={['/user', '/company']}
                  component={UserProfile}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/my-programs"
                  component={Overview}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/logs"
                  component={Logs}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/rewards"
                  component={Rewards}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/finance/history/bugbounty"
                  component={WalletHistory}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/finance/history/ptaas"
                  component={WalletHistory}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/finance/conversion-history"
                  component={ConversionHistory}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/finance"
                  component={FinanceContainer}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/programs/history/:slug"
                  component={ProgramHistory}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/programs/:id"
                  component={ProgramContainer}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/programs"
                  component={Programs}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/program/ptaas/create"
                  isPtaas={true}
                  isEditing={false}
                  exact={true}
                  component={Program}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/program/bugbounty/create"
                  isPtaas={false}
                  isEditing={false}
                  exact={true}
                  component={Program}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/program/:id/update"
                  isEditing={true}
                  component={Program}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/reports/create"
                  component={Report}
                  isAuthenticated={hasSession}
                  isEditing={false}
                />
                <PrivateRoute
                  path="/dashboard/reports/:id/update"
                  component={Report}
                  isAuthenticated={hasSession}
                  isEditing={true}
                />
                <PrivateRoute
                  path="/dashboard/reports/:id"
                  component={ReportViewDefault}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/report/:id"
                  component={ReportViewDefault}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/reports"
                  component={Reports}
                  exact={false}
                  isAuthenticated={hasSession}
                />

                <PrivateRoute
                  path="/dashboard/ranking/guide"
                  component={RankingGuide}
                  exact={true}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/ranking/:slug"
                  component={RankingProfile}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard/ranking"
                  component={Ranking}
                  isAuthenticated={hasSession}
                />
                <Route path="/dashboard/academy" exact={false}>
                  <Switch>
                    <PrivateRoute
                      path={`/dashboard/academy/hackthebox`}
                      isAuthenticated={hasSession}
                      exact
                      component={AcademyDetail}
                      render={() => <AcademyHackTheBox {...routeProps} />}
                    />
                    <PrivateRoute
                      path={`/dashboard/academy/stationx`}
                      isAuthenticated={hasSession}
                      exact
                      component={AcademyDetail}
                      render={() => <AcademyStationX {...routeProps} />}
                    />
                    <PrivateRoute
                      path={`/dashboard/academy/hackrock`}
                      isAuthenticated={hasSession}
                      exact
                      component={AcademyDetail}
                      render={() => <AcademyHackRock {...routeProps} />}
                    />
                    <Route
                      path={`/dashboard/academy`}
                      component={AcademyIndex}
                      exact
                      {...routeProps}
                    />
                  </Switch>
                </Route>
                <PrivateRoute
                  path="/dashboard/refer-customer"
                  component={ReferCustomer}
                  isAuthenticated={hasSession}
                />
                <PrivateRoute
                  path="/dashboard"
                  component={Overview}
                  isAuthenticated={hasSession}
                />
              </Switch>
            </PrivateRoute>
            <PublicRoute path="/" component={Login} {...routeProps} exact />
            <Route path="" component={NotFound} {...routeProps} />
          </Switch>
        </Router>
        <div style={styles.container}>
          <ChangeLanguage
            handleLanguageChange={this.handleLanguageChange}
            changeLanguage={changeLanguage}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  appContainer: {
    position: 'relative',
  },
  container: {
    position: 'fixed',
    bottom: '10px',
    left: '10px',
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Negro con opacidad
    padding: '5px', // Ajusta el relleno según tus preferencias
    borderRadius: '5px', // Ajusta el radio de la esquina según tus preferencias
  },
  transparentSelect: {
    width: '100%', // O ajusta según tus preferencias
    opacity: '0.7', // Ajusta la opacidad según tus preferencias
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
  },
  option: {
    backgroundColor: 'white',
    color: 'black', // Cambia el color del texto dentro de las opciones
  },
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  hasSession: state.auth.loggedIn,
  secondFactorEnabled: state.auth.secondFactorEnabled,
  isHydrated: state.persist.isHydrated,
  secondValidated: state.auth.secondValidated,
});

export default withSizes(mapSizes)(connect(mapStateToProps)(App));
