import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Checkbox,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Tooltip,
  Popconfirm,
  message,
} from 'antd';
import { Skills } from '../constants/Data';
import { fetchProfile, updateSkills } from '../redux/actions/currentUser';
import withSizes from 'react-sizes';
import * as _ from 'lodash';
import { addMediaProgram } from '../redux/actions/program';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';
import { openNotification } from '../utils/General';

import WrappedFileForm from './forms/FileForm';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';
import LoaderComponent from './LoaderComponent';

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;

class SkillsForm extends Component {
  state = {
    fileList: [],
    file: null,
    fileId: null,
    loading: false,
    certificates_count: 0,
    profile: null,
    certifications: null,
  };

  componentDidMount() {
    this.getProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      form: { setFieldsValue },
    } = this.props;

    if (prevState.profile !== this.state.profile) {
      let certifications = this.state.profile.certifications;
    }
  }

  getProfile = async () => {
    try {
      this.setState({ loading: true });
      const response = await axiosInstance.get(`/profile/`);
      this.setState({ profile: response.data });
    } catch (error) {
      handleAxiosError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  getSkills = () => {
    return (
      <Row>
        {Skills.map((p, index) => {
          return (
            <Col sm={12} key={index}>
              <Checkbox value={p.code} className="mb-3">
                {p.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({ loading: true });
          const response = await axiosInstance.patch(`/profile/`, values);
          this.setState({ profile: response.data });
          openNotification(
            'success',
            'Tus skills han sido actualizados exitosamente',
            ''
          );
        } catch (error) {
          handleAxiosError(error);
        } finally {
          this.setState({ loading: false });
        }
      }
    });
  };

  render() {
    const { form, t } = this.props;
    const { getFieldDecorator } = form;

    if (this.state.loading || this.state.profile === null)
      return <LoaderComponent />;

    const certifications = this.state.profile.certifications.map((item) => {
      return {
        id: item.id,
        name: item.name,
        file_id: item.file.id,
      };
    });
    const extraInfo = this.state.profile.certifications.map((item) => {
      return {
        name: item.name,
        files: [
          {
            uid: item.file.id,
            name: item.file.name,
            url: item.file.url,
            status: 'done',
          },
        ],
      };
    });
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <h4 className="profile-title">{t('profile.skills.title')}</h4>
        <p className="p-description mb-3">{t('profile.skills.desc')}</p>

        <FormItem>
          {getFieldDecorator('skills', {
            initialValue: this.state.profile.skills,
            rules: [
              { required: true, message: t('profile.skills.skill-message') },
            ],
          })(<CheckboxGroup>{this.getSkills()}</CheckboxGroup>)}
        </FormItem>

        <h4 className="profile-title">{t('profile.skills.certificates')}</h4>
        <p className="p-description">
          {t('profile.skills.certificates-desc')}
          <br />
          <span style={{ fontSize: '10px' }}>{t('profile.skills.format')}</span>
        </p>

        <FormItem>
          {getFieldDecorator('certifications', {
            initialValue: certifications,
            rules: [
              { required: true, message: t('profile.skills.skill-message') },
            ],
          })(<WrappedFileForm extraInfo={extraInfo} />)}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-hacker btn-block mb-3"
          loading={this.state.loading}
        >
          <p className="p-button">{t('profile.save-button')}</p>
        </Button>
      </Form>
    );
  }
}

const NormalSkillsForm = Form.create({
  mapPropsToFields(props) {
    return {
      skills: {
        value: props.profile.skills,
      },
    };
  },
})(SkillsForm);

const mapStateToProps = (state) => ({
  files: state.program.files,
  hasError: state.program.hasError,
  success: state.currentUser.success,
  errorMessage: state.currentUser.errorMessage,
});
export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(NormalSkillsForm))
);
