import React, { Component } from 'react';
import { Col, Image, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AcademyIndex = (props) => {
  const { t } = useTranslation();

  const mdSize = window.innerWidth >= 768 && window.innerWidth < 992;

  return (
    <Row justify="center" align="middle">
      <Col
        lg={{ span: 18, offset: 3 }}
        sm={{ span: 22, offset: 1 }}
        xs={{ span: 22, offset: 1 }}
      >
        <Row className="section-title">
          <Col
            xl={{ span: 12, offset: 0 }}
            lg={{ span: 10, offset: 0 }}
            md={{ span: 10, offset: 0 }}
            sm={{ span: 20, offset: 1 }}
            xs={{ span: 20, offset: 0 }}
          >
            <h1> {t('academy.benefits-label')}</h1>
            <h3>{t('academy.benefits-description')}</h3>

            <p
              style={{
                marginTop: '10%',
                fontSize: '36px',
                textTransform: 'uppercase',
                color: '#0018F5',
              }}
            >
              {t('academy.offers')}
            </p>
          </Col>
          <Col
            xl={{ span: 10, offset: 2 }}
            lg={{ span: 10, offset: 2 }}
            md={{ span: 10, offset: 1 }}
            sm={{ span: 20, offset: 3 }}
            xs={{ span: 20, offset: 3 }}
          >
            <Link to={'/dashboard/academy/hackthebox'}>
              <Image
                style={{
                  width: mdSize ? '70%' : '80%',
                  display: 'block',
                  marginTop: window.innerWidth < 992 ? '10vh' : '10vh',
                  marginLeft: window.innerWidth < 992 ? '0vh' : '13vh',
                  transition: 'transform 0.3s',
                }}
                src="/images/hackthebox.png"
                preview={false}
                onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </Link>
            <Link to={'/dashboard/academy/hackrock'}>
              <Image
                style={{
                  width: mdSize ? '70%' : '80%',
                  display: 'block',
                  marginTop: '6vh',
                  transition: 'transform 0.3s',
                }}
                src="/images/hackrocks.png"
                preview={false}
                onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </Link>
            <Link to={'/dashboard/academy/stationx'}>
              <Image
                style={{
                  width: mdSize ? '70%' : '80%',
                  display: 'block',
                  marginTop: '6vh',
                  marginLeft: window.innerWidth < 992 ? '0vh' : '13vh',
                  marginBottom: '5vh',
                  transition: 'transform 0.3s',
                }}
                src="/images/stationx.png"
                preview={false}
                onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
              />
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withTranslation()(withRouter(AcademyIndex));
