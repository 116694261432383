import * as Action from '../actions/ActionTypes';

const initialState = {
  record: null,
  requesting: null,
  message: null,
  hasError: null,
};

class ProgramFeatureReducer {
  static reduce(state = initialState, action) {
    if (ProgramFeatureReducer[action.type]) {
      return ProgramFeatureReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.GET_PROGRAM_FEATURE_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.GET_PROGRAM_FEATURE_SUCCESS](state, action) {
    const { result } = action.response;
    const { files } = result;

    return {
      ...state,
      requesting: false,
      hasError: false,
      record: result,
    };
  }

  static [Action.GET_PROGRAM_FEATURE_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      message: action.error,
    };
  }

  static [Action.DELETE_PROGRAM_FEATURE_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.DELETE_PROGRAM_FEATURE_SUCCESS](state, action) {
    const { result, message } = action.response;

    return {
      ...state,
      requesting: false,
      hasError: false,
      message: message,
      recoerd: null,
    };
  }

  static [Action.DELETE_PROGRAM_FEATURE_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      message: action.error,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default ProgramFeatureReducer.reduce;
