import * as Action from './ActionTypes';
import { routes } from '../../constants/APIEndpoints';
import { CALL_API } from '../../middleware/api';

const getPublicPrograms = (params) => {
  return {
    [CALL_API]: {
      types: [
        Action.LIST_PROGRAMS_REQUEST,
        Action.LIST_PROGRAMS_SUCCESS,
        Action.LIST_PROGRAMS_ERRORS,
      ],
      endpoint: routes.PUBLIC_PROGRAMS,
      method: 'GET',
      data: {},
      params: params,
    },
  };
};

const getMyPrograms = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_PROGRAMS_REQUEST,
      Action.LIST_PROGRAMS_SUCCESS,
      Action.LIST_PROGRAMS_ERRORS,
    ],
    endpoint: routes.MY_PROGRAMS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const getMyProgramsFiltered = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_PROGRAMS_FILTERED_REQUEST,
      Action.LIST_PROGRAMS_FILTERED_SUCCESS,
      Action.LIST_PROGRAMS_FILTERED_ERRORS,
    ],
    endpoint: routes.MY_PROGRAMS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const getFinancePrograms = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_FINANCE_PROGRAMS_REQUEST,
      Action.LIST_FINANCE_PROGRAMS_SUCCESS,
      Action.LIST_FINANCE_PROGRAMS_ERRORS,
    ],
    endpoint: routes.FINANCE_PROGRAMS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const getFinanceProgramsPtaas = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_FINANCE_PROGRAMS_PTAAS_REQUEST,
      Action.LIST_FINANCE_PROGRAMS_PTAAS_SUCCESS,
      Action.LIST_FINANCE_PROGRAMS_PTAAS_ERRORS,
    ],
    endpoint: routes.FINANCE_PROGRAMS_PTAAS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const exportFinancePrograms = (params) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_FINANCE_PROGRAMS_REQUEST,
      Action.EXPORT_FINANCE_PROGRAMS_SUCCESS,
      Action.EXPORT_FINANCE_PROGRAMS_ERRORS,
    ],
    endpoint: routes.FINANCE_PROGRAMS,
    method: 'GET',
    data: {},
    params: params,
    responseType: 'blob',
  },
});

export const subscribeAllPublicPrograms = (params) => (dispatch, getState) => {
  return dispatch(getPublicPrograms(params));
};

export const subscribeAllMyPrograms = (params) => (dispatch, getState) => {
  return dispatch(getMyPrograms(params));
};

export const subscribeAllMyProgramsFiltered =
  (params) => (dispatch, getState) => {
    return dispatch(getMyProgramsFiltered(params));
  };

export const subscribeFinancePrograms = (params) => (dispatch, getState) => {
  return dispatch(getFinancePrograms(params));
};

export const subscribeFinanceProgramsPtaas =
  (params) => (dispatch, getState) => {
    return dispatch(getFinanceProgramsPtaas(params));
  };

export const getProgramFinanceForDownload =
  (params) => (dispatch, getState) => {
    return dispatch(exportFinancePrograms(params));
  };
