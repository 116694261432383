import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Col, Input, Row, Select } from 'antd';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const FormItem = Form.Item;
const Option = Select.Option;
class FilterProgramForm extends Component {
  static defaultProps = {
    renderCreateButton: () => {},
  };

  showMessage = (type) => {
    const { t } = this.props;
    let message;
    switch (type) {
      case 'ptaas':
        message = t('programs.create-modal.ptaas.not-available');
        break;
      case 'bugbounty':
        message = t('programs.create-modal.bugbounty.not-available');
        break;
    }
    openNotification('info', message, t('programs.create-modal.alert'));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, filters } = this.props;

    return (
      <Card style={{ marginBottom: '2vh', paddingBottom: '-20px' }}>
        <h6>
          <b>{t('dashboard.programs.title')}</b>
        </h6>
        <Form className="filter-form" style={{ marginTop: '3vh' }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} md={6}>
              <FormItem>
                {getFieldDecorator('search', {
                  initialValue: (filters && filters.search) || '',
                })(
                  <Input
                    placeholder={t('programs.filters.search-box')}
                    name="search"
                    onChange={(e) => this.props.onSearchChange(e.target.value)}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem>
                {getFieldDecorator('status', {
                  initialValue: filters && filters.status,
                })(
                  <Select
                    allowClear
                    placeholder={t('programs.filters.status')}
                    name="status"
                    onChange={(value) =>
                      this.props.onFilterChange('status', value)
                    }
                  >
                    <Option value={'active'}>
                      {t('programs.status.active')}
                    </Option>
                    {this.props.showInactive && [
                      <Option value={'inactive'} key="inactive">
                        {t('programs.status.inactive')}
                      </Option>,
                    ]}
                    {this.props.showClosed && [
                      <Option value={'closed'} key="closed">
                        {t('programs.status.closed')}
                      </Option>,
                    ]}
                    <Option value={'paused'}>
                      {t('programs.status.paused')}
                    </Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              <FormItem>
                {getFieldDecorator('is_ptaas', {
                  initialValue: this.props.filters.is_ptaas,
                })(
                  <Select
                    allowClear
                    placeholder={t('reports.filters.program_type.title')}
                    onChange={(value) =>
                      this.props.onFilterChange('is_ptaas', value)
                    }
                  >
                    <Option value={true}>PTaaS</Option>
                    <Option value={false}>Bug Bounty</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} md={6}>
              {this.props.renderCreateButton()}
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

const WrappedFilterProgramForm = Form.create()(FilterProgramForm);
export default withTranslation()(withRouter(WrappedFilterProgramForm));
