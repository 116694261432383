import React, { Component } from 'react';
import Login from '../containers/Login';

class PasswordResetRequest extends Component {
  render() {
    return (
      <Login resetPasswordRequest={true} history={this.props.history}></Login>
    );
  }
}

export default PasswordResetRequest;
