import * as Action from './ActionTypes';
import { routes } from '../../constants/APIEndpoints';
import { CALL_API } from '../../middleware/api';

const callWalletStatus = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_WALLET_STATUS_REQUEST,
      Action.GET_WALLET_STATUS_SUCCESS,
      Action.GET_WALLET_STATUS_ERRORS,
    ],
    endpoint: routes.GET_WALLET_STATUS,
    method: 'POST',
    data: params,
  },
});
const callAddWalletFunds = (params) => ({
  [CALL_API]: {
    types: [
      Action.PUT_WALLET_FUNDS_REQUEST,
      Action.PUT_WALLET_FUNDS_SUCCESS,
      Action.PUT_WALLET_FUNDS_ERRORS,
    ],
    endpoint: routes.ADD_WALLET_FUNDS,
    method: 'POST',
    data: params,
  },
});

const callAddWalletCredits = (params) => ({
  [CALL_API]: {
    types: [
      Action.ADD_WALLET_CREDITS_REQUEST,
      Action.ADD_WALLET_CREDITS_SUCCESS,
      Action.ADD_WALLET_CREDITS_ERRORS,
    ],
    endpoint: routes.ADD_WALLET_CREDITS,
    method: 'POST',
    data: params,
  },
});

const callAddProgramFunds = (params, id) => ({
  [CALL_API]: {
    types: [
      Action.PUT_PROGRAM_FUNDS_REQUEST,
      Action.PUT_PROGRAM_FUNDS_SUCCESS,
      Action.PUT_PROGRAM_FUNDS_ERRORS,
    ],
    endpoint: routes.ADD_PROGRAM_FUNDS.replace(':id', id),
    method: 'POST',
    data: params,
  },
});

const callGetProgramHistory = (uuid, params) => ({
  [CALL_API]: {
    types: [
      Action.GET_PROGRAM_HISTORY_REQUEST,
      Action.GET_PROGRAM_HISTORY_SUCCESS,
      Action.GET_PROGRAM_HISTORY_ERRORS,
    ],
    endpoint: routes.GET_PROGRAM_HISTORY.replace(':id', uuid),
    method: 'GET',
    data: {},
    params: params,
  },
});

const callGetWalletHistory = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_HISTORYWALLET_REQUEST,
      Action.GET_HISTORYWALLET_SUCCESS,
      Action.GET_HISTORYWALLET_ERRORS,
    ],
    endpoint: routes.GET_HISTORYWALLET,
    method: 'GET',
    data: {},
    params: params,
  },
});

const callGetWalletHistoryPtaas = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_HISTORY_WALLET_PTAAS_REQUEST,
      Action.GET_HISTORY_WALLET_PTAAS_SUCCESS,
      Action.GET_HISTORY_WALLET_PTAAS_ERRORS,
    ],
    endpoint: routes.GET_HISTORY_WALLET_PTAAS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const callgetCommission = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_COMMISSION_REQUEST,
      Action.GET_COMMISSION_SUCCESS,
      Action.GET_COMMISSION_ERRORS,
    ],
    endpoint: routes.GET_COMMISSION,
    method: 'POST',
    data: params,
  },
});

const exportWalletHistory = (params) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_WALLET_HISTORY_REQUEST,
      Action.EXPORT_WALLET_HISTORY_SUCCESS,
      Action.EXPORT_WALLET_HISTORY_ERRORS,
    ],
    endpoint: routes.GET_HISTORYWALLET,
    method: 'GET',
    data: {},
    params: params,
    responseType: 'blob',
  },
});

const exportProgramHistory = (params, uuid) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_PROGRAM_HISTORY_REQUEST,
      Action.EXPORT_PROGRAM_HISTORY_SUCCESS,
      Action.EXPORT_PROGRAM_HISTORY_ERRORS,
    ],
    endpoint: routes.GET_PROGRAM_HISTORY.replace(':id', uuid),
    method: 'GET',
    data: {},
    params: params,
    responseType: 'blob',
  },
});

const callgetConversion = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_CONVERSION_REQUEST,
      Action.GET_CONVERSION_SUCCESS,
      Action.GET_CONVERSION_ERRORS,
    ],
    endpoint: routes.GET_CONVERSION,
    method: 'POST',
    data: params,
  },
});

const callGetConversionHistory = (params) => ({
  [CALL_API]: {
    types: [
      Action.GET_CONVERSION_HISTORY_REQUEST,
      Action.GET_CONVERSION_HISTORY_SUCCESS,
      Action.GET_CONVERSION_HISTORY_ERRORS,
    ],
    endpoint: routes.GET_CONVERSION_HISTORY,
    method: 'GET',
    data: {},
    params: params,
  },
});

export const getWalletStatus =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callWalletStatus(params));
  };

export const addWalletFunds = (params) => (dispatch, getState) => {
  return dispatch(callAddWalletFunds(params));
};

export const addWalletCredits = (params) => (dispatch, getState) => {
  return dispatch(callAddWalletCredits(params));
};

export const addProgramFunds = (params, id) => (dispatch, getState) => {
  return dispatch(callAddProgramFunds(params, id));
};

export const getProgramHistory = (uuid, params) => (dispatch, getState) => {
  return dispatch(callGetProgramHistory(uuid, params));
};

export const getWalletHistory = (params) => (dispatch, getState) => {
  return dispatch(callGetWalletHistory(params));
};

export const getWalletHistoryPtaas = (params) => (dispatch, getState) => {
  return dispatch(callGetWalletHistoryPtaas(params));
};

export const getCommission = (params) => (dispatch, getState) => {
  return dispatch(callgetCommission(params));
};

export const getWalletHistoryforDownload = (params) => (dispatch, getState) => {
  return dispatch(exportWalletHistory(params));
};

export const getProgramHistoryforDownload =
  (params, uuid) => (dispatch, getState) => {
    return dispatch(exportProgramHistory(params, uuid));
  };

export const getConversion = (params) => (dispatch, getState) => {
  return dispatch(callgetConversion(params));
};

export const getConversionHistory = (params) => (dispatch, getState) => {
  return dispatch(callGetConversionHistory(params));
};
