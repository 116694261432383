import * as Action from './ActionTypes';
import { CALL_API } from '../../middleware/api';
import { routes } from '../../constants/APIEndpoints';

const getProgram = (id) => ({
  [CALL_API]: {
    types: [
      Action.GET_PROGRAM_REQUEST,
      Action.GET_PROGRAM_SUCCESS,
      Action.GET_PROGRAM_ERROR,
    ],
    endpoint: routes.GET_PROGRAM + id + '/',
    method: 'GET',
    data: {},
  },
});

const saveProgram = (params) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PROGRAM_REQUEST,
      Action.CREATE_PROGRAM_SUCCESS,
      Action.CREATE_PROGRAM_ERROR,
    ],
    endpoint: routes.CREATE_PROGRAMS,
    method: 'POST',
    data: params,
  },
});

const editProgram = (params, id) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_PROGRAM_REQUEST,
      Action.UPDATE_PROGRAM_SUCCESS,
      Action.UPDATE_PROGRAM_ERROR,
    ],
    endpoint: routes.UPDATE_PROGRAMS + id + '/',
    method: 'PATCH',
    data: params,
  },
});

const callUpdateProgramAvatar = (id, params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_AVATAR_PROGRAM_REQUEST,
      Action.UPDATE_AVATAR_PROGRAM_SUCCESS,
      Action.UPDATE_AVATAR_PROGRAM_ERROR,
    ],
    endpoint: routes.UPDATE_AVATAR_PROGRAMS.replace(':program_id', id),
    method: 'POST',
    data: params,
  },
});

const callUpdateProgramDescription = (params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_DESCRIPTION_PROGRAM_REQUEST,
      Action.UPDATE_DESCRIPTION_PROGRAM_SUCCESS,
      Action.UPDATE_DESCRIPTION_PROGRAM_ERROR,
    ],
    endpoint: routes.UPDATE_DESCRIPTION_PROGRAM.replace(
      ':program_uuid',
      params.uuid
    ),
    method: 'PUT',
    data: params,
  },
});

const joinToProgram = (id) => ({
  [CALL_API]: {
    types: [
      Action.USER_JOIN_PROGRAMS_REQUEST,
      Action.USER_JOIN_PROGRAMS_SUCCESS,
      Action.USER_JOIN_PROGRAMS_ERRORS,
    ],
    endpoint: routes.JOIN_PROGRAM.replace(':id', id),
    method: 'POST',
    data: {},
  },
});

const addTarget = (id) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PROGRAM_TARGET_REQUEST,
      Action.CREATE_PROGRAM_TARGET_SUCCESS,
      Action.CREATE_PROGRAM_TARGET_ERROR,
    ],
    endpoint: routes.CREATE_TARGET_PROGRAM + id + '/',
    method: 'POST',
    data: {},
  },
});

const deleteTarget = (id) => ({
  [CALL_API]: {
    types: [
      Action.REMOVE_PROGRAM_TARGET_REQUEST,
      Action.REMOVE_PROGRAM_TARGET_SUCCESS,
      Action.REMOVE_PROGRAM_TARGET_ERROR,
    ],
    endpoint: routes.REMOVE_TARGET_PROGRAM + id + '/',
    method: 'DELETE',
    data: {},
  },
});

const addFile = (id, file) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PROGRAM_FILE_REQUEST,
      Action.CREATE_PROGRAM_FILE_SUCCESS,
      Action.CREATE_PROGRAM_FILE_ERROR,
    ],
    endpoint: routes.CREATE_FILE_PROGRAM + id + '/',
    method: 'POST',
    data: file,
  },
});

const deleteFile = (id) => ({
  [CALL_API]: {
    types: [
      Action.REMOVE_PROGRAM_FILE_REQUEST,
      Action.REMOVE_PROGRAM_FILE_SUCCESS,
      Action.REMOVE_PROGRAM_FILE_ERROR,
    ],
    endpoint: routes.REMOVE_FILE_PROGRAM + id + '/',
    method: 'DELETE',
    data: { id },
  },
});

const listFeatures = (id, params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_PROGRAM_FEATURES_REQUEST,
      Action.LIST_PROGRAM_FEATURES_SUCCESS,
      Action.LIST_PROGRAM_FEATURES_ERROR,
    ],
    endpoint: routes.LIST_PROGRAM_FEATURES.replace(':id', id),
    method: 'GET',
    data: {},
    params: params,
  },
});

const createFeature = (params, id) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PROGRAM_FEATURE_REQUEST,
      Action.CREATE_PROGRAM_FEATURE_SUCCESS,
      Action.CREATE_PROGRAM_FEATURE_ERROR,
    ],
    endpoint: routes.CREATE_PROGRAM_FEATURE.replace(':id', id),
    method: 'POST',
    data: params,
  },
});

const changeState = (params, id) => ({
  [CALL_API]: {
    types: [
      Action.CHANGE_PROGRAM_STATE_REQUEST,
      Action.CHANGE_PROGRAM_STATE_SUCCESS,
      Action.CHANGE_PROGRAM_STATE_ERROR,
    ],
    endpoint: routes.CHANGE_PROGRAM_STATE.replace(':id', id),
    method: 'PATCH',
    data: params,
  },
});

const addProgramMedia = (file) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PROGRAM_FILE_REQUEST,
      Action.CREATE_PROGRAM_FILE_SUCCESS,
      Action.CREATE_PROGRAM_FILE_ERROR,
    ],
    endpoint: routes.ADD_MEDIA,
    method: 'POST',
    data: file,
  },
});

const exportSudoku = (params, id) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_SUDOKU_REQUEST,
      Action.EXPORT_SUDOKU_SUCCESS,
      Action.EXPORT_SUDOKU_ERRORS,
    ],
    endpoint: routes.GET_SUDOKU.replace(':id', id),
    method: 'POST',
    data: params,
    responseType: 'blob',
  },
});

export const createProgram = (params) => (dispatch, getState) => {
  return dispatch(saveProgram(params));
};

export const updateProgram = (params, uid) => (dispatch, getState) => {
  return dispatch(editProgram(params, uid));
};

export const addMediaProgram = (params) => (dispatch, getState) => {
  return dispatch(addProgramMedia(params));
};

export const updateProgramAvatar = (id, params) => (dispatch, getState) => {
  return dispatch(callUpdateProgramAvatar(id, params));
};

export const updateProgramDescription = (params) => (dispatch, getState) => {
  return dispatch(callUpdateProgramDescription(params));
};

export const joinProgram = (program_id) => (dispatch, getState) => {
  return dispatch(joinToProgram(program_id));
};

export const fetchProgram = (program_id) => (dispatch, getState) => {
  return dispatch(getProgram(program_id));
};

export const createProgramTarget = (program_id) => (dispatch, getState) => {
  return dispatch(addTarget(program_id));
};

export const deleteProgramTarget = (target_id) => (dispatch, getState) => {
  return dispatch(deleteTarget(target_id));
};

export const createProgramFile = (program_id, file) => (dispatch, getState) => {
  return dispatch(addFile(program_id, file));
};

export const deleteProgramFile = (target_id) => (dispatch, getState) => {
  return dispatch(deleteFile(target_id));
};

export const addProgramAvatar =
  (uuid, avatar_url, avatar) => (dispatch, getState) => {
    dispatch({
      type: Action.UPDATE_AVATAR_PROGRAM,
      program_uuid: uuid,
      avatar: avatar,
      url: avatar_url,
    });
  };

export const listProgramFeatures = (id, params) => (dispatch, getState) => {
  return dispatch(listFeatures(id, params));
};

export const addProgramFeature = (params, id) => (dispatch, getState) => {
  return dispatch(createFeature(params, id));
};

export const clearProgram = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_PROGRAM,
  });
};

export const changeProgramState = (params, id) => (dispatch, getState) => {
  return dispatch(changeState(params, id));
};

export const clearProgramUpdate = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_PROGRAM_UPDATE,
  });
};

export const getSudokuforDownload = (params, id) => (dispatch, getState) => {
  return dispatch(exportSudoku(params, id));
};
