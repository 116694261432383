import React, { useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { useTranslation } from 'react-i18next';
import '../styles/profile.less';
import TwoFactorOtpForm from './TwoFactorOtpForm';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';
import { connect } from 'react-redux';

const TwoFactorValidation = (props) => {
  const { t } = useTranslation();
  const { dispatch } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOtpSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/login/verify/', {
        otp_token: values.token,
      });

      dispatch((dispatch) => {
        dispatch({ type: 'VALIDATE_SF_SUCCESS' });
        return Promise.resolve();
      }).then(() => {
        window.location.href = '/dashboard';
      });
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Row type="flex" justify="space-around" align="middle" gutter={[8, 40]}>
        <Col>
          <MailOutlined className="icon-mail centered-content" />
          <p className="card-content-size-l text-center mb-3">
            {t('login.2fa.message')}
          </p>
        </Col>
      </Row>
      <Row gutter={[8, 40]}>
        <Col span={24}>
          <TwoFactorOtpForm
            onSubmit={handleOtpSubmit}
            centered={true}
            auth={true}
            isLoading={isLoading}
            forceNumber={true}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <p className="card-title-section card-content-size-m">
            {t('login.2fa.problem')}
          </p>
          <Link to={'/auth/recover'}>{t('login.2fa.backup')}</Link>
        </Col>
        <Col span={24}>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              showModal();
            }}
            style={{ color: '#1890ff' }}
          >
            {t('login.2fa.help.label')}
          </a>
        </Col>
      </Row>
      <Modal
        title={t('login.2fa.help.title')}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <p className="p-highlighted p-large">{t('login.2fa.help.text1')}</p>
        <p className="p-highlighted p-large mb-2">
          {t('login.2fa.help.text2')}
        </p>
        <p className="p-large">
          {t('login.2fa.help.text3')} {supportEmail}
        </p>
        <p className="p-large mt-2">{t('login.2fa.help.text4')}</p>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(TwoFactorValidation);
