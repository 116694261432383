export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const routes = {
  // SESSIONS
  SIGN_IN: '/login/',
  SIGN_OUT: '/logout/',

  // REGISTRATION
  SIGN_UP_HACKER: '/hackers/sign-up/',
  SIGN_UP_COMPANY: '/companies/sign-up/',
  CREATE_COMPANY_EMPLOYEE: '/invitations/sign-up/',

  // PASSWORDS
  PASSWORD_RESET_REQUEST: '/password-reset/', // + email
  PASSWORD_RESET_VALIDATE: '/users/passwords/reset_validate/', // + token
  PASSWORD_RESET: '/password-confirm/', // + token

  // CHANGE PASSWORD
  UPDATE_PASSWORD: '/change-password/',

  //CHANGE PLAN
  CHANGE_PLAN: '/current-company/request-plan-change/',

  // USER DATA
  ME: '/me/',
  PROFILE: '/profile/',
  COMPANY_PROFILE: '/current-company/',
  UPDATE_PROFILE: '/profile/',
  UPDATE_COMPANY_PROFILE: '/current-company/',
  GET_SECTORS: '/companies/sectors/',
  UPDATE_SKILLS: '/profile/',
  ADD_PAYMENTS: '/profiles/hackers/payments',
  REMOVE_PAYMENTS: '/paypal_accounts/', // + id (paypal account),
  REMOVE_CERTIFICATE: '/security_certificates/', // + id (certificate)
  LIST_NOTIFICATIONS: '/notifications/',
  CHECK_NOTIFICATIONS: '/notifications/mark_all_as_read/',
  GET_COMPANIES: '/filters/companies/',
  CHANGE_COMPANY: '/change-current-company/',

  // CHANGE LANGUAGE,
  CHANGE_LANGUAGE: '/profile/',

  // REFER CUSTOMER
  REFER_CUSTOMER: '/refer-a-customer/',

  // COMPANY DATA
  UPDATE_COMPANY: '/profiles/companies/main',
  UPDATE_COMPANY_INVOICING: '/profiles/companies/payments',
  GET_COMPANY_EMPLOYEES: '/current-company/users/',
  GET_INVITATIONS_EMPLOYEES: '/current-company/pending-invitations/',
  INVITES_COMPANY_EMPLOYEES: '/current-company/invite/',
  REINVITE_COMPANY_EMPLOYEE: '/invitations/resend',
  ACTIVATE_COMPANY_EMPLOYEE: '/invitations/', // + :id/activate
  DELETE_COMPANY_EMPLOYEE: '/current-company/users/:id/',
  DELETE_INVITATION: '/current-company/pending-invitations/:id/',
  DEACTIVATE_COMPANY_EMPLOYEE: '/invitations/', // + :id/deactivate
  TRANSFER_OWNER: '/current-company/users/:id/transfer-ownership/',

  // PROGRAMS
  PUBLIC_PROGRAMS: '/programs/',
  MY_PROGRAMS: '/programs/',
  GET_PROGRAM: '/programs/', // + :id
  CREATE_PROGRAMS: '/programs/',
  UPDATE_PROGRAMS: '/programs/', // + :id
  UPDATE_AVATAR_PROGRAMS: '/programs/:program_id/update-image/',
  ACTIVITIES_PROGRAM: '/programs/:id/activities/',
  ACTIVITIES_REPORT: '/reports/:id/activities/',
  UPDATE_DESCRIPTION_PROGRAM: '/programs/:program_uuid/description',
  DELETE_PROGRAMS: '/programs/', // + :id
  CREATE_TARGET_PROGRAM: '/programs/targets/', // + :program_uuid
  REMOVE_TARGET_PROGRAM: '/programs/targets/', // + :id
  CREATE_FILE_PROGRAM: '/programs/files/', // + :program_uuid
  REMOVE_FILE_PROGRAM: '/programs/files/', // + :id
  JOIN_PROGRAM: '/programs/:id/join/', // + :program_slug
  LIST_PROGRAM_FEATURES: '/programs/:id/features/',
  GET_PROGRAM_FEATURE: '/programs/features/:id',
  CREATE_PROGRAM_FEATURE: '/programs/:id/features/',
  DELETE_PROGRAM_FEATURE: '/programs/:program_pk/features/:id', // program.id, feature.id
  FINANCE_PROGRAMS: '/finances/bugbounty/', // HAY QUE CAMBIAR
  FINANCE_PROGRAMS_PTAAS: '/finances/ptaas/',
  CHANGE_PROGRAM_STATE: '/programs/:id/',
  GET_PROGRAM_HISTORIES: '/programs/:id/histories',
  GET_SUDOKU: '/programs/:id/generate-draft-document/',

  //SERVICES
  GET_SERVICES: '/current-company/services/',

  // REPORTS
  GET_REPORTS: '/reports/',
  GET_REPORTS_DOWNLOAD: '/reports/',
  GET_UNIT_REPORT: '/reports/:id/',
  GET_REPORT: '/reports/', // + :uid
  CREATE_REPORT: '/reports/',
  CHANGE_STATUS_REPORT: '/reports/:id/', // + :report_state_id  (:uid report)
  UPDATE_REPORT: '/reports/', // + :uid
  DELETE_REPORT: '/reports/:id/', // + :uid
  CREATE_REPORT_STEP: '/reports/steps/', // + :uid
  DELETE_REPORT_STEP: '/reports/steps/', // + :id  (step)
  ADD_ATTACHMENT_STEP: '/reports/steps/files/', // + :id (step_id)
  REMOVE_ATTACHMENT_STEP: '/reports/steps/files/', // + :id (attachment_id)
  GET_TARGETS: '/filters/targets/',
  GET_ALL_PROGRAMS: '/filters/programs/',
  GET_ATTACK_TYPE_LIST: '/reports/attack-types/',

  ADD_MEDIA: '/media/upload/',
  UPLOAD_PROFILE_IMAGE: '/profile/update-profile-image/',
  UPLOAD_COMPANY_IMAGE: '/current-company/update-image/',

  // COMMENTS
  GET_COMMENTS: '/reports/:id/comments/', // + :uid (report)
  CREATE_COMMENT: '/reports/:id/comments/', // + :uid (report)
  UPDATE_COMMENT: '/reports/:id/comments/', // + comment_id
  DELETE_COMMENT: '/reports/:uuid/comments/:id/', // + reportID + commentID

  // RANKING
  GET_RANKING: '/hackers/ranking/',
  GET_RANKING_PROFILE: '/hackers/:username/',

  // INVITATIONS
  INVITATION_AUTHENTICATE: '/invitations/', // + token,

  // PAYMENTS
  LIST_PAYMENTS: '/payments/',
  CREATE_PAYMENT: '/payments/:report_uuid',
  PAYMENT_STATISTICS: '/payments/statistics/',
  CREDIT_STATS: '/finances/stats/credits/',
  GET_INVOICE: '/orders/:id/invoice/',

  // STATISTICS
  REPORT_STATISTICS: '/statistics/',

  // SCORES LIST
  LIST_SCORES: '/hackers/scores',

  // ACTIVITY LIST
  LIST_ACTIVITIES: '/activities/',

  // FILES EXPORTS
  REPORTS_PDF_EXPORT: '/pdf/reports.pdf',

  // WALLET
  GET_WALLET_STATUS: '/wallets/status',
  ADD_WALLET_FUNDS: '/current-company/add-funds/',
  ADD_WALLET_CREDITS: '/current-company/add-funds/ptaas/',
  ADD_PROGRAM_FUNDS: '/programs/:id/transfer/',
  GET_PROGRAM_HISTORY: '/programs/:id/wallet-history/',
  GET_HISTORYWALLET: '/current-company/wallet-history/bugbounty/',
  GET_HISTORY_WALLET_PTAAS: '/current-company/wallet-history/ptaas/',
  GET_CONVERSION_HISTORY: '/current-company/conversion-history/',
  GET_COMMISSION: '/current-company/add-funds-info/',
  GET_CONVERSION: '/current-company/convert/dollars-to-credits/',

  // 2FA VALIDATION
  ACCOUNT_VALIDATION: '/login/verify/',
};
