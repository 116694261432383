import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tabs } from 'antd';
import { Route, withRouter, Redirect } from 'react-router-dom';

import '../themes/statistics.less';
import { withTranslation } from 'react-i18next';

import FinanceBugbounty from './FinanceBugbounty';
import FinancePtaas from './FinancePtaas';

const TabPane = Tabs.TabPane;

class FinanceContainer extends Component {
  tabClickHandler = (key) => {
    this.props.history.push(key);
  };

  render() {
    const { ability, t } = this.props;

    const storedTab = localStorage.getItem('financeSelectedTab');

    const canAddBugbounty = ability.can('add_bugbounty_program', 'Company');
    const canAddPtaas = ability.can('add_ptaas_program', 'Company');

    if (this.props.location.pathname === '/dashboard/finance') {
      if (canAddBugbounty && canAddPtaas && storedTab) {
        if (storedTab === 'bugbounty') {
          return <Redirect to="/dashboard/finance/bugbounty" />;
        } else if (storedTab === 'ptaas') {
          return <Redirect to="/dashboard/finance/ptaas" />;
        }
      } else if (canAddBugbounty) {
        return <Redirect to="/dashboard/finance/bugbounty" />;
      } else if (canAddPtaas) {
        return <Redirect to="/dashboard/finance/ptaas" />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>
                <b>{t('budget.title')}</b>
              </h1>
              <h4>{t('budget.desc')}</h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div id="programCompanyView">
                <Tabs
                  activeKey={this.props.location.pathname}
                  onTabClick={this.tabClickHandler}
                  className="custom-ant-tabs"
                >
                  <TabPane
                    tab={<span>BUG BOUNTY</span>}
                    key="/dashboard/finance/bugbounty"
                    disabled={!canAddBugbounty}
                    style={{
                      cursor: canAddBugbounty ? 'default' : 'not-allowed',
                    }}
                  />

                  <TabPane
                    tab={<span>PTAAS</span>}
                    key="/dashboard/finance/ptaas"
                    disabled={!canAddPtaas}
                    style={{
                      cursor: canAddPtaas ? 'default' : 'not-allowed',
                    }}
                  />
                </Tabs>
              </div>
            </Col>
          </Row>

          <Route path="/dashboard/finance/ptaas" component={FinancePtaas} />
          <Route
            path="/dashboard/finance/bugbounty"
            component={FinanceBugbounty}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  ability: state.ability,
});
export default withRouter(
  withTranslation()(connect(mapStateToProps)(FinanceContainer))
);
