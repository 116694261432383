import React, { Component } from 'react';
import { Card, Timeline } from 'antd';
import * as _ from 'lodash';

import '../themes/loading-icon.less';
import { withTranslation } from 'react-i18next';
import { activityTranslation } from '../utils/ActivitiesHelper';
import moment from 'moment';
import Pagination from './Pagination';
import LoaderComponent from './LoaderComponent';

class HistoryList extends Component {
  getActivityListContent(activities) {
    return activities.map((activity, index) => {
      const { t } = this.props;
      const { actor, code, action_object, target, timestamp } = activity;
      const timeStamp = moment(timestamp);

      const date = timeStamp.format('DD/MM/YYYY');
      const { title, description } = activityTranslation(t, code, {
        actor,
        action_object,
        target,
      });

      return (
        <Timeline.Item key={index}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p className="p-simple">{description}</p>
            <p className="p-simple">{date}</p>
          </div>
        </Timeline.Item>
      );
    });
  }

  handlePaginateData = (data) => {
    this.updatePage(data);
  };

  updatePage(page) {
    this.props.updatePage(page);
  }

  render() {
    const { activities, loading, currentPage, totalItems, totalPages, t } =
      this.props;
    return (
      <div className="activity-list">
        <div className="list-container">
          {loading ? (
            <LoaderComponent />
          ) : _.isEmpty(activities) ? (
            <Card hoverable={true} className="top-hacker">
              <p className="text-center">{t('reports.no-history')}</p>
            </Card>
          ) : (
            <Timeline>{this.getActivityListContent(activities)}</Timeline>
          )}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            verbose={
              totalItems === 1
                ? t('activities.verbose_name')
                : t('activities.verbose_name_plural')
            }
            onPageChange={this.props.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(HistoryList);
