import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import withSizes from 'react-sizes';
import { getProgramParamsFilter } from '../utils/ReportHelper';
import { mapSizes } from '../mapSizes';

class Paginate extends Component {
  state = {};

  sendDataToParent(page) {
    this.props.onData(page);
  }

  firstPage = () => {
    const { currentPage, flag, dispatch, dispatcher, currentID } = this.props;
    if (currentPage > 1) {
      if (flag) {
        dispatch(dispatcher(currentID, { page: 1 }));
      } else {
        dispatch(dispatcher({ page: 1 }));
      }
    }
  };

  lastPage = () => {
    const {
      flag,
      numberOfPages,
      dispatch,
      dispatcher,
      currentPage,
      currentID,
    } = this.props;

    if (currentPage !== numberOfPages) {
      if (flag) {
        dispatch(dispatcher(currentID, { page: numberOfPages }));
      } else {
        dispatch(dispatcher({ page: numberOfPages }));
      }
    }
  };

  prevPage = () => {
    const { currentPage, currentID, dispatch, dispatcher, flag, filters } =
      this.props;

    if (currentPage > 1) {
      if (flag) {
        dispatch(dispatcher(currentID, { page: currentPage - 1 }));
        let page = currentPage - 1;
        this.sendDataToParent(page);
      } else {
        if (filters) {
          let page = currentPage - 1;
          filters.payload.page = currentPage - 1;
          let filter = filters.payload;
          dispatch(dispatcher(filter));
          this.sendDataToParent(page);
        } else {
          let attributes = { page: currentPage - 1 };
          attributes = getProgramParamsFilter(attributes);
          dispatch(dispatcher(attributes));
          this.sendDataToParent(currentPage - 1);
        }
      }
    }
  };

  nextPage = () => {
    const {
      currentPage,
      numberOfPages,
      currentID,
      dispatch,
      dispatcher,
      flag,
      filters,
    } = this.props;
    if (currentPage < numberOfPages) {
      if (flag) {
        let page = currentPage + 1;
        dispatch(dispatcher(currentID, { page: currentPage + 1 }));
        this.sendDataToParent(page);
      } else {
        if (filters) {
          let page = currentPage + 1;
          filters.payload.page = currentPage + 1;
          let filter = filters.payload;
          dispatch(dispatcher(filter));
          this.sendDataToParent(page);
        } else {
          let attributes = { page: currentPage + 1 };
          attributes = getProgramParamsFilter(attributes);
          dispatch(dispatcher(attributes));
          this.sendDataToParent(currentPage + 1);
        }
      }
    }
  };

  render() {
    const {
      currentPage,
      numberOfElements,
      elementName,
      isMobile,
      numberOfPages,
    } = this.props;
    const limitPage = numberOfPages;
    return (
      <Row className="paginate">
        <Col
          sm={24}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={`text-${isMobile ? 'center' : 'right'}`}
        >
          <div style={{ display: 'flex' }}>
            <p style={{ marginLeft: '10px' }}>{`${numberOfElements}`}</p>
            &nbsp;
            <p>{elementName}</p>
          </div>

          <div className="paginateIcons" style={{ margin: '0px 10px 0px 0px' }}>
            {currentPage !== 1 ? (
              <Button onClick={this.prevPage}>
                <LeftOutlined />
              </Button>
            ) : (
              <Button disabled>
                <LeftOutlined />
              </Button>
            )}

            {currentPage === 1 ? (
              <Button style={{ display: 'none' }}></Button>
            ) : (
              <Button
                className="currentPage"
                style={{ height: '30px', width: '30px', margin: '0 3px' }}
                onClick={this.prevPage}
              >
                {currentPage - 1}
              </Button>
            )}

            <b
              className="currentPage"
              style={{
                backgroundColor: '#108ee9',
                borderColor: '#000000',
                borderRadius: '3px',
                color: 'white',
                padding: '5px 10px',
                margin: '0 3px',
              }}
            >
              {currentPage}
            </b>

            {currentPage !== numberOfPages && numberOfElements > 10 ? (
              <Button
                className="currentPage"
                style={{ height: '30px', width: '30px', margin: '0 3px' }}
                onClick={this.nextPage}
              >
                {currentPage + 1}
              </Button>
            ) : (
              <Button style={{ display: 'none' }}></Button>
            )}

            {currentPage !== limitPage && numberOfElements > 10 ? (
              <Button onClick={this.nextPage}>
                <RightOutlined />
              </Button>
            ) : (
              <Button disabled>
                <RightOutlined />
              </Button>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  filters: ownProps.filters,
  currentPage: ownProps.currentPage,
  numberOfPages: ownProps.numberOfPages,
  numberOfElements: ownProps.numberOfElements,
  flag: ownProps.flag,
  currentID: ownProps.currentID,
});
export default withSizes(mapSizes)(connect(mapStateToProps)(Paginate));
