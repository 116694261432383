import { useMemo } from 'react';
import moment from 'moment';

const useProgramDetails = (program) => {
  const lockedMoney = parseInt(program.locked);
  const totalBudget = parseInt(program.total_budget);
  const totalPaid = parseInt(program.total_paid);

  const calculateBudgetPercent = (totalBudget, totalPaid, lockedMoney) => {
    if (totalBudget === 0) return 0;
    let percent = ((totalPaid + lockedMoney) / totalBudget) * 100;
    return percent > 100 ? 100 : percent;
  };

  const formatDate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : '';
  };

  const convertDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const budgetPercent = useMemo(
    () => calculateBudgetPercent(totalBudget, totalPaid, lockedMoney),
    [totalBudget, totalPaid, lockedMoney]
  );

  const creationDate = useMemo(
    () => formatDate(program.created_at),
    [program.created_at]
  );
  const updateDate = useMemo(
    () => formatDate(program.updated_at),
    [program.updated_at]
  );

  let startDate = '';
  let endDate = '';
  if (program.is_ptaas) {
    startDate = convertDate(program.pentest_as_a_service.start_at);
    endDate = convertDate(program.pentest_as_a_service.end_at);
  }

  return {
    budgetPercent,
    creationDate,
    updateDate,
    startDate,
    endDate,
  };
};

export default useProgramDetails;
