import * as Action from '../actions/ActionTypes';

const initialState = {
  list: [],
  currentProfile: null,
  numberOfElements: null,
  requesting: false,
  hasError: false,
  errorMessage: null,
};

class RankingReducer {
  static reduce(state = initialState, action) {
    if (RankingReducer[action.type]) {
      return RankingReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_RANKING_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.LIST_RANKING_SUCCESS](state, action) {
    return {
      ...state,
      list: action.response,
      numberOfElements: action.response.lenght,
      hasError: false,
      requesting: false,
    };
  }

  static [Action.LIST_RANKING_ERRORS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.GET_RANKING_PROFILE_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.GET_RANKING_PROFILE_SUCCESS](state, action) {
    const { response } = action;
    return {
      ...state,
      currentProfile: response,
      hasError: false,
      requesting: false,
    };
  }

  static [Action.GET_RANKING_PROFILE_ERRORS](state, action) {
    const { error } = action;
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: error,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default RankingReducer.reduce;
