import * as Action from '../actions/ActionTypes';
import moment from 'moment';
import { saveAs } from 'file-saver';

const initialState = {
  list: [],
  statistics: {},
  numberOfElements: null,
  totalPaidAmount: null,
  totalPendingAmount: null,
  locked_money: null,
  payment_success: false,
  requesting: false,
  hasError: false,
  isFetching: false,
  errorMessage: null,
  numberOfPages: 1,
};

class PaymentsReducer {
  static reduce(state = initialState, action) {
    if (PaymentsReducer[action.type]) {
      return PaymentsReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_PAYMENTS_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.LIST_PAYMENTS_SUCCESS](state, action) {
    const res = {
      ...state,
      list: action.response.results,
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      requesting: false,
      filters: [],
      payment_success: true,
      totalPaidAmount: action.response.total_paid, // TODO: fixme
      totalPendingAmount: action.response.total_pending,
      locked_money: action.response.total_locked,
    };
    return res;
  }

  static [Action.LIST_PAYMENTS_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      payment_success: false,
      hasError: true,
    };
  }

  static [Action.PAYMENT_STATISTICS_REQUEST](state, action) {
    return {
      ...state,
      fetching: true,
    };
  }

  static [Action.PAYMENT_STATISTICS_SUCCESS](state, action) {
    return {
      ...state,
      fetching: false,
      hasError: false,
      statistics: action.response,
    };
  }

  static [Action.PAYMENT_STATISTICS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      fetching: false,
    };
  }

  static [Action.CREDIT_STATISTICS_REQUEST](state, action) {
    return {
      ...state,
      fetching: true,
    };
  }

  static [Action.CREDIT_STATISTICS_SUCCESS](state, action) {
    return {
      ...state,
      fetching: false,
      hasError: false,
      statistics: action.response,
    };
  }

  static [Action.CREDIT_STATISTICS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      fetching: false,
    };
  }

  static [Action.EXPORT_PAYMENTS_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_PAYMENTS_SUCCESS](state, action) {
    const { response } = action;
    if (response.type === 'text/csv') {
      saveAs(
        response,
        `Payments - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
      );
    } else {
      saveAs(
        response,
        `Payments - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`
      );
    }

    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_PAYMENTS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }

  static [Action.EXPORT_INVOICE_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_INVOICE_SUCCESS](state, action) {
    const { response } = action;
    saveAs(response, `Invoice - ${moment().format('YYYY-MM-DD HHmmss')}.pdf`);

    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_INVOICE_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default PaymentsReducer.reduce;
