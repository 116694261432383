import React from 'react';
import { Dropdown } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../themes/reports-export.less';

const ReportsExport = (props) => {
  const { t } = useTranslation();

  const items = [
    {
      key: '1',
      label: (
        <a
          className="reports-exports-menu-btn"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_API_URL}/reports/${props.report.id}/?format=csv`}
        >
          CSV
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          className="reports-exports-menu-btn"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_API_URL}/reports/${props.report.id}/?format=xlsx`}
        >
          XLSX
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          className="reports-exports-menu-btn"
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_API_URL}/reports/${props.report.id}/export/`}
        >
          HTML as zip
        </a>
      ),
    },
  ];

  return (
    <Dropdown className="reports-export-dropdown" menu={{ items }}>
      <span style={{ width: '100%', fontSize: '18px', marginBottom: '5px' }}>
        {t('exports.download-button')}
      </span>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(ReportsExport);
