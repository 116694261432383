import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'antd';
import FilterReportForm from '../components/FilterReportForm';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import Pagination from '../components/Pagination';
import ReportItemDefault from '../components/ReportItemDefault';
import qs from 'qs';
import DownloadReportsForm from '../components/DownloadReportsForm';
import { subscribeReportsDownload } from '../redux/actions/reports';
import axiosInstance from '../axiosInstance';
import { isCompanyMode } from '../utils/General';
import '../styles/reports.less';
import LoaderComponent from '../components/LoaderComponent';
import { Link } from 'react-router-dom';

const Reports = (props) => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const { t } = useTranslation();

  const [results, setResults] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [showFilters, setShowFilters] = useState(
    Object.keys(queryParams).length > 0
  );

  const [programsForFilter, setProgramsForFilter] = useState([]);
  const [programsForFilterLoading, setProgramsForFilterLoading] =
    useState(false);

  const [targetsForFilter, setTargetsForFilter] = useState([]);
  const [targetsForFilterLoading, setTargetsForFilterLoading] = useState(false);

  const itemsPerPage = 10;

  const [filters, setFilters] = useState({
    page: queryParams['page'] ? parseInt(queryParams['page']) : null,
    search: queryParams['search'],
    status: queryParams['status'],
    program: queryParams['program'],
    targets: queryParams['targets'],
    severity: queryParams['severity'],
    is_ptaas:
      queryParams['is_ptaas'] === 'true'
        ? true
        : queryParams['is_ptaas'] === 'false'
        ? false
        : undefined,
    retest_status: queryParams['retest_status'],
    mitigation_status: queryParams['mitigation_status'],
  });

  const [loading, setLoading] = useState(false);

  // Create a debounced version of the handleSearch function
  const debouncedHandleSearchChange = useCallback(
    debounce((value) => {
      handleSearchChange(value);
    }, 300), // Adjust the delay (in milliseconds) as needed
    [filters]
  );

  const paramsSerializer = (params) => {
    const modifiedParams = {};

    for (const key in params) {
      if (
        params[key] === null ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        continue; // Skip fields with null values
      }

      if (Array.isArray(params[key])) {
        modifiedParams[`${key}__in`] = params[key];
      } else {
        modifiedParams[key] = params[key];
      }
    }

    return qs.stringify(modifiedParams, { arrayFormat: 'comma' });
  };

  const fetchData = () => {
    const params = {
      ...filters,
    };

    setLoading(true);

    axiosInstance
      .get(`/reports/`, { params, paramsSerializer })
      .then((response) => {
        setResults(response.data.results);
        setTotalItems(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        setLoading(false);
      });
  };

  const fetchProgramsForFilter = () => {
    const params = {
      ...filters,
    };

    setProgramsForFilterLoading(true);

    axiosInstance
      .get(`/filters/programs/`, { params })
      .then((response) => {
        setProgramsForFilter(response.data);
        setProgramsForFilterLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        setProgramsForFilterLoading(false);
      });
  };

  const fetchTargetsForFilter = () => {
    const params = {
      ...filters,
    };

    setTargetsForFilterLoading(true);

    axiosInstance
      .get(`/filters/targets/`, { params })
      .then((response) => {
        setTargetsForFilter(response.data);
        setTargetsForFilterLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        setTargetsForFilterLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchProgramsForFilter();
    fetchTargetsForFilter();
  }, []);

  useEffect(() => {
    updateQueryParams();
    fetchData();
  }, [filters]);

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page });
  };

  const updateQueryParams = () => {
    const queryParams = qs.stringify(filters, {
      arrayFormat: 'brackets',
      skipNulls: true,
    });
    props.history.push(`?${queryParams}`);
  };

  const handleSearchChange = (value) => {
    handleFilterChange('search', value);
  };

  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value, page: null });
  };

  const onToggleShowFilter = () => {
    setShowFilters(!showFilters);
  };

  const handleDownloadReportClick = () => {
    const params = {
      ...filters,
      format: 'xlsx',
    };

    const modifiedParams = {};
    for (const key in params) {
      if (
        params[key] === null ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        continue; // Skip fields with null values
      }

      if (Array.isArray(params[key])) {
        modifiedParams[`${key}__in`] = params[key].join(',');
      } else {
        modifiedParams[key] = params[key];
      }
    }

    props.dispatch(subscribeReportsDownload(modifiedParams));
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Row>
      <Col
        xs={{ span: 22, offset: 1 }}
        sm={{ span: 22, offset: 1 }}
        lg={{ span: 18, offset: 3 }}
      >
        <Row className="section-title">
          <Col span={24}>
            <h1>
              <b>{t('reports.title')}</b>
            </h1>
            <h4>{t('reports.desc')}</h4>
          </Col>
        </Row>

        <div id="companyReportView">
          <Row>
            <Col span={24}>
              <div id="filterBox" className="text-right">
                <FilterReportForm
                  currentPage={filters.page}
                  filters={filters}
                  onSearchChange={debouncedHandleSearchChange}
                  onFilterChange={handleFilterChange}
                  companyMode={isCompanyMode(props.currentUser)}
                  showFilters={showFilters}
                  targets={targetsForFilter}
                  programs={programsForFilter}
                  onToggleShowFilter={onToggleShowFilter}
                  renderDownload={() => (
                    <DownloadReportsForm onClick={handleDownloadReportClick} />
                  )}
                />

                <div>
                  <div className="report-list">
                    {loading ? (
                      <LoaderComponent />
                    ) : results.length > 0 ? (
                      <div id="reports">
                        {results.map((report, index) => {
                          const queryParams = qs.stringify(filters, {
                            arrayFormat: 'brackets',
                            skipNulls: true,
                          });
                          return (
                            <Link
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                              key={index}
                              to={{
                                pathname: `/dashboard/report/${report.id}`,
                                search: `?${queryParams}`,
                                state: { reportsForQuickAccess: results },
                              }}
                            >
                              <ReportItemDefault
                                report={report}
                                filters={filters}
                              />
                            </Link>
                          );
                        })}
                        <div className="flex-inline mb-3">
                          <div className="ptaas-line"></div>
                          <p className="label">{t('reports.program-ptaas')}</p>
                          <div className="bb-line"></div>
                          <p className="label">{t('reports.program-bb')}</p>
                        </div>
                        <Pagination
                          currentPage={filters.page || 1}
                          totalPages={totalPages}
                          totalItems={totalItems}
                          verbose={
                            totalItems === 1
                              ? t('reports.verbose_name')
                              : t('reports.verbose_name_plural')
                          }
                          onPageChange={handlePageChange}
                        />
                      </div>
                    ) : (
                      <p
                        className="text-center"
                        style={{ fontStyle: 'italic', marginTop: '30px' }}
                      >
                        {t('reports.no-reports')}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(Reports);
