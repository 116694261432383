import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { isCompanyMode } from '../utils/General';

class NotFound extends Component {
  render() {
    const { currentUser } = this.props;

    return (
      <div
        id={!isCompanyMode(currentUser) ? 'hackerNotFound' : 'companyNotFound'}
      >
        <Row>
          <Col
            sm={{ span: 24 }}
            md={{ span: 8, offset: 8 }}
            lg={{ span: 8, offset: 8 }}
          >
            <div className="error-box">
              <h1>404</h1>
              <p>Pagina no encontrada</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});
export default connect(mapStateToProps)(NotFound);
