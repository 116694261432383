import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const ProgramFeatureDelete = (props) => {
  return (
    <Button
      className="pull-right"
      onClick={() => props.handleDelete(props.feature.id)}
      hidden={props.hidden}
    >
      <DeleteOutlined style={{ fontSize: 16, color: '#888' }} />
    </Button>
  );
};

export default ProgramFeatureDelete;
