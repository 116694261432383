import * as Action from './ActionTypes';
import { CALL_API } from '../../middleware/api';
import { routes } from '../../constants/APIEndpoints';

export const getSignIn = (email, password) => ({
  [CALL_API]: {
    types: [
      Action.SIGN_IN_REQUEST,
      Action.SIGN_IN_SUCCESS,
      Action.SIGN_IN_ERROR,
    ],
    endpoint: routes.SIGN_IN,
    method: 'POST',
    data: {
      username: email,
      password: password,
    },
  },
});

export const getSignOut = () => ({
  [CALL_API]: {
    types: [
      Action.SIGN_OUT_REQUEST,
      Action.SIGN_OUT_SUCCESS,
      Action.SIGN_OUT_ERROR,
    ],
    endpoint: routes.SIGN_OUT,
    method: 'POST',
    data: {},
  },
});

export const getCloseSessionTime = () => ({
  type: [Action.AUTH_CLOSE_SESSION_TIME],
});

export const signUpUserHacker = (params) => ({
  [CALL_API]: {
    types: [
      Action.SIGN_UP_REQUEST,
      Action.SIGN_UP_SUCCESS,
      Action.SIGN_UP_ERROR,
    ],
    endpoint: routes.SIGN_UP_HACKER,
    method: 'POST',
    data: params,
  },
});

export const signUpUserCompany = (params) => ({
  [CALL_API]: {
    types: [
      Action.SIGN_UP_REQUEST,
      Action.SIGN_UP_SUCCESS,
      Action.SIGN_UP_ERROR,
    ],
    endpoint: routes.SIGN_UP_COMPANY,
    method: 'POST',
    data: params,
  },
});

export const passwordResetValidate = (token) => ({
  [CALL_API]: {
    types: [
      Action.PASSWORD_RESET_VALIDATE_REQUEST,
      Action.PASSWORD_RESET_VALIDATE_SUCCESS,
      Action.PASSWORD_RESET_VALIDATE_ERROR,
    ],
    endpoint: routes.PASSWORD_RESET_VALIDATE + token,
    method: 'GET',
    data: {},
  },
});

export const passwordResetRequest = (email, url) => ({
  [CALL_API]: {
    types: [
      Action.PASSWORD_RESET_REQUEST_REQUEST,
      Action.PASSWORD_RESET_REQUEST_SUCCESS,
      Action.PASSWORD_RESET_REQUEST_ERROR,
    ],
    endpoint: routes.PASSWORD_RESET_REQUEST,
    method: 'POST',
    data: email,
    params: url,
  },
});

export const passwordReset = (params) => ({
  [CALL_API]: {
    types: [
      Action.PASSWORD_RESET_REQUEST,
      Action.PASSWORD_RESET_SUCCESS,
      Action.PASSWORD_RESET_ERROR,
    ],
    endpoint: routes.PASSWORD_RESET,
    method: 'POST',
    data: params,
  },
});

export const authenticate2Fa = (otpCode) => ({
  [CALL_API]: {
    types: [
      Action.VALIDATE_SF_REQUEST,
      Action.VALIDATE_SF_SUCCESS,
      Action.VALIDATE_SF_ERROR,
    ],
    endpoint: routes.ACCOUNT_VALIDATION,
    method: 'POST',
    data: otpCode,
  },
});

export const signUpHacker = (params) => (dispatch, getState) => {
  return dispatch(signUpUserHacker(params));
};

export const signUpCompany = (params) => (dispatch, getState) => {
  return dispatch(signUpUserCompany(params));
};

export const userPasswordResetRequest =
  (email, url) => (dispatch, getState) => {
    return dispatch(passwordResetRequest(email, url));
  };

export const userPasswordResetValidate = (token) => (dispatch, getState) => {
  return dispatch(passwordResetValidate(token));
};

export const userPasswordReset = (params) => (dispatch, getState) => {
  return dispatch(passwordReset(params));
};
