import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

import '../themes/statistics.less';
import { withTranslation } from 'react-i18next';
import Activities from './Activities';
import AntPaginate from '../components/AntPaginate';
import axiosInstance from '../axiosInstance';
import { isCompanyMode } from '../utils/General';

class Logs extends Component {
  static defaultProps = {
    itemsPerPage: 10,
  };

  state = {
    activities: [],
    totalItems: 0,
    loading: false,
    filters: {
      page: 1,
    },
  };

  componentDidMount() {
    this.fetchActivities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters.page !== prevState.filters.page) {
      this.fetchActivities();
    }
  }

  fetchActivities() {
    this.setState({ loading: true });

    const { filters } = this.state;
    const params = {
      ...filters,
    };

    axiosInstance
      .get(`/activities/`, { params })
      .then((response) => {
        this.setState({
          activities: response.data.results,
          totalItems: response.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading: false });
      });
  }

  handlePageChange = (page) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          page: page,
        },
      }),
      () => {}
    );
  };

  render() {
    const { currentUser, t } = this.props;

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>{t('activities.title')}</h1>
              <h4>
                {!isCompanyMode(currentUser)
                  ? t('activities.desc-hacker')
                  : t('activities.desc-company')}
              </h4>
            </Col>
          </Row>
          <Row id="Statistics">
            <Col span={24}>
              <div className="app-container">
                <Activities
                  activities={this.state.activities}
                  loading={this.state.loading}
                  renderPagination={() => (
                    <AntPaginate
                      currentPage={this.state.filters.page}
                      totalItems={this.state.totalItems}
                      pageSize={this.props.itemsPerPage}
                      onPageChange={this.handlePageChange}
                      verbose={t('paginate.activities')}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});
export default withTranslation()(connect(mapStateToProps)(Logs));
