export const activityTranslation = (
  t,
  code,
  { actor, action_object, target }
) => {
  const params = {
    actor_value: actor !== null ? actor.value : null,
    actor_type: actor !== null ? actor.type : null,
    action_object_value: action_object !== null ? action_object.value : null,
    action_object_type: action_object !== null ? action_object.type : null,
    target_value: target !== null ? target.value : null,
    target_type: target !== null ? target.type : null,
  };

  const title = t(`activities.verbose.${code}.title`, params);
  const description = t(`activities.verbose.${code}.description`, params);

  return { title, description };
};
