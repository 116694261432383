import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';
import '../styles/profile.less';

class User extends Component {
  render() {
    return (
      <Row justify={'center'} className="app-container">
        <Col
          xs={{ span: 22 }}
          md={{ span: 18 }}
          lg={{ span: 18 }}
          xl={{ span: 14 }}
          id="profile"
        >
          <Card hoverable={false}>{this.props.children}</Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});
export default connect(mapStateToProps)(User);
