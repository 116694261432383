import React from 'react';
import { EnterOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Button, message, Modal, Input, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const AcademyHackRock = () => {
  const { t } = useTranslation();

  const smSize = window.innerWidth < 1200;
  const xsSize = window.innerWidth < 768;

  const [copied, setCopied] = useState(false);
  const [visible, setVisible] = useState(false);

  const code = 'CYSCOPE25';

  const HROCKS_AFFILIATE_URL = process.env.REACT_APP_HROCKS_AFFILIATE_URL;

  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        message.success(t('academy.hackrock.modal.copy'));
      })
      .catch(() => message.error('Error al copiar el código al portapapeles'));
  };

  return (
    <Row type="flex" style={{ alignItems: 'center' }} justify="center">
      <Col
        xl={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        sm={{ span: 20, order: 2 }}
        xs={{ span: 20, order: 2 }}
      >
        <div
          onClick={() => setVisible(true)}
          style={{
            width: xsSize ? '100%' : '75%',
            marginTop: smSize ? '5vh' : '0vh',
          }}
        >
          <a>
            <Image
              style={{ width: '100%', display: 'block' }}
              src="/images/hackrocks.png"
              preview={false}
            />
            <Button
              type="primary"
              ghost
              style={{
                width: '100%',
                height: '40px',
                fontSize: '150%',
                borderColor: 'grey',
                color: 'grey',
                fontWeight: 'bold',
                marginTop: '1vh',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {t('academy.hackrock.discount')}
              <EnterOutlined />
            </Button>
          </a>
        </div>
        <Modal
          title={t('academy.hackrock.modal.title')}
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <h6>{t('academy.hackrock.modal.description')}</h6>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={code}
              readOnly
              style={{
                fontSize: '16px',
                backgroundColor: '#f1f1f1',
                padding: '8px',
                borderRadius: '8px',
                marginRight: '8px',
                border: 'none',
                outline: 'none',
                marginTop: '2vh',
              }}
            />
            <LegacyIcon
              type={copied ? 'check' : 'copy'}
              onClick={
                copied
                  ? () => {
                      setCopied(false);
                    }
                  : () => {
                      copyToClipboard(code);
                    }
              }
              style={{
                fontSize: '18px',
                cursor: 'pointer',
                marginTop: '2vh',
              }}
            />
          </div>
          <h6 style={{ marginTop: '3vh' }}>
            {t('academy.hackrock.modal.click')}{' '}
            <a
              onClick={() => {
                setVisible(true);
              }}
              href={HROCKS_AFFILIATE_URL}
              target="_blank"
              rel="noreferrer"
            >
              {t('academy.hackrock.modal.here')}{' '}
            </a>
            {t('academy.hackrock.modal.link')}
          </h6>
        </Modal>
      </Col>
      <Col
        xl={{ span: 10, order: 2 }}
        lg={{ span: 10, order: 2 }}
        sm={{ span: 20, order: 1 }}
        xs={{ span: 20, order: 1 }}
      >
        <div style={{ fontSize: '19px', marginTop: smSize ? '5vh' : '2vh' }}>
          <strong>{t('academy.hackrock.detail.section1.part1-bold')}</strong>
          {t('academy.hackrock.detail.section1.and')}
          <strong>{t('academy.hackrock.detail.section1.part2-bold')}</strong>
          {t('academy.hackrock.detail.section1.part3')}
        </div>
        <div style={{ fontSize: '19px', marginTop: '2vh' }}>
          {t('academy.hackrock.detail.section2.part1')}
          <strong>{t('academy.hackrock.detail.section2.part2-bold')}</strong>
          {t('academy.hackrock.detail.section2.part3')}
        </div>
        <div style={{ fontSize: '19px', marginTop: '2vh' }}>
          {t('academy.hackrock.detail.section3.part1')}
          <strong>{t('academy.hackrock.detail.section3.part2-bold')}</strong>
          {t('academy.hackrock.detail.section1.and')}
          {t('academy.hackrock.detail.section3.part3-bold')}
        </div>
        <div
          style={{
            fontSize: '19px',
            marginTop: '2vh',
            marginBottom: '2vh',
          }}
        >
          {t('academy.hackrock.detail.section4.part1')}
          <strong>{t('academy.hackrock.detail.section4.part2-bold')}</strong>
          {t('academy.hackrock.detail.section4.part3')}
        </div>
      </Col>
    </Row>
  );
};

export default AcademyHackRock;
