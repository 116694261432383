import React from 'react';

const NumberDisplay = ({ number, showSymbol = false, showUSD = false }) => {
  // Convert the string to a floating-point number
  const parsedNumber = parseFloat(number);

  // Check if the parsed number has a decimal part
  const hasDecimal = parsedNumber % 1 !== 0;

  // Create a formatter for currency with the appropriate options
  const formatter = new Intl.NumberFormat('de-DE', {
    style: showSymbol ? 'currency' : 'decimal',
    currency: 'USD', // Adjust the currency code as needed
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: hasDecimal ? 2 : 0,
  });

  // Format the number
  let formattedNumber = formatter.format(parsedNumber);

  // Add the "USD" suffix if showUSD is true
  formattedNumber = showUSD ? `${formattedNumber} USD` : formattedNumber;

  // Render the formatted number
  return <span>{formattedNumber}</span>;
};

export default NumberDisplay;
