import React, { Component } from 'react';
import * as _ from 'lodash';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';

import { withTranslation } from 'react-i18next';
import ReportDetail from '../components/ReportDetail';

import qs from 'qs';
import axiosInstance from '../axiosInstance';
import { connect } from 'react-redux';
import { combineAbilities, defineProgramAbilityFor } from '../ability';
import { subject } from '@casl/ability';
import { isCompanyMode } from '../utils/General';
import '../styles/report-view-default.less';

import { handleAxiosError } from '../utils/Http';
import { openNotification } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';
import { Link } from 'react-router-dom';

class ReportViewDefault extends Component {
  constructor(props) {
    super(props);
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    this.itemsPerPage = 10;

    this.state = {
      program: [],
      programLoading: false,

      report: null,
      reportLoading: false,

      role: null,
      roleLoading: false,

      comments: [],
      commentsLoading: false,

      addCommentLoading: false,
      activeComment: '',

      reportsForQuickAccess: [],
      reportsForQuickAccessLoading: false,
      reportsForQuickAccessTotalItems: 0,

      reportsForQuickAccessPrev: [],
      reportsForQuickAccessPrevLoading: false,
      reportsForQuickAccessPrevPage: null,
      reportsForQuickAccessNext: [],
      reportsForQuickAccessNextLoading: false,
      reportsForQuickAccessNextPage: null,

      activities: [],
      activitiesLoading: false,
      activitiesTotalItems: 0,
      activitiesFilters: {},

      filters: {
        page: queryParams['page'] ? parseInt(queryParams['page']) : null,
        search: queryParams['search'],
        status: queryParams['status'],
        program: queryParams['program'],
        targets: queryParams['targets'],
        severity: queryParams['severity'],
        retest_status: queryParams['retest_status'],
        mitigation_status: queryParams['mitigation_status'],
      },

      finalAbility: props.userAbility,
    };
  }

  componentDidMount() {
    this.fetchReport();
    this.fetchReportComments();
    this.fetchReportsForQuickAccess();
    this.fetchActivities();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.report !== this.state.report) {
      this.fetchProgram();
      this.fetchRole();
      this.setState({ finalAbility: this.props.userAbility });
    }

    if (prevState.reportsForQuickAccess !== this.state.reportsForQuickAccess) {
      const id = this.props.match.params.id;
      const index = this.state.reportsForQuickAccess.findIndex(
        (obj) => obj.id === id
      );

      const currentPage = this.state.filters.page || 1;

      if (index !== -1) {
        if (
          index === this.state.reportsForQuickAccess.length - 1 &&
          currentPage * this.itemsPerPage <
            this.state.reportsForQuickAccessTotalItems
        ) {
          if (this.state.reportsForQuickAccessNextPage !== currentPage + 1) {
            this.fetchReportsForQuickAccessNext();
          }
        }
        if (currentPage !== 1 && index === 0) {
          if (this.state.reportsForQuickAccessPrevPage !== currentPage - 1) {
            this.fetchReportsForQuickAccessPrev();
          }
        }
      }
    }

    if (prevProps.location !== this.props.location) {
      this.fetchReport();
      this.fetchReportComments();
      this.fetchActivities();

      const prevQueryParams = new URLSearchParams(prevProps.location.search);
      const currentQueryParams = new URLSearchParams(
        this.props.location.search
      );

      const prevPage = prevQueryParams.get('page')
        ? parseInt(prevQueryParams.get('page'))
        : 1;
      const currentPage = currentQueryParams.get('page')
        ? parseInt(currentQueryParams.get('page'))
        : 1;

      const id = this.props.match.params.id;
      const index = this.state.reportsForQuickAccess.findIndex(
        (obj) => obj.id === id
      );

      if (index !== -1) {
        if (
          index === this.state.reportsForQuickAccess.length - 1 &&
          currentPage * this.itemsPerPage <
            this.state.reportsForQuickAccessTotalItems
        ) {
          if (this.state.reportsForQuickAccessNextPage !== currentPage + 1) {
            this.fetchReportsForQuickAccessNext();
          }
        }
        if (currentPage !== 1 && index === 0) {
          if (this.state.reportsForQuickAccessPrevPage !== currentPage - 1) {
            this.fetchReportsForQuickAccessPrev();
          }
        }
      }

      if (currentPage !== prevPage) {
        if (currentPage > prevPage) {
          if (this.state.reportsForQuickAccessNextPage === currentPage) {
            this.setState({
              reportsForQuickAccess: [...this.state.reportsForQuickAccessNext],
              reportsForQuickAccessPrev: [...this.state.reportsForQuickAccess],
              reportsForQuickAccessPrevPage: prevPage,
            });
          } else {
            this.setState({
              reportsForQuickAccessPrev: [...this.state.reportsForQuickAccess],
              reportsForQuickAccessPrevPage: prevPage,
            });
            this.fetchReportsForQuickAccess();
          }
        }

        if (currentPage < prevPage) {
          if (this.state.reportsForQuickAccessPrevPage === currentPage) {
            this.setState({
              reportsForQuickAccess: [...this.state.reportsForQuickAccessPrev],
              reportsForQuickAccessNext: [...this.state.reportsForQuickAccess],
              reportsForQuickAccessNextPage: prevPage,
            });
          } else {
            this.setState({
              reportsForQuickAccessNext: [...this.state.reportsForQuickAccess],
              reportsForQuickAccessNextPage: prevPage,
            });
            this.fetchReportsForQuickAccess();
          }
        }
      }
    }
  }

  fetchRole() {
    this.setState({ roleLoading: true });

    const params = {};

    axiosInstance
      .get(`/programs/${this.state.report.program.id}/role/`, { params })
      .then((response) => {
        this.setState({
          role: response.data.role,
          roleLoading: false,
          finalAbility: combineAbilities(
            this.props.userAbility,
            defineProgramAbilityFor(this.props.currentUser, response.data.role)
          ),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error('Error 404: Resource not found');
        } else {
          console.error('Error fetching data', error);
        }
        this.setState({ roleLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchReport() {
    this.setState({ reportLoading: true });

    const params = {};

    axiosInstance
      .get(`/reports/${this.props.match.params.id}/`, { params })
      .then((response) => {
        this.setState({
          report: response.data,
          reportLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ reportLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchProgram() {
    this.setState({ programLoading: true });

    const params = {};

    axiosInstance
      .get(`/programs/${this.state.report.program.id}/`, { params })
      .then((response) => {
        this.setState({
          program: response.data,
          programLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ programLoading: false }); // Set loading to false even if there is an error
      });
  }

  gotoReport(report) {
    const { filters } = this.state;
    const queryParams = qs.stringify(filters, { arrayFormat: 'brackets' });
    this.props.history.push(`/dashboard/report/${report.id}?${queryParams}`);
  }

  paramsSerializer = (params) => {
    const modifiedParams = {};

    for (const key in params) {
      if (
        params[key] === null ||
        (Array.isArray(params[key]) && params[key].length === 0)
      ) {
        continue; // Skip fields with null values
      }

      if (Array.isArray(params[key])) {
        modifiedParams[`${key}__in`] = params[key];
      } else {
        modifiedParams[key] = params[key];
      }
    }

    return qs.stringify(modifiedParams, { arrayFormat: 'comma' });
  };

  fetchReportsForQuickAccess() {
    this.setState({ reportsForQuickAccessLoading: true });

    const params = {
      ...this.state.filters,
    };

    axiosInstance
      .get(`/reports/`, { params, paramsSerializer: this.paramsSerializer })
      .then((response) => {
        this.setState({
          reportsForQuickAccess: response.data.results,
          reportsForQuickAccessLoading: false,
          reportsForQuickAccessTotalItems: response.data.count,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ reportsForQuickAccessLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchReportsForQuickAccessPrev(page = null) {
    this.setState({ reportsForQuickAccessPrevLoading: true });

    const currentPage = this.state.filters.page || 1;

    const params = {
      ...this.state.filters,
      page: page || currentPage - 1,
    };

    axiosInstance
      .get(`/reports/`, { params, paramsSerializer: this.paramsSerializer })
      .then((response) => {
        this.setState({
          reportsForQuickAccessPrev: response.data.results,
          reportsForQuickAccessPrevLoading: false,
          reportsForQuickAccessTotalItems: response.data.count,
          reportsForQuickAccessPrevPage: currentPage - 1,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ reportsForQuickAccessPrevLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchReportsForQuickAccessNext(page = null) {
    this.setState({ reportsForQuickAccessNextLoading: true });

    const currentPage = this.state.filters.page || 1;

    const params = {
      ...this.state.filters,
      page: page || currentPage + 1,
    };

    axiosInstance
      .get(`/reports/`, { params, paramsSerializer: this.paramsSerializer })
      .then((response) => {
        this.setState({
          reportsForQuickAccessNext: response.data.results,
          reportsForQuickAccessNextLoading: false,
          reportsForQuickAccessTotalItems: response.data.count,
          reportsForQuickAccessNextPage: currentPage + 1,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ reportsForQuickAccessNextLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchReportComments() {
    this.setState({ commentsLoading: true });

    const params = {};

    axiosInstance
      .get(`/reports/${this.props.match.params.id}/comments/`, { params })
      .then((response) => {
        this.setState({
          comments: response.data.results,
          commentsLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ commentsLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchActivities() {
    this.setState({ activitiesLoading: true });

    const params = {
      ...this.state.activitiesFilters,
    };

    axiosInstance
      .get(`/reports/${this.props.match.params.id}/activities/`, { params })
      .then((response) => {
        this.setState({
          activities: response.data.results,
          activitiesTotalItems: response.data.count,
          activitiesLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ activitiesLoading: false }); // Set loading to false even if there is an error
      });
  }

  onAddComment = (comment) => {
    this.setState({ addCommentLoading: true });

    const params = {
      comment: comment,
    };

    axiosInstance
      .post(`/reports/${this.props.match.params.id}/comments/`, params)
      .then((response) => {
        this.fetchReportComments();
        this.fetchActivities();
        this.setState({ addCommentLoading: false });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ addCommentLoading: false }); // Set loading to false even if there is an error
      });
  };

  handleActivitiesPageChange = (page) => {
    this.setState(
      (prevState) => ({
        activitiesFilters: {
          ...prevState.activitiesFilters,
          page: page,
        },
      }),
      () => {
        this.fetchActivities();
      }
    );
  };

  handleMitigate = async (status) => {
    const { t } = this.props;
    try {
      const response = await axiosInstance.patch(
        `/reports/${this.state.report.id}/`,
        { mitigation_status: status }
      );

      this.setState({
        report: {
          ...this.state.report,
          mitigation_status: response.data.mitigation_status,
        },
      });

      openNotification(
        'success',
        t('notifications.reports.update'),
        t('notifications.reports.mitigation')
      );
    } catch (error) {
      handleAxiosError(error);
    }
  };

  handleRetest = async (status) => {
    const { t } = this.props;
    try {
      const response = await axiosInstance.patch(
        `/reports/${this.state.report.id}/`,
        { retest_status: status }
      );

      this.setState({
        report: {
          ...this.state.report,
          retest_status: response.data.retest_status,
        },
      });

      openNotification(
        'success',
        t('notifications.reports.update'),
        t('notifications.reports.retest')
      );
    } catch (error) {
      handleAxiosError(error);
    }
  };

  handleDeleteReport = async () => {
    const { t } = this.props;

    try {
      await axiosInstance.delete(`/reports/${this.state.report.id}/`);

      openNotification(
        'success',
        `${t('notifications.reports.remove')}`,
        `${t('notifications.reports.remove-message')}`
      );

      this.props.history.push('/dashboard/reports');
    } catch (error) {
      openNotification('error', 'Error', error);
    }
  };

  handleChangeReportStatus = async (status) => {
    const { t } = this.props;
    try {
      const response = await axiosInstance.patch(
        `/reports/${this.state.report.id}/`,
        { status: status }
      );

      this.setState({
        report: {
          ...this.state.report,
          status: response.data.status,
        },
      });

      openNotification(
        'success',
        t(`reports.notifications.status-update.success`),
        ''
      );
      this.fetchActivities();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  renderEmptyView = () => {
    const { t } = this.props;
    return (
      <Card hoverable={false}>
        <div className="app-container" style={{ paddingLeft: '20px' }}>
          <h2>{t(`select-a-report`)}</h2>
        </div>
      </Card>
    );
  };

  gotoPrevReport() {
    // Find the index of the object with the given id
    const id = this.props.match.params.id;

    const index = this.state.reportsForQuickAccess.findIndex(
      (obj) => obj.id === id
    );

    // Check if the index is found and if there is a next object
    if (index === -1) return;
    if (index > 0) {
      let report = this.state.reportsForQuickAccess[index - 1];
      this.gotoReport(report);
    } else if (index === 0) {
      const currentPage = this.state.filters.page || 1;
      if (currentPage > 1) {
        if (
          !this.state.reportsForQuickAccessPrevLoading &&
          this.state.reportsForQuickAccessPrev.length > 0
        ) {
          let report = this.state.reportsForQuickAccessPrev.slice(-1)[0];

          const currentPage = this.state.filters.page || 1;
          this.setState(
            {
              filters: {
                ...this.state.filters,
                page: currentPage - 1,
              },
            },
            () => {
              this.gotoReport(report);
            }
          );
        }
      }
    }
  }

  gotoNextReport() {
    // Find the index of the object with the given id
    const id = this.props.match.params.id;

    const index = this.state.reportsForQuickAccess.findIndex(
      (obj) => obj.id === id
    );

    // Check if the index is found and if there is a next object
    if (index === -1) return;
    if (index < this.state.reportsForQuickAccess.length - 1) {
      let report = this.state.reportsForQuickAccess[index + 1];
      this.gotoReport(report);
    } else if (index === this.state.reportsForQuickAccess.length - 1) {
      const currentPage = this.state.filters.page || 1;
      if (
        currentPage * this.itemsPerPage <
        this.state.reportsForQuickAccessTotalItems
      ) {
        if (
          !this.state.reportsForQuickAccessNextLoading &&
          this.state.reportsForQuickAccessNext.length > 0
        ) {
          let report = this.state.reportsForQuickAccessNext[0];

          const currentPage = this.state.filters.page || 1;
          this.setState(
            {
              filters: {
                ...this.state.filters,
                page: currentPage + 1,
              },
            },
            () => {
              this.gotoReport(report);
            }
          );
        }
      }
    }
  }

  hasPrevReport() {
    const id = this.props.match.params.id;
    const index = this.state.reportsForQuickAccess.findIndex(
      (obj) => obj.id === id
    );
    // Check if the index is found and if there is a next object
    if (index === -1) return false;
    const currentPage = this.state.filters.page || 1;
    return !(currentPage === 1 && index === 0);
  }

  hasNextReport() {
    const id = this.props.match.params.id;
    const index = this.state.reportsForQuickAccess.findIndex(
      (obj) => obj.id === id
    );
    // Check if the index is found and if there is a next object
    if (index === -1) return false;
    const currentPage = this.state.filters.page || 1;
    return (
      (currentPage - 1) * this.itemsPerPage + (index + 1) <
      this.state.reportsForQuickAccessTotalItems
    );
  }

  render() {
    const { report, reportLoading } = this.state;
    const { t, location } = this.props;
    const currentSearch = location.search;

    return (
      <Row id="report-view-default">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 16, offset: 4 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>{t('reports.title')}</h1>
              <h4>{t('reports.subtitle')}</h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="toolbar-container">
                <div style={{ marginLeft: '20px' }}>
                  <Link
                    to={{
                      pathname: '/dashboard/reports',
                      search: currentSearch,
                    }}
                    className="link-text"
                  >
                    <span className="align-items">
                      <LeftOutlined />
                      {t('reports.back')}
                    </span>
                  </Link>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <a
                    disabled={!this.hasPrevReport()}
                    onClick={() => this.gotoPrevReport()}
                    className="link-text"
                  >
                    <LeftOutlined className="arrow" />
                  </a>
                  <span> - </span>
                  <a
                    disabled={!this.hasNextReport()}
                    onClick={() => this.gotoNextReport()}
                    className="link-text"
                  >
                    <RightOutlined className="arrow" />
                  </a>
                </div>
              </div>
            </Col>

            <Col span={24}>
              {reportLoading ? (
                <LoaderComponent label={false} />
              ) : _.isEmpty(report) ? (
                this.renderEmptyView()
              ) : (
                <ReportDetail
                  loading={this.state.reportLoading}
                  report={this.state.report}
                  companyMode={isCompanyMode(this.props.currentUser)}
                  comments={this.state.comments}
                  program={this.state.program}
                  programLoading={this.state.programLoading}
                  activeComment={this.state.activeComment}
                  onAddComment={this.onAddComment}
                  handleRetest={this.handleRetest}
                  handleMitigate={this.handleMitigate}
                  handleDeleteReport={this.handleDeleteReport}
                  handleChangeStatus={this.handleChangeReportStatus}
                  canExport={this.state.finalAbility.can(
                    'export',
                    subject('Report', this.state.report)
                  )}
                  canDelete={this.state.finalAbility.can(
                    'destroy',
                    subject('Report', this.state.report)
                  )}
                  canEdit={this.state.finalAbility.can(
                    'update',
                    subject('Report', this.state.report)
                  )}
                  canAddComment={this.state.finalAbility.can(
                    'add_comment',
                    subject('Report', this.state.report)
                  )}
                  canChangeStatus={this.state.finalAbility.can(
                    'change_status',
                    subject('Report', this.state.report)
                  )}
                  canChangeRetestStatus={this.state.finalAbility.can(
                    'change_retest_status',
                    subject('Report', this.state.report)
                  )}
                  canChangeMitigationStatus={this.state.finalAbility.can(
                    'change_mitigation_status',
                    subject('Report', this.state.report)
                  )}
                  activities={this.state.activities}
                  activitiesLoading={this.state.activitiesLoading}
                  activitiesTotalItems={this.state.activitiesTotalItems}
                  activitiesFilters={this.state.activitiesFilters}
                  handleActivitiesPageChange={this.handleActivitiesPageChange}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  userAbility: state.ability,
});

export default withTranslation()(connect(mapStateToProps)(ReportViewDefault));
