import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Option = Select.Option;

const ProgramStatusForm = (props) => {
  const { t } = useTranslation();
  const { program } = props;

  return (
    <Form style={{ marginTop: '10px' }}>
      <Col sm={{ span: 24 }} className="mb-3">
        <Select
          placeholder="Estado del programa"
          value={program.status}
          onChange={props.onChangeStatus}
          disable={!program.is_approved}
        >
          <Option value={'inactive'}>{t('programs.status.inactive')}</Option>
          <Option value={'paused'}>{t('programs.status.paused')}</Option>
          <Option value={'closed'}>{t('programs.status.closed')}</Option>
          <Option value={'active'}>{t('programs.status.active')}</Option>
        </Select>
      </Col>
    </Form>
  );
};

export default ProgramStatusForm;
