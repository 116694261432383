import { Button, Col, Input, Row } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import React, { useState, useEffect } from 'react';

import '../styles/profile.less';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

const FormItem = Form.Item;

const OtpForm = (props) => {
  const { t } = useTranslation();
  const { form, onSubmit, centered, auth, isLoading, isModal } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const goBack = () => {
    if (auth) {
      localStorage.clear();
      window.location.href = '/login';
    } else {
      props.history.goBack();
    }
  };

  const handleInputChange = (e) => {
    if (props.forceNumber) {
      const value = e.target.value.replace(/[^0-9]/g, '');
      e.target.value = value;
      setIsButtonDisabled(value.length !== 6);
    } else {
      setIsButtonDisabled(e.target.value.length === 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col span={centered ? 24 : 12}>
          <FormItem>
            {form.getFieldDecorator('token', {
              rules: [
                {
                  required: true,
                  message: t('profile.security.2fa.setup.qr.code-message'),
                },
              ],
            })(
              <Input
                type="text"
                maxLength={props.forceNumber ? 6 : undefined}
                onInput={handleInputChange}
                placeholder={t(
                  'profile.security.2fa.setup.qr.code-placeholder'
                )}
                className="otp-input"
                autoComplete="off"
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          className={centered ? 'centered-content' : 'right-content'}
        >
          <Button
            className="button-secondary mr-2 size-l"
            onClick={() => (isModal ? isModal(false) : goBack())}
          >
            {t('cancel')}
          </Button>
          <Button
            htmlType="submit"
            className="button-primary size-l"
            disabled={isButtonDisabled}
            loading={isLoading}
          >
            {t('continue')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const TwoFactorOtpForm = Form.create()(OtpForm);

export default withRouter(TwoFactorOtpForm);
