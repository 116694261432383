import React, { useState, useEffect } from 'react';
import {
  MinusCircleOutlined,
  UploadOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Upload, message, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../../axiosInstance';
import { handleAxiosError } from '../../utils/Http';

const { Item: FormItem } = Form;

const FileForm = ({ form, value = [], onChange, extraInfo }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [keys, setKeys] = useState(value.map((item, idx) => idx));
  const [keyCount, setKeyCount] = useState(value.length);
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState(extraInfo.map((item) => item.files));

  const { t } = useTranslation();

  useEffect(() => {
    setFieldsValue({ files: value });
  }, []);

  useEffect(() => {
    setKeys(value.map((item, idx) => idx));
    setFieldsValue({ files: value });
  }, [value]);

  // Add a new file input
  const addFile = () => {
    const nextKeys = keys.concat(keyCount);
    setKeyCount(keyCount + 1);
    setKeys(nextKeys);
    const files = getFieldValue('files') || [];
    const nextFiles = files.concat({});
    setFieldsValue({ files: nextFiles });
    onChange(nextFiles);
  };

  // Remove a file input
  const removeFile = (k) => {
    if (keys.length === 1) {
      return;
    }
    setKeys(keys.filter((key) => key !== k));
    const files = getFieldValue('files') || [];
    const nextFiles = files.filter((file, index) => index !== k);
    setFieldsValue({ files: nextFiles });
    onChange(nextFiles);
    let newFileList = fileList.filter((file, index) => index !== k);
    setFileList(newFileList);
  };

  // Handle file change
  const handleFileChange = async (info, index) => {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }

    const files = getFieldValue('files') || [];
    files[index] = { ...files[index], media_id: info.fileList };
    setFieldsValue({ files });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
      }
    });
  };

  const handleUpload = async (file, index) => {
    let formData = new FormData();
    formData.append('file', file);
    try {
      setIsUploading(true);
      const response = await axiosInstance.post(`/media/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const files = getFieldValue('files') || [];
      files[index] = { ...files[index], file_id: response.data.media_id };
      setFieldsValue({ files });
      let newFileList = [...fileList];
      newFileList[index] = [
        {
          uid: response.data.media_id,
          name: response.data.label,
          url: response.data.original_image,
          status: 'done',
        },
      ];
      setFileList(newFileList);
      onChange(files);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsUploading(false);
    }
  };

  // Render file inputs
  const renderFiles = () => {
    if (keys.length > 0) {
      return keys.map((k, index) => (
        <Row key={k}>
          <Col xs={16} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <FormItem>
              {getFieldDecorator(`files[${k}].name`, {
                rules: [
                  { required: true, message: 'Please input the file name!' },
                ],
              })(
                <Input
                  placeholder={t('profile.skills.certificate-placeholder')}
                  style={{ width: '60%', marginRight: 8 }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={4} sm={{ span: 8 }} md={{ span: 10 }} lg={{ span: 10 }}>
            <FormItem>
              {getFieldDecorator(`files[${k}].file_id`, {
                getValueFromEvent: (e) =>
                  Array.isArray(e) ? e : e && e.fileList,
                rules: [{ required: true, message: 'Please upload the file!' }],
              })(<Input type="hidden" />)}
              <Upload
                fileList={fileList[k]}
                multiple={false}
                onChange={(info) => handleFileChange(info, k)}
                beforeUpload={(file) => {
                  handleUpload(file, k);
                  return false;
                }}
                style={{ width: '60%', marginRight: 8 }}
              >
                <Button>
                  <UploadOutlined /> {t('profile.skills.file-button')}
                </Button>
              </Upload>
            </FormItem>
          </Col>
          <Col xs={2} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }}>
            {keys.length > 1 && index !== 0 ? (
              <MinusCircleOutlined
                className="btn-remove-certificate"
                disabled={keys.length === 1}
                onClick={() => removeFile(k)}
              />
            ) : (
              <Button className="btn-add-certificate" onClick={addFile}>
                <PlusCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>
      ));
    } else {
      return (
        <Button className="btn-add-certificate" onClick={addFile}>
          <PlusCircleOutlined />
        </Button>
      );
    }
  };

  return <div>{renderFiles()}</div>;
};

const WrappedFileForm = Form.create({ name: 'file_form' })(FileForm);

export default WrappedFileForm;
