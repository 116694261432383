import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'antd';

import Pagination from '../components/Pagination';
import FilterProgramForm from '../components/FilterProgramForm';
import CreateProgramButton from '../components/CreateProgramButton';
import ProgramItem from '../components/ProgramItem';

import * as _ from 'lodash';
import debounce from 'lodash.debounce';
import axiosInstance from '../axiosInstance';
import CreateProgramModal from '../components/CreateProgramModal';

import { isCompanyMode } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';
import { Link } from 'react-router-dom';

import qs from 'qs';

class Programs extends Component {
  constructor(props) {
    super(props);
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    this.state = {
      results: [],
      totalItems: 0,
      selectedProgramType: null,
      showCreateProgramModal: false,
      filters: {
        page: queryParams['page'] ? parseInt(queryParams['page']) : 1,
        search: queryParams['search'] || '',
        status: queryParams['status'] || undefined,
        is_ptaas:
          queryParams['is_ptaas'] === 'true'
            ? true
            : queryParams['is_ptaas'] === 'false'
            ? false
            : undefined,
      },
      loading: false, // Add loading state
    };

    this.itemsPerPage = 10;
    this.handleSearchChange = debounce(this.handleSearchChange, 300);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const prevQueryParams = qs.parse(prevProps.location.search, {
      ignoreQueryPrefix: true,
    });
    const currentQueryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    const prevPage = prevQueryParams['page']
      ? parseInt(prevQueryParams['page'])
      : 1;
    const currentPage = currentQueryParams['page']
      ? parseInt(currentQueryParams['page'])
      : 1;

    const prevSearch = prevQueryParams['search'];
    const currentSearch = currentQueryParams['search'];

    const prevStatus = prevQueryParams['status'];
    const currentStatus = currentQueryParams['status'];

    const prevIsPtaas =
      prevQueryParams['is_ptaas'] === 'true'
        ? true
        : prevQueryParams['is_ptaas'] === 'false'
        ? false
        : undefined;
    const currentIsPtaas =
      currentQueryParams['is_ptaas'] === 'true'
        ? true
        : currentQueryParams['is_ptaas'] === 'false'
        ? false
        : undefined;

    if (
      currentPage !== prevPage ||
      currentSearch !== prevSearch ||
      currentStatus !== prevStatus ||
      currentIsPtaas !== prevIsPtaas
    ) {
      this.setState(
        {
          filters: {
            page: currentPage,
            search: currentSearch,
            status: currentStatus,
            is_ptaas: currentIsPtaas,
          },
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  fetchData() {
    this.setState({ loading: true });

    const { filters } = this.state;
    const params = {
      ...filters,
    };

    axiosInstance
      .get(`/programs/`, { params })
      .then((response) => {
        this.setState({
          results: response.data.results,
          totalItems: response.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading: false }); // Set loading to false even if there is an error
      });
  }

  handlePageChange = (page) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          page: page,
        },
      }),
      () => {
        this.updateQueryParams();
      }
    );
  };

  handleFilterChange = (name, value) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: value,
          page: null,
        },
      }),
      () => {
        this.updateQueryParams();
      }
    );
  };

  handleSearchChange = (value) => {
    this.handleFilterChange('search', value);
  };

  handleSelectProgramType = (ptype) => {
    this.setState({
      selectedProgramType: ptype,
    });
  };

  handleCreateProgram = () => {
    this.props.history.push({
      pathname: `/dashboard/program/${this.state.selectedProgramType}/create`,
    });
  };

  updateQueryParams() {
    const { filters } = this.state;
    const queryParams = qs.stringify(filters, {
      arrayFormat: 'brackets',
      skipNulls: true,
    });
    this.props.history.push(`?${queryParams}`);
  }

  openCreateProgramModal = () => {
    this.setState({ showCreateProgramModal: true });
  };

  closeCreateProgramModal = () => {
    this.setState({ showCreateProgramModal: false });
  };

  render() {
    const { results, totalItems, filters } = this.state;
    const { t, currentUser, ability } = this.props;

    const totalPages = Math.ceil(totalItems / this.itemsPerPage);

    const showLoading = this.state.loading;

    return (
      <React.Fragment>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            lg={{ span: 18, offset: 3 }}
          >
            <Row className="section-title">
              <Col span={24}>
                <h1>
                  <b>{t('programs.title')}</b>
                </h1>
                <h4>{t('programs.desc')}</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="programs-container">
                  <FilterProgramForm
                    role={currentUser.role}
                    currentPage={filters.page}
                    showInactive={isCompanyMode(this.props.currentUser)}
                    showClosed={isCompanyMode(this.props.currentUser)}
                    filters={filters}
                    onSearchChange={this.handleSearchChange}
                    onFilterChange={this.handleFilterChange}
                    renderCreateButton={() =>
                      ability.can('add_program', 'Company') && (
                        <CreateProgramButton
                          onClick={this.openCreateProgramModal}
                        />
                      )
                    }
                  />

                  <div id="companyProgramList" className="mt-4">
                    {showLoading ? (
                      <LoaderComponent />
                    ) : results.length === 0 ? (
                      <Row className="no-filters">
                        <Col sm={{ offset: 6, span: 12 }}>
                          <Card>
                            <h3 className="text-center">
                              {t('dashboard.programs-list')}
                            </h3>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      results.map((program, index) => {
                        return (
                          <Link
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}
                            key={index}
                            to={`/dashboard/programs/${program.id}`}
                          >
                            <ProgramItem
                              program={program}
                              companyMode={isCompanyMode(
                                this.props.currentUser
                              )}
                              dashboard={false}
                            />
                          </Link>
                        );
                      })
                    )}
                  </div>

                  {!_.isEmpty(results) && (
                    <Pagination
                      currentPage={filters.page}
                      totalPages={totalPages}
                      totalItems={totalItems}
                      verbose={
                        totalItems === 1
                          ? t('programs.verbose_name')
                          : t('programs.verbose_name_plural')
                      }
                      onPageChange={this.handlePageChange}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <CreateProgramModal
          closeModal={this.closeCreateProgramModal}
          openModal={this.openCreateProgramModal}
          visible={this.state.showCreateProgramModal}
          selectedProgramType={this.state.selectedProgramType}
          isPtaasAvailable={ability.can('add_ptaas_program', 'Company')}
          isBugbountyAvailable={ability.can('add_bugbounty_program', 'Company')}
          handleSelectProgramType={this.handleSelectProgramType}
          handleCreateProgram={this.handleCreateProgram}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  ability: state.ability,
});

export default withTranslation()(connect(mapStateToProps)(Programs));
