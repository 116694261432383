import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { handleAxiosError } from '../utils/Http';

import { EditOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Radio, Row, Col, Select } from 'antd';
import * as _ from 'lodash';
import Markdown from 'react-remarkable';

import { MarkOptions, MarkdownClean } from '../utils/MarkdownHelper';
import ReportFormSteps from './ReportFormSteps';
import '../themes/report-form.less';
import '../themes/default.less';
import { withTranslation } from 'react-i18next';

import axiosInstance from '../axiosInstance';

import DynamicUrlList from './DynamicUrlList';

import SeverityField from './SeverityField';
import { openNotification } from '../utils/General';
import '../styles/report-form-new.less';

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { TextArea } = Input;

class ReportFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: props.initialValues || { targets: [null] },
      savingDraft: false,
      saving: false,
      draftMode: false,
    };

    if (props.initialValues) {
      if (props.initialValues.targets.length === 0) {
        this.state.initialValues.targets = [null];
      }
    }
  }

  componentDidMount() {
    const { initialValues, form } = this.props;
    form.setFieldsValue(this.state.initialValues);
  }

  validateTargets = (rule, value, callback) => {
    const { t, program } = this.props;
    if (program.is_ptaas) {
      if (value.length > 1) {
        const f = value.find((item) => item === null);
        if (f !== undefined) {
          callback(
            'Error! You can not add the target "other" with more targets.'
          );
        }
      }
    }
    callback();
  };

  editMarkdown = (e) => {
    const id = e;
    const name = _.split(id, '-', 1);
    const input = document.getElementById(name[0]);
    const markdown = document.getElementsByClassName(name[0] + '-p')[0];

    input.classList.add('ant-input');
    input.classList.remove('hidden');
    markdown.classList.add('hidden');
  };

  showPreview = (e) => {
    const { form } = this.props;
    const id = e;
    const name = _.split(id, '-', 1);
    const value = form.getFieldValue(name[0]);
    const input = document.getElementById(name[0]);
    const markdown = document.getElementsByClassName(id)[0];

    this.setState((state) => {
      state[name] = value;
    });

    input.classList.add('hidden');
    markdown.classList.remove('hidden');
  };

  requiredNotInDraftValidator = (_, value, callback) => {
    const { t } = this.props;
    const { draftMode } = this.state;
    if (draftMode) {
      callback();
      return;
    }
    if (
      value === '' ||
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      callback('This field is required');
    } else {
      callback();
    }
  };

  handleSubmit = () => {
    const { t } = this.props;
    const { draftMode } = this.state;
    this.props.form.validateFieldsAndScroll(
      { force: true },
      async (err, values) => {
        if (!err) {
          try {
            values.is_draft = draftMode;
            values.program = this.props.program.id;

            values.targets = values.targets.filter(
              (item) => item !== null && item !== undefined
            ); // remove 'other'
            values.urls = values.urls.filter((item) => item.url !== '');
            if (!values.attack_type) {
              delete values.attack_type;
            }

            if (values.severity.startsWith('CVSS')) {
              values.cvss_vector = values.severity;
              delete values.severity;
            }
            values.steps = !values.steps
              ? []
              : values.steps.map((item) => {
                  if (!item.media) {
                    return { ...item, media: { media_ids: [] } };
                  }
                  return item;
                });

            if (this.props.initialValues) {
              // Send PATCH request to update the entity

              this.setState({ saving: !draftMode, savingDraft: draftMode });

              const response = await axiosInstance.patch(
                `/reports/${this.props.initialValues.id}/`,
                values
              );
              if (draftMode) {
                openNotification(
                  'success',
                  `${t('notifications.reports.update-draft')}`,
                  t('notifications.reports.update-draft-message')
                );
              } else {
                openNotification(
                  'success',
                  `${t('notifications.reports.update')}`,
                  t('notifications.reports.update-message')
                );
              }
              this.props.history.push(`/dashboard/reports/${response.data.id}`);
            } else {
              // Send POST request to create a new entity
              const response = await axiosInstance.post('/reports/', values);
              if (draftMode) {
                openNotification(
                  'success',
                  `${t('notifications.reports.create-draft')}`,
                  `${t('notifications.reports.create-draft-message')}`
                );
              } else {
                openNotification(
                  'success',
                  `${t('notifications.reports.create')}`,
                  `${t('notifications.reports.create-message')}`
                );
              }
              this.props.history.push(`/dashboard/reports/${response.data.id}`);
            }
          } catch (error) {
            handleAxiosError(error);
          } finally {
            this.setState({ saving: false, savingDraft: false });
          }
        }
      }
    );
  };

  render() {
    const { program, t, attackTypes } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { initialValues, saving, savingDraft } = this.state;

    const targetsList = program.targets.map((target, index) => (
      <Option key={target.id} value={target.id}>
        {target.target || '-'}
      </Option>
    ));
    targetsList.push(
      <Option key="other-option" value={null}>
        {t('other')}
      </Option>
    );

    return (
      <Form
        id="report-form"
        onSubmit={(e) => {
          e.preventDefault();
          this.setState(
            { draftMode: e.nativeEvent.submitter.name === 'draft' },
            () => this.handleSubmit(e)
          );
        }}
        layout="vertical"
      >
        <FormItem label={t('reports.form.name')}>
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Please input the name!' },
              { min: 3, message: 'Name must be at least 3 characters long' },
            ],
          })(<Input placeholder="Enter the name" />)}
        </FormItem>

        <SeverityField
          form={this.props.form}
          initialValue={
            initialValues.cvss_vector
              ? initialValues.cvss_vector
              : initialValues.severity
          }
        />

        <FormItem label={t('reports.form.attack-type')}>
          {getFieldDecorator('attack_type', {
            rules: [
              {
                required: true,
                message: t('reports.form.attack-type-message'),
                validator: this.requiredNotInDraftValidator,
              },
            ],
          })(
            <Select
              showSearch
              placeholder={t('reports.form.attack-type-message')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {attackTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((type, index) => (
                  <Option key={index} value={type.code}>
                    {type.name}
                  </Option>
                ))}
            </Select>
          )}
        </FormItem>

        {program.is_ptaas === true ? (
          <FormItem label="Target">
            {getFieldDecorator(`targets`, {
              rules: [
                {
                  required: true,
                  message: t('reports.form.target-message'),
                  validator: this.requiredNotInDraftValidator,
                },
                { validator: this.validateTargets },
              ],
            })(
              <Select
                mode="multiple"
                placeholder={t('reports.form.target-message')}
              >
                {targetsList}
              </Select>
            )}
          </FormItem>
        ) : (
          <FormItem label="Target">
            {getFieldDecorator(`targets[0]`, {
              rules: [
                {
                  required: true,
                  validator: (_, value, callback) => {
                    if (this.state.draftMode) {
                      callback();
                      return;
                    }
                    if (value === '' || value === undefined) {
                      callback(t('reports.form.target-message'));
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(
              <Select placeholder={t('reports.form.target-message')}>
                {targetsList}
              </Select>
            )}
          </FormItem>
        )}

        <DynamicUrlList
          label={t('reports.form.url')}
          form={this.props.form}
          values={initialValues.urls}
          fieldOptions={{
            rules: [
              { required: true, validator: this.requiredNotInDraftValidator },
            ],
          }}
        />

        <FormItem label={t('reports.form.description')}>
          <div>
            <div className="markdown-header">
              <div className="pull-right">
                <RadioGroup defaultValue="a" className="markdown-buttons">
                  <RadioButton
                    value="a"
                    onClick={() => this.editMarkdown('description-e')}
                  >
                    <EditOutlined />
                  </RadioButton>

                  <RadioButton
                    value="description"
                    onClick={() => this.showPreview('description-p')}
                  >
                    <EyeOutlined />
                  </RadioButton>
                </RadioGroup>
              </div>
            </div>
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input the description!',
                  validator: this.requiredNotInDraftValidator,
                },
                {
                  min: 10,
                  message: 'Description must be at least 10 characters long',
                },
              ],
            })(
              <TextArea
                placeholder={t('reports.form.description-placeholder')}
                rows={6}
              />
            )}
            <div className="tui-editor-contents description-p hidden">
              <Markdown
                source={initialValues.description}
                options={MarkOptions}
              />
            </div>
          </div>
        </FormItem>
        <FormItem label={t('reports.form.impact')}>
          <div className="markdown">
            <div className="markdown-header">
              <div className="pull-right">
                <RadioGroup defaultValue="a" className="markdown-buttons">
                  <RadioButton
                    value="a"
                    onClick={() => this.editMarkdown('impact-e')}
                  >
                    <EditOutlined />
                  </RadioButton>

                  <RadioButton
                    value="b"
                    onClick={() => this.showPreview('impact-p')}
                  >
                    <EyeOutlined />
                  </RadioButton>
                </RadioGroup>
              </div>
            </div>
            {getFieldDecorator('impact', {
              rules: [
                {
                  required: true,
                  message: 'Ingresa el impacto',
                  validator: this.requiredNotInDraftValidator,
                },
              ],
            })(
              <TextArea
                name="impact"
                placeholder={t('reports.form.impact-placeholder')}
                rows={6}
              />
            )}
            <div className="tui-editor-contents impact-p hidden">
              <Markdown source={initialValues.impact} options={MarkOptions} />
            </div>
          </div>
        </FormItem>

        <FormItem label={t('reports.form.counter')}>
          <div className="markdown">
            <div className="markdown-header">
              <div className="pull-right">
                <RadioGroup defaultValue="a" className="markdown-buttons">
                  <RadioButton
                    value="a"
                    onClick={() =>
                      this.editMarkdown('additional_information-e')
                    }
                  >
                    <EditOutlined />
                  </RadioButton>

                  <RadioButton
                    value="b"
                    onClick={() => this.showPreview('additional_information-p')}
                  >
                    <EyeOutlined />
                  </RadioButton>
                </RadioGroup>
              </div>
            </div>
            {getFieldDecorator('additional_information', {
              rules: [
                {
                  required: true,
                  message: t('reports.form.counter-message'),
                  validator: this.requiredNotInDraftValidator,
                },
              ],
            })(
              <TextArea
                rows={6}
                placeholder={t('reports.form.counter-placeholder')}
              />
            )}
            <div className="tui-editor-contents additional_information-p hidden">
              <Markdown
                source={initialValues.additional_information}
                options={MarkOptions}
              />
            </div>
          </div>
        </FormItem>

        <FormItem label={t('reports.form.references.label')}>
          <div className="markdown">
            <div className="markdown-header">
              <div className="pull-right">
                <RadioGroup defaultValue="a" className="markdown-buttons">
                  <RadioButton
                    value="a"
                    onClick={() => this.editMarkdown('reference-e')}
                  >
                    <EditOutlined />
                  </RadioButton>

                  <RadioButton
                    value="b"
                    onClick={() => this.showPreview('reference-p')}
                  >
                    <EyeOutlined />
                  </RadioButton>
                </RadioGroup>
              </div>
            </div>
            {getFieldDecorator('references', {
              rules: [
                {
                  required: false,
                  message: t('reports.form.reference.message'),
                },
              ],
            })(
              <TextArea
                rows={6}
                placeholder={`https://nvd.nist.gov/vuln/detail/CVE-20XX-YYYY\nhttps://owasp.org/Top10/AXXX_XXXX`}
              />
            )}
            <div className="tui-editor-contents additional_information-p hidden">
              <Markdown
                source={initialValues.references}
                options={MarkOptions}
              />
            </div>
          </div>
        </FormItem>

        {!program.is_ptaas && (
          <React.Fragment>
            <FormItem label="Request">
              <div className="markdown">
                <div className="markdown-header">
                  <div className="pull-right">
                    <RadioGroup defaultValue="a" className="markdown-buttons">
                      <RadioButton value="a">
                        <EditOutlined
                          id="request-e"
                          onClick={() => this.editMarkdown('request-e')}
                        />
                      </RadioButton>

                      <RadioButton value="b">
                        <EyeOutlined
                          id="request-p"
                          onClick={() => this.showPreview('request-p')}
                        />
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>
                {getFieldDecorator('request', {
                  initialValue: MarkdownClean(initialValues.request),
                })(
                  <TextArea
                    placeholder='GET /example?&q="><img src=x onerror=alert(document.cookie)> HTTP/1.1
                                Host: www.example.com
                                User-Agent: Mozilla/5.0
                                Accept: */*
                                Accept-Language: en-US,en;q=0.5
                                Accept-Encoding: gzip, deflate
                                Connection: close (9kB)'
                    rows={6}
                  />
                )}
                <div className="tui-editor-contents request-p hidden">
                  <Markdown
                    source={initialValues.request}
                    options={MarkOptions}
                  />
                </div>
              </div>
            </FormItem>

            <FormItem label={t('reports.form.vulnerable')}>
              <div className="markdown">
                <div className="markdown-header">
                  <div className="pull-right">
                    <RadioGroup defaultValue="a" className="markdown-buttons">
                      <RadioButton
                        value="a"
                        onClick={() =>
                          this.editMarkdown('affected_parameters-e')
                        }
                      >
                        <EditOutlined />
                      </RadioButton>

                      <RadioButton
                        value="b"
                        onClick={() =>
                          this.showPreview('affected_parameters-p')
                        }
                      >
                        <EyeOutlined />
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>
                {getFieldDecorator('affected_parameters', {
                  initialValue: MarkdownClean(
                    initialValues.affected_parameters
                  ),
                })(<TextArea placeholder="id=1&user=admin etc etc.." />)}
                <div className="tui-editor-contents affected_parameters-p hidden">
                  <Markdown
                    source={initialValues.affected_parameters}
                    options={MarkOptions}
                  />
                </div>
              </div>
            </FormItem>
            <FormItem label={t('reports.form.payload')}>
              <div className="markdown">
                <div className="markdown-header">
                  <div className="pull-right">
                    <RadioGroup defaultValue="a" className="markdown-buttons">
                      <RadioButton
                        value="a"
                        onClick={() => this.editMarkdown('payload-e')}
                      >
                        <EditOutlined />
                      </RadioButton>

                      <RadioButton
                        value="b"
                        onClick={() => this.showPreview('payload-p')}
                      >
                        <EyeOutlined />
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>
                {getFieldDecorator('payload', {
                  rules: [
                    {
                      required: false,
                      message: t('reports.form.payload-message'),
                    },
                  ],
                  initialValue: MarkdownClean(initialValues.payload),
                })(
                  <TextArea placeholder="javascript:alert(document.cookie);//" />
                )}
                <div className="tui-editor-contents payload-p hidden">
                  <Markdown
                    source={initialValues.payload}
                    options={MarkOptions}
                  />
                </div>
              </div>
            </FormItem>
          </React.Fragment>
        )}
        {this.props.initialValues && initialValues.proof_of_concept && (
          <FormItem label={t('reports.form.poc')}>
            <div className="markdown">
              <div className="markdown-header">
                <div className="pull-right">
                  <RadioGroup defaultValue="a" className="markdown-buttons">
                    <RadioButton
                      value="a"
                      onClick={() => this.editMarkdown('concept_proof-e')}
                    >
                      <EditOutlined />
                    </RadioButton>
                    <RadioButton
                      value="b"
                      onClick={() => this.showPreview('concept_proof-p')}
                    >
                      <EyeOutlined />
                    </RadioButton>
                  </RadioGroup>
                </div>
              </div>
              {getFieldDecorator('proof_of_concept', {
                rules: [
                  { required: false, message: t('reports.form.poc-message') },
                ],
                initialValue: MarkdownClean(initialValues.proof_of_concept),
              })(
                <TextArea
                  placeholder='<html>
                            <body>
                            <form action="https://www.google.com/example" method="POST">
                            <input type="hidden" name="change" value="12" />
                            <input type="submit" value="Submit request" />
                            </form>
                            </body>
                            </html>'
                  rows={6}
                />
              )}
              <div className="tui-editor-contents concept_proof-p hidden">
                <Markdown
                  source={initialValues.concept_proof}
                  options={MarkOptions}
                />
              </div>
            </div>
          </FormItem>
        )}

        <ReportFormSteps
          initialValues={
            this.props.initialValues && this.props.initialValues.steps
          }
          form={this.props.form}
          firstFieldOptions={{
            rules: [
              { required: true, validator: this.requiredNotInDraftValidator },
            ],
          }}
        />

        <FormItem>
          {!this.props.initialValues || initialValues.is_draft ? (
            <Row gutter={6}>
              <Col xs={24} sm={12} md={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  name="draft"
                  className="btn-draft btn-block"
                >
                  {savingDraft ? (
                    <div>
                      <LoadingOutlined /> {t('reports.form.saving')}
                    </div>
                  ) : (
                    <p className="p-button">
                      {t('reports.form.save-draft-button')}
                    </p>
                  )}
                </Button>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  name="final"
                  className="btn-hacker btn-block"
                >
                  {saving ? (
                    <div>
                      <LoadingOutlined /> {t('reports.form.saving')}
                    </div>
                  ) : (
                    <p className="p-button">{t('reports.form.save-button')}</p>
                  )}
                </Button>
              </Col>
            </Row>
          ) : (
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                name="final"
                className="btn-hacker btn-block"
              >
                {saving ? (
                  <div>
                    <LoadingOutlined /> {t('reports.form.saving')}
                  </div>
                ) : (
                  <p className="p-button">{t('reports.form.save-button')}</p>
                )}
              </Button>
            </Col>
          )}
        </FormItem>
      </Form>
    );
  }
}

const NormalReportForm = Form.create()(ReportFormNew);

export default withTranslation()(withRouter(NormalReportForm));
