import * as Action from '../actions/ActionTypes';

const initialState = {
  isFetching: false,
  hasError: false,
  errorMessage: null,
  services: [],
};

class ServiceReducer {
  static reduce(state = initialState, action) {
    if (ServiceReducer[action.type]) {
      return ServiceReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.GET_SERVICE_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_SERVICE_SUCCESS](state, action) {
    const result = action.response.results;
    return {
      ...state,
      isFetching: false,
      hasError: false,
      services: result,
    };
  }

  static [Action.GET_SERVICE_ERROR](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
}

export default ServiceReducer.reduce;
