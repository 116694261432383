import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Col, Row, Tooltip } from 'antd';
import UserDetail from './UserDetail';
import '../themes/ranking-profile.less';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

class RankingProfileView extends Component {
  onLinkClick(programId) {
    if (!programId) {
      openNotification('error', 'No estás autorizado para ver este programa.');
    }
  }

  getMainPrograms(programs) {
    return programs.map((program, i) => {
      const linkUrl = program.id ? `/dashboard/programs/${program.id}` : '#';

      return (
        <Col xs={6} sm={6} key={i}>
          {program.id ? (
            <Link to={linkUrl} onClick={() => this.onLinkClick(program.id)}>
              <Tooltip title={program.name}>
                <Avatar
                  src={
                    program.avatar_thumbnail_s_url ||
                    '/images/program-default.png'
                  }
                  size="large"
                  alt={program.name}
                />
              </Tooltip>
            </Link>
          ) : (
            <Tooltip title={program.name}>
              <Avatar
                src={
                  program.avatar_thumbnail_s_url ||
                  '/images/program-default.png'
                }
                size="large"
                alt={program.name}
              />
            </Tooltip>
          )}
        </Col>
      );
    });
  }

  render() {
    const {
      profile,
      profile: {
        ranking,
        valid_reports,
        average_score,
        accuracy,
        score,
        programs_on_wall,
      },
      t,
    } = this.props;
    return (
      <div id="RankingProfileView">
        <Row justify={'center'} gutter={[16, 16]}>
          <Col span={24}>
            <UserDetail currentUser={profile} />
          </Col>
          <Col xs={12} sm={12} md={12}>
            <Card>
              <h2 style={{ fontSize: '20px' }}>Ranking</h2>
              <h1
                className="text-center"
                style={{ fontSize: '30px' }}
              >{`${ranking}`}</h1>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <Card>
              <h2 style={{ fontSize: '20px' }}>{t('ranking.points')}</h2>
              <h1
                className="text-center"
                style={{ fontSize: '30px' }}
              >{`${score} pts`}</h1>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <h2 className="text-center" style={{ fontSize: '20px' }}>
                {t('ranking.performance')}
              </h2>
              <Row>
                <Col xs={12} sm={8} md={8}>
                  <Card>
                    <h1 className="text-center" style={{ fontSize: '30px' }}>
                      {valid_reports}
                    </h1>
                    <h3 className="text-center">
                      {t('ranking.vulnerabilities')}
                    </h3>
                  </Card>
                </Col>

                <Col xs={12} sm={8} md={8}>
                  <Card>
                    <h1 className="text-center" style={{ fontSize: '30px' }}>
                      {Math.round(accuracy * 100)}%
                    </h1>
                    <h3 className="text-center">{t('ranking.accuracy')}</h3>
                  </Card>
                </Col>

                <Col xs={24} sm={8} md={8}>
                  <Card>
                    <h1 className="text-center" style={{ fontSize: '30px' }}>
                      {average_score}
                    </h1>
                    <h3 className="text-center">{t('ranking.average')}</h3>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card>
              <h2 style={{ fontSize: '20px' }}>{t('ranking.wall')}</h2>

              <p>{t('ranking.wall-programs')}</p>

              <Row id="ProgramsOnWall">
                {this.getMainPrograms(programs_on_wall)}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(RankingProfileView);
