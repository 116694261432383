import React, { Component } from 'react';
import { Row, Card } from 'antd';
import NumberDisplay from '../utils/NumberDisplay';
import { withTranslation } from 'react-i18next';

class PaymentTotalAmountWallet extends Component {
  render() {
    const { title, value, is_ptaas, t } = this.props;

    return (
      <Card
        className="total-amount"
        style={{
          padding: '0px',
          height: '110px',
          width: '100%',
          marginTop: '5px',
        }}
        bodyStyle={{ padding: '0px' }}
      >
        <h5
          className="text-left"
          style={{
            fontSize: '15px',
            fontWeight: 'bold',
            marginTop: '10px',
            marginLeft: '5px',
          }}
        >
          {title}
        </h5>
        <Row style={{ padding: '0px' }}>
          <h3
            style={{
              textAlign: 'end',
              padding: '0px',
              position: 'absolute',
              right: '2.0rem',
              top: '1.5rem',
            }}
          >
            <NumberDisplay number={value} />
            <span
              style={{
                fontSize: 'initial',
                fontWeight: 'normal',
                textTransform: 'uppercase',
              }}
            >
              {is_ptaas ? ' ' + t('budget.ptaas.converter.credits') : ' USD'}
            </span>
          </h3>
        </Row>
      </Card>
    );
  }
}

export default withTranslation()(PaymentTotalAmountWallet);
