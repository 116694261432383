import React, { Component } from 'react';
import { Col, Row } from 'antd';
import RankingProfileView from '../components/RankingProfileView';

import axiosInstance from '../axiosInstance';
import LoaderComponent from '../components/LoaderComponent';

class RankingProfile extends Component {
  state = {
    profile: null,
    loading: false,
  };

  fetchRankingForProfile() {
    this.setState({ loading: true });

    const params = {};

    axiosInstance
      .get(`/hackers/${this.props.match.params.slug}/`, { params })
      .then((response) => {
        this.setState({
          profile: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.fetchRankingForProfile();
  }

  render() {
    const { loading, profile } = this.state;

    return (
      <Row justify={'center'} className="mt-3 mb-3">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 18 }}>
          {loading || profile === null ? (
            <LoaderComponent label={false} />
          ) : (
            <RankingProfileView profile={profile} />
          )}
        </Col>
      </Row>
    );
  }
}

export default RankingProfile;
