import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, notification } from 'antd';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const { TextArea } = Input;

class CommentForm extends Component {
  state = {
    buttonVisible: false,
  };

  formRef = React.createRef();

  handleFocus = () => {
    this.setState({ buttonVisible: true });
  };

  handleClickOutside = (event) => {
    if (this.formRef.current && !this.formRef.current.contains(event.target)) {
      this.setState({ buttonVisible: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      form: { setFieldsValue },
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onAddComment(values.content);
        setFieldsValue({ content: '' });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div ref={this.formRef}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('content', {
              rules: [{ required: true, message: 'Ingresa tu comentario' }],
              initialValue: this.props.activeComment,
            })(
              <TextArea
                placeholder={this.props.t('reports.comments.comment')}
                rows={6}
                onFocus={this.handleFocus}
              />
            )}
          </FormItem>
          {this.state.buttonVisible && (
            <FormItem>
              <Row>
                <Col xs={{ span: 24, offset: 0 }} span={8} offset={16}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-hacker btn-block"
                    loading={false}
                  >
                    <p className="p-button">
                      {this.props.t('reports.comments.comment-button')}
                    </p>
                  </Button>
                </Col>
              </Row>
            </FormItem>
          )}
        </Form>
      </div>
    );
  }
}

const NormalCommentForm = Form.create()(CommentForm);

export default withTranslation()(NormalCommentForm);
