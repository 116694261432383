import moment from 'moment';
import * as Action from '../actions/ActionTypes';
import { saveAs } from 'file-saver';

const initialState = {
  available_wallet_amount: null,
  available_quote: null,
  available_total: null,
  requesting: false,
  hasError: false,
  errorMessage: null,
  numberOfElements: null,
  numberOfPages: 1,
  isFetching: false,
  wallet: {
    available: null,
    expended: null,
    list: null,
    requested: null,
  },
  program: {
    available: null,
    expended: null,
    list: null,
    locked: null,
    quote: null,
  },
  fundsInfo: {
    affected: null,
    amount: null,
    commission: null,
    commission_starts_at: null,
    yearly_bounty_limit: null,
  },
  conversion: null,
};

class WalletReducer {
  static reduce(state = initialState, action) {
    if (WalletReducer[action.type]) {
      return WalletReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.GET_WALLET_STATUS_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.GET_WALLET_STATUS_SUCCESS](state, action) {
    const {
      response: {
        result: { available_wallet_amount, available_quote, available_total },
      },
    } = action;

    const res = {
      ...state,
      available_quote: available_quote,
      available_total: available_total,
      available_wallet_amount: available_wallet_amount,
      hasError: false,
      requesting: false,
    };
    return res;
  }

  static [Action.GET_WALLET_STATUS_ERRORS](state) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.PUT_WALLET_FUNDS_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.PUT_WALLET_FUNDS_SUCCESS](state) {
    const res = {
      ...state,
      hasError: false,
      requesting: false,
    };
    return res;
  }

  static [Action.PUT_WALLET_FUNDS_ERRORS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.ADD_WALLET_CREDITS_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.ADD_WALLET_CREDITS_SUCCESS](state) {
    const res = {
      ...state,
      hasError: false,
      requesting: false,
    };
    return res;
  }

  static [Action.ADD_WALLET_CREDITS_ERRORS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.PUT_PROGRAM_FUNDS_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.PUT_PROGRAM_FUNDS_SUCCESS](state) {
    const res = {
      ...state,
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.PUT_PROGRAM_FUNDS_ERRORS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
  static [Action.GET_PROGRAM_HISTORY_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.GET_PROGRAM_HISTORY_SUCCESS](state, action) {
    const {
      response: {
        results,
        available,
        total_budget,
        total_paid,
        locked
      },
    } = action;

    const res = {
      ...state,
      hasError: false,
      requesting: false,
      program: {
        available: available,
        expended: total_paid,
        locked: locked,
        quote: total_budget,
        list: results,
        name: '',
      },
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
    };

    return res;
  }

  static [Action.GET_PROGRAM_HISTORY_ERRORS](state) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.GET_HISTORYWALLET_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_HISTORYWALLET_SUCCESS](state, action) {
    const res = {
      ...state,
      hasError: false,
      isFetching: false,
      wallet: {
        available: action.response.current_balance,
        expended: action.response.credited,
        list: action.response.results,
        requested: action.response.debited,
      },
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
    };
    return res;
  }

  static [Action.GET_HISTORYWALLET_ERRORS](state) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
    };
  }

  static [Action.GET_HISTORY_WALLET_PTAAS_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_HISTORY_WALLET_PTAAS_SUCCESS](state, action) {
    const res = {
      ...state,
      hasError: false,
      isFetching: false,
      wallet: {
        available: action.response.current_balance,
        expended: action.response.credited,
        list: action.response.results,
        requested: action.response.debited,
      },
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
    };
    return res;
  }

  static [Action.GET_HISTORYWALLET_ERRORS](state) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
    };
  }

  static [Action.GET_CONVERSION_HISTORY_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_CONVERSION_HISTORY_SUCCESS](state, action) {
    return {
      ...state,
      conversion: action.response.results,
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.GET_CONVERSION_HISTORY_ERRORS](state) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
    };
  }

  static [Action.GET_COMMISSION_REQUEST](state) {
    return {
      ...state,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.GET_COMMISSION_SUCCESS](state, action) {
    const {
      affected,
      amount,
      commission,
      commission_starts_at,
      yearly_bounty_limit,
      commission_percentage,
    } = action.response;
    const res = {
      ...state,
      hasError: false,
      fundsInfo: {
        affected: affected,
        amount: amount,
        commission: commission,
        commission_starts_at: commission_starts_at,
        commission_percentage: commission_percentage,
        yearly_bounty_limit: yearly_bounty_limit,
      },
    };

    return res;
  }

  static [Action.GET_COMMISSION_ERRORS](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EXPORT_WALLET_HISTORY_REQUEST](state) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_WALLET_HISTORY_SUCCESS](state, action) {
    const { response } = action;
    if (response.type === 'text/csv') {
      saveAs(
        response,
        `Wallet History - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
      );
    } else {
      saveAs(
        response,
        `Wallet History - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`
      );
    }

    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_WALLET_HISTORY_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }

  static [Action.EXPORT_PROGRAM_HISTORY_REQUEST](state) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_PROGRAM_HISTORY_SUCCESS](state, action) {
    const { response } = action;
    if (response.type === 'text/csv') {
      saveAs(
        response,
        `Program History - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
      );
    } else {
      saveAs(
        response,
        `Program History - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`
      );
    }

    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_PROGRAM_HISTORY_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }

  static [Action.GET_CONVERSION_REQUEST](state) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_CONVERSION_SUCCESS](state) {
    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.GET_CONVERSION_ERRORS](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.SIGN_OUT]() {
    return initialState;
  }
}

export default WalletReducer.reduce;
