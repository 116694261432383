import React, { useState } from 'react';
import HelperSizeCheck from '../../utils/HelperSizeCheck';

import axiosInstance from '../../axiosInstance';

import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button, Row, Col, Upload, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import '../../themes/program-form.less';
import '../../themes/default.less';

// Variables
const FormItem = Form.Item;
const confirm = Modal.confirm;

const ManyFilesField = ({
  value = [],
  onChange,
  filesProp,
  onRemoveItem,
  onSuccess,
  onError,
  onFileSizeError,
}) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState(filesProp);

  const handleDeleteFile = (fId, index) => {
    confirm({
      title: t(`dialogs.titles.are-you-sure-file-deletion`),
      okText: t('yes'),
      cancelText: t('no'),
      okType: 'danger',
      onOk: () => {
        setFiles(files.filter((f) => f.id !== fId));
        onChange(value.filter((e) => e !== fId));
        onRemoveItem();
      },
      onCancel() {},
    });
  };

  const props = {
    customRequest: () => {},
    onChange: (info) => {
      let formData = new FormData();
      formData.append('file', info.file.originFileObj);
      const allowUpload = HelperSizeCheck.checkSize(info);

      if (allowUpload) {
        setIsUploading(true);
        axiosInstance
          .post(`/media/upload/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            setIsUploading(false);
            let modFile = { ...response.data };
            modFile['id'] = modFile.media_id;
            delete modFile.media_id;
            setFiles([...files, modFile]);
            let newValue = [...value, response.data.media_id];
            onChange(newValue);
            onSuccess();
          })
          .catch((error) => {
            setIsUploading(false);
            onError();
          });
      } else {
        onFileSizeError();
      }
    },
  };

  return (
    <Row gutter={16}>
      <Col sm={12}>
        <FormItem label={t('programs.form.files')}>
          <Upload {...props} showUploadList={false}>
            <Button className="programFile">
              <UploadOutlined /> {t('programs.form.files-upload-button')}
            </Button>
          </Upload>
          <div>
            <ul className="custom-list">
              {files.map((f, index) => (
                <li key={index}>
                  <span style={{ color: 'blue' }}>
                    <PaperClipOutlined />
                    &nbsp;{' '}
                    <a href={f.original_image} target="_blank">
                      {f.label || f.name}
                    </a>
                  </span>
                  <span style={{ marginLeft: '15px' }}>
                    <DeleteOutlined onClick={() => handleDeleteFile(f.id, index)} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </FormItem>
      </Col>

      <Col sm={12}>
        <small className="fileDescription">
          {t('programs.form.files-rules')}
        </small>
      </Col>
    </Row>
  );
};

export default ManyFilesField;
