import { REHYDRATE } from 'redux-persist/constants';

const initialState = {
  isHydrated: false,
};

class Persist {
  static reduce(state = initialState, action) {
    switch (action.type) {
      case REHYDRATE:
        return {
          ...state,
          isHydrated: true,
        };
      default:
        return state;
    }
  }
}

export default Persist.reduce;
