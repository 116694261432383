import React from 'react';
import { EnterOutlined } from '@ant-design/icons';
import { Col, Row, Card, Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';

const AcademyStationX = () => {
  const { t } = useTranslation();

  const smSize = window.innerWidth < 1200;
  const xsSize = window.innerWidth < 768;

  const STATIONX_AFFILIATE_URL1 = process.env.REACT_APP_STATIONX_AFFILIATE_URL;

  return (
    <Row type="flex" style={{ alignItems: 'center' }} justify="center">
      <Col
        xl={{ span: 8, order: 1 }}
        lg={{ span: 10, order: 1 }}
        sm={{ span: 20, order: 2 }}
        xs={{ span: 20, order: 2 }}
      >
        <div
          style={{
            width: xsSize ? '100%' : '75%',
            display: 'inline-block',
            marginTop: smSize ? '5vh' : '0vh',
            marginBottom: smSize ? '5vh' : '0vh',
          }}
        >
          <a
            href={STATIONX_AFFILIATE_URL1}
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'black',
              textDecoration: 'none',
              display: 'block',
            }}
          >
            <Image
              style={{ width: '100%', display: 'block' }}
              src="/images/stationx.png"
              preview={false}
            />
            <Button
              type="primary"
              ghost
              style={{
                width: '100%',
                height: '40px',
                fontSize: '130%',
                borderColor: 'grey',
                color: 'grey',
                fontWeight: 'bold',
                marginTop: '1vh',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {t('academy.stationx.skills')}
              <EnterOutlined />
            </Button>
          </a>
        </div>
      </Col>
      <Col
        xl={{ span: 10, order: 1 }}
        lg={{ span: 10, order: 1 }}
        sm={{ span: 20, order: 1 }}
        xs={{ span: 20, order: 1 }}
      >
        <div style={{ fontSize: '19px', marginTop: smSize ? '5vh' : '0vh' }}>
          {t('academy.stationx.detail.section1.part1')}
          <strong>{t('academy.stationx.detail.bold')}.</strong>
        </div>
        <div style={{ fontSize: '19px', marginTop: '2vh' }}>
          <strong>{t('academy.stationx.detail.bold')}</strong>
          {t('academy.stationx.detail.section2.part1')}
        </div>
        <div style={{ fontSize: '19px', marginTop: '2vh' }}>
          <strong>{t('academy.stationx.detail.bold')}</strong>
          {t('academy.stationx.detail.section3.part1')}
        </div>
        <div style={{ fontSize: '19px', marginTop: '2vh' }}>
          <strong>{t('academy.stationx.detail.bold')}</strong>
          {t('academy.stationx.detail.section4.part1')}
        </div>
        <div
          style={{
            fontSize: '19px',
            marginTop: '2vh',
            marginBottom: '2vh',
          }}
        >
          {t('academy.stationx.detail.section5.part1')}
        </div>
      </Col>
    </Row>
  );
};

export default AcademyStationX;
