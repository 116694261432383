import { saveAs } from 'file-saver';
import * as Action from '../actions/ActionTypes';
import * as _ from 'lodash';
import moment from 'moment';

const initialState = {
  requesting: false,
  isFetching: false,
  isPushingFile: false,
  isRemoved: false,
  isSavedFile: false,
  isRemovedFile: false,
  isUpdate: false,
  hasError: null,
  uuid: null,
  program: null,
  files: [],
  errorMessage: null,
  isSavedTarget: false,
  avatarLoaded: true,
  avatarRequesting: false,
  avatarNotFound: null,
  fileName: [],
  fileUid: '',
  success: false,
  sudoku: null,
};

class ProgramReducer {
  static reduce(state = initialState, action) {
    if (ProgramReducer[action.type]) {
      return ProgramReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.GET_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.GET_PROGRAM_SUCCESS](state, action) {
    const result = action.response;
    const files = [];

    return {
      ...state,
      isFetching: false,
      hasError: false,
      program: result,
      files: files,
    };
  }

  static [Action.GET_PROGRAM_ERROR](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
      program: null,
      errorMessage: action.error,
    };
  }

  static [Action.USER_JOIN_PROGRAMS_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.USER_JOIN_PROGRAMS_SUCCESS](state, action) {
    return {
      ...state,
      program: {
        ...state.program,
        amJoined: true,
      },
      isPushing: false,
      hasError: false,
      isJoined: true,
    };
  }

  static [Action.USER_JOIN_PROGRAMS_ERRORS](state, action) {
    return {
      ...state,
      program: {
        ...state.program,
        amJoined: true,
      },
      isPushing: false,
      hasError: false,
      isJoined: true,
    };
  }

  static [Action.CREATE_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.CREATE_PROGRAM_SUCCESS](state, action) {
    const result = action.response;
    return {
      ...state,
      isPushing: false,
      isSaved: true,
      uuid: result.id,
      hasError: false,
    };
  }

  static [Action.CREATE_PROGRAM_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CREATE_PROGRAM_FILE_REQUEST](state, action) {
    return {
      ...state,
      isPushingFile: true,
      hasError: false,
    };
  }

  static [Action.CREATE_PROGRAM_FILE_SUCCESS](state, action) {
    const result = action.response;
    const { program } = state;
    const files = [...state.files];

    files.push({
      label: result.label,
      media_id: result.media_id,
      original_image: result.original_image,
      size: result.size,
      thumbnail_180: result.thumbnail_180,
    });
    return {
      ...state,
      files: files[files.length - 1],
      fileName: files.map((m) => m.label),
      isPushingFile: false,
      isSavedFile: true,
      hasError: false,
    };
  }

  static [Action.CREATE_PROGRAM_FILE_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushingFile: false,
    };
  }

  static [Action.REMOVE_PROGRAM_FILE_REQUEST](state, action) {
    return {
      ...state,
      isPushingFile: true,
    };
  }

  static [Action.REMOVE_PROGRAM_FILE_SUCCESS](state, action) {
    const { result } = action.response;
    const file = _.filter(state.files, (file) => {
      return file.id === result.id;
    });
    _.remove(state.files, (file) => {
      return file.id === result.id;
    });

    return {
      ...state,
      isPushingFile: false,
      isRemovedFile: true,
    };
  }

  static [Action.REMOVE_PROGRAM_FILE_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushingFile: false,
    };
  }

  static [Action.CREATE_PROGRAM_TARGET_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      isSavedTarget: false,
    };
  }

  static [Action.CREATE_PROGRAM_TARGET_SUCCESS](state, action) {
    const result = action.response.result;
    const newTarget = {
      id: result.id,
      file: result.description,
      file_url: result.file_url,
    };

    state.program.targets.push(newTarget);

    return {
      ...state,
      isPushing: false,
      isSavedTarget: true,
      program: {
        ...state.program,
      },
    };
  }

  static [Action.CREATE_PROGRAM_TARGET_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }

  static [Action.REMOVE_PROGRAM_TARGET_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.REMOVE_PROGRAM_TARGET_SUCCESS](state, action) {
    const { result } = action.response;
    const target = _.filter(state.program.targets, (target) => {
      return target.id === result.id;
    });

    _.remove(state.program.targets, (target) => {
      return target.id.toString() === result.id;
    });

    return {
      ...state,
      isPushing: false,
      isRemoved: true,
      program: {
        ...state.program,
      },
    };
  }

  static [Action.REMOVE_PROGRAM_TARGET_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }

  static [Action.UPDATE_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
      isUpdate: false,
    };
  }

  static [Action.UPDATE_PROGRAM_SUCCESS](state, action) {
    return {
      ...state,
      program: action.response,
      isPushing: false,
      isUpdate: true,
    };
  }

  static [Action.UPDATE_PROGRAM_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      isUpdate: false,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_DESCRIPTION_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
      isSaved: false,
    };
  }

  static [Action.UPDATE_DESCRIPTION_PROGRAM_SUCCESS](state, action) {
    const { result } = action.response;

    return {
      ...state,
      program: {
        ...state.program,
        description: result.description,
      },
      isPushing: false,
      isSaved: true,
    };
  }

  static [Action.UPDATE_DESCRIPTION_PROGRAM_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_AVATAR_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
      isSaved: false,
    };
  }

  static [Action.UPDATE_AVATAR_PROGRAM_SUCCESS](state, action) {
    return {
      ...state,
      program: {
        ...state.program,
        avatar_url: action.response.image,
      },
      isPushing: false,
      isSaved: true,
    };
  }

  static [Action.UPDATE_AVATAR_PROGRAM_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_AVATAR_PROGRAM](state, action) {
    const { program_uuid, url, avatar, program } = action;

    return {
      ...state,
      isPushingFile: false,
      isSavedFile: true,
      avatarRequesting: true,
      avatarLoaded: false,
      avatarNotFound: null,
      program: {
        ...state.program,
        avatar_url: url,
        avatar: avatar,
      },
    };
  }

  static [Action.LIST_PROGRAM_FEATURES_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.LIST_PROGRAM_FEATURES_SUCCESS](state, action) {
    const { results, count } = action.response;
    return {
      ...state,
      isPushing: false,
      hasError: false,
      program: {
        ...state.program,
        features: {
          ...state.program.features,
          entities: results,
          totalLength: count,
        },
      },
    };
  }

  static [Action.LIST_PROGRAM_FEATURES_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.ADD_PROGRAM_FEATURE_SUCCESS](state, action) {
    const { program } = state;
    if (program && program.features) {
      const { data } = action;
      const {
        features: { totalLength },
      } = program;

      const programFeatures = _.orderBy(
        _.values(data.programFeatures),
        ['created_at_original_format'],
        ['desc']
      );

      return {
        ...state,
        program: {
          ...state.program,
          features: {
            ...state.program.features,
            entities: programFeatures,
          },
        },
      };
    } else {
      return { ...state };
    }
  }

  static [Action.CREATE_PROGRAM_FEATURE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.CREATE_PROGRAM_FEATURE_SUCCESS](state, action) {
    const { response, total_length } = action.response;
    return {
      ...state,
      isPushing: false,
      hasError: false,
      program: {
        ...state.program,
      },
    };
  }

  static [Action.CHANGE_PROGRAM_STATE_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
    };
  }

  static [Action.CHANGE_PROGRAM_STATE_SUCCESS](state, action) {
    const { status } = action.response;

    return {
      ...state,
      isFetching: false,
      hasError: false,
      program: {
        ...state.program,
        status: status,
        success: true,
      },
    };
  }

  static [Action.CHANGE_PROGRAM_STATE_ERROR](state, action) {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.error,
      hasError: true,
    };
  }

  static [Action.CREATE_PROGRAM_FEATURE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EXPORT_SUDOKU_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_SUDOKU_SUCCESS](state, action) {
    const { response } = action;

    return {
      ...state,
      sudoku: response,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_SUDOKU_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }

  static [Action.CLEAR_PROGRAM](state, action) {
    return initialState;
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default ProgramReducer.reduce;
