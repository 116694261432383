import * as Action from './ActionTypes';
import { routes } from '../../constants/APIEndpoints';
import { CALL_API } from '../../middleware/api';

const getComments = (id) => ({
  [CALL_API]: {
    types: [
      Action.LIST_COMMENTS_REQUEST,
      Action.LIST_COMMENTS_SUCCESS,
      Action.LIST_COMMENTS_ERRORS,
    ],
    endpoint: routes.GET_COMMENTS.replace(':id', id),
    method: 'GET',
    data: {},
  },
});

const saveComment = (id, params) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_COMMENT_REQUEST,
      Action.CREATE_COMMENT_SUCCESS,
      Action.CREATE_COMMENT_ERRORS,
    ],
    endpoint: routes.CREATE_COMMENT.replace(':id', id),
    method: 'POST',
    data: params,
  },
});

const deleteComment = (id, reportID) => ({
  [CALL_API]: {
    types: [
      Action.DELETE_COMMENT_REQUEST,
      Action.DELETE_COMMENT_SUCCESS,
      Action.DELETE_COMMENT_ERRORS,
    ],
    endpoint: routes.DELETE_COMMENT.replace(':uuid', reportID).replace(
      ':id',
      id
    ),
    method: 'DELETE',
    data: {},
  },
});

export const getCommentsReports = (uuid) => (dispatch, getState) => {
  return dispatch(getComments(uuid));
};

export const deleteComments = (id, reportID) => (dispatch, getState) => {
  return dispatch(deleteComment(id, reportID));
};

export const createComment = (uuid, params) => (dispatch, getState) => {
  return dispatch(saveComment(uuid, params));
};

export const addComment = (params) => (dispatch) => {
  return dispatch({
    type: Action.ADD_COMMENT_SUCCESS,
    payload: false,
    data: params,
  });
};

export const reportEmptyComments = () => (dispatch) => {
  return dispatch({
    type: Action.LIST_EMPTY_COMMENTS,
    payload: [],
  });
};
