import React, { Component } from 'react';
import { Row, Card } from 'antd';
import NumberDisplay from '../utils/NumberDisplay';

class PaymentTotalAmount extends Component {
  render() {
    const { title, value, currency } = this.props;

    return (
      <Card
        className="total-amount"
        style={{ padding: '0px', height: '120px' }}
        bodyStyle={{ padding: '0px' }}
      >
        <h5 className="text-left title-card-amount">{title}</h5>
        <h3 className="card-amount">
          <NumberDisplay number={value} />
          <span>{currency ? ' USD' : ''}</span>
        </h3>
      </Card>
    );
  }
}

export default PaymentTotalAmount;
