import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Avatar, Input, Button, Row, Col, Select, Upload } from 'antd';
import ImageCrop from './ImageCrop';
import withSizes from 'react-sizes';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';
import { openNotification } from '../utils/General';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class CompanyProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: props.initialValues || {},
      fileList: [],
      sectorList: [],
      mayCropFile: false,
      loadingButton: false,
      companyAvatarRequesting: false,
      companyAvatarLoading: false,
      companyAvatarUploadContent: null,
      sectors: [],
      sectorsLoading: false,
    };
  }

  componentDidMount() {
    this.fetchSectors();
    const { form } = this.props;
    form.setFieldsValue(this.state.initialValues);
  }

  getUploader = () => {
    const { t } = this.props;
    const props = {
      customRequest: function () {
        return null;
      },
      multiple: false,
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        this.setState({ hasFile: false });
        this.props.form.resetFields(['avatar']);
      },
      beforeUpload: (file) => {
        if (this.state.fileList.length < 1) {
          this.setState(({ fileList }) => ({
            fileList: [...fileList, file],
            hasFile: true,
            mayCropFile: true,
          }));
        }
        return false;
      },
      fileList: this.state.fileList,
    };

    return (
      <Upload
        {...props}
        className="userUploader"
        disabled={this.props.readonly}
      >
        <Button
          disabled={this.state.hasFile || this.props.readonly}
          className="button-primary"
          style={{
            height: 40,
            padding: '10px 40px',
          }}
        >
          <p>{t('profile.add-photo-button')}</p>
        </Button>
      </Upload>
    );
  };

  fetchSectors() {
    this.setState({ sectorsLoading: true });

    const params = {};

    axiosInstance
      .get(`/companies/sectors/`, { params })
      .then((response) => {
        this.setState({
          sectors: response.data,
          sectorsLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ sectorsLoading: false }); // Set loading to false even if there is an error
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, t } = this.props;
    const { fileList } = this.state;

    form.validateFieldsAndScroll({ force: true }, async (err, values) => {
      if (!err) {
        try {
          let response;
          this.setState({
            loadingButton: true,
          });
          response = await axiosInstance.patch(`/current-company/`, values);

          this.setState({
            loadingButton: false,
            initialValues: {
              name: response.data.name,
              sector: response.data.sector,
              website: response.data.website,
              description: response.data.description,
              corporate_name: response.data.corporate_name,
              address: response.data.address,
              phone: response.data.phone,
              contact_email: response.data.contact_email,
              country: response.data.country,
              commercial_number: response.data.commercial_number,
              business_activity: response.data.business_activity,
            },
          });
          openNotification(
            'success',
            `${t('notifications.profile.update')}`,
            `${t('notifications.profile.update-company')}`
          );
        } catch (error) {
          handleAxiosError(error);
        } finally {
          this.setState({
            loadingButton: false,
          });
        }
      }
    });
  };

  cropImageDone = (croppedFile) => {
    const { form, file } = this.props;
    if (croppedFile != null) {
      this.setState({ mayCropFile: false, fileList: [croppedFile] });
      form.setFieldsValue({
        avatar: { file: croppedFile, fileList: [croppedFile] },
      });
    } else {
      this.setState(({ fileList }) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
      this.setState({ hasFile: false, mayCropFile: false });
      this.props.form.resetFields(['avatar']);
    }
  };

  render() {
    const {
      currentUser,
      form: { getFieldDecorator },
      isMobile,
      t,
    } = this.props;
    const {
      mayCropFile,
      fileList,
      companyAvatarRequesting,
      companyAvatarUploadContent,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <h4 className="profile-title">{t('profile.company.title')}</h4>
        <Row
          gutter={4}
          type="flex"
          justify="start"
          align="middle"
          className="avatar-row mb-3"
        >
          <Col xs={12} xl={6} className="avatar-col">
            <p className="subtitle-profile mt-3">{t('profile.add-photo')}</p>
            <Avatar
              size="large"
              src={
                !companyAvatarRequesting
                  ? companyAvatarUploadContent ||
                    (currentUser.currentCompany &&
                      currentUser.currentCompany.avatarThumbnailSUrl)
                  : ''
              }
            />
          </Col>
          <Col
            xs={12}
            xl={6}
            hidden={this.props.readonly}
            className="upload-col"
          >
            <FormItem label="" className={isMobile ? null : 'mt-5'}>
              {getFieldDecorator('avatar', {
                rules: [{ required: false }],
              })(this.getUploader())}
            </FormItem>
          </Col>
        </Row>
        <FormItem label={t('profile.name')}>
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: t('profile.company.name-message') },
            ],
          })(
            <Input
              placeholder={t('profile.company.name-placeholder')}
              disabled={this.props.readonly}
            />
          )}
        </FormItem>

        <FormItem label={t('profile.company.sector')}>
          {getFieldDecorator('sector', {
            rules: [
              { required: true, message: t('profile.company.sector-message') },
            ],
          })(
            <Select
              disabled={this.props.readonly}
              placeholder={t('profile.company.sector-message')}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.sectors.map((sector, index) => {
                return (
                  <Option key={sector.code} value={`${sector.code}`}>
                    {sector.name}
                  </Option>
                );
              })}
            </Select>
          )}
        </FormItem>

        <FormItem label={t('profile.company.url')}>
          {getFieldDecorator('website', {
            rules: [
              { required: false, message: t('profile.company.url-message') },
              {
                type: 'url',
                message: t('profile.company.url-message'),
              },
            ],
          })(
            <Input
              placeholder="https://www.company.com"
              disabled={this.props.readonly}
            />
          )}
        </FormItem>
        <FormItem label={t('profile.company.desc')}>
          {getFieldDecorator('description', {
            rules: [
              {
                required: false,
                message: 'Ingresa una descripción de tu empresa',
              },
            ],
          })(
            <TextArea
              placeholder={t('profile.company.desc-placeholder')}
              rows={6}
              disabled={this.props.readonly}
            />
          )}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-company btn-block"
          loading={this.state.loadingButton}
          hidden={this.props.readonly}
        >
          <p className="p-button">{t('profile.save-button')}</p>
        </Button>

        {mayCropFile ? (
          <ImageCrop
            file={_.first(fileList)}
            cropDone={this.cropImageDone}
            company={true}
          />
        ) : (
          ''
        )}
      </Form>
    );
  }
}

const NormalCompanyProfileForm = Form.create()(CompanyProfileForm);

const mapStateToProps = (state, ownProps) => ({
  currentUser: ownProps.user,
  companyAvatarRequesting: state.currentUser.companyAvatarRequesting,
});

export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(NormalCompanyProfileForm))
);
