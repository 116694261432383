import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Image } from 'antd';
import { changeAdminCompany } from '../redux/actions/currentUser';
import { openNotification } from '../utils/General';

class CompanyChangeAdmin extends Component {
  handleCompany = (company) => {
    const { dispatch } = this.props;

    dispatch(changeAdminCompany({ company: company.key })).then(() => {
      if (this.props.success) {
        openNotification('success', 'Compañía cambiada exitosamente', '');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        openNotification(
          'error',
          'Se ha producido un error',
          this.props.errorMessage
        );
      }
    });
  };

  render() {
    const { companies } = this.props;

    const menu = (
      <Menu onClick={this.handleCompany}>
        {companies.map((company) => (
          <Menu.Item key={company.id}>{company.name}</Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <a href="#">
          <Image
            style={{ position: 'relative', top: '1px', marginRight: '1vh' }}
            src={'/images/company.png'}
            preview={false}
            className="notifications-icon"
          />
        </a>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state) => ({
  companies: state.currentUser.companies,
  success: state.currentUser.success,
  errorMessage: state.currentUser.errorMessage,
});
export default connect(mapStateToProps)(CompanyChangeAdmin);
