import React, { Component } from 'react';
import { ExceptionOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Select, Row, Spin, Card } from 'antd';
import { FilterReportStatusList } from '../constants/Data';
import StatisticsBarSeries from './StatisticsBarSeries';
import * as _ from 'lodash';
import '../themes/filter-report-statistics-form.less';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;

class ReportStatistics extends Component {
  onProgramChange = (e) => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      values['program'] = e;

      this.onFiltersChange(values);
    });
  };

  onStateChange = (e) => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      values['statuses'] = e;

      this.onFiltersChange(values);
    });
  };

  onYearChange = (e) => {
    const { form } = this.props;

    form.validateFields((err, values) => {
      values['year'] = e;

      this.onFiltersChange(values);
    });
  };

  onFiltersChange(values) {
    this.props.onFiltersChange(values);
  }

  getYearOptions = () => {
    const { currentUserYears } = this.props.statistics;

    return currentUserYears.map((year, i) => {
      return (
        <Option key={i} value={`${year}`}>
          {' '}
          {`${year}`}
        </Option>
      );
    });
  };

  getProgramList() {
    if (this.props.programs) {
      return this.props.programs.map((program, i) => {
        const { name, id } = program;
        return (
          <Option value={`${id}`} key={i}>
            {name}
          </Option>
        );
      });
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      statistics: { currentUserYears },
      t,
    } = this.props;
    const currentYear = _.last(currentUserYears);

    const StatusList = Object.keys(FilterReportStatusList).map((k) => {
      return (
        <Option key={k}>{t(`statistics.filters.report-status.${k}`)}</Option>
      );
    });

    return (
      <Spin spinning={this.props.loading}>
        <Card>
          <h6 style={{ fontSize: '16px' }}>
            <b>{t('dashboard.company-reports')}</b>
          </h6>
          <Form
            style={{ backgroundColor: 'white' }}
            onSubmit={this.handleSubmit}
            id="filter-report-statistics-form"
            className="filter-form"
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <FormItem>
                  {getFieldDecorator('year')(
                    <Select
                      className="placeholderGraphics"
                      placeholder={`${currentYear}`}
                      onChange={this.onYearChange}
                    >
                      {this.getYearOptions()}
                    </Select>
                  )}
                </FormItem>
              </Col>

              {this.props.companyMode ? (
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <FormItem>
                    {getFieldDecorator('program')(
                      <Select
                        className="placeholderGraphics"
                        placeholder={t('statistics.filters.programs')}
                        onChange={this.onProgramChange}
                      >
                        <Option value={null}>
                          {t('reports.filters.program.desc')}
                        </Option>
                        {this.getProgramList()}
                      </Select>
                    )}
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <FormItem>
                  {getFieldDecorator('statuses', {
                    initialValue: Object.keys(FilterReportStatusList),
                  })(
                    <Select onChange={this.onStateChange} mode="multiple">
                      {StatusList}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                style={{ overflowX: 'auto' }}
                xs={24}
                sm={22}
                md={22}
                lg={24}
                xl={24}
              >
                {Object.keys(this.props.statistics).length ? (
                  <StatisticsBarSeries
                    data={{ collection: this.props.statistics }}
                  />
                ) : (
                  <div
                    style={{
                      width: '730px',
                      height: '350px',
                      paddingTop: '100px',
                      textAlign: 'center',
                    }}
                  >
                    <ExceptionOutlined style={{ fontSize: '32px', marginBottom: '20px' }} />
                    <p style={{ fontWeight: '500' }}>{t('reports.no-reports')}</p>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    );
  }
}

const WrappedReportStatistics = Form.create()(ReportStatistics);

export default withTranslation()(WrappedReportStatistics);
