import React from 'react';
import { Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';

const DownloadReportsForm = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        style={{
          width: '100%',
          height: '50%',
          background: '#717982',
        }}
        onClick={props.onClick}
      >
        <div className="centered-content">
          <Image
            src={process.env.PUBLIC_URL + '/images/icn_download.png'}
            preview={false}
            style={{ margin: '10px 1px' }}
          />
          <p className="p-button">{t('exports.download-button')}</p>
        </div>
      </Button>
    </div>
  );
};

export default DownloadReportsForm;
