import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import NotificationItem from './NotificationItem';
import '../themes/notification-list.less';
import LoaderComponent from './LoaderComponent';
import '../styles/dashboard.less';

class NotificationList extends Component {
  getMenuList = () => {
    const { notifications, closeDropdown, t } = this.props;

    if (notifications.length === 0)
      return (
        <Card hoverable={false}>
          <h2>You have no notifications</h2>
        </Card>
      );

    return notifications.map((notification, i) => {
      return (
        <NotificationItem
          t={t}
          notification={notification}
          key={i}
          closeDropdown={closeDropdown}
        />
      );
    });
  };

  getMore = () => {
    const { getMoreData } = this.props;

    getMoreData();
  };

  render() {
    const { notifications, maxLength, t } = this.props;

    return (
      <div id="notifications">
        <h4 className="dropdown-title">
          {t('notifications.verbose_name_plural')}
        </h4>

        <div className="notification-dropdown-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.getMore}
            hasMore={notifications.length < maxLength}
            loader={<LoaderComponent size={'medium'} label={false} key={0} />}
            useWindow={false}
          >
            {this.getMenuList()}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  notifications: ownProps.notifications,
  t: ownProps.t,
});

export default connect(mapStateToProps)(NotificationList);
