import * as Action from './ActionTypes';
import { routes } from '../../constants/APIEndpoints';
import { CALL_API } from '../../middleware/api';

const callPayments = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_PAYMENTS_REQUEST,
      Action.LIST_PAYMENTS_SUCCESS,
      Action.LIST_PAYMENTS_ERROR,
    ],
    endpoint: routes.LIST_PAYMENTS,
    method: 'GET',
    params: params,
    data: {},
  },
});

const callPaymentStatistics = (params) => ({
  [CALL_API]: {
    types: [
      Action.PAYMENT_STATISTICS_REQUEST,
      Action.PAYMENT_STATISTICS_SUCCESS,
      Action.PAYMENT_STATISTICS_ERRORS,
    ],
    endpoint: routes.PAYMENT_STATISTICS,
    method: 'GET',
    params: params,
    data: {},
  },
});

const callCreditStatistics = (params) => ({
  [CALL_API]: {
    types: [
      Action.CREDIT_STATISTICS_REQUEST,
      Action.CREDIT_STATISTICS_SUCCESS,
      Action.CREDIT_STATISTICS_ERRORS,
    ],
    endpoint: routes.CREDIT_STATS,
    method: 'GET',
    params: params,
    data: {},
  },
});

const exportPayments = (params) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_PAYMENTS_REQUEST,
      Action.EXPORT_PAYMENTS_SUCCESS,
      Action.EXPORT_PAYMENTS_ERRORS,
    ],
    endpoint: routes.LIST_PAYMENTS,
    method: 'GET',
    data: {},
    params: params,
    responseType: 'blob',
  },
});

const exportInvoice = (id) => ({
  [CALL_API]: {
    types: [
      Action.EXPORT_INVOICE_REQUEST,
      Action.EXPORT_INVOICE_SUCCESS,
      Action.EXPORT_INVOICE_ERRORS,
    ],
    endpoint: routes.GET_INVOICE.replace(':id', id),
    method: 'GET',
    data: {},
    params: {},
    responseType: 'blob',
  },
});

export const getPayments = (params) => (dispatch, getState) => {
  return dispatch(callPayments(params));
};

export const getPaymentStatistics =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callPaymentStatistics(params));
  };

export const getCreditStatistics =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callCreditStatistics(params));
  };

export const getPaymentsforDownload = (params) => (dispatch, getState) => {
  return dispatch(exportPayments(params));
};

export const getInvoice = (id) => (dispatch, getState) => {
  return dispatch(exportInvoice(id));
};
