import React, { Component } from 'react';
import { connect } from 'react-redux';
import Payments from '../containers/Payments';
import { Col, Row } from 'antd';
import DownloadPaymentsFormNew from '../components/DownloadPaymentsFormNew';
import { withTranslation } from 'react-i18next';

class CompanyRewards extends Component {
  render() {
    const { paymentsList, t } = this.props;

    return (
      <React.Fragment>
        <Row>
          <Col xs={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <h1 style={{ fontSize: '20px' }}>{t('rewards.title')}</h1>
          </Col>
          <Col
            xs={{ span: 8, offset: 4 }}
            lg={{ span: 6, offset: 6 }}
            xl={{ span: 6, offset: 6 }}
            style={{ marginTop: '-5px', marginBottom: '15px' }}
          >
            <DownloadPaymentsFormNew
              payments={paymentsList}
              path={'/payments/export_my_payments'}
              fileName={'payments'}
            />
          </Col>
        </Row>

        <Payments />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  paymentsList: state.payments.list,
});
export default withTranslation()(connect(mapStateToProps)(CompanyRewards));
