import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Avatar } from 'antd';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { countryFlags } from '../utils/CountryFlags';

class RankingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  getRankingList() {
    const { ranking, t } = this.props;

    if (!_.isEmpty(ranking)) {
      return ranking.map((hacker, index) => {
        const hackerFlag = hacker.country ? countryFlags(hacker.country) : '🏳️';
        return (
          <Link to={`/dashboard/ranking/${hacker.username}`} key={index}>
            <Card
              hoverable={true}
              className={'top-hacker'}
              style={{ margin: '0 auto' }}
            >
              <Row className="text-center">
                <Col
                  xs={{ span: 3 }}
                  sm={{ span: 3 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                >
                  <p className="position">
                    <span className="numberCircle">{hacker.ranking}</span>
                  </p>
                </Col>
                <Col
                  xs={{ span: 1, offset: 2 }}
                  sm={{ span: 1, offset: 2 }}
                  md={{ span: 1, offset: 2 }}
                  lg={{ span: 1, offset: 1 }}
                >
                  <Avatar
                    src={
                      hacker.avatar_thumbnail_s_url ||
                      '/images/profile-default.png'
                    }
                    size="large"
                  />
                </Col>
                <Col
                  xs={{ span: 4, offset: 4 }}
                  sm={{ span: 4, offset: 1 }}
                  md={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                >
                  <p className="nickname text-left">
                    {hacker.username} {hackerFlag}
                  </p>
                </Col>

                <Col
                  xs={{ span: 7, offset: 3 }}
                  sm={{ span: 5, offset: 8 }}
                  lg={{ span: 10, offset: 4 }}
                >
                  <p className="top-hacker-point text-center">{hacker.score}</p>
                </Col>
              </Row>
            </Card>
          </Link>
        );
      });
    } else {
      return (
        <Card hoverable={true} className="top-hacker">
          <p className="text-center"> {t('ranking.no-hackers')} </p>
        </Card>
      );
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Row>
        <Col span={24}>
          <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Col
              sm={{ span: 20, offset: 2 }}
              md={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
            >
              <h1
                style={{ fontSize: '22px', letterSpacing: '1.2x' }}
                className="text-center"
              >
                <b>All-time SuperHeroes</b>
              </h1>
              <p className="text-center">{t('ranking.all-time-description')}</p>
            </Col>
          </Row>
          <Row id="ranking-container">
            <Col span={24}>
              <div className="app-container">
                <div id={'hackerRanking'}>
                  <Card style={{ marginBottom: '10px' }}>
                    <Row>
                      <Col
                        xs={{ span: 3 }}
                        sm={{ span: 3 }}
                        md={{ span: 3 }}
                        lg={{ span: 3 }}
                      >
                        <p
                          className="nickname"
                          style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            marginLeft: '15px',
                          }}
                        >
                          {t('ranking.position')}
                        </p>
                      </Col>
                      <Col
                        xs={{ span: 5, offset: 5 }}
                        sm={{ span: 6, offset: 4 }}
                        md={{ span: 6, offset: 4 }}
                        lg={{ span: 5, offset: 2 }}
                      >
                        <p
                          className="nickname"
                          style={{ fontSize: '16px', fontWeight: '700' }}
                        >
                          {t('ranking.name')}
                        </p>
                      </Col>

                      <Col
                        xs={{ span: 6, offset: 5 }}
                        sm={{ span: 4, offset: 7 }}
                        md={{ span: 4, offset: 7 }}
                        lg={{ span: 7, offset: 6 }}
                      >
                        <p
                          className="nickname text-center"
                          style={{ fontSize: '16px', fontWeight: '700' }}
                        >
                          {t('ranking.points')}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                  {this.getRankingList()}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(RankingList);
