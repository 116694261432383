import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Image, Menu } from 'antd';
import {
  subscribeNotificationList,
  checkNotifications,
} from '../redux/actions/currentUser';
import NotificationList from '../components/NotificationList';
import NotificationItem from '../components/NotificationItem';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { isCompanyMode } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';
import '../styles/dashboard.less';

class Notifications extends Component {
  state = {
    notifications: null,
    dropdownVisible: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(subscribeNotificationList({ page: 1 }));
  }

  componentDidUpdate() {
    const { dispatch, t } = this.props;

    clearInterval(this.intervalId);

    this.intervalId = setInterval(() => {
      dispatch(subscribeNotificationList({ page: 1 }));
    }, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getMenuList = () => {
    const {
      notifications: { maxLength },
      requesting,
      t,
    } = this.props;

    const closeDropdown = () => {
      this.setState({ dropdownVisible: false });
    };

    return requesting ? (
      <Menu>
        <Menu.Item key="0">
          <LoaderComponent size={'medium'} label={false} />
        </Menu.Item>
      </Menu>
    ) : (
      <NotificationList
        t={t}
        notifications={this.props.notifications}
        getMoreData={this.getMoreNotifications}
        maxLength={maxLength}
        closeDropdown={closeDropdown}
      />
    );
  };

  getMoreNotifications = () => {
    const {
      appState: {
        notifications: { nextPage },
      },
      dispatch,
    } = this.props;
    dispatch(subscribeNotificationList({ page: nextPage }));
  };

  handleDropdownVisibleChange = (visible) => {
    if (visible) {
      this.markAsView();
    }
    this.setState({ dropdownVisible: visible });
  };

  markAsView = () => {
    const { dispatch } = this.props;
    dispatch(checkNotifications());
    dispatch(subscribeNotificationList({ page: 1 }));
    this.setState({ dropdownVisible: false });
  };

  render() {
    const { unreaded, currentUser } = this.props;

    const iconPath = `/images/notifications-h4d.png`;
    const dotPath = `/images/new-notifications-${
      !isCompanyMode(currentUser) ? 'hacker' : 'company'
    }.png`;
    return (
      <div id="notifications">
        <Dropdown
          overlay={this.getMenuList()}
          trigger={['click']}
          visible={this.state.dropdownVisible}
          onVisibleChange={this.handleDropdownVisibleChange}
          placement="bottomRight"
        >
          <div className="notifications-container">
            <img // ESTO SE CAMBIARA POR <BADGE />
              src={iconPath}
              className="notifications-icon"
            />
            {unreaded > 0 && (
              <img // ESTO SE CAMBIARA POR <BADGE />
                src={dotPath}
                className="notification-dot-icon"
              />
            )}
          </div>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  notifications: state.currentUser.notifications.entities,
  requesting: state.currentUser.requesting,
  unreaded: state.currentUser.notifications.unreaded,
  maxLength: state.currentUser.notifications.maxLength,
  hasError: state.currentUser.hasError,
  errorMessage: state.currentUser.errorMessage,
  appState: {},
});
export default withTranslation()(connect(mapStateToProps)(Notifications));
