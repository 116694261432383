import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyEmployeeSignUpForm from '../components/CompanyEmployeeSignUpForm';
import { openNotification } from '../utils/General';

class EmployeeSignUp extends Component {
  componentWillReceiveProps(nextProps) {
    const { isInviting } = this.props;

    if (isInviting && !nextProps.isInviting) {
      if (nextProps.hasErrors) {
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'error',
            'Ha ocurrido un error inesperado.',
            nextProps.errorMessage
          );
        }, 0);
      }
    }
  }

  getForm() {
    const { history, match } = this.props;

    return (
      <CompanyEmployeeSignUpForm token={match.params.token} history={history} />
    );
  }

  render() {
    const form = this.getForm();

    return (
      <div id="EmployeeSignUp">
        <div id="CompanyEmployeeSignUpFormContainer">{form}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInviting: state.companyEmployees.isInviting,
});
export default connect(mapStateToProps)(EmployeeSignUp);
