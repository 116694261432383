import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  ProgramTypes,
  Languages,
  programDefaultDescription,
} from '../constants/Data';
import { MarkOptions } from '../utils/MarkdownHelper';
import '../themes/program-form.less';
import '../themes/default.less';
import DynamicTargetList from '../components/DynamicTargetList';

import { handleAxiosError } from '../utils/Http';
import ManyFilesField from '../components/fields/ManyFilesField';

import { CameraOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Avatar,
  Checkbox,
  Tabs,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Select,
  Upload,
  InputNumber,
} from 'antd';
import * as _ from 'lodash';
import Markdown from 'react-remarkable';
import ImageCrop from '../components/ImageCrop';
import { withTranslation, useTranslation } from 'react-i18next';
import { programDefaultDescriptionUS } from '../constants/DataUS';

import axiosInstance from '../axiosInstance';
import { openNotification } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';
import '../styles/program-form.less';

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const CustomServiceField = ({
  value = [],
  onChange,
  services,
  persistedServices,
}) => {
  const { t } = useTranslation();

  const onToggleCheck = (e) => {
    if (onChange) {
      let newValue = [...value];
      if (e.target.checked === false) {
        newValue = newValue.filter((item) => item.service !== e.target.value);
      } else {
        let psvc = persistedServices.find(
          (item) => item.service === e.target.value
        );
        if (psvc) {
          newValue.push(psvc);
        } else {
          newValue.push({ service: e.target.value });
        }
      }
      onChange(newValue);
    }
  };

  return services.map((svc, index) => {
    const code = svc.code;
    const short = t(`services.${code}.short`).toUpperCase();

    return (
      <Row
        className="service"
        key={code}
        style={{ height: 90, margin: '35px 0px' }}
      >
        <Col xs={3} sm={3} lg={6} style={{ display: 'flex' }}>
          <Checkbox
            value={code}
            onChange={onToggleCheck}
            checked={value.find((item) => item.service === code)}
          />
          <p
            style={{
              marginLeft: '15px',
              lineHeight: 1.5,
              color: '#000',
              fontWeight: 'bold',
            }}
          >
            {t(`services.${code}.name`)}
          </p>
        </Col>

        <Col
          xs={14}
          sm={18}
          lg={8}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              padding: '0px 8px',
              justifyContent: 'center',
              borderRadius: '50px',
              height: '30px',
              width: '60%',
              background:
                code === 'private'
                  ? '#33ACFF'
                  : code === 'triage'
                  ? '#FFD700'
                  : code === 'retest'
                  ? '#FF0000'
                  : '#33DD35',
            }}
          >
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0',
                color: 'white',
              }}
            >
              {short}
            </p>
          </div>
        </Col>

        <Col xs={14} sm={18} lg={10}>
          <p style={{ lineHeight: 1.5, paddingRight: 20, fontSize: 15 }}>
            {t(`services.${code}.description`)}
          </p>
        </Col>
      </Row>
    );
  });
};

class ProgramBugbountyNew extends Component {
  constructor(props) {
    super(props);
    const language = localStorage.getItem('selectedLanguage');
    this.state = {
      initialValues: props.initialValues || {
        rewards_info: {
          bounty_low: 50,
          bounty_medium: 150,
          bounty_high: 250,
          bounty_critical: 300,
        },
        services: [],
        targets: [],
        program_types: [],
        description:
          language === 'en'
            ? programDefaultDescriptionUS
            : programDefaultDescription,
      },
      draftMode: props.initialValues ? props.initialValues.is_draft : true,
      services: [],
      saving: false,
      programAvatarUpload: null,
      programAvatarUploadContent: null,
      avatar64: null,
      mayCropFile: false,
      currentTab: '1',
    };

    this.state.initialValues = {
      ...this.state.initialValues,
      program_types: this.state.initialValues.program_types.map(
        (item) => item.code
      ),
      file_ids: props.initialValues
        ? props.initialValues.files.map((f) => f.id)
        : [],
    };
  }

  requiredNotInDraftValidator = (_, value, callback) => {
    const { t } = this.props;
    const { draftMode } = this.state;
    if (draftMode) {
      callback();
      return;
    }
    if (
      value === '' ||
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      callback('This field is required');
    } else {
      callback();
    }
  };

  validateGreaterThanZero = (rule, value) => {
    const { draftMode } = this.state;

    const isApproved =
      this.props.initialValues && this.props.initialValues.is_approved;

    if (draftMode || isApproved) {
      return Promise.resolve();
    }

    if (value && value <= 0) {
      return Promise.reject('The value must be greater than zero');
    }
    return Promise.resolve();
  };

  componentDidMount() {
    const { form } = this.props;
    const { initialValues } = this.state;

    this.fetchServices();

    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }

  fetchServices() {
    this.setState({ servicesLoading: true });

    const params = {};

    axiosInstance
      .get(`/current-company/services/`, { params })
      .then((response) => {
        this.setState({
          services: response.data.results,
          servicesLoading: false,
          servicesTotalItems: response.data.count,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        handleAxiosError(error);
        this.setState({ servicesLoading: false });
      });
  }

  getProgramTypes() {
    const { t } = this.props;
    return ProgramTypes.map((p, index) => {
      return (
        <Option value={p.code} key={index.toString()}>
          {t(`programs.program-types.${p.code}`)}
        </Option>
      );
    });
  }

  getLanguages() {
    return Languages.map((p) => {
      return (
        <Option value={p.code} key={p.code}>
          {this.props.t(`languages.${p.code}`)}
        </Option>
      );
    });
  }

  handleChangeMultiple(value) {
    this.setState({ program_type: value });
  }

  onChangeTab = (tabKey) => {
    const { form, t } = this.props;
    let validateFields = [];

    if (!this.state.initialValues || this.state.initialValues.is_draft) {
      validateFields = [
        'name',
        'rewards_info.bounty_critical',
        'rewards_info.bounty_high',
        'rewards_info.bounty_medium',
        'rewards_info.bounty_low',
      ];
    } else if (this.state.currentTab === '1') {
      validateFields = ['name', 'description', 'program_types'];
    } else if (this.state.currentTab === '2') {
      validateFields = [
        'rewards_info.bounty_critical',
        'rewards_info.bounty_high',
        'rewards_info.bounty_medium',
        'rewards_info.bounty_low',
        'budget',
      ];
    }

    form.validateFieldsAndScroll(validateFields, (err, values) => {
      if (err) {
        openNotification(
          'error',
          `${t('notifications.warning')}`,
          `${t('notifications.reports.fields')}`
        );
      } else {
        this.setState({
          currentTab: tabKey,
        });
      }
    });
  };

  updateAvatar = () => {
    const { t } = this.props;
    const props = {
      customRequest: function () {
        return null;
      },
      accept: 'image/*',
      showUploadList: false,
      beforeUpload: (file) => {
        const isLt60M = file.size / 1024 / 1024 < 60;
        if (!isLt60M) {
          openNotification(
            'warning',
            t('notifications.warning'),
            t('file-less-than-size', { size: '60MB' })
          );
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            this.setState({
              programAvatarUpload: file,
              avatar64: base64String,
              mayCropFile: true,
            });
          };
          reader.onload = function (e) {}.bind(this);
          reader.readAsDataURL(file);
        }
        return isLt60M;
      },
    };

    return (
      <Upload {...props} className="avatar-uploader">
        <Button shape="circle" icon={<CameraOutlined />} />
      </Upload>
    );
  };

  cropImageDone = (croppedFile) => {
    this.setState({ mayCropFile: false, programAvatarUpload: croppedFile });
  };

  handleSubmit = () => {
    const { t, form, history } = this.props;
    const { draftMode, currentTab } = this.state;

    let cTab = parseInt(currentTab);
    if (cTab < 4) {
      this.setState({ currentTab: `${cTab + 1}` }, () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
      return;
    }

    form.validateFieldsAndScroll({ force: true }, async (err, values) => {
      if (!err) {
        try {
          values.is_draft = draftMode;

          values.services = values.services.filter((item) => !!item.service);
          values.targets = values.targets.filter((item) => item.target !== '');

          let response;
          if (this.props.initialValues) {
            this.setState({
              saving: true,
            });
            response = await axiosInstance.patch(
              `/programs/${this.props.initialValues.id}/`,
              values
            );
            openNotification(
              'success',
              `${t('notifications.programs.create')}`,
              `${t('notifications.programs.create-message')}`
            );
          } else {
            response = await axiosInstance.post('/programs/', values);
            openNotification(
              'success',
              `${t('notifications.programs.update')}`,
              `${t('notifications.programs.update-message')}`
            );
          }

          history.push(`/dashboard/programs/${response.data.id}`);
        } catch (error) {
          handleAxiosError(error);
        } finally {
          this.setState({
            saving: false,
          });
        }
      }
    });
  };

  render() {
    const { t, program } = this.props;
    const { currentTab, initialValues, loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    if (this.props.initialValues && (loading || program === null))
      return <LoaderComponent label={false} />;

    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.setState(
            { draftMode: e.nativeEvent.submitter.name === 'draft' },
            () => this.handleSubmit()
          );
        }}
        id="ProgramForm"
        layout="vertical"
      >
        <Tabs
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          tabPosition="top"
          onTabClick={this.onChangeTab}
        >
          <TabPane
            className="tab-pane"
            tab={
              <div className="flex-inline">
                <div
                  className={
                    currentTab === '1'
                      ? 'circle-active mr-1'
                      : 'circle-active circle-no-active mr-1'
                  }
                >
                  1
                </div>{' '}
                {t('programs.form.tabs.1')}{' '}
              </div>
            }
            key="1"
          >
            <Row>
              <Col span={24}>
                <h2 className="mb-3">{t('programs.form.scope')}</h2>
                <FormItem label={t('programs.form.fields.name.name')}>
                  {getFieldDecorator('name', {
                    rules: [
                      {
                        required: true,
                        message: t('programs.form.fields.name.rules.required'),
                      },
                    ],
                  })(
                    <Input
                      placeholder={t('programs.form.fields.name.placeholder')}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem label={t('programs.form.fields.program-type.name')}>
                  {getFieldDecorator('program_types', {
                    rules: [
                      {
                        required: true,
                        message: t(
                          'programs.form.fields.program-type.rules.required'
                        ),
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      onChange={(e) => this.handleChangeMultiple(e)}
                    >
                      {this.getProgramTypes()}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label={t('programs.form.fields.project-id.name')}>
                  {getFieldDecorator('custom_id', {
                    rules: [{ required: false }],
                  })(
                    <Input
                      placeholder={t(
                        'programs.form.fields.project-id.placeholder'
                      )}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label={t('programs.form.fields.description.name')}>
                  <div className="markdown">
                    <div className="markdown-header">
                      <div className="pull-left">
                        <p>{t('programs.features.edit')}</p>
                      </div>
                      <div className="pull-right">
                        <RadioGroup
                          defaultValue="a"
                          className="markdown-buttons"
                        >
                          <RadioButton value="a" onClick={this.editMarkdown}>
                            <EditOutlined />
                          </RadioButton>

                          <RadioButton value="b" onClick={this.showPreview}>
                            <EyeOutlined />
                          </RadioButton>
                        </RadioGroup>
                      </div>
                    </div>
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: true,
                          message: t('programs.form.fields.description.name'),
                          validator: this.requiredNotInDraftValidator,
                        },
                      ],
                    })(
                      <TextArea
                        placeholder={t('programs.form.fields.description.name')}
                        rows={6}
                      />
                    )}
                    <div className="tui-editor-contents description-p hidden">
                      <Markdown
                        source={this.state.description}
                        options={MarkOptions}
                      />
                    </div>
                  </div>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DynamicTargetList
                  label={t('programs.form.fields.target.name')}
                  form={this.props.form}
                  values={initialValues.targets}
                  fieldOptions={{
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please input target or delete this field.',
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  }}
                />

                <FormItem>
                  {getFieldDecorator(`file_ids`)(
                    <ManyFilesField
                      filesProp={
                        this.props.initialValues
                          ? this.props.initialValues.files
                          : []
                      }
                      onSuccess={(response) => {
                        openNotification(
                          'success',
                          t('notifications.files.upload'),
                          t('notifications.files.upload-message')
                        );
                      }}
                      onError={(error) => {
                        handleAxiosError(error);
                      }}
                      onRemoveItem={(error) => {}}
                      onFileSizeError={() => {
                        openNotification(
                          'warning',
                          `${t('notifications.warning')}`,
                          `${t('notifications.files.size-check')}`
                        );
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col sm={10}>
                <FormItem label={t('programs.form.fields.language.name')}>
                  {getFieldDecorator('language', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'programs.form.fields.language.rules.required'
                        ),
                      },
                    ],
                  })(
                    <Select
                      placeholder={t(
                        'programs.form.fields.language.placeholder'
                      )}
                      className="placeholderNewProgram"
                    >
                      {this.getLanguages()}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Col xs={24} sm={24} md={24}>
              <Button
                size="large"
                className="program-form-btn btn-company block-center"
                htmlType="submit"
                loading={this.state.loadingButton}
              >
                <p className="p-button">{t('continue')}</p>
              </Button>
            </Col>
          </TabPane>
          <TabPane
            className="tab-pane"
            tab={
              <div className="flex-inline">
                <div
                  className={
                    currentTab === '2'
                      ? 'circle-active mr-1'
                      : 'circle-active circle-no-active mr-1'
                  }
                >
                  2
                </div>{' '}
                {t('programs.form.tabs.2')}
              </div>
            }
            key="2"
          >
            <Row>
              <Col>
                <h2>{t('programs.form.rewards-title')}</h2>
              </Col>
            </Row>

            <small>{t('programs.form.rewards-desc')}</small>

            <br />
            <br />

            <Row>
              <Col>
                <FormItem label={t('programs.form.rewards-budget')}>
                  {getFieldDecorator('budget', {
                    rules: [
                      {
                        required: true,
                        message: `${t('programs.form.rewards-budget-message')}`,
                        validator: this.requiredNotInDraftValidator,
                      },
                      { validator: this.validateGreaterThanZero },
                    ],
                  })(
                    <InputNumber
                      placeholder={0}
                      disabled={
                        this.props.initialValues &&
                        this.props.initialValues.is_approved
                      }
                      style={{ width: '100%' }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col span={24}>
                <p>{t('programs.form.rewards-amount-desc')}</p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12} sm={12}>
                <FormItem label={t('programs.form.rewards-amounts.critical')}>
                  {getFieldDecorator('rewards_info.bounty_critical', {
                    rules: [
                      {
                        required: true,
                        message: `${t(
                          'programs.form.rewards-amounts.critical-message'
                        )}`,
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  })(<InputNumber style={{ width: '100%' }} />)}
                </FormItem>
              </Col>

              <Col xs={12} sm={12}>
                <FormItem label={t('programs.form.rewards-amounts.high')}>
                  {getFieldDecorator('rewards_info.bounty_high', {
                    rules: [
                      {
                        required: true,
                        message: `${t(
                          'programs.form.rewards-amounts.high-message'
                        )}`,
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  })(<InputNumber style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={12} sm={12}>
                <FormItem label={t('programs.form.rewards-amounts.medium')}>
                  {getFieldDecorator('rewards_info.bounty_medium', {
                    rules: [
                      {
                        required: true,
                        message: `${t(
                          'programs.form.rewards-amounts.medium-message'
                        )}`,
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  })(<InputNumber style={{ width: '100%' }} />)}
                </FormItem>
              </Col>

              <Col xs={12} sm={12}>
                <FormItem label={t('programs.form.rewards-amounts.low')}>
                  {getFieldDecorator('rewards_info.bounty_low', {
                    rules: [
                      {
                        required: true,
                        message: `${t(
                          'programs.form.rewards-amounts.low-message'
                        )}`,
                        validator: this.requiredNotInDraftValidator,
                      },
                    ],
                  })(<InputNumber style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  size="large"
                  className="program-form-btn btn-company block-center"
                  htmlType="submit"
                  loading={this.state.loadingButton}
                >
                  <p className="p-button">{t('continue')}</p>
                </Button>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            className="tab-pane"
            tab={
              <div className="flex-inline">
                <div
                  className={
                    currentTab === '3'
                      ? 'circle-active mr-1'
                      : 'circle-active circle-no-active mr-1'
                  }
                >
                  3
                </div>{' '}
                {t('programs.form.tabs.3')}
              </div>
            }
            key="3"
          >
            <Row>
              <Col span={24}>
                <h2>{t('programs.services')}</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p
                  style={{
                    borderBottom: 'solid 1px #e0e0e0',
                    paddingBottom: 20,
                    marginBottom: 0,
                  }}
                >
                  {t('programs.form.services-desc')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem>
                  {getFieldDecorator(
                    `services`,
                    {}
                  )(
                    <CustomServiceField
                      services={this.state.services}
                      persistedServices={this.state.initialValues.services}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  size="large"
                  className="program-form-btn btn-company block-center"
                  htmlType="submit"
                  loading={this.state.loadingButton}
                >
                  <p className="p-button">{t('continue')}</p>
                </Button>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            className="tab-pane"
            tab={
              <div className="flex-inline">
                <div
                  className={
                    currentTab === '4'
                      ? 'circle-active mr-1'
                      : 'circle-active circle-no-active mr-1'
                  }
                >
                  4
                </div>{' '}
                {t('programs.form.tabs.4')}
              </div>
            }
            key="4"
          >
            {this.state.currentTab === '4' && this.renderOverview()}
            {initialValues.is_draft === false ? (
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24}>
                  <Button
                    size="large"
                    htmlType="submit"
                    name="final"
                    loading={this.state.loadingSubmitButton}
                    className="program-form-btn btn-company block-center"
                  >
                    {!this.props.initialValues ? (
                      <p className="p-button">
                        {t('programs.form.update-button')}
                      </p>
                    ) : (
                      <p className="p-button">
                        {t('programs.form.save-button')}
                      </p>
                    )}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={6}>
                <Col xs={24} sm={12} md={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    name="draft"
                    className="btn-draft btn-block"
                  >
                    <p className="p-button">
                      {t('programs.form.save-draft-button')}
                    </p>
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Button
                    size="large"
                    htmlType="submit"
                    name="final"
                    loading={this.state.loadingSubmitButton}
                    className="program-form-btn btn-company block-center"
                    onClick={this.saveChangeAndNextTab}
                  >
                    {this.props.initialValues ? (
                      <p className="p-button">
                        {t('programs.form.update-button')}
                      </p>
                    ) : (
                      <p className="p-button">
                        {t('programs.form.save-button')}
                      </p>
                    )}
                  </Button>
                </Col>
              </Row>
            )}
          </TabPane>
        </Tabs>
      </Form>
    );
  }

  renderOverview = () => {
    const { t, form } = this.props;
    const { mayCropFile, programAvatarUpload, avatarLoading, avatar64 } =
      this.state;

    const { initialValues } = this.state;

    const values = form.getFieldsValue();
    return (
      <div className="overview-program">
        <div className="header">
          <Row gutter={24}>
            <Col xs={8} span={6} className="program-avatar">
              <Avatar
                size="large"
                src={
                  !avatarLoading && programAvatarUpload
                    ? avatar64
                    : initialValues.avatar_thumbnail_s_url
                }
              />

              {this.updateAvatar()}

              {mayCropFile && (
                <ImageCrop
                  file={programAvatarUpload}
                  cropDone={this.cropImageDone}
                />
              )}
            </Col>
            <Col xs={16} span={18}>
              <h1>{values.name}</h1>
              <Row>
                <Col xs={24} span={12}>
                  {values.program_types.length > 0 ? (
                    <div>
                      <p className="subtitle">
                        {t('programs.form.project-type')}
                      </p>
                      <ul className="description">
                        {values.program_types.map((p) => {
                          const pt = ProgramTypes.find(
                            (item) => item.code === p
                          );
                          return (
                            <li key={pt.code}>
                              <strong>{pt.name}</strong>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <span>-</span>
                    </div>
                  )}
                </Col>
                <Col xs={24} span={12}>
                  <p className="subtitle">{t('programs.form.privacy')}</p>
                  <p className="description">
                    {!values.is_public
                      ? t('programs.form.private')
                      : t('programs.form.public')}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </div>
        <div className="description">
          <FormItem label="Descripción" colon={false}>
            <div className="tui-editor-contents">
              <Markdown source={values.description} options={MarkOptions} />
            </div>
          </FormItem>
        </div>
        <div className="targets">
          <FormItem label={t('programs.form.fields.target.name')} colon={false}>
            {values.targets.length > 0
              ? values.targets.map((target, index) => {
                  if (target.target) {
                    return (
                      <p className="description" key={index}>
                        {target.target}
                      </p>
                    );
                  }
                })
              : t('programs.form.fields.target.empty')}
          </FormItem>
        </div>
        <div className="description">
          <FormItem label={t('fields.language.verbose_name')} colon={false}>
            <p className="description">
              {values.language
                ? t(`languages.${values.language}`)
                : t('fields.misc.empty')}
            </p>
          </FormItem>
        </div>
        <hr />
      </div>
    );
  };
}

const ProgramBugbountyForm = Form.create()(ProgramBugbountyNew);
export default withTranslation()(withRouter(ProgramBugbountyForm));
