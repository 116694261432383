import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Col, Row, Table, Tooltip } from 'antd';

import '../themes/statistics.less';
import { getProgramHistory } from '../redux/actions/wallet';
import PaymentTotalAmountProgram from '../components/PaymentTotalAmountProgram';
import DownloadHistoryForm from '../components/DownloadHistoryForm';
import moment from 'moment';
import Paginate from '../components/Paginate';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import DOMPurify from 'dompurify';
import { routes } from '../constants/APIEndpoints';
import { API_ROOT } from '../middleware/api';

class ProgramHistory extends Component {
  state = {
    currentPage: 1,
    is_ptaas: null,
  };

  componentWillMount() {
    const { uuid } = this.props;

    if (!uuid) {
      this.props.history.goBack();
    }
  }

  componentDidMount() {
    this.handleLocationProps(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.handleLocationProps(this.props.location);
    }
  }

  handleLocationProps(location) {
    if (location.state && location.state.ptaas !== null) {
      this.setState({ is_ptaas: location.state.ptaas }, () => {
        this.handleDispatch();
      });
    } else {
      this.props.history.push('/dashboard/finance');
    }
  }

  handleDispatch() {
    const { dispatch, uuid } = this.props;
    const { currentPage } = this.state;

    if (this.state.is_ptaas !== null) {
      dispatch(getProgramHistory(uuid, { page: currentPage }));
    }
  }

  convertDate(date) {
    const fechaHora = moment(date);

    return fechaHora.format('DD/MM/YYYY HH:mm');
  }

  handlePaginateData = (data) => {
    this.setState({ loading: true, currentPage: data });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    const { numberOfElements, numberOfPages, t, uuid } = this.props;
    const { available, expended, locked, list, quote } = this.props.program;
    const { is_ptaas } = this.state;

    const columns = [
      {
        title: t('budget.programs.wallet.date'),
        className: 'column-initial',
        dataIndex: 'date',
        width: '10%',
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: t('budget.programs.wallet.user'),
        dataIndex: 'user',
        width: '15%',
        sorter: (a, b) => a.user.length - b.user.length,
      },
      {
        title: t('budget.programs.wallet.detail'),
        dataIndex: 'description',
        width: '20%',
        sorter: (a, b) => a.description_html.localeCompare(b.description_html),
        render: (text, record) => {
          if (record.report) {
            const cleanedTitle = DOMPurify.sanitize(record.report.name);
            return (
              <Tooltip placement="right" title={cleanedTitle}>
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Tooltip>
            );
          } else {
            return <span>{text}</span>;
          }
        },
      },
      {
        title: t('budget.programs.wallet.amount'),
        className: 'column-right',
        dataIndex: 'amount',
        width: '10%',
        sorter: (a, b) => a.amount - b.amount,
        render: (text) =>
          text !== null ? (
            <NumberDisplay number={text} />
          ) : (
            t('budget.programs.wallet.no-registered')
          ),
      },
    ];
    if (!is_ptaas) {
      columns.push({
        title: t('budget.programs.wallet.balance'),
        className: 'column-final',
        dataIndex: 'balance',
        width: '14%',
        sorter: (a, b) => a.balance - b.balance,
        render: (text) =>
          text !== null ? (
            <NumberDisplay number={text} />
          ) : (
            t('budget.programs.wallet.no-registered')
          ),
      });
    }

    const data = [];
    for (let indice in list) {
      const element = list[indice];
      const dataAction = element.description;

      let detailExport = null;
      if (dataAction && element.uuid) {
        detailExport =
          dataAction[0] + (element.uuid ? ' - ' + dataAction[1] : '');
      }
      const createdAt = this.convertDate(element.created_at);
      data.push({
        key: indice,
        date:
          createdAt !== null
            ? createdAt
            : t('budget.programs.wallet.no-registered'),
        user:
          element.username !== null
            ? element.username
            : t('budget.programs.wallet.no-registered'),
        description: element.description_html,
        detailExport:
          detailExport !== null
            ? detailExport
            : t('budget.programs.wallet.no-registered'),
        amount: element.value,
        balance: element.running_balance,
        report: element.report,
      });
    }

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>{t('budget.programs.wallet.title')}</h1>
              <h4>{t('budget.programs.wallet.description')}</h4>
            </Col>
          </Row>
          {!is_ptaas && (
            <Row gutter={24}>
              <Col span={24}>
                <Row>
                  <Col xs={{ span: 24 }} sm={8} md={8} lg={{ span: 11 }}>
                    <p
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: '5%',
                        top: '2.65rem',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      {t('budget.programs.total-budget').toUpperCase()}
                    </p>
                    <PaymentTotalAmountProgram
                      style={{ width: '100%' }}
                      value={Math.round(quote)}
                      currency={'Dolares'}
                      paid={true}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={8}
                    md={8}
                    lg={{ span: 11, offset: 2 }}
                  >
                    <p
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: '5%',
                        top: '2.65rem',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      {t('budget.programs.paid').toUpperCase()}
                    </p>
                    <PaymentTotalAmountProgram
                      value={Math.round(expended)}
                      currency={'Dolares'}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={8} md={8} lg={{ span: 11 }}>
                    <p
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: '5%',
                        top: '2.65rem',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      {t('budget.programs.reserved').toUpperCase()}
                    </p>
                    <PaymentTotalAmountProgram
                      value={Math.round(locked)}
                      currency={'Dolares'}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={8}
                    md={8}
                    lg={{ span: 11, offset: 2 }}
                  >
                    <p
                      style={{
                        position: 'absolute',
                        zIndex: '2',
                        left: '5%',
                        top: '2.65rem',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                      }}
                    >
                      {t('budget.programs.available').toUpperCase()}
                    </p>
                    <PaymentTotalAmountProgram
                      value={Math.round(available)}
                      currency={'Dolares'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: 'white',
                  marginTop: '2vh',
                  marginBottom: '2vh',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0px 1.25rem 1.25rem 24px',
                    paddingTop: '15px',
                  }}
                >
                  <h1 style={{ fontSize: '16px', marginBottom: '0' }}>
                    {t('budget.wallet-history.history')}
                  </h1>
                  {data.length > 0 && (
                    <DownloadHistoryForm
                      baseFilename="WalletHistory"
                      doAppendDatetime={true}
                      baseUrl={`${API_ROOT}${routes.GET_PROGRAM_HISTORY.replace(
                        ':id',
                        uuid
                      )}`}
                    />
                  )}
                </div>

                <div style={{ margin: '1.25rem 10px' }}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 780 }}
                    pagination={false}
                  />
                </div>
                <h1
                  style={{
                    fontSize: '12px',
                    margin: '20px 0px 30px 10px',
                    color: 'gray',
                    position: 'relative',
                    width: '100%',
                    height: '0',
                  }}
                >
                  {t('budget.programs.table-footer')}
                </h1>
                <Paginate
                  currentPage={this.state.currentPage}
                  numberOfElements={numberOfElements}
                  numberOfPages={numberOfPages}
                  dispatcher={getProgramHistory}
                  onData={this.handlePaginateData}
                  flag={true}
                  currentID={uuid}
                  elementName={
                    numberOfElements === 1
                      ? t('registers.verbose_name')
                      : t('registers.verbose_name_plural')
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  uuid:
    ownProps.match.params && ownProps.match.params.slug
      ? ownProps.match.params.slug
      : false,
  currentUser: state.currentUser,
  program: {
    available: state.wallet.program.available,
    expended: state.wallet.program.expended,
    list: state.wallet.program.list,
    locked: state.wallet.program.locked,
    quote: state.wallet.program.quote,
    name: state.wallet.program.name,
  },
  numberOfElements: state.wallet.numberOfElements,
  numberOfPages: state.wallet.numberOfPages,
});
export default withTranslation()(connect(mapStateToProps)(ProgramHistory));
