import { convertToDate } from '../../utils/General';
import * as Action from '../actions/ActionTypes';

const initialState = {
  list: [],
  histories: [],
  dates: [],
  count: null,
  requesting: false,
  hasError: false,
  errorMessage: null,
  showBar: false,
  numberOfPages: 1,
};

class HistoryReducer {
  static reduce(state = initialState, action) {
    if (HistoryReducer[action.type]) {
      return HistoryReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.PROGRAM_ACTIVITIES_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.PROGRAM_ACTIVITIES_SUCCESS](state, action) {
    const {
      response: {
        result: { list, count },
      },
    } = action;
    const res = {
      ...state,
      list: list,
      count: count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.PROGRAM_ACTIVITIES_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.PROGRAM_ACTIVITIES_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.REPORT_ACTIVITIES_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.REPORT_ACTIVITIES_SUCCESS](state, action) {
    const res = {
      ...state,
      list: action.response.results,
      count: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.REPORT_ACTIVITIES_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.PROGRAM_HISTORIES_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.PROGRAM_HISTORIES_SUCCESS](state, action) {
    const { result } = action.response;

    const historyFormatList = result.list.map((item, index) => {
      const obj = JSON.parse(item);
      const program = JSON.parse(obj.old_version);

      return {
        index,
        program,
      };
    });

    const res = {
      ...state,
      histories: historyFormatList,
      dates: result.dates.map((x) => convertToDate(x)),
      maxLength: result.maxLength,
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.PROGRAM_HISTORIES_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }

  static [Action.SHOW_BAR_HISTORY](state, action) {
    return {
      ...state,
      showBar: true,
    };
  }
  static [Action.HIDE_BAR_HISTORY](state, action) {
    return {
      ...state,
      showBar: false,
    };
  }
}

export default HistoryReducer.reduce;
