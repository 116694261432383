export const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const Roles = {
  Company: 'company',
  Hacker: 'hacker',
};

export const Publicidad = [
  { id: '1', name: 'Prensa' },
  { id: '2', name: 'Evento' },
  { id: '3', name: 'Redes sociales' },
  { id: '4', name: 'Otro' },
];

export const ReportStatusList = {
  new: {
    color: 'red',
  },
  triage: {
    color: 'orange',
  },
  'missing-info': {
    color: 'green',
  },
  info: {
    color: 'navy',
  },
  'does-not-apply': {
    color: '#79C7E3',
  },
  duplicated: {
    color: 'pink',
  },
  'out-of-scope': {
    color: 'black',
  },
  spam: {
    color: 'yellow',
  },
  quarantine: {
    color: '#E20BCF',
  },
  valid: {
    color: 'blue',
  },
};

export const retestStatusList = ['pending', 'ongoing', 'completed'];
export const mitigationStatusList = [
  'can-not-verify',
  'not-fixed',
  'fixed',
  'partially-fixed',
];

export const FilterReportStatusList = {
  valid: {
    name: 'Valid',
    color: 'blue',
  },
  'not-valid': {
    name: 'Not valid',
    color: 'orange',
  },
  review: {
    name: 'To review',
    color: '#79C7E3',
  },
};

export const PaymentMethods = [{ id: '458', name: 'Paypal' }];

export const SeverityList = {
  critical: {
    color: '#91243E',
    score: 9.0,
  },
  high: {
    color: '#DD4B50',
    score: 7.0,
  },
  medium: {
    color: '#F18C43',
    score: 4.0,
  },
  low: {
    color: '#F8C851',
    score: 1.0,
  },
  none: {
    color: '#888888',
    score: 0.0,
  },
};

export const ProgramTypes = [
  { code: 'mob-app', name: 'Mobile App' },
  { code: 'desk-app', name: 'Desktop App' },
  { code: 'web-app', name: 'Web Application' },
  { code: 'infra', name: 'Infrastructure' },
  { code: 'iot', name: 'IoT' },
  { code: 'api', name: 'API' },
  { code: 'cloud', name: 'Cloud' },
];

export const Languages = [
  { code: 'es', name: 'spanish' },
  { code: 'en', name: 'english' },
];

export const Services = [
  {
    id: '1',
    name: 'Programa privado',
    description:
      ' Ideal, si deseas lanzar un programa exclusivo y cerrado, con un acuerdo de confidencialidad personalizado',
    mount: 'Gratis',
  },
  {
    id: '2',
    name: 'Triage de reportes',
    description:
      'CyScope filtra, revisa, reproduce y valida tus reportes de seguridad para garantizar la calidad de los resultados obtenidos.',
    mount: '30',
  },
  {
    id: '3',
    name: 'Retest de vulnerabilidades',
    description:
      'CyScope se encarga de comprobar que la mitigación de las vulnerabilidades fue exitosamente realizada por tu equipo de desarrollo.',
    mount: '40',
  },
  {
    id: '4',
    name: 'Squad especial',
    description:
      'CyScope invita a los mejores hackers de la plataforma o selecciona los perfiles específicos que requieres para tu programa.',
    mount: '50',
  },
];

export const PreferedLanguages = [
  { id: 'es', name: 'Español' },
  { id: 'en', name: 'English' },
];

export const Countries = [
  { id: 'AF', name: 'Afghanistan' },
  { id: 'AX', name: 'Åland Islands' },
  { id: 'AL', name: 'Albania' },
  { id: 'DZ', name: 'Algeria' },
  { id: 'AS', name: 'American Samoa' },
  { id: 'AD', name: 'Andorra' },
  { id: 'AO', name: 'Angola' },
  { id: 'AI', name: 'Anguilla' },
  { id: 'AQ', name: 'Antarctica' },
  { id: 'AG', name: 'Antigua and Barbuda' },
  { id: 'AR', name: 'Argentina' },
  { id: 'AM', name: 'Armenia' },
  { id: 'AW', name: 'Aruba' },
  { id: 'AU', name: 'Australia' },
  { id: 'AT', name: 'Austria' },
  { id: 'AZ', name: 'Azerbaijan' },
  { id: 'BS', name: 'Bahamas' },
  { id: 'BH', name: 'Bahrain' },
  { id: 'BD', name: 'Bangladesh' },
  { id: 'BB', name: 'Barbados' },
  { id: 'BY', name: 'Belarus' },
  { id: 'BE', name: 'Belgium' },
  { id: 'BZ', name: 'Belize' },
  { id: 'BJ', name: 'Benin' },
  { id: 'BM', name: 'Bermuda' },
  { id: 'BT', name: 'Bhutan' },
  { id: 'BO', name: 'Bolivia, Plurinational State of' },
  { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { id: 'BA', name: 'Bosnia and Herzegovina' },
  { id: 'BW', name: 'Botswana' },
  { id: 'BV', name: 'Bouvet Island' },
  { id: 'BR', name: 'Brazil' },
  { id: 'IO', name: 'British Indian Ocean Territory' },
  { id: 'BN', name: 'Brunei Darussalam' },
  { id: 'BG', name: 'Bulgaria' },
  { id: 'BF', name: 'Burkina Faso' },
  { id: 'BI', name: 'Burundi' },
  { id: 'CV', name: 'Cape Verde' },
  { id: 'KH', name: 'Cambodia' },
  { id: 'CM', name: 'Cameroon' },
  { id: 'CA', name: 'Canada' },
  { id: 'KY', name: 'Cayman Islands' },
  { id: 'CF', name: 'Central African Republic' },
  { id: 'TD', name: 'Chad' },
  { id: 'CL', name: 'Chile' },
  { id: 'CN', name: 'China' },
  { id: 'CX', name: 'Christmas Island' },
  { id: 'CC', name: 'Cocos (Keeling) Islands' },
  { id: 'CO', name: 'Colombia' },
  { id: 'KM', name: 'Comoros' },
  { id: 'CG', name: 'Congo' },
  { id: 'CD', name: 'Congo, The Democratic Republic of the' },
  { id: 'CK', name: 'Cook Islands' },
  { id: 'CR', name: 'Costa Rica' },
  { id: 'CI', name: "Côte d'Ivoire" },
  { id: 'HR', name: 'Croatia' },
  { id: 'CU', name: 'Cuba' },
  { id: 'CW', name: 'Curaçao' },
  { id: 'CY', name: 'Cyprus' },
  { id: 'CZ', name: 'Czech Republic' },
  { id: 'DK', name: 'Denmark' },
  { id: 'DJ', name: 'Djibouti' },
  { id: 'DM', name: 'Dominica' },
  { id: 'DO', name: 'Dominican Republic' },
  { id: 'EC', name: 'Ecuador' },
  { id: 'EG', name: 'Egypt' },
  { id: 'SV', name: 'El Salvador' },
  { id: 'GQ', name: 'Equatorial Guinea' },
  { id: 'ER', name: 'Eritrea' },
  { id: 'EE', name: 'Estonia' },
  { id: 'SZ', name: 'Eswatini' },
  { id: 'ET', name: 'Ethiopia' },
  { id: 'FK', name: 'Falkland Islands (Malvinas)' },
  { id: 'FO', name: 'Faroe Islands' },
  { id: 'FJ', name: 'Fiji' },
  { id: 'FI', name: 'Finland' },
  { id: 'FR', name: 'France' },
  { id: 'GF', name: 'French Guiana' },
  { id: 'PF', name: 'French Polynesia' },
  { id: 'TF', name: 'French Southern Territories' },
  { id: 'GA', name: 'Gabon' },
  { id: 'GM', name: 'Gambia' },
  { id: 'GE', name: 'Georgia' },
  { id: 'DE', name: 'Germany' },
  { id: 'GH', name: 'Ghana' },
  { id: 'GI', name: 'Gibraltar' },
  { id: 'GR', name: 'Greece' },
  { id: 'GL', name: 'Greenland' },
  { id: 'GD', name: 'Grenada' },
  { id: 'GP', name: 'Guadeloupe' },
  { id: 'GU', name: 'Guam' },
  { id: 'GT', name: 'Guatemala' },
  { id: 'GG', name: 'Guernsey' },
  { id: 'GN', name: 'Guinea' },
  { id: 'GW', name: 'Guinea-Bissau' },
  { id: 'GY', name: 'Guyana' },
  { id: 'HT', name: 'Haiti' },
  { id: 'HM', name: 'Heard Island and McDonald Islands' },
  { id: 'VA', name: 'Holy See (Vatican City State)' },
  { id: 'HN', name: 'Honduras' },
  { id: 'HK', name: 'Hong Kong' },
  { id: 'HU', name: 'Hungary' },
  { id: 'IS', name: 'Iceland' },
  { id: 'IN', name: 'India' },
  { id: 'ID', name: 'Indonesia' },
  { id: 'IR', name: 'Iran, Islamic Republic of' },
  { id: 'IQ', name: 'Iraq' },
  { id: 'IE', name: 'Ireland' },
  { id: 'IM', name: 'Isle of Man' },
  { id: 'IL', name: 'Israel' },
  { id: 'IT', name: 'Italy' },
  { id: 'JM', name: 'Jamaica' },
  { id: 'JP', name: 'Japan' },
  { id: 'JE', name: 'Jersey' },
  { id: 'JO', name: 'Jordan' },
  { id: 'KZ', name: 'Kazakhstan' },
  { id: 'KE', name: 'Kenya' },
  { id: 'KI', name: 'Kiribati' },
  { id: 'KP', name: "Korea, Democratic People's Republic of" },
  { id: 'KR', name: 'Korea, Republic of' },
  { id: 'KW', name: 'Kuwait' },
  { id: 'KG', name: 'Kyrgyzstan' },
  { id: 'LA', name: "Lao People's Democratic Republic" },
  { id: 'LV', name: 'Latvia' },
  { id: 'LB', name: 'Lebanon' },
  { id: 'LS', name: 'Lesotho' },
  { id: 'LR', name: 'Liberia' },
  { id: 'LY', name: 'Libya' },
  { id: 'LI', name: 'Liechtenstein' },
  { id: 'LT', name: 'Lithuania' },
  { id: 'LU', name: 'Luxembourg' },
  { id: 'MO', name: 'Macao' },
  { id: 'MG', name: 'Madagascar' },
  { id: 'MW', name: 'Malawi' },
  { id: 'MY', name: 'Malaysia' },
  { id: 'MV', name: 'Maldives' },
  { id: 'ML', name: 'Mali' },
  { id: 'MT', name: 'Malta' },
  { id: 'MH', name: 'Marshall Islands' },
  { id: 'MQ', name: 'Martinique' },
  { id: 'MR', name: 'Mauritania' },
  { id: 'MU', name: 'Mauritius' },
  { id: 'YT', name: 'Mayotte' },
  { id: 'MX', name: 'Mexico' },
  { id: 'FM', name: 'Micronesia, Federated States of' },
  { id: 'MD', name: 'Moldova, Republic of' },
  { id: 'MC', name: 'Monaco' },
  { id: 'MN', name: 'Mongolia' },
  { id: 'ME', name: 'Montenegro' },
  { id: 'MS', name: 'Montserrat' },
  { id: 'MA', name: 'Morocco' },
  { id: 'MZ', name: 'Mozambique' },
  { id: 'MM', name: 'Myanmar' },
  { id: 'NA', name: 'Namibia' },
  { id: 'NR', name: 'Nauru' },
  { id: 'NP', name: 'Nepal' },
  { id: 'NL', name: 'Netherlands' },
  { id: 'NC', name: 'New Caledonia' },
  { id: 'NZ', name: 'New Zealand' },
  { id: 'NI', name: 'Nicaragua' },
  { id: 'NE', name: 'Niger' },
  { id: 'NG', name: 'Nigeria' },
  { id: 'NU', name: 'Niue' },
  { id: 'NF', name: 'Norfolk Island' },
  { id: 'MP', name: 'Northern Mariana Islands' },
  { id: 'NO', name: 'Norway' },
  { id: 'OM', name: 'Oman' },
  { id: 'PK', name: 'Pakistan' },
  { id: 'PW', name: 'Palau' },
  { id: 'PS', name: 'Palestine, State of' },
  { id: 'PA', name: 'Panama' },
  { id: 'PG', name: 'Papua New Guinea' },
  { id: 'PY', name: 'Paraguay' },
  { id: 'PE', name: 'Peru' },
  { id: 'PH', name: 'Philippines' },
  { id: 'PN', name: 'Pitcairn' },
  { id: 'PL', name: 'Poland' },
  { id: 'PT', name: 'Portugal' },
  { id: 'PR', name: 'Puerto Rico' },
  { id: 'QA', name: 'Qatar' },
  { id: 'MK', name: 'Republic of North Macedonia' },
  { id: 'RO', name: 'Romania' },
  { id: 'RU', name: 'Russian Federation' },
  { id: 'RW', name: 'Rwanda' },
  { id: 'RE', name: 'Réunion' },
  { id: 'BL', name: 'Saint Barthélemy' },
  { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { id: 'KN', name: 'Saint Kitts and Nevis' },
  { id: 'LC', name: 'Saint Lucia' },
  { id: 'MF', name: 'Saint Martin (French part)' },
  { id: 'PM', name: 'Saint Pierre and Miquelon' },
  { id: 'VC', name: 'Saint Vincent and the Grenadines' },
  { id: 'WS', name: 'Samoa' },
  { id: 'SM', name: 'San Marino' },
  { id: 'ST', name: 'Sao Tome and Principe' },
  { id: 'SA', name: 'Saudi Arabia' },
  { id: 'SN', name: 'Senegal' },
  { id: 'RS', name: 'Serbia' },
  { id: 'SC', name: 'Seychelles' },
  { id: 'SL', name: 'Sierra Leone' },
  { id: 'SG', name: 'Singapore' },
  { id: 'SX', name: 'Sint Maarten (Dutch part)' },
  { id: 'SK', name: 'Slovakia' },
  { id: 'SI', name: 'Slovenia' },
  { id: 'SB', name: 'Solomon Islands' },
  { id: 'SO', name: 'Somalia' },
  { id: 'ZA', name: 'South Africa' },
  { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { id: 'SS', name: 'South Sudan' },
  { id: 'ES', name: 'Spain' },
  { id: 'LK', name: 'Sri Lanka' },
  { id: 'SD', name: 'Sudan' },
  { id: 'SR', name: 'Suriname' },
  { id: 'SJ', name: 'Svalbard and Jan Mayen' },
  { id: 'SE', name: 'Sweden' },
  { id: 'CH', name: 'Switzerland' },
  { id: 'SY', name: 'Syrian Arab Republic' },
  { id: 'TW', name: 'Taiwan, Province of China' },
  { id: 'TJ', name: 'Tajikistan' },
  { id: 'TZ', name: 'Tanzania, United Republic of' },
  { id: 'TH', name: 'Thailand' },
  { id: 'TL', name: 'Timor-Leste' },
  { id: 'TG', name: 'Togo' },
  { id: 'TK', name: 'Tokelau' },
  { id: 'TO', name: 'Tonga' },
  { id: 'TT', name: 'Trinidad and Tobago' },
  { id: 'TN', name: 'Tunisia' },
  { id: 'TR', name: 'Turkey' },
  { id: 'TM', name: 'Turkmenistan' },
  { id: 'TC', name: 'Turks and Caicos Islands' },
  { id: 'TV', name: 'Tuvalu' },
  { id: 'UG', name: 'Uganda' },
  { id: 'UA', name: 'Ukraine' },
  { id: 'AE', name: 'United Arab Emirates' },
  { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { id: 'UM', name: 'United States Minor Outlying Islands' },
  { id: 'US', name: 'United States of America' },
  { id: 'UY', name: 'Uruguay' },
  { id: 'UZ', name: 'Uzbekistan' },
  { id: 'VU', name: 'Vanuatu' },
  { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
  { id: 'VN', name: 'Viet Nam' },
  { id: 'VG', name: 'Virgin Islands, British' },
  { id: 'VI', name: 'Virgin Islands, U.S.' },
  { id: 'WF', name: 'Wallis and Futuna' },
  { id: 'EH', name: 'Western Sahara' },
  { id: 'YE', name: 'Yemen' },
  { id: 'ZM', name: 'Zambia' },
  { id: 'ZW', name: 'Zimbabwe' },
];

export const headerHistory = ['Fecha', 'Usuario', 'Detalle', 'Monto', 'Saldo'];
export const headerPayment = [
  'Fecha',
  'Programa',
  'ID',
  'Hacker',
  'Severidad',
  'Estado',
  'Monto',
];

export const headerReport = [
  'ID',
  'Creado',
  'Actualizado',
  'Hacker',
  'Pagado',
  'Monto',
  'Link',
  'Programa',
  'Nombre del reporte',
  'Tipo de ataque',
  'CVSS',
  'Severidad',
  'Estado del reporte',
  'Target',
  'URL',
  'Descripción',
  'Impacto',
  'Request',
  'Parámetros vulnerables',
  'Payload',
  'Prueba de concepto',
  'Referencia',
  'Contramedidas',
  'Paso 1',
  'Paso 2',
  'Paso 3',
];

export const headerProgram = [
  'Nombre',
  'Estado',
  'Presupuesto Total',
  'Reservado',
  'Pagado',
  'Disponible',
];

export const Skills = [
  { code: 'mobile', name: 'Mobile App' },
  { code: 'desktop', name: 'Desktop App' },
  { code: 'web', name: 'Web Application' },
  { code: 'infrastructure', name: 'Infrastructure' },
  { code: 'iot', name: 'IoT' },
];

export const programDefaultDescription = `
*Ejemplo de programa*


La siguiente descripción es a modo de ejemplo para orientar la creación de su programa. Siéntese libre de personalizarla modificando, eliminando y/o agregando reglas, pruebas y otra información que considere relevante. Recuerde que además puede orientar al hacker sobre la infraestructura o aplicación a revisar, para buscar vulnerabilidades de su interés.

> *Sobre la {empresa}*
>
Breve descripción de la empresa. Se recomienda incluir la visión y misión de la organización. 
Adicionalmente, usted está invitado a presentar la filosofía de seguridad de su empresa - por ejemplo: la importancia del bug bounty para su organización, la forma en la cual desea colaborar con la comunidad de Hackers de CyScope y los beneficios esperados.

----------

> *Objetivos del programa*
>
Indicar los objetivos específicos del presente programa de bug bounty en relación a los targets de pruebas.
Ejemplos:
Buscar vulnerabilidades que generan un IMPACTO de seguridad para el negocio.
Verificar que el hacker no pueda suplantar o acceder a la cuenta de otro usuario.
Verificar que los datos sensibles de sus clientes estén protegidos.
Etc.

----------

> *Target(s)*
>
 https://www.{empresa}.com

> **Credenciales de pruebas:**
>
Usuario: usuario1
Contraseña : micontraseña12

> ** Target(s) fuera de scope**
>
https://otrodominio.{empresa}.com

----------

> *Política de Divulgación & Confidencialidad*
>
Al participar en este programa, el hacker acepta el siguiente listado de acciones NO permitidas:
> - Proporcionar información relacionada con el programa y los resultados obtenidos a terceros que no están directamente involucrados en el programa o gestión de este.
> - Divulgar una vulnerabilidad sin la autorización escrita de CyScope y de {empresa}.
> - Divulgar una vulnerabilidad a terceros, o en otros medios, aun cuando haya sido validada y mitigada por {empresa}.
> - Utilizar un lenguaje informal e inapropiado al momento de redactar el reporte de vulnerabilidad.
> - Violar las normas de confidencialidad.
>
Para que el reporte sea aceptado y recompensado, el hacker debe cumplir una serie de requisitos:
> - Estar alineado con las reglas del programa.
> - Estar dentro del ámbito de pruebas definido (targets) en el programa.
> - Incluir una descripción clara, detallada, concisa e ilustrada de la vulnerabilidad, del sistema afectado, su impacto, nivel de riesgo, parámetros y una prueba de concepto pertinente. Las pruebas de concepto pueden ser a través de pantallazos o videos.
> - Incluir la información clara y detallada tales como los distintos pasos a seguir para reproducir el error. Las vulnerabilidades deben ser ejecutables y accionables es decir revelar fallas de seguridad, y no teóricas.
> - El pago del reporte está reservado para el primer hacker que haya reportado una vulnerabilidad válida. Los reportes duplicados no se pagan, pero el hacker gana puntos que le permiten aumentar su posicionamiento en la plataforma de CyScope.
> - Crear un reporte por tipo de vulnerabilidad única e indicar el o los sistemas afectados.  
> - Si el equipo administrador del programa requiere información adicional sobre un reporte, se comunicará con el hacker vía correo electrónico o a través del chat del programa.
>
Importante: Los reportes incompletos no cumplen con la política de divulgación de la plataforma y no serán aceptados por el equipo administrador del programa. Las vulnerabilidades reportadas, que están fuera del ámbito del programa, no serán analizadas ni recompensadas.
>

----------

> *Pruebas inelegibles*
>
Las siguientes actividades son estrictamente prohibidas:
> - Realizar pruebas sobre el sistema de manera descontrolada, afectando la disponibilidad, confidencialidad e integridad de los datos de {empresa}.
> - Realizar cambios en el sistema evaluado y compartir con terceros el acceso al sistema.
> - Realizar ataques de fuerza bruta, ingeniería social, intrusión física y spam.
> - Integrar el uso de malware en las pruebas.
> - Subir vulnerabilidad o contenido relacionado con {empresa} a utilidades de terceros.
> - Perjudicar a {empresa} a través de actividades no programadas o acordadas.
> - Explotar los accesos a sistemas, cuentas, usuarios o datos de usuarios obtenidos. En este caso, el hacker deberá detener sus pruebas y elaborar un reporte para notificar a la empresa. No podrá investigar más usando esta debilidad.
> - Realizar ataques DoS o DDoS sin haber recibido una autorización escrita previa de CyScope y {empresa}. Si el hacker sospecha de un DoS de capa de aplicación, debe ponerse en contacto con CyScope para solicitar permiso: info@cyscope.io
> - Intentar una de estas actividades, podría resultar en una descalificación del programa y eliminación inmediata de la plataforma. Dependiendo de la gravedad de la infracción, se podrían tomar acciones judiciales.
>
Los siguientes tipos de pruebas y vulnerabilidades no serán recompensados y no se aceptarán reportes de este tipo:
> - Excepciones no controladas (error 500).
> - Enumeración UUID.
> - CSV injection.
> - Cross-Site Request Forgery (CSRF) con impacto limitado.
> - Reportes de escaneos SSL/TLS.
> - Reportes especulativos con impacto teórico sin prueba de concepto.
> - Reportes de escaneos automatizados sin una validación manual.
> - Extraer la versión del sistema.
> - Errores de Debug o revelación de la ruta del sistema (Path disclosure).
> - Ausencia de cabeceras de seguridad.

----------

> *Pruebas elegibles*
> Se buscan vulnerabilidades con un impacto real, tales como:
>
> | Severidad | Nombre corto | Ejemplo                                       |
> | --------- | ------------ | --------------------------------------------- |
> | Critico   | RCE          | Remote Code Execution                         |
> | Critica   | XXE          | XML External Entity                           |
> | Alta      | SQLi         | SQL Injection                                 |
> | Alta      | IDOR         | Insecure Direct Object Reference              |
> | Alta      | SSRF         | Server-Side Request Forgery                   |
> | Alta      | ---          | Authentication or Authorization Bypass        |
> | Alta      | ATO          | Account Takeover                              |
> | Alta      | SSI          | Server-Side Inclusions Injection              |
> | Medio     | ---          | S3 Bucket Upload                              |
> | Medio     | XSS          | Cross-Site Scripting                          |
> | Medio     | UE           | PII User Enumeration                          |
> | Medio     | CSRF         | Cross-Site Request Forgery                    |
> | Medio     | CRLFi        | CRLF Injection                                |
> | Medio     | SDTO         | Subdomain Takeover                            |
> | Medio     | ---          | Information Exposure                          |
> | Bajo      | ---          | Missing Encryption of Sensitive Data          |
> | Bajo      | ---          | Open Redirect                                 |
> | Bajo      | ---          | Improper Restriction in form with captcha.    |
>

----------

Al aceptar este programa, el hacker declara haber leído y entendido la descripción integral del programa.
La participación del hacker está regulada por los Términos y Condiciones Generales (y respectivos anexos) de CyScope que puedes consultar aqui aquí: https://cyscope.io/terms-and-conditions/

`;
export const programDefaultPtaasDescription = `
Bienvenid@ a CyScope, la plataforma de Pentest-as-a-service desarrollada por Dreamlab Technologies.

[Name_Customer] solicita el apoyo de un equipo experto en ciberseguridad para evaluar la postura de seguridad de su(s) [target type] en modalidad [black/grey/White box].

> * OBJETIVOS *
The objectives of this new program are:
Ej: Cumplimiento 
Ej: Evento especial
Ej: Necesidad de evaluar una nueva función 
Ej: Nueva versión
Ej: Preocupación por algunos componentes del objetivo
Etc.

> * ALCANCE *
(if Web App)
> | NAME      | URL          | VERSION          |
(if External infrastructure) 
> | IP List      |          | 
> | Or IP Range      |          |
(if mobile App)
> | NAME      | OS (Android/iOS)        | URL           | VERSION          |
[Name_Customer] entregó información adicional sobre el ámbito de pruebas: [tecnologias/tipo de infraestructura]

El equipo de ciberseguridad realizará el pentest en un ambiente [productivo/desarrollo/QA].
 
> * ACCESSOS *
El equipo de ciberseguridad realizará el pentest con las siguientes credenciales: 
> | URL      | Role          | Username/Pass          |
> | URL      | Role          | Username/Pass          |


> * RESTRICCIONES Y ESPECIFICACIONES * 

El cliente decidió aplicar algunas restricciones al programa y especificar lo siguiente: 
Ej: Horario y/o fechas en las cuales NO se puede ejecutar pruebas
Ej: El equipo tiene autorización para explotar vulnerabilidades, o el equipo puede explotar vulnerabilidades bajo ciertas consideraciones.
Ej: Exclusiones (subdominios, IPs, etc fuera del scope).

Recuerda que el Pentest-as-a-Service debe centrarse únicamente en el alcance entregado por el cliente. Todos los problemas de seguridad que afecten a objetivos que estén fuera del alcance no se aceptarán durante el QA técnico. 

Si tienes alguna pregunta sobre la Plataforma, no dudes en ponerte en contacto con nosotros: info@cyscope.io

Happy Hacking!



`;

export const footerUrl = {
  companies: {
    ptaas: 'https://cyscope.io/pentest-as-a-service/',
    bugbounty: 'https://cyscope.io/bug-bounty-programs/',
    faq: 'https://cyscope.io/contact/',
  },
  hackers: {
    hacktivities: 'https://cyscope.io/hackers/',
  },
  legal: {
    TAC: 'https://cyscope.io/terms-and-conditions/',
    agreement: 'https://cyscope.io/confidentiality-agreement/',
    disclosure: 'https://cyscope.io/disclosure-policy/',
    privacy: 'https://cyscope.io/privacy-policy/',
    ranking: 'https://cyscope.io/ranking-and-sanctions/',
  },
};

export const getInitState = function () {
  return {
    notifications: {
      loading: true,
      loadingMore: false,
      notifications: [],
      maxLength: 0,
      nextPage: 0,
      unreaded: 0,
      getMoreNotifications: null,
      newNotification: null,
    },
    payments: {
      loading: true,
      filtering: false,
      list: null,
      currentPage: 1,
      filters: {
        byState: 'Paid',
      },
      numberOfElements: null,
    },
    programs: {
      allMyPrograms: null,
    },
    program: {
      loading: true,
      program: null,
    },
    report: {
      loading: true,
      report: null,
    },
    scores: {
      loading: true,
      refreshing: false,
      list: null,
      currentPage: 1,
      numberOfElements: null,
      refresh: null,
    },
    activities: {
      loading: true,
      list: null,
      currentPage: 1,
      numberOfElements: null,
      refresh: null,
    },
    history: {
      loading: true,
      list: null,
      currentPage: 1,
      numberOfElements: null,
      refresh: null,
    },
    statistics: {
      collection: null,
      months: null,
      currentUserYears: null,
      filters: {},
      filtering: false,
    },
    wallet: {
      loading: false,
      available_wallet_amount: 0,
      available_quote: 0,
      available_total: 0,
    },
    paymentsPreview: {
      loading: false,
      list: null,
      totalPaidAmount: 0,
      totalPendingAmount: 0,
      maxLength: 0,
    },
    scoresPreview: {
      loading: false,
      list: null,
      maxLength: 0,
    },
    activitiesPreview: {
      loading: false,
      activities: null,
      maxLength: 0,
    },
  };
};
