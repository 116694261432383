import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CreditCardOutlined, FileOutlined } from '@ant-design/icons';
import { Card, Table, Tooltip } from 'antd';
import moment from 'moment';
import withSizes from 'react-sizes';
import * as _ from 'lodash';

import '../themes/basic-list.less';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import { getInvoice } from '../redux/actions/payments';
import { mapSizes } from '../mapSizes';
import { Can } from '@casl/react';

import { subject } from '@casl/ability';
import { isCompanyMode } from '../utils/General';
import { openNotification } from '../utils/General';

class PaymentList extends Component {
  getTable() {
    const { payments, isMobile, currentUser, t } = this.props;

    const customSeverityOrder = ['low', 'medium', 'high', 'critical'];
    const columns = _.concat(
      _.concat(
        [
          {
            title: t('budget.rewards.payments.date'),
            dataIndex: 'created_at',
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            render: (text) => (
              <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                {moment(text).format('DD/MM/YYYY')}
              </div>
            ),
          },
          {
            title: t('budget.rewards.payments.program'),
            dataIndex: 'program',
            onFilter: (value, record) => record.program.indexOf(value) === 0,
            sorter: (a, b) => a.program.localeCompare(b.program),
            render: (text) => (
              <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                {text}
              </div>
            ),
            width: isMobile ? '14%' : '21%',
          },
          {
            title: 'ID',
            dataIndex: 'friendlyId',
            sorter: (a, b) => a.friendlyId.localeCompare(b.friendlyId),
            render: (text, record) => (
              <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                <Tooltip placement="right" title={record.reportName}>
                  {text}
                </Tooltip>
              </div>
            ),
          },
        ],
        !isCompanyMode(currentUser)
          ? [
              {
                title: t('budget.rewards.payments.company'),
                dataIndex: 'company',
                sorter: (a, b) => a.company.localeCompare(b.company),
                render: (text) => (
                  <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                    {text}
                  </div>
                ),
              },
            ]
          : [
              {
                title: 'Hacker',
                dataIndex: 'nickname',
                sorter: (a, b) => a.nickname.localeCompare(b.nickname),
                render: (text) => (
                  <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                    {text}
                  </div>
                ),
              },
            ]
      ),
      [
        {
          title: t('budget.rewards.payments.severity'),
          dataIndex: 'severity',
          sorter: (a, b) =>
            customSeverityOrder.indexOf(a.severity) -
            customSeverityOrder.indexOf(b.severity),
          render: (text) => (
            <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
              {t(`severities.${text}`)}
            </div>
          ),
        },
        {
          ...(!isCompanyMode(currentUser)
            ? {
                title: (
                  <Tooltip
                    placement="right"
                    title={t('budget.rewards.payments.status-tooltip')}
                  >
                    {t('budget.rewards.payments.status')}
                  </Tooltip>
                ),
                dataIndex: 'state',
                sorter: (a, b) => a.state.localeCompare(b.state),
                render: (text, record) => (
                  <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
                    <Tooltip placement="right" title={record.paid_at}>
                      {t(`payments.status.${text}`)}
                    </Tooltip>
                  </div>
                ),
              }
            : {}),
        },
        {
          title: t('budget.rewards.payments.amount'),
          className: 'column-right',
          dataIndex: 'amount',
          width: isMobile && '8%',
          sorter: (a, b) => a.amount - b.amount,
          render: (text) => (
            <div style={{ wordBreak: 'break-word', fontSize: '12px' }}>
              <NumberDisplay number={text} />
            </div>
          ),
        },
        {
          title: (
            <Tooltip
              placement="right"
              title={t('budget.rewards.payments.actions-tooltip')}
            >
              {
                <span className="pull-right">
                  {t('budget.rewards.payments.actions')}
                </span>
              }
            </Tooltip>
          ),
          className: 'column-final',
          dataIndex: 'actions',
        },
      ]
    );

    const dataSource = payments.map((payment, i) => {
      const { report } = payment;
      const { program } = report;
      const { company } = program;

      return Object.assign(
        Object.assign({
          key: i.toString(),
          created_at: payment.created_at,
          company: company.name,
          program: program.name,
          friendlyId: report.friendly_id,
          severity: report.severity,
          state: payment.status,
          nickname: report.user.username,
          reportName: report.name,
        }),
        {
          amount: payment.amount,
          actions: (
            <span className="pull-right">
              <Tooltip title={t('budget.rewards.payments.report')}>
                <a href={`/dashboard/report/${report.id}`} target="_blank">
                  {
                    <FileOutlined
                      style={{
                        marginRight: '5px',
                        color: 'black',
                        fontSize: '18px',
                      }} />
                  }
                </a>
              </Tooltip>
              <Can
                I="download_invoice"
                this={subject('Payment', payment)}
                ability={this.props.ability}
              >
                {() => (
                  <span
                    className="pull-right"
                    onClick={() => {
                      this.downloadInvoice(payment.order.id);
                    }}
                  >
                    <Tooltip title={t('budget.rewards.payments.voucher')}>
                      <CreditCardOutlined
                        style={{
                          fontSize: '20px',
                          marginLeft: '15px',
                          cursor: 'pointer',
                        }} />
                    </Tooltip>
                  </span>
                )}
              </Can>
            </span>
          ),
        }
      );
    });

    return (
      <div style={{ margin: '20px 0px' }}>
        <Table
          scroll={{ x: 950 }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
        <br />
        <h1
          style={{
            fontSize: '12px',
            marginTop: '10px',
            color: 'gray',
            position: 'relative',
            bottom: '27px',
            width: '100%',
            height: '0',
            marginTop: '2vh',
          }}
        >
          {t('budget.programs.table-footer')}
        </h1>
      </div>
    );
  }

  downloadInvoice(id) {
    const { dispatch, t } = this.props;

    dispatch(getInvoice(id)).then(() => {
      if (this.props.hasError) {
        openNotification('error', 'Error', this.props.errorMessage);
      } else {
        openNotification(
          'success',
          t('notifications.title.success'),
          t('notifications.files.voucher-download')
        );
      }
    });
  }

  render() {
    const { payments, t } = this.props;
    return (
      <div className="payment-list basic-list">
        {_.isEmpty(payments) ? (
          <Card hoverable={true} className="top-hacker">
            <p className="text-center"> {t('rewards.no-payments')} </p>
          </Card>
        ) : (
          <div className="list-container">{this.getTable()}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  hasError: state.payments.hasError,
  errorMessage: state.payments.errorMessage,
  ability: state.ability,
});
export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(PaymentList))
);
