import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Image, Row, Tooltip } from 'antd';
import moment from 'moment';
import withSizes from 'react-sizes';
import Marckdown from 'react-remarkable';
import { MarkOptions } from '../utils/MarkdownHelper';
import ReportCommentDelete from './ReportCommentDelete';
import { mapSizes } from '../mapSizes';

class CommentItem extends Component {
  render() {
    const { comment, isMobile, currentUser } = this.props;
    const createdAt = moment(comment.created_at).format('DD/MM/YYYY HH:mm');
    return (
      <div className="comment-item">
        <Row>
          <Col
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <Tooltip title={comment.user.username}>
              <Image
                style={{ height: '40px', width: '40px', borderRadius: '40px' }}
                src={
                  comment.user.avatar_thumbnail_s_url || `/images/person.png`
                }
                preview={false}
                className={isMobile ? null : 'block-center'}
              />
            </Tooltip>
          </Col>
          <Col
            xs={{ span: 19, offset: 1 }}
            sm={{ span: 20, offset: 0 }}
            md={{ span: 20, offset: 0 }}
            lg={{ span: 20, offset: 0 }}
          >
            <div className="comment-content">
              <div className="date">
                {comment.user.username + ' '}
                {createdAt} hrs
              </div>
              <Marckdown source={comment.comment} options={MarkOptions} />
            </div>
          </Col>
          {currentUser.username === comment.user.username ? (
            <Col
              xs={{ span: 1 }}
              sm={{ span: 1, offset: 1 }}
              md={{ span: 1, offset: 1 }}
              lg={{ span: 1, offset: 1 }}
            >
              <ReportCommentDelete
                comment={comment}
                reportUID={this.props.reportUID}
              />
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
  comment: ownProps.comment,
});

export default withSizes(mapSizes)(connect(mapStateToProps)(CommentItem));
