import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, Button, Row, Col, Modal, Image } from 'antd';
import { updatePlanCompany } from '../redux/actions/currentUser';
import { convertToDate } from '../utils/General';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import { openNotification } from '../utils/General';

const { TextArea } = Input;

class CompanySelectedPlan extends Component {
  state = {
    loadingButton: false,
    visible: false,
    confirmLoading: false,
    comment: '',
    showMore: true,
  };

  componentDidUpdate(prevProps) {
    const { t } = this.props;

    if (prevProps.isChanged && !this.props.isChanged) {
      if (this.props.hasError) {
        this.setState({ loadingButton: false });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'error',
            `${t('notifications.error')}`,
            this.props.errorMessage
          );
        }, 0);
      } else {
        this.setState({ loadingButton: false });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'success',
            `${t('notifications.profile.update')}`,
            `${t('notifications.profile.change-plan')}`
          );
        }, 0);
      }
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    const { dispatch, currentUser } = this.props;
    const { comment } = this.state;

    if (comment === '') {
      return;
    }
    this.setState({
      confirmLoading: true,
    });
    dispatch(updatePlanCompany({ comment: comment }));
    this.setState({
      visible: false,
      confirmLoading: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeText = (event) => {
    let value = event.target.value;
    this.setState({
      comment: value,
    });
  };

  render() {
    const { visible, confirmLoading, comment, showMore } = this.state;
    const { activePlan } = this.props.currentUser.currentCompany;
    const { t } = this.props;

    const {
      plan: { name, description, monthly_price, yearly_price },
      user_limit,
      program_limit,
      annual_refill_top,
      commission,
      features: {
        has_account_management,
        has_budget_management,
        has_custom_nda,
        has_customer_support,
        has_program_management,
        has_rewards_management,
        has_standard_background_check,
      },
      end_at,
      commission_starts_at,
    } = activePlan;

    return (
      <div>
        {activePlan ? (
          <div>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <div style={{ margin: '20px 10px' }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="plan_container">
                      <Row>
                        <div className="title-box">
                          <Col
                            lg={{ span: 2 }}
                            sm={{ span: 2 }}
                            xs={{ span: 4 }}
                          >
                            <Image
                              src="/images/icon-send.png"
                              width={26}
                              preview={false}
                            />
                          </Col>
                          <Col
                            lg={{ span: 14 }}
                            sm={{ span: 14 }}
                            xs={{ span: 20 }}
                          >
                            <h4>
                              {name ? name : '-'}{' '}
                              <span className="description">
                                ({description})
                              </span>{' '}
                            </h4>
                          </Col>
                          <Col
                            lg={{ span: 4 }}
                            sm={{ span: 4 }}
                            xs={{ span: 24 }}
                          >
                            <Button
                              type="primary"
                              onClick={this.showModal}
                              loading={this.state.loadingButton}
                              className="btn-company btn-plan"
                            >
                              {t('profile.billing.plan.change-plan-button')}
                            </Button>
                          </Col>
                        </div>
                      </Row>
                      <table className="subscription-table">
                        <tbody>
                          <tr>
                            <td>{t('profile.billing.plan.users')}</td>
                            <td>{user_limit ? user_limit : '-'}</td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.programs')}</td>
                            <td>{program_limit ? program_limit : '-'}</td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.anual-plan')}</td>
                            <td>
                              {yearly_price ? (
                                <NumberDisplay
                                  number={yearly_price}
                                  showUSD={true}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.date-renovation')}</td>
                            <td>{end_at ? convertToDate(end_at) : '-'}</td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.max-budget')}</td>
                            <td>
                              {commission_starts_at ? (
                                <NumberDisplay
                                  number={commission_starts_at}
                                  showUSD={true}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.commission')}</td>
                            <td>{commission ? commission : '-'}%</td>
                          </tr>
                          <tr>
                            <td>{t('profile.billing.plan.anual-refill')}</td>
                            <td>
                              {annual_refill_top ? (
                                <NumberDisplay
                                  number={annual_refill_top}
                                  showUSD={true}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                          {showMore && (
                            <React.Fragment>
                              <tr>
                                <td>
                                  {t('profile.billing.plan.customer-support')}
                                </td>
                                <td>
                                  {has_customer_support ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>{t('profile.billing.plan.account')}</td>
                                <td>
                                  {has_account_management ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>{t('profile.billing.plan.budget')}</td>
                                <td>
                                  {has_budget_management ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('profile.billing.plan.program-management')}
                                </td>
                                <td>
                                  {has_program_management ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('profile.billing.plan.reward-management')}
                                </td>
                                <td>
                                  {has_rewards_management ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>{t('profile.billing.plan.back-check')}</td>
                                <td>
                                  {has_standard_background_check ? (
                                    <CheckOutlined className="check-icon" />
                                  ) : (
                                    <CloseOutlined className="cancel-icon" />
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} lg={6}>
              <Row type="flex" justify="center" align="top">
                <Modal
                  visible={visible}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      size="large"
                      loading={confirmLoading}
                      className="btn-company"
                      onClick={this.handleOk}
                      disabled={comment === ''}
                      style={{
                        width: '83%',
                        marginRight: '7px',
                        height: '40px',
                        marginBottom: '10px',
                      }}
                    >
                      <span style={{ textTransform: 'uppercase' }}>
                        {t('profile.billing.plan.send')}
                      </span>
                    </Button>,
                  ]}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      paddingTop: '15px',
                      margin: '10px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '17px',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('profile.billing.plan.change-plan')}
                    </p>
                  </div>
                  <Row
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      margin: '15px 0px 0px 0px',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        textAlign: 'start',
                      }}
                    >
                      {t('profile.billing.plan.comments')}
                    </p>
                    <TextArea
                      style={{
                        width: '83%',
                        marginLeft: '29px',
                        backgroundColor: 'white',
                        borderColor: '#d9d9d9',
                      }}
                      rows={7}
                      onChange={this.onChangeText}
                    />
                  </Row>
                </Modal>
              </Row>
            </Col>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <h2>{t('profile.billing.plan.no-plan')}</h2>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  currentUser: ownProps.user,
  isChanged: state.currentUser.isChanged,
  hasError: state.currentUser.hasError,
  errorMessage: state.currentUser.errorMessage,
});
export default withTranslation()(connect(mapStateToProps)(CompanySelectedPlan));
