import React, { Component } from 'react';
import { Layout, Row, Col, Card, Image } from 'antd';
import PasswordResetRequestForm from '../components/PasswordResetRequestForm';
import PasswordResetForm from '../components/PasswordResetForm';
import LoginForm from '../components/LoginForm';
import withSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';
import './Login.css';
import '../themes/hacker-login-layout.less';

const { Content } = Layout;

const Login = (props) => {
  const { isMobile, isBigDesktop } = props;

  const getChildren = () => {
    if (props.resetPasswordRequest) {
      return <PasswordResetRequestForm history={props.history} />;
    } else if (props.resetPasswordForm) {
      return <PasswordResetForm history={props.history} token={props.token} />;
    } else {
      return <LoginForm />;
    }
  };

  return (
    <Layout id="hackerLoginLayout">
      <Content>
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: '100vh' }}
          className="login-container"
        >
          <Col xs={22} sm={20} md={16} lg={12}>
            <Card
              style={
                isMobile
                  ? logInStyleMobile
                  : isBigDesktop
                  ? logInStyleDesktop
                  : logInStyleTablet
              }
            >
              <Row justify="center" align="middle">
                <Col span={24}>
                  <Row justify="center" align="middle" gutter={[0, 32]}>
                    <Col xs={22} sm={20} md={16} lg={12}>
                      <img
                        src={'/images/logo-cyscope-azul.svg'}
                        className="logo-login"
                      />
                    </Col>

                    <Col span={24}>{getChildren()}</Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
const logInStyleDesktop = {
  backgroundColor: '#FFFFFF',
};

const logInStyleTablet = {
  backgroundColor: '#FFFFFF',
};

const logInStyleMobile = {
  backgroundColor: '#FFFFFF',
};

export default withSizes(mapSizes)(Login);
