import React, { Component } from 'react';
import CompanyPrograms from '../components/CompanyPrograms';
import { Card, Col, Row } from 'antd';

import '../themes/statistics.less';
import CompanyRewards from '../components/CompanyRewards';
import WalletBalance from '../components/WalletBalance';
import { withTranslation } from 'react-i18next';
import FilterPaymentStatisticsForm from '../components/FilterPaymentStatisticsForm';

import axiosInstance from '../axiosInstance';
import LoaderComponent from '../components/LoaderComponent';

class FinanceBugbounty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programs: [],
      programsLoading: false,
      programsTotalItems: 0,
      programsPage: 1,

      companyCurrentBalance: null,
      programTotalBalance: null,

      statistics: null,
      statisticsLoading: false,
      statisticsFilters: {},

      programsFilters: {
        page: 1,
      },
    };

    this.itemsPerPage = 10;
  }

  componentDidMount() {
    localStorage.setItem('financeSelectedTab', 'bugbounty');
    this.fetchPrograms();
    this.fetchStatistics();
  }

  handlePageChange = (page) => {
    this.setState(
      (prevState) => ({
        programsFilters: {
          ...prevState.programsFilters,
          page: page,
        },
      }),
      () => {
        this.fetchPrograms();
      }
    );
  };

  handlePlotFilterChange = (name, value) => {
    this.setState(
      (prevState) => ({
        statisticsFilters: {
          ...prevState.statisticsFilters,
          [name]: value,
        },
      }),
      () => {
        this.fetchStatistics();
      }
    );
  };

  fetchPrograms() {
    this.setState({ programsLoading: true });

    const params = {
      ...this.state.programsFilters,
    };

    axiosInstance
      .get(`/finances/bugbounty/`, { params })
      .then((response) => {
        this.setState({
          programs: response.data.results,
          programsTotalItems: response.data.count,
          programsLoading: false,

          companyCurrentBalance: parseFloat(
            response.data.company_current_balance
          ),
          programTotalBalance: parseFloat(response.data.program_total_balance),
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ programsLoading: false });
      });
  }

  fetchStatistics() {
    this.setState({ statisticsLoading: true });

    const params = {
      ...this.state.statisticsFilters,
    };

    axiosInstance
      .get(`/payments/statistics/`, { params })
      .then((response) => {
        this.setState({
          statistics: response.data,
          statisticsLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ statisticsLoading: false });
      });
  }

  render() {
    const {
      programsLoading,
      programsTotalItems,
      programsFilters,
      programs,
      companyCurrentBalance,
      programTotalBalance,
      statisticsLoading,
      statistics,
      statisticsFilters,
    } = this.state;

    return (
      <React.Fragment>
        <Row gutter={[0, 16]} justify={'space-between'}>
          <Col lg={8} sm={24} xs={24}>
            <Card className="custom-card" style={{ marginBottom: 10 }}>
              <div className="custom-card-content">
                <WalletBalance
                  companyCurrentBalance={companyCurrentBalance}
                  programTotalBalance={programTotalBalance}
                  loading={programsLoading}
                />
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 14 }}>
            <Card className="custom-card" style={{ marginBottom: 10 }}>
              <div className="custom-card-content">
                {statisticsLoading || statistics === null ? (
                  <LoaderComponent label={false} />
                ) : (
                  <div style={{ padding: '15px' }}>
                    <FilterPaymentStatisticsForm
                      collection={statistics}
                      currentUserYears={statistics.currentUserYears}
                      isPtaas={false}
                      handleFilterChange={this.handlePlotFilterChange}
                      filters={statisticsFilters}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <Row id="Statistics">
          <Col span={24}>
            <div className="app-container">
              <CompanyPrograms
                isPtaas={false}
                programs={programs}
                currentPage={programsFilters.page}
                totalPages={Math.ceil(programsTotalItems / this.itemsPerPage)}
                totalItems={programsTotalItems}
                loading={programsLoading}
                companyCurrentBalance={companyCurrentBalance}
                handlePageChange={this.handlePageChange}
              />
            </div>
          </Col>
        </Row>
        <Row id="StatisticsForRewards">
          <Col span={24}>
            <div className="app-container">
              <CompanyRewards />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FinanceBugbounty);
