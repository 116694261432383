import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    computedMatch: { isExact },
    isAuthenticated,
  } = rest;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const PublicRoute = ({
  component: Component,
  authed,
  second,
  secondValidated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} {...rest} />
        ) : second === true && secondValidated === true ? (
          <Redirect to="/dashboard" />
        ) : second === true && secondValidated === false ? (
          <Redirect to="/auth/two-factor" />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
};
