import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Col, Row } from 'antd';

class Pagination extends React.Component {
  handlePageChange = (page) => {
    this.props.onPageChange(page);
  };

  render() {
    const { currentPage, totalPages, totalItems, verbose, isMobile } =
      this.props;

    return (
      <Row className="paginate">
        <Col
          sm={24}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={`text-${isMobile ? 'center' : 'right'}`}
        >
          <div style={{ display: 'flex' }}>
            <p style={{ marginLeft: '10px' }}>{`${totalItems}`}</p>
            &nbsp;
            <p>{verbose}</p>
          </div>

          <div className="paginateIcons" style={{ margin: '0px 10px 0px 0px' }}>
            {currentPage !== 1 ? (
              <Button onClick={() => this.handlePageChange(currentPage - 1)}>
                <LeftOutlined />
              </Button>
            ) : (
              <Button disabled>
                <LeftOutlined />
              </Button>
            )}

            {currentPage === 1 ? (
              <Button style={{ display: 'none' }}></Button>
            ) : (
              <Button
                className="currentPage"
                style={{ height: '30px', width: '30px', margin: '0 3px' }}
                onClick={() => this.handlePageChange(currentPage - 1)}
              >
                {currentPage - 1}
              </Button>
            )}

            <b
              className="currentPage"
              style={{
                backgroundColor: '#108ee9',
                borderColor: '#000000',
                borderRadius: '3px',
                color: 'white',
                padding: '5px 10px',
                margin: '0 3px',
              }}
            >
              {currentPage}
            </b>

            {currentPage !== totalPages && totalItems > 10 ? (
              <Button
                className="currentPage"
                style={{ height: '30px', width: '30px', margin: '0 3px' }}
                onClick={() => this.handlePageChange(currentPage + 1)}
              >
                {currentPage + 1}
              </Button>
            ) : (
              <Button style={{ display: 'none' }}></Button>
            )}

            {currentPage !== totalPages && totalItems > 10 ? (
              <Button onClick={() => this.handlePageChange(currentPage + 1)}>
                <RightOutlined />
              </Button>
            ) : (
              <Button disabled>
                <RightOutlined />
              </Button>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default Pagination;
