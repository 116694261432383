import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio, Row, Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import { SeverityList } from '../constants/Data';
import Cvss from 'cvss-calculator';

const { Item: FormItem } = Form;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ratingCvssStyle = {
  marginRight: '15px',
  color: '#000000',
  fontSize: '16px',
  fontFamily: 'Lato, sans-serif',
};

const scoreCvssStyle = {
  marginLeft: '15px',
  color: '#000000',
  fontSize: '16px',
  fontFamily: 'Lato, sans-serif',
  fontStyle: 'italic',
};

const colorSeverityStyle = {
  height: '22px',
  width: '22px',
  borderRadius: '50%',
  border: '1px solid #000000',
};

const ReportFormSteps = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [initialValue, setInitialValue] = useState(
    props.initialValue || 'none'
  );

  const defaultCvss3 = 'CVSS:3.1/AV:N/AC:L/PR:N/UI:N/S:U/C:N/I:N/A:N';

  useEffect(() => {
    if (props.initialValue) {
      setInitialValue(props.initialValue);
      setFieldsValue({ severity: props.initialValue });
    }
  }, [props.initialValues, setFieldsValue]);

  const { t } = useTranslation();

  let parsedCvss;
  if (initialValue && initialValue.startsWith('CVSS')) {
    parsedCvss = new Cvss(initialValue);
  } else {
    parsedCvss = new Cvss(defaultCvss3);
  }

  const handleToggleScoring = () => {
    let value;
    if (props.initialValue) {
      if (getScoring() === 'cvss') {
        if (props.initialValue.startsWith('CVSS')) {
          value = parsedCvss.getRating().toLowerCase();
        } else {
          value = props.initialValue;
        }
      } else {
        if (props.initialValue.startsWith('CVSS')) {
          value = props.initialValue;
        } else {
          value = defaultCvss3;
        }
      }
    } else {
      if (getScoring() === 'cvss') {
        value = getSeverity();
      } else {
        value = defaultCvss3;
      }
    }

    setInitialValue(value);
    setFieldsValue({ severity: value });
  };

  const onChangeValueBasicSeverity = (e) => {
    const value = typeof e === 'string' ? e : e.target.value;
    setInitialValue(value);
    setFieldsValue({ severity: value });
  };

  const selectCVSSValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const regex = new RegExp('\\b' + name + ':\\b(.)');
    const newInitialValue = initialValue.replace(regex, name + ':' + value);
    setInitialValue(newInitialValue);
    setFieldsValue({ severity: newInitialValue });
  };

  const getScoring = () => {
    if (initialValue && initialValue.startsWith('CVSS')) {
      return 'cvss';
    }
    return 'basic';
  };

  const getSeverity = () => {
    if (getScoring() === 'cvss') {
      return parsedCvss.getRating().toLowerCase();
    } else {
      return initialValue || 'none';
    }
  };

  const attr = SeverityList[getSeverity()];

  const getScore = () => {
    if (getScoring() === 'cvss') {
      return parsedCvss.getBaseScore();
    } else {
      return attr.score;
    }
  };

  const getColor = () => {
    return attr.color;
  };

  return (
    <React.Fragment>
      <FormItem>
        {getFieldDecorator(`severity`, {
          initialValue: initialValue,
        })(<Input type="hidden" />)}
      </FormItem>

      <FormItem label={t('reports.form.severity')}>
        <p className="input-description">{t('reports.form.cvss-desc')}</p>
        <Row>
          <Col xs={24} sm={24} lg={16}>
            <FormItem className="severity-btn-group">
              <RadioGroup
                size="large"
                disabled={false}
                value={initialValue ? initialValue : 'none'}
                onChange={onChangeValueBasicSeverity}
              >
                <RadioButton value="none">
                  {t('reports.filters.severity.none')}
                </RadioButton>
                <RadioButton value="low">
                  {t('reports.filters.severity.low')}
                </RadioButton>
                <RadioButton value="medium">
                  {t('reports.filters.severity.medium')}
                </RadioButton>
                <RadioButton value="high">
                  {t('reports.filters.severity.high')}
                </RadioButton>
                <RadioButton value="critical">
                  {t('reports.filters.severity.critical')}
                </RadioButton>
              </RadioGroup>
            </FormItem>
          </Col>

          <Col xs={24} sm={24} lg={8}>
            <FormItem>
              <Checkbox
                style={{ color: 'black' }}
                onChange={handleToggleScoring}
                checked={getScoring() === 'basic'}
              >
                {t('reports.form.severity-check')}
              </Checkbox>
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <Row className="mb-5">
        <Col span={12}>
          <div className="label-container">
            <label className="label-form">{t('reports.form.calculator')}</label>
          </div>
        </Col>
        <Col span={12} className="right-content">
          <div
            style={{
              ...colorSeverityStyle,
              backgroundColor: getColor(),
              borderColor: getColor(),
            }}
          ></div>
          <div style={ratingCvssStyle} className="mr-2 ml-2">
            {t(`reports.filters.severity.${getSeverity()}`)}
          </div>
          <div style={scoreCvssStyle}>
            {getScore()}
            {'  '}
          </div>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <FormItem label="Attack Vector">
            <RadioGroup
              name="AV"
              value={parsedCvss.cvssMap.short.AV}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">Network</RadioButton>
              <RadioButton value="A">Adjacent</RadioButton>
              <RadioButton value="L">Local</RadioButton>
              <RadioButton value="P">Physical</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Scope">
            <RadioGroup
              name="S"
              value={parsedCvss.cvssMap.short.S}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="U">Unchanged</RadioButton>
              <RadioButton value="C">Changed</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <FormItem label="Attack Complexity">
            <RadioGroup
              name="AC"
              value={parsedCvss.cvssMap.short.AC}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="L">Low</RadioButton>
              <RadioButton value="H">High</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Confidentiality">
            <RadioGroup
              name="C"
              value={parsedCvss.cvssMap.short.C}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">None</RadioButton>
              <RadioButton value="L">Low</RadioButton>
              <RadioButton value="H">High</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <FormItem label="Privileges Required">
            <RadioGroup
              name="PR"
              value={parsedCvss.cvssMap.short.PR}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">None</RadioButton>
              <RadioButton value="L">Low</RadioButton>
              <RadioButton value="H">High</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Integrity">
            <RadioGroup
              name="I"
              value={parsedCvss.cvssMap.short.I}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">None</RadioButton>
              <RadioButton value="L">Low</RadioButton>
              <RadioButton value="H">High</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <FormItem label="User Interaction">
            <RadioGroup
              name="UI"
              value={parsedCvss.cvssMap.short.UI}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">None</RadioButton>
              <RadioButton value="R">Required</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Availability">
            <RadioGroup
              name="A"
              value={parsedCvss.cvssMap.short.A}
              onChange={selectCVSSValue}
              disabled={getScoring() !== 'cvss'}
            >
              <RadioButton value="N">None</RadioButton>
              <RadioButton value="L">Low</RadioButton>
              <RadioButton value="H">High</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReportFormSteps;
