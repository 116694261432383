import * as Action from './ActionTypes';
import { CALL_API } from '../../middleware/api';
import { routes } from '../../constants/APIEndpoints';
import axiosInstance from '../../axiosInstance';
import { setAbility } from '../reducers/abilities';

export const getUser = () => ({
  [CALL_API]: {
    types: [
      Action.GET_USER_REQUEST,
      Action.GET_USER_SUCCESS,
      Action.GET_USER_ERROR,
    ],
    endpoint: routes.ME,
    method: 'GET',
    data: {},
  },
});

export const getUserAndAbilities = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/me/');
    dispatch({ type: Action.GET_USER_SUCCESS, response: response.data });

    if (['company'].includes(response.data.role)) {
      try {
        const response2 = await axiosInstance.get('/current-company/role/');
        dispatch(setAbility(response.data, response2.data.role));
      } catch (error) {
        dispatch(setAbility(response.data));
      }
    } else {
      dispatch(setAbility(response.data));
    }
  } catch (error) {}
};

const getProfile = () => ({
  [CALL_API]: {
    types: [
      Action.GET_PROFILE_REQUEST,
      Action.FETCH_PROFILE_SUCCESS,
      Action.FETCH_PROFILE_ERROR,
    ],
    endpoint: routes.PROFILE,
    method: 'GET',
    data: {},
  },
});

const updateProfile = (params) => ({
  [CALL_API]: {
    types: [
      Action.PROFILE_UPDATE_REQUEST,
      Action.PROFILE_UPDATE_SUCCESS,
      Action.PROFILE_UPDATE_ERROR,
    ],
    endpoint: routes.UPDATE_PROFILE,
    method: 'PATCH',
    data: params,
  },
});

const getCompanyProfile = () => ({
  [CALL_API]: {
    types: [
      Action.FETCH_COMPANY_PROFILE_REQUEST,
      Action.FETCH_COMPANY_PROFILE_SUCCESS,
      Action.FETCH_COMPANY_PROFILE_ERROR,
    ],
    endpoint: routes.COMPANY_PROFILE,
    method: 'GET',
    data: {},
  },
});

const updateCompanyProfile = (params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_COMPANY_PROFILE_REQUEST,
      Action.UPDATE_COMPANY_PROFILE_SUCCESS,
      Action.UPDATE_COMPANY_PROFILE_ERROR,
    ],
    endpoint: routes.UPDATE_COMPANY_PROFILE,
    method: 'PATCH',
    data: params,
  },
});

const addSkills = (params) => ({
  [CALL_API]: {
    types: [
      Action.SKILL_UPDATE_REQUEST,
      Action.PROFILE_UPDATE_SUCCESS,
      Action.SKILL_UPDATE_ERROR,
    ],
    endpoint: routes.UPDATE_SKILLS,
    method: 'PATCH',
    data: params,
  },
});

const changePassword = (params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_PASSWORD_REQUEST,
      Action.UPDATE_PASSWORD_SUCCESS,
      Action.UPDATE_PASSWORD_ERROR,
    ],
    endpoint: routes.UPDATE_PASSWORD,
    method: 'POST',
    data: params,
  },
});

const createPaypalAccount = (params) => ({
  [CALL_API]: {
    types: [
      Action.PROFILE_UPDATE_REQUEST,
      Action.PROFILE_UPDATE_SUCCESS,
      Action.PROFILE_UPDATE_ERROR,
    ],
    endpoint: routes.UPDATE_PROFILE,
    method: 'PATCH',
    data: params,
  },
});

const deletePaypalAccount = (id) => ({
  [CALL_API]: {
    types: [
      Action.PROFILE_UPDATE_REQUEST,
      Action.PAYPAL_REMOVE_SUCCESS,
      Action.PAYPAL_REMOVE_ERROR,
    ],
    endpoint: routes.REMOVE_PAYMENTS + id,
    method: 'DELETE',
    data: {},
  },
});

const deleteCertificate = (id) => ({
  [CALL_API]: {
    types: [
      Action.PROFILE_UPDATE_REQUEST,
      Action.CERTIFICATE_REMOVE_SUCCESS,
      Action.CERTIFICATE_REMOVE_ERROR,
    ],
    endpoint: routes.REMOVE_CERTIFICATE + id,
    method: 'DELETE',
    data: {},
  },
});

const callCheckNotifications = (params) => ({
  [CALL_API]: {
    types: [
      Action.CHECK_NOTIFICATIONS_REQUEST,
      Action.CHECK_NOTIFICATIONS_SUCCESS,
      Action.CHECK_NOTIFICATIONS_ERROR,
    ],
    endpoint: routes.CHECK_NOTIFICATIONS,
    method: 'PUT',
    data: params,
  },
});

const getNotificationList = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_NOTIFICATIONS_REQUEST,
      Action.LIST_NOTIFICATIONS_SUCCESS,
      Action.LIST_NOTIFICATIONS_ERROR,
    ],
    endpoint: routes.LIST_NOTIFICATIONS,
    method: 'GET',
    data: {},
  },
});

const changePlanCompany = (params) => ({
  [CALL_API]: {
    types: [
      Action.CHANGE_PLAN_REQUEST,
      Action.CHANGE_PLAN_SUCCESS,
      Action.CHANGE_PLAN_ERROR,
    ],
    endpoint: routes.CHANGE_PLAN,
    method: 'POST',
    data: params,
  },
});

const uploadProfileImage = (params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_PROFILE_IMAGE_REQUEST,
      Action.UPDATE_PROFILE_IMAGE_SUCCESS,
      Action.UPDATE_PROFILE_IMAGE_ERROR,
    ],
    endpoint: routes.UPLOAD_PROFILE_IMAGE,
    method: 'POST',
    data: params,
  },
});

const uploadCompanyImage = (params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_COMPANY_IMAGE_REQUEST,
      Action.UPDATE_COMPANY_IMAGE_SUCCESS,
      Action.UPDATE_COMPANY_IMAGE_ERROR,
    ],
    endpoint: routes.UPLOAD_COMPANY_IMAGE,
    method: 'POST',
    data: params,
  },
});

const getSectors = (params) => ({
  [CALL_API]: {
    types: [
      Action.FETCH_COMPANY_SECTORS_REQUEST,
      Action.FETCH_COMPANY_SECTORS_SUCCESS,
      Action.FETCH_COMPANY_SECTORS_ERROR,
    ],
    endpoint: routes.GET_SECTORS,
    method: 'GET',
    data: params,
  },
});

const callCompanies = (params) => ({
  [CALL_API]: {
    types: [
      Action.FETCH_COMPANIES_REQUEST,
      Action.FETCH_COMPANIES_SUCCESS,
      Action.FETCH_COMPANIES_ERROR,
    ],
    endpoint: routes.GET_COMPANIES,
    method: 'GET',
    data: {},
  },
});

const updateAdminCompany = (params) => ({
  [CALL_API]: {
    types: [
      Action.CHANGE_CURRENT_COMPANY_REQUEST,
      Action.CHANGE_CURRENT_COMPANY_SUCCESS,
      Action.CHANGE_CURRENT_COMPANY_ERROR,
    ],
    endpoint: routes.CHANGE_COMPANY,
    method: 'POST',
    data: params,
  },
});

export const updateLanguage = (params) => ({
  [CALL_API]: {
    types: [
      Action.CHANGE_LANGUAGE_REQUEST,
      Action.CHANGE_LANGUAGE_SUCCESS,
      Action.CHANGE_LANGUAGE_ERROR,
    ],
    endpoint: routes.CHANGE_LANGUAGE,
    method: 'PATCH',
    data: params,
  },
});

const saveReferCustomer = (params) => ({
  [CALL_API]: {
    types: [
      Action.REFER_CUSTOMER_REQUEST,
      Action.REFER_CUSTOMER_SUCCESS,
      Action.REFER_CUSTOMER_ERROR,
    ],
    endpoint: routes.REFER_CUSTOMER,
    method: 'POST',
    data: params,
  },
});

export const resetUserError = () => ({
  type: Action.RESET_ERROR,
  payload: false,
});

// NEW ENDOPOINTS
export const fetchProfile = () => (dispatch, getState) => {
  return dispatch(getProfile());
};

export const fetchCompanyProfile = () => (dispatch, getState) => {
  return dispatch(getCompanyProfile());
};

export const updateUserCompany = (params) => (dispatch, getState) => {
  return dispatch(updateCompanyProfile(params));
};

export const fetchSectors =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(getSectors(params));
  };

export const updateUser = (params) => (dispatch, getState) => {
  return dispatch(updateProfile(params));
};

export const updateSkills = (params) => (dispatch, getState) => {
  return dispatch(addSkills(params));
};

export const updatePassword = (params) => (dispatch, getState) => {
  return dispatch(changePassword(params));
};

export const getCompanies = (params) => (dispatch, getState) => {
  return dispatch(callCompanies(params));
};

export const changeAdminCompany = (params) => (dispatch, getState) => {
  return dispatch(updateAdminCompany(params));
};

export const updateUserCompanyInvoicing = (params) => (dispatch, getState) => {
  return dispatch(updateCompanyProfile(params));
};

export const addPaypalAccount = (params) => (dispatch, getState) => {
  return dispatch(createPaypalAccount(params));
};

export const removePaypalAccount = (id) => (dispatch, getState) => {
  return dispatch(deletePaypalAccount(id));
};

export const removeCertificate = (id) => (dispatch, getState) => {
  return dispatch(deleteCertificate(id));
};

export const checkNotifications =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callCheckNotifications(params));
  };

export const subscribeNotificationList =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(getNotificationList(params));
  };

export const updatePlanCompany =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(changePlanCompany(params));
  };

export const referCustomer = (params) => (dispatch, getState) => {
  return dispatch(saveReferCustomer(params));
};

// UPLOAD FILES
export const updateProfileImage = (params) => (dispatch, getState) => {
  return dispatch(uploadProfileImage(params));
};

export const updateCompanyImage = (params) => (dispatch, getState) => {
  return dispatch(uploadCompanyImage(params));
};

export const adddNotifications = (params) => (dispatch) => {
  return dispatch({
    type: Action.ADD_NOTIFICATIONS_SUCCESS,
    payload: false,
    data: params,
  });
};
