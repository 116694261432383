import * as Action from './ActionTypes';
import { CALL_API } from '../../middleware/api';
import { routes } from '../../constants/APIEndpoints';

const listCompanyEmployees = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_COMPANY_EMPLOYEES_REQUEST,
      Action.LIST_COMPANY_EMPLOYEES_SUCCESS,
      Action.LIST_COMPANY_EMPLOYEES_ERROR,
    ],
    endpoint: routes.GET_COMPANY_EMPLOYEES,
    method: 'GET',
    data: {},
  },
});

const listInvitationsEmployees = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_INVITATIONS_EMPLOYEES_REQUEST,
      Action.LIST_INVITATIONS_EMPLOYEES_SUCCESS,
      Action.LIST_INVITATIONS_EMPLOYEES_ERROR,
    ],
    endpoint: routes.GET_INVITATIONS_EMPLOYEES,
    method: 'GET',
    data: {},
  },
});

const subscribeInvitesCompanyEmployees = (params) => ({
  [CALL_API]: {
    types: [
      Action.INVITES_COMPANY_EMPLOYEES_REQUEST,
      Action.INVITES_COMPANY_EMPLOYEES_SUCCESS,
      Action.INVITES_COMPANY_EMPLOYEES_ERROR,
    ],
    endpoint: routes.INVITES_COMPANY_EMPLOYEES,
    method: 'POST',
    data: params,
  },
});

const subscribeReInviteCompanyEmployee = (params) => ({
  [CALL_API]: {
    types: [
      Action.REINVITE_COMPANY_EMPLOYEE_REQUEST,
      Action.REINVITE_COMPANY_EMPLOYEE_SUCCESS,
      Action.REINVITE_COMPANY_EMPLOYEE_ERROR,
    ],
    endpoint: routes.REINVITE_COMPANY_EMPLOYEE,
    method: 'PUT',
    data: params,
  },
});

const subscribeInvitationAuthenticate = (token) => ({
  [CALL_API]: {
    types: [
      Action.INVITATION_AUTHENTICATE_REQUEST,
      Action.INVITATION_AUTHENTICATE_SUCCESS,
      Action.INVITATION_AUTHENTICATE_ERROR,
    ],
    endpoint: routes.INVITATION_AUTHENTICATE + token,
    method: 'GET',
    data: { token: token },
  },
});

const subscribeEmployeeCreate = (params) => ({
  [CALL_API]: {
    types: [
      Action.EMPLOYEE_CREATE_REQUEST,
      Action.EMPLOYEE_CREATE_SUCCESS,
      Action.EMPLOYEE_CREATE_ERROR,
    ],
    endpoint: routes.CREATE_COMPANY_EMPLOYEE,
    method: 'POST',
    data: params,
  },
});

const subscribeEmployeeActivate = (slug) => ({
  [CALL_API]: {
    types: [
      Action.EMPLOYEE_ACTIVATE_REQUEST,
      Action.EMPLOYEE_ACTIVATE_SUCCESS,
      Action.EMPLOYEE_ACTIVATE_ERROR,
    ],
    endpoint: routes.ACTIVATE_COMPANY_EMPLOYEE + slug + '/activate',
    method: 'PUT',
    data: slug,
  },
});

const subscribeEmployeeDeactivate = (slug) => ({
  [CALL_API]: {
    types: [
      Action.EMPLOYEE_DEACTIVATE_REQUEST,
      Action.EMPLOYEE_DEACTIVATE_SUCCESS,
      Action.EMPLOYEE_DEACTIVATE_ERROR,
    ],
    endpoint: routes.DEACTIVATE_COMPANY_EMPLOYEE + slug + '/deactivate',
    method: 'PUT',
    data: slug,
  },
});

const subscribeEmployeeDelete = (id) => ({
  [CALL_API]: {
    types: [
      Action.EMPLOYEE_DELETE_REQUEST,
      Action.EMPLOYEE_DELETE_SUCCESS,
      Action.EMPLOYEE_DELETE_ERROR,
    ],
    endpoint: routes.DELETE_COMPANY_EMPLOYEE.replace(':id', id),
    method: 'DELETE',
    data: {},
  },
});

const subscribeInvitationDelete = (id) => ({
  [CALL_API]: {
    types: [
      Action.INVITATION_DELETE_REQUEST,
      Action.INVITATION_DELETE_SUCCESS,
      Action.INVITATION_DELETE_ERROR,
    ],
    endpoint: routes.DELETE_INVITATION.replace(':id', id),
    method: 'DELETE',
    data: {},
  },
});

const subscribeTransferOwner = (id) => ({
  [CALL_API]: {
    types: [
      Action.TRANSFER_OWNER_REQUEST,
      Action.TRANSFER_OWNER_SUCCESS,
      Action.TRANSFER_OWNER_ERROR,
    ],
    endpoint: routes.TRANSFER_OWNER.replace(':id', id),
    method: 'POST',
    data: {},
  },
});

export const companyEmployees = () => (dispatch, getState) => {
  return dispatch(listCompanyEmployees());
};

export const invitationsEmployees = () => (dispatch, getState) => {
  return dispatch(listInvitationsEmployees());
};

export const invitesCompanyEmployees = (params) => (dispatch, getState) => {
  return dispatch(subscribeInvitesCompanyEmployees(params));
};

export const invitationAuthenticate = (token) => (dispatch, getState) => {
  return dispatch(subscribeInvitationAuthenticate(token));
};

export const reInviteCompanyEmployee = (params) => (dispatch, getState) => {
  return dispatch(subscribeReInviteCompanyEmployee(params));
};

export const employeeCreate = (params) => (dispatch, getState) => {
  return dispatch(subscribeEmployeeCreate(params));
};

export const employeeActivate = (slug) => (dispatch, getState) => {
  return dispatch(subscribeEmployeeActivate(slug));
};

export const employeeDeactivate = (slug) => (dispatch, getState) => {
  return dispatch(subscribeEmployeeDeactivate(slug));
};

export const employeeDelete = (id) => (dispatch, getState) => {
  return dispatch(subscribeEmployeeDelete(id));
};

export const invitationDelete = (id) => (dispatch, getState) => {
  return dispatch(subscribeInvitationDelete(id));
};

export const transferOwner = (id) => (dispatch, getState) => {
  return dispatch(subscribeTransferOwner(id));
};
