import { LeftOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RankingGuide = (props) => {
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id='ranking-guide'>
            <Row justify="center">
                <Col 
                    xs={{ span: 22, offset: 1 }} 
                    sm={{ span: 22, offset: 1 }} 
                    lg={{ span: 18, offset: 3 }}
                >
                    <Card                         
                        bordered={false}
                        style={{ margin: '20px 0', borderRadius: '10px' }}
                    >
                        <div className="custom-card-header">
                            <div className="left-content">
                                <a onClick={props.history.goBack} style={{ fontSize: '15px', display: 'flex', alignItems: 'center' }}>
                                <LeftOutlined style={{ fontSize: '15px', marginRight: '5px' }} />
                                {t('reports.back')}
                                </a>
                            </div>
                            <h6 className="center-title">{t('ranking.guide.title')}</h6>
                            <div className="right-placeholder"></div> {/* Placeholder para mantener el espacio */}
                        </div>
                        <hr />
                        <div>
                            <p>{t('ranking.guide.p1')}</p>
                            <h6>{t('ranking.guide.h6-1')}</h6>
                            <p>{t('ranking.guide.p2')}</p>
                            <h6>{t('ranking.guide.h6-2')}</h6>
                            <ul>
                                <li>{t('ranking.guide.ul1-li1')}</li>
                                <li>{t('ranking.guide.ul1-li2')}</li>
                                <li>{t('ranking.guide.ul1-li3')}</li>
                                <li>{t('ranking.guide.ul1-li4')}</li>
                            </ul>
                            <h6>{t('ranking.guide.h6-3')}</h6>
                            <p>{t('ranking.guide.p3')}</p>
                            <h6>{t('ranking.guide.h6-4')}</h6>
                            <ul>
                                <li>{t('ranking.guide.ul2-li1')}</li>
                                <li>{t('ranking.guide.ul2-li2')}</li>
                                <li>{t('ranking.guide.ul2-li3')}</li>
                            </ul>
                            <h6>{t('ranking.guide.h6-5')}</h6>
                            <p>{t('ranking.guide.p4')}</p>
                            <h6>{t('ranking.guide.h6-6')}</h6>
                            <ul>
                                <li>{t('ranking.guide.ul3-li1')}</li>
                                <li>{t('ranking.guide.ul3-li2')}</li>
                            </ul>
                            <h6>{t('ranking.guide.h6-7')}</h6>
                            <p>{t('ranking.guide.p5')}</p>
                            <h6>{t('ranking.guide.h6-8')}</h6>
                            <p>{t('ranking.guide.p6')}</p>
                            <b>{t('ranking.guide.b1')}</b>
                        </div>
                    </Card>
                </Col>
            </Row>

        </div>
    );
}

export default RankingGuide

