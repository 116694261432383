import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { employeeCreate } from '../redux/actions/companyEmployees';
import { openNotification } from '../utils/General';

const FormItem = Form.Item;

class CompanyEmployeeSignUpForm extends Component {
  state = {
    loadingButton: false,
    confirmDirty: false,
  };

  componentWillReceiveProps(nextProps) {
    const { isPushing, hasError, history } = this.props;

    if (isPushing && !nextProps.isPushing) {
      if (nextProps.hasError) {
        this.setState({ loadingButton: false });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'error',
            'Ha ocurrido un error inesperado.',
            nextProps.errorMessage
          );
        }, 0);
      } else {
        this.setState({ loadingButton: false });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'success',
            'Su cuenta ha sido creada exitosamente',
            ''
          );
        }, 0);
        history.push('/login/');
      }
    }
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password1')) {
      callback('Tu contraseña debe coincidir');
    } else {
      callback();
    }
  };

  checkConfirmPassword = (rule, value, callback) => {
    const form = this.props.form;
    const expression =
      '^(?:(?=.|\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&=+\\-_*]).*)$';
    const match = !value ? null : value.match(expression);

    if (value && this.state.confirmDirty) {
      form.validateFields(['password2'], { force: true });
    } else if (value && match === null) {
      callback(
        'Tu contraseña debe contener mayúsculas, minúsculas y un carácter especial'
      );
    }
    callback();
  };

  handleConfirmPassword = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { dispatch, form, token } = this.props;
    this.setState({ loadingButton: true });

    form.validateFields((err, values) => {
      if (!err) {
        dispatch(employeeCreate(values));
      } else {
        this.setState({ loadingButton: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} id="CompanyEmployeeSignUpForm">
        <h2>Ingrese sus datos de registro</h2>

        <FormItem label="Nombre">
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Ingresa tu nombre' }],
          })(<Input placeholder="John" className="col-sm-12" />)}
        </FormItem>

        <FormItem label="Apellido">
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Ingresa tu nombre completo' }],
          })(<Input placeholder="Smith" className="col-sm-12" />)}
        </FormItem>

        <FormItem label="Nombre de usuario">
          {getFieldDecorator('username', {
            rules: [
              { required: true, message: 'Ingresa tu nombre de usuario' },
              {
                min: 5,
                message:
                  'Tu nombre de usuario debe contener mínimo 5 caracteres',
              },
            ],
          })(<Input placeholder="johnsmith" />)}
        </FormItem>

        <FormItem label="Contraseña">
          {getFieldDecorator('password1', {
            rules: [
              { required: true, message: 'Ingresa tu contraseña' },
              {
                min: 8,
                message: 'Tu contraseña debe tener más de 8 caracteres',
              },
              { validator: this.checkConfirmPassword },
            ],
          })(<Input type="password" autoComplete="new-password" />)}
        </FormItem>

        <FormItem label="Confirmar Contraseña">
          {getFieldDecorator('password2', {
            rules: [
              { required: true, message: 'Confirma tu contraseña' },
              { validator: this.checkPassword },
            ],
          })(
            <Input
              type="password"
              onBlur={this.handleConfirmPassword}
              autoComplete="new-password"
            />
          )}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-company btn-block"
          loading={this.state.loadingButton}
        >
          Registrarte
        </Button>
      </Form>
    );
  }
}

const NormalCompanyEmployeeSignUpForm = Form.create()(
  CompanyEmployeeSignUpForm
);
const mapStateToProps = (state) => ({
  isPushing: state.companyEmployees.isPushing,
  hasError: state.companyEmployees.hasError,
  errorMessage: state.companyEmployees.errorMessage,
});
export default connect(mapStateToProps)(NormalCompanyEmployeeSignUpForm);
