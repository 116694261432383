import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Modal } from 'antd';
import { resetUserError, updatePassword } from '../redux/actions/currentUser';
import { getSignOut } from '../redux/actions/auth';
import { useTranslation, withTranslation } from 'react-i18next';
import { isCompanyMode } from '../utils/General';
import { openNotification } from '../utils/General';
import { withRouter } from 'react-router';
import TwoFactorOptions from './TwoFactorOptions';
import { handleAxiosError } from '../utils/Http';
import axiosInstance from '../axiosInstance';
import TwoFactorOtpForm from './TwoFactorOtpForm';

const FormItem = Form.Item;

const CustomButton = ({ onClick, children, ...rest }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [inputRequired, setInputRequired] = useState(false);

  // Handle API check to see if input is required
  const handleApiCheck = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get('/two-factor/challenge/');
      const needsToVerify = response.data['needs_to_verify'];

      if (needsToVerify) {
        setInputRequired(true); // If input is required, show modal
        setIsModalVisible(true);
      } else {
        onClick(); // Execute the function directly if no input is required
      }
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission from the modal
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/two-factor/challenge/', {
        token: values.token,
      });

      // Once input is valid, hide the modal and run the onClick function
      setIsModalVisible(false);
      onClick(); // Pass the input to the onClick handler if needed
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button onClick={handleApiCheck} loading={loading} {...rest}>
        {children}
      </Button>

      <Modal
        title={t('profile.security.2fa.title')}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <p>{t('profile.security.2fa.modal-description')}</p>
          </Col>
          <Col span={24}>
            <TwoFactorOtpForm
              onSubmit={handleFormSubmit}
              centered={true}
              isModal={handleCancel}
              isLoading={loading}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

class SecurityForm extends Component {
  state = {
    loadingButton: false,
    confirmDirty: false,
    verifyState: null,
    isModalOpen: false,
  };

  componentDidUpdate(nextProps) {
    const { dispatch, form, isPasswordUpdated, hasError, t } = this.props;

    if (
      nextProps.isPasswordUpdated &&
      isPasswordUpdated !== nextProps.isPasswordUpdated
    ) {
      this.setState({ loadingButton: false });
      let $timer = setTimeout(() => {
        clearTimeout($timer);
        $timer = null;
        openNotification(
          'success',
          `${t('notifications.profile.security-pass')}`,
          `${t('notifications.profile.security-pass-message')}`
        );
      }, 0);
      dispatch(getSignOut());
    }

    if (nextProps.hasError !== hasError && nextProps.hasError) {
      this.setState({ loadingButton: false });
      let $timer = setTimeout(() => {
        clearTimeout($timer);
        $timer = null;
        openNotification('error', 'Tu contraseña actual no es correcta', '');
      }, 0);

      form.validateFieldsAndScroll(
        { password: 'Tu contraseña actual no es correcta' },
        { scroll: true }
      );
      dispatch(resetUserError());
    }
  }

  handleConfirmPassword = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback(t('profile.security.pass-check2'));
    } else {
      callback();
    }
  };

  checkConfirmPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    const expression =
      '^(?:(?=.|\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&=+\\-_*]).*)$';
    const match = !value ? null : value.match(expression);

    if (value && this.state.confirmDirty) {
      form.validateFields(['new_password_confirmation'], { force: true });
    } else if (value && match === null) {
      callback(t('profile.security.pass-check1'));
    }
    callback();
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  remove2fa = () => {
    const { t } = this.props;

    Modal.confirm({
      title: t('profile.security.remove.title'),
      content: t('profile.security.remove.text'),
      okText: t('delete'),
      cancelText: t('cancel'),
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          borderColor: 'red',
          color: '#fff',
        },
      },
      onOk: async () => {
        // Aquí va la lógica para remover 2FA
        try {
          const response = await axiosInstance.delete('/two-factor/', {
            name: 'default',
          });
          openNotification('success', `ok`, `removed`);
          window.location.href = '/user/security';
        } catch (error) {
          handleAxiosError(error);
        }
      },
      onCancel: () => {},
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    this.setState({ loadingButton: true });

    form.validateFields((err, values) => {
      if (!err) {
        const attributes = {
          old_password: values.old_password,
          new_password: values.new_password,
        };
        dispatch(updatePassword(attributes));
        form.resetFields();
      } else {
        this.setState({ loadingButton: false });
      }
    });
  };

  render() {
    const {
      currentUser,
      form: { getFieldDecorator },
      t,
    } = this.props;

    const isTwoFactorActive = currentUser.profile.verify;
    const twoFactorState = isTwoFactorActive ? 'enabled' : 'disabled';

    return (
      <div>
        <h4 className="profile-title">{t('profile.security.2fa.title')}</h4>
        <p className="p-description">{t('profile.security.2fa.description')}</p>

        <Row className="two-factor mt-3">
          <Col xl={12}>
            <div className="flex-inline">
              <div className={`bullet ${twoFactorState} mr-2`} />
              <p className="p-detail">
                {t(`profile.security.2fa.${twoFactorState}`)}
              </p>
            </div>
            {isTwoFactorActive && (
              <p className="small-detail">
                {currentUser.profile.verifyMethod == 'email'
                  ? t(`profile.security.2fa.method-email`)
                  : t(`profile.security.2fa.method-generator`)}
              </p>
            )}
          </Col>
          <Col xl={12} className="right-content">
            <CustomButton
              className="button-primary"
              onClick={() =>
                isTwoFactorActive
                  ? this.remove2fa()
                  : this.props.history.push(`/two-factor/setup`)
              }
            >
              {t(`profile.security.2fa.button-${twoFactorState}`)}
            </CustomButton>
          </Col>
        </Row>
        {currentUser.profile.backup && (
          <Row className="two-factor mt-3 mb-5">
            <Col xl={12}>
              <div className="flex-inline">
                <div className={`bullet enabled mr-2`} />
                <p className="p-detail">
                  {t(`profile.security.2fa.setup.backup-codes.title`)}
                </p>
              </div>
              <p className="small-detail">
                {t(`profile.security.2fa.remaining-codes`)}
                {currentUser.profile.backupRemainingCodes}
              </p>
            </Col>
            <Col xl={12} className="right-content">
              <CustomButton
                className="button-primary"
                onClick={() => this.showModal()}
              >
                {t(`profile.security.2fa.codes-button`)}
              </CustomButton>
              <TwoFactorOptions.TwoFactorModalBackupCodes
                open={this.state.isModalOpen}
                onClose={this.closeModal}
              />
            </Col>
          </Row>
        )}
        <hr />
        <h3 className="profile-title mt-5">
          {t('profile.security.pass-title')}
        </h3>
        <Form onSubmit={this.handleSubmit} layout="vertical">
          <Row>
            <Col span={16}>
              <FormItem label={t('profile.security.current-pass')}>
                {getFieldDecorator('old_password', {
                  rules: [
                    {
                      required: true,
                      message: t('profile.security.current-pass-message'),
                    },
                  ],
                })(
                  <Input
                    type="password"
                    placeholder={t('profile.security.current-pass-placeholder')}
                    autoComplete="new-password"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label={t('profile.security.new-pass')}>
                {getFieldDecorator('new_password', {
                  rules: [
                    {
                      required: true,
                      message: t('profile.security.new-pass-message'),
                    },
                    { min: 8, message: t('profile.security.pass-check3') },
                    { validator: this.checkConfirmPassword },
                  ],
                })(
                  <Input
                    type="password"
                    placeholder={t('profile.security.new-pass-placeholder')}
                    autoComplete="new-password"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={16}>
              <FormItem label={t('profile.security.new-pass2')}>
                {getFieldDecorator('new_password_confirmation', {
                  rules: [
                    {
                      required: true,
                      message: t('profile.security.new-pass2-message'),
                    },
                    { validator: this.checkPassword },
                  ],
                })(
                  <Input
                    type="password"
                    onBlur={this.handleConfirmPassword}
                    placeholder={t('profile.security.new-pass-placeholder')}
                    autoComplete="new-password"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={16}>
              <Button
                type="primary"
                htmlType="submit"
                className={`btn-${
                  !isCompanyMode(currentUser) ? 'hacker' : 'company'
                } btn-block`}
                loading={this.state.loadingButton}
              >
                <p className="p-button">
                  {t('profile.security.update-password-button')}
                </p>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const NormalSecurityForm = Form.create()(SecurityForm);

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  hasError: state.currentUser.hasError,
  isPasswordUpdated: state.currentUser.isPasswordUpdated,
  verify: state.auth.secondFactorEnabled,
});
export default withTranslation()(
  withRouter(connect(mapStateToProps)(NormalSecurityForm))
);
