import React from 'react';
import { Button, Card, Modal, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const CreateProgramModal = (props) => {
  const { t } = useTranslation();

  const handleSelectProgramType = (ptype) => {
    if (ptype === 'ptaas' && !props.isPtaasAvailable) {
      showMessage('ptaas');
    } else if (ptype === 'bugbounty' && !props.isBugbountyAvailable) {
      showMessage('ptaas');
    } else {
      props.handleSelectProgramType(ptype);
    }
  };

  const showMessage = (type) => {
    let message;
    switch (type) {
      case 'ptaas':
        message = t('programs.create-modal.ptaas.not-available');
        break;
      case 'bugbounty':
        message = t('programs.create-modal.bugbounty.not-available');
        break;
    }
    openNotification('info', message, t('programs.create-modal.alert'));
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.closeModal}
      footer={null}
      width={700}
      height={600}
    >
      <h5
        style={{
          marginTop: '2vh',
          marginBottom: '2vh',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {t('programs.create-modal.title')}
      </h5>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card
          onClick={() => handleSelectProgramType('ptaas')}
          hoverable
          style={{
            width: 300,
            marginLeft: '2vh',
            marginRight: '1vh',
            marginBottom: '2vh',
            borderWidth: props.selectedProgramType === 'ptaas' ? '3px' : '1px',
            borderColor:
              props.selectedProgramType === 'ptaas' ? '#0018F5' : '#f0f0f0',
            color: props.selectedProgramType === 'ptaas' ? 'black' : 'grey',
            cursor: props.isPtaasAvailable ? 'pointer' : 'not-allowed',
          }}
        >
          <Tooltip
            title={
              !props.isPtaasAvailable &&
              t('programs.create-modal.ptaas.not-available') +
                '\n' +
                t('programs.create-modal.alert')
            }
          >
            <div>
              <h5
                style={{
                  color:
                    props.selectedProgramType === 'ptaas' ? 'black' : 'grey',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Pentest as a Service
              </h5>
              <p
                style={{
                  color:
                    props.selectedProgramType === 'ptaas'
                      ? 'rgba(0, 0, 0, 0.5)'
                      : 'rgba(128, 128, 128, 0.5)',
                }}
              >
                {t('programs.create-modal.ptaas.description')}
              </p>
            </div>
          </Tooltip>
        </Card>
        <Card
          onClick={() => handleSelectProgramType('bugbounty')}
          hoverable
          style={{
            width: 300,
            marginRight: '2vh',
            marginLeft: '1vh',
            marginBottom: '2vh',
            borderWidth:
              props.selectedProgramType === 'bugbounty' ? '3px' : '1px',
            borderColor:
              props.selectedProgramType === 'bugbounty' ? '#0018F5' : '#f0f0f0',
            color: props.selectedProgramType === 'bugbounty' ? 'black' : 'grey',
            cursor: props.isBugbountyAvailable ? 'pointer' : 'not-allowed',
          }}
        >
          <Tooltip
            title={
              !props.isBugbountyAvailable &&
              t('programs.create-modal.bugbounty.not-available') +
                '\n' +
                t('programs.create-modal.alert')
            }
          >
            <div>
              <h5
                style={{
                  color:
                    props.selectedProgramType === 'bugbounty'
                      ? 'black'
                      : 'grey',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Bug Bounty
              </h5>
              <p
                style={{
                  color:
                    props.selectedProgramType === 'bugbounty'
                      ? 'rgba(0, 0, 0, 0.5)'
                      : 'rgba(128, 128, 128, 0.5)',
                }}
              >
                {t('programs.create-modal.bugbounty.description')}
              </p>
            </div>
          </Tooltip>
        </Card>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      >
        <Button
          size="large"
          className="btn-company block-center"
          style={{ width: '120px', height: '55px', fontSize: '16px' }}
          onClick={props.handleCreateProgram}
          disabled={
            !props.selectedProgramType === 'ptaas' &&
            !props.selectedProgramType === 'bugbounty'
          }
        >
          {t('programs.create-modal.continue-button')}
        </Button>
      </div>
    </Modal>
  );
};

export default withRouter(CreateProgramModal);
