import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Image, Input, Row } from 'antd';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import '../styles/profile.less';

const TwoFactorAuth = (props) => {
  const { t } = useTranslation();
  const currentPath = window.location.pathname;
  const isReminderPath = currentPath === '/auth/reminder';

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Image
            src={'/images/logo-cysteria-azul.svg'}
            preview={false}
            className="logo-container2"
          />
        </Col>
      </Row>
      <Row id="two-factor">
        <Col
          xs={20}
          sm={14}
          md={10}
          lg={10}
          xl={isReminderPath ? 10 : 6}
          className="auth"
        >
          <Card
            title={!isReminderPath && t('profile.security.2fa.title')}
            className="centered-title-card"
          >
            {props.children}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(TwoFactorAuth);
