import * as Action from '../actions/ActionTypes';

const initialState = {
  list: [],
  numberOfElements: null,
  requesting: false,
  hasError: false,
  errorMessage: null,
};

class ScoresReducer {
  static reduce(state = initialState, action) {
    if (ScoresReducer[action.type]) {
      return ScoresReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_SCORES_REQUEST](state) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.LIST_SCORES_SUCCESS](state, action) {
    const {
      response: {
        result: { list, numberOfElements },
      },
    } = action;

    const res = {
      ...state,
      list: list,
      numberOfElements: numberOfElements,
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.LIST_SCORES_ERROR](state) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.SIGN_OUT]() {
    return initialState;
  }
}

export default ScoresReducer.reduce;
