import React, { useState, useEffect } from 'react';

import {
  DeleteOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Radio, Row, Col, Upload, Modal } from 'antd';
import HelperSizeCheck from '../utils/HelperSizeCheck';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import axiosInstance from '../axiosInstance';
import { openNotification } from '../utils/General';

const { Item: FormItem } = Form;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const confirm = Modal.confirm;

const ReportFormSteps = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [isUpload, setIsUpload] = useState(false);

  const [initialValues, setInitialValues] = useState([
    {
      id: undefined,
      content: '',
      mode: 'text',
      media: { media_ids: [], media_files: [] },
    },
  ]);

  useEffect(() => {
    if (props.initialValues) {
      const newInitialValues = props.initialValues.map((item) => {
        if (!item.media || !item.media.media_files) {
          return {
            ...item,
            mode: 'text',
            media: { media_ids: [], media_files: [] },
          };
        }
        return {
          ...item,
          mode: 'text',
          media: {
            ...item.media,
            media_ids: item.media.media_files.map((mf) => mf.id),
          },
        };
      });
      setInitialValues(newInitialValues);
      setFieldsValue(newInitialValues);
    }
  }, [props.initialValues, setFieldsValue]);

  const { t } = useTranslation();

  const getPlaceHolderOnSteps = (count) => {
    switch (count) {
      case 1:
        return t('reports.form.step-placeholder1');
      case 2:
        return t('reports.form.step-placeholder2');
      default:
        return `${t('reports.form.step-placeholder3')} ${count}`;
    }
  };

  const onChangeMode = (e, index) => {
    const { value } = e.target;
    const newInitialValues = [...initialValues];

    newInitialValues[index] = {
      ...newInitialValues[index],
      mode: value,
    };
    setInitialValues(newInitialValues);
    props.form.setFieldsValue({ steps: newInitialValues });
  };

  const handleDeleteFile = (step_index, media_index) => {
    confirm({
      title: t(`dialogs.titles.are-you-sure-file-deletion`),
      okText: t(`yes`),
      okType: 'danger',
      cancelText: t(`no`),
      onOk: () => {
        const newInitialValues = [...initialValues];
        newInitialValues[step_index].media.media_ids.splice(media_index, 1);
        setInitialValues(newInitialValues);
        props.form.setFieldsValue({ steps: newInitialValues });
      },
      onCancel() {},
    });
  };

  const handleAdd = () => {
    const newInitialValues = [
      ...initialValues,
      { id: undefined, content: '', mode: 'text', media: { media_files: [] } },
    ];
    setInitialValues(newInitialValues);
    props.form.setFieldsValue({ steps: newInitialValues });
  };

  const handleRemove = (index) => {
    const newInitialValues = initialValues.filter((_, i) => i !== index);
    setInitialValues(newInitialValues);
    props.form.setFieldsValue({ steps: newInitialValues });
  };

  const handleChange = (index, event) => {
    const newInitialValues = initialValues.map((input, i) =>
      i === index ? { ...input, content: event.target.value } : input
    );
    setInitialValues(newInitialValues);
    props.form.setFieldsValue({ steps: newInitialValues });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDraggedIndex(null);
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('index'));
    const newInitialValues = [...initialValues];

    const element = newInitialValues.splice(dragIndex, 1)[0]; // Remove the element from the original position
    newInitialValues.splice(dropIndex, 0, element); // Insert the element at the new position

    setInitialValues(newInitialValues);
    props.form.setFieldsValue({ steps: newInitialValues });
  };

  const renderSteps = () => {
    return initialValues.map((step, index) => {
      const colSpan =
        (index === 0 && initialValues.length > 1) || index !== 0 ? 22 : 24;

      const fileProps = {
        customRequest: () => {},
        beforeUpload: (file) => {
          const isLt2M = file.size / 1024 / 1024 < 60;
          if (!isLt2M) {
          }
          return isLt2M;
        },
        onChange: (info) => {
          let formData = new FormData();
          formData.append('file', info.file.originFileObj);
          const allowUpload = HelperSizeCheck.checkSize(info);

          if (allowUpload) {
            setIsUpload(true);
            axiosInstance
              .post(`/media/upload/`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                const newInitialValues = [...initialValues];
                newInitialValues[index].media.media_files.push({
                  id: response.data.media_id,
                  url: response.data.original_image,
                  name: response.data.label,
                });

                newInitialValues[index].media.media_ids.push(
                  response.data.media_id
                );
                setInitialValues(newInitialValues);
                props.form.setFieldsValue({ steps: newInitialValues });

                setIsUpload(false);
                openNotification(
                  'success',
                  t('notifications.files.upload'),
                  t('notifications.files.upload-message')
                );
              })
              .catch((error) => {
                console.error(error);
                setIsUpload(false);
              });
          } else {
            openNotification(
              'warning',
              `${t('notifications.warning')}`,
              `${t('notifications.files.size-check')}`
            );
          }
        },
      };

      return (
        <div
          key={index}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e, index)}
          style={{
            backgroundColor: draggedIndex === index ? '#f0f0f0' : 'transparent',
            marginBottom: '20px',
          }}
        >
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Row>
                <span>
                  {index === 0 && <br />}
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: 30,
                        height: 30,
                        textAlign: 'center',
                        lineHeight: '30px',
                        color: '#4a4a4a',
                        background: '#f4f3f3',
                        borderRadius: '100%',
                      }}
                    >
                      {index + 1}
                    </span>
                    <div style={{ display: 'flex' }}>
                      <p>{t('reports.form.step')}: &nbsp;</p>
                      <p>{index + 1}</p>
                    </div>
                  </div>
                </span>
              </Row>
              <Row>
                <Col lg={1}>
                  <div
                    style={{
                      position: 'relative',
                      right: '25px',
                      top: '25px',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="4" y1="8" x2="20" y2="8"></line>
                      <line x1="4" y1="12" x2="20" y2="12"></line>
                      <line x1="4" y1="16" x2="20" y2="16"></line>
                    </svg>
                  </div>
                </Col>
                <Col lg={22} pull={1}>
                  <div className={`${step.mode !== 'text' ? 'hidden' : ''}`}>
                    <FormItem>
                      {getFieldDecorator(`steps[${index}].id`, {
                        initialValue: step.id,
                      })(<Input type="hidden" />)}
                    </FormItem>
                    <FormItem className="labelUpdate">
                      {getFieldDecorator(
                        `steps[${index}].content`,
                        index === 0
                          ? {
                              ...props.firstFieldOptions,
                              initialValue: step.content,
                            }
                          : { initialValue: step.content }
                      )(
                        <TextArea
                          rows={6}
                          placeholder={getPlaceHolderOnSteps(index)}
                          onChange={(event) => handleChange(index, event)}
                        />
                      )}
                    </FormItem>
                  </div>
                  <div className="hidden">
                    {step.media &&
                      step.media.media_files.map((mf, mfindex) => (
                        <FormItem key={`mid-${index}-${mfindex}`}>
                          {getFieldDecorator(
                            `steps[${index}].media.media_ids[${mfindex}]`,
                            {
                              initialValue: mf.id,
                            }
                          )(<Input type="hidden" />)}
                        </FormItem>
                      ))}
                  </div>
                  {step.mode === `media` && (
                    <div>
                      <Upload {...fileProps} fileList={null}>
                        <div
                          className="attachment-container"
                          style={{ marginBottom: '1vh' }}
                        >
                          <h5 style={{ color: 'black' }}>
                            {t('reports.form.files')}
                          </h5>
                          <small style={{ color: 'black' }}>
                            {t('reports.form.file-rules')}
                          </small>
                          <br />
                          <Button className="programFile">
                            {isUpload ? (
                              <LoadingOutlined />
                            ) : (
                              <UploadOutlined />
                            )}{' '}
                            {isUpload
                              ? t('reports.form.files-upload')
                              : t('reports.form.files-upload-button')}
                          </Button>
                        </div>
                      </Upload>
                      {step.media.media_files &&
                        step.media.media_files.length > 0 && (
                          <div>
                            {step.media.media_files.map((mf, mfindex) => {
                              return (
                                <div key={mf.media_id}>
                                  {mf.url !== undefined ? (
                                    <div>
                                      <span style={{ color: 'blue' }}>
                                        <PaperClipOutlined />
                                        &nbsp;{' '}
                                        <a
                                          href={mf.url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {mf.name}
                                        </a>
                                      </span>
                                      <span style={{ marginLeft: '15px' }}>
                                        <DeleteOutlined
                                          onClick={() =>
                                            handleDeleteFile(index, mfindex)
                                          } />
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <span style={{ color: 'blue' }}>
                                        <PaperClipOutlined />
                                        &nbsp;<div>{mf.name}</div>
                                      </span>
                                      <span style={{ marginLeft: '15px' }}>
                                        <DeleteOutlined
                                          onClick={() =>
                                            handleDeleteFile(index, mfindex)
                                          } />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  )}
                </Col>
                <Col lg={1} style={{ top: '25px', left: '10px' }}>
                  {initialValues.length > 1 && (
                    <MinusCircleOutlined
                      className="btn-remove-step"
                      style={{ cursor: 'pointer' }}
                      disabled={initialValues.length === 1}
                      onClick={() => handleRemove(index)} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={22}>
                  <div className="step-attachments-footer">
                    <RadioGroup
                      value={step.mode}
                      onChange={(e) => onChangeMode(e, index)}
                    >
                      <RadioButton value={`text`}>
                        {t('reports.form.text-tab')}
                      </RadioButton>
                      <RadioButton value={`media`}>
                        {t('reports.form.file-tab')}
                      </RadioButton>
                    </RadioGroup>
                    <div className="pull-right step-attachments-text-footer">
                      <span>Markdown</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <p
        style={{
          color: '#888888',
          fontSize: 15,
          fontFamily: "'Lato', sans-serif",
          fontWeight: 'bold',
        }}
      >
        *{t('reports.form.step-number')}
      </p>
      <div style={{ marginTop: '3vh' }}>{renderSteps()}</div>
      <Row>
        <Col style={{ marginBottom: '25px' }}>
          <Button onClick={handleAdd} className="btn-add-step">
            <PlusCircleOutlined />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  errorMessage: state.report.errorMessage,
  isSuccess: state.report.isSuccess,
});

export default connect(mapStateToProps)(ReportFormSteps);
