import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Dropdown, Image, Menu, Spin } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getProgramFinanceForDownload } from '../redux/actions/programs';

class DownloadProgramsForm extends Component {
  getProgramStatus = (program) => {
    let programState = null;

    if (program.is_draft) {
      programState = 'Borrador';
    } else if (!program.is_approved) {
      programState = 'En revisión';
    } else if (program.status === 'closed') {
      programState = 'Cerrado';
    } else if (program.status === 'paused') {
      programState = 'Pausado';
    } else if (program.status === 'active') {
      programState = 'Publicado';
    } else {
      programState = 'Inactivo';
    }

    return programState;
  };

  exportProgramWallet(format) {
    const { dispatch } = this.props;
    const attributes = { format: format };

    dispatch(getProgramFinanceForDownload(attributes));
  }

  render() {
    const { t } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Button
            className="reports-exports-menu-btn"
            onClick={() => this.exportProgramWallet('xlsx')}
          >
            XLSX
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            className="reports-exports-menu-btn"
            onClick={() => this.exportProgramWallet('csv')}
          >
            CSV
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <div
          style={{
            display: 'block',
            width: 'calc(100% - 20px)',
            margin: '0 15px',
            textAlign: 'center',
            fontSize: 14,
            borderRadius: 5,
            cursor: 'pointer',
            marginTop: '25px',
            color: 'white',
          }}
        >
          <Dropdown overlay={menu} disabled={this.props.isFetching}>
            <Button
              style={{ width: '100%', height: '50%', background: '#717982' }}
            >
              {this.props.isFetching ? (
                <div
                  style={{
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: '4px',
                    padding: '10px',
                    marginRight: '10px',
                  }}
                >
                  <div className="spinner-exports">
                    <Spin />
                  </div>
                </div>
              ) : (
                <div className="centered-content">
                  <Image
                    src={process.env.PUBLIC_URL + '/images/icn_download.png'}
                    preview={false}
                    style={{ margin: '10px 1px' }}
                  />
                  <p className="p-button">{t('exports.download-button')}</p>
                </div>
              )}
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const WrappedDownloadProgramsForm = Form.create()(DownloadProgramsForm);

const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps)(WrappedDownloadProgramsForm)
);
