import { Button, Card, Col, Icon, message, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';

import '../styles/profile.less';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import TwoFactorOtpForm from './TwoFactorOtpForm';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';
import { saveAs } from 'file-saver';
import { DownloadOutlined, CheckOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import * as Action from '../redux/actions/ActionTypes';
import { openNotification } from '../utils/General';

const TwoFactorEmail = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/two-factor/initiate/', {
          method: 'email',
        });
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleOtpSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/two-factor/validate/', {
        method: 'email',
        token: values['token'],
      });
      history.push('/two-factor/backup-codes', { method: 'email' });
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Row gutter={[8, 40]}>
        <Col span={20}>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.email.otp-title')}
          </h4>
          <p className="card-content">
            {t('profile.security.2fa.setup.email.otp-description')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TwoFactorOtpForm onSubmit={handleOtpSubmit} isLoading={isLoading} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TwoFactorRecover = (props) => {
  const { t } = useTranslation();
  const { history, dispatch } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleOtpSubmit = async (values) => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/login/verify/', {
        otp_token: values.token,
      });
      dispatch({ type: Action.VALIDATE_SF_SUCCESS });
      window.location.href = '/dashboard';
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Row gutter={[8, 40]}>
        <Col span={20}>
          <h4 className="card-title-section">{t('login.2fa.recover.title')}</h4>
          <p className="card-content mb-3">
            {t('login.2fa.recover.description')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TwoFactorOtpForm
            onSubmit={handleOtpSubmit}
            centered={true}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TwoFactorFinish = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row gutter={[8, 40]}>
        <Col span={16}>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.enabled.title')}
          </h4>
          <p className="card-content mb-3">
            {t('profile.security.2fa.setup.enabled.description')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="right-content">
          <Button
            className="button-primary size-l"
            onClick={() => props.history.push('/user/security')}
          >
            <p>{t('profile.security.2fa.setup.enabled.finish-button')}</p>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TwoFactorReminder = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row gutter={[8, 32]}>
        <Col span={16}>
          <h4 className="card-title-section">
            {t('login.2fa.reminder.title')}
          </h4>
          <p className="card-content-size-l mb-3">
            {t('login.2fa.reminder.description1')}
          </p>
          <p className="card-content-size-l">
            {t('login.2fa.reminder.description2')}
          </p>
        </Col>
        <Col xl={12}>
          <Button
            className="button-primary mr-2"
            onClick={() => props.history.push('/two-factor/setup')}
          >
            <p>{t('login.2fa.reminder.configure-button')}</p>
          </Button>
          <Button
            className="button-secondary"
            onClick={() => props.history.push('/dashboard')}
          >
            <p>{t('login.2fa.reminder.skip-button')}</p>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const TwoFactorModalBackupCodes = (props) => {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const [backupCodes, setBackupCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(
          '/two-factor/show-backup-codes/'
        );
        setBackupCodes(response.data);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  const handleDownload = () => {
    const blob = new Blob([backupCodes.join('\n')], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, 'backup-codes.txt');
  };

  const handleRegenerateBackupCodes = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        '/two-factor/regenerate-backup-codes/'
      );
      setBackupCodes(response.data);
      openNotification(
        'success',
        t('notifications.title.success'),
        t('notifications.profile.generate-backup-codes')
      );
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t(`profile.security.2fa.setup.backup-codes.title`)}
      open={open}
      onOk={onClose}
      onCancel={onClose}
      id={'two-factor'}
      footer={null}
    >
      <Row gutter={[8, 40]}>
        <Col span={24}>
          <p className="card-content mb-2">
            {t('profile.security.2fa.setup.backup-codes.codes')}
          </p>
          <div className="mb-3">
            <pre>
              <code>{backupCodes.join('\n')}</code>
            </pre>
          </div>
        </Col>
        <Col span={8}>
          <Button className="copy-button" onClick={handleDownload}>
            <div className="flex-inline">
              <DownloadOutlined className="mr-1" />
              <p>{t('download')}</p>
            </div>
          </Button>
        </Col>
        <Col span={16} className="right-content">
          <Button className="copy-button" onClick={handleRegenerateBackupCodes}>
            {t('profile.security.2fa.generate')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
const mapStateToProps = (state) => ({});

export default {
  TwoFactorEmail,
  TwoFactorRecover: connect(mapStateToProps)(TwoFactorRecover),
  TwoFactorFinish: withRouter(TwoFactorFinish),
  TwoFactorReminder,
  TwoFactorModalBackupCodes,
};
