import React from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import withSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';
const FormItem = Form.Item;

class DynamicTargetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values:
        !props.values || props.values.length === 0
          ? [{ target: '' }]
          : props.values,
    };
  }

  handleRemove = (index) => {
    const newValues = this.state.values.filter((_, i) => i !== index);
    this.setState({ values: newValues });
    this.props.form.setFieldsValue({ targets: newValues });
  };

  handleAdd = () => {
    const newValues = [...this.state.values, { target: '' }];
    this.setState({ values: newValues });
    this.props.form.setFieldsValue({ targets: newValues });
  };

  handleChange = (index, event) => {
    const newValues = this.state.values.map((input, i) =>
      i === index ? { ...input, target: event.target.value } : input
    );
    this.setState({ values: newValues });
    this.props.form.setFieldsValue({ targets: newValues });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, isMobile } = this.props;
    const { values } = this.state;

    return values.map((value, index) => {
      return (
        <React.Fragment key={index}>
          <FormItem>
            {getFieldDecorator(`targets[${index}].id`, {
              initialValue: value.id,
            })(<Input type="hidden" />)}
          </FormItem>
          <FormItem
            label={index === 0 ? t('programs.form.fields.target.name') : ''}
          >
            {getFieldDecorator(`targets[${index}].target`, {
              ...this.props.fieldOptions,
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: value.target,
            })(
              <Input
                placeholder={t('programs.form.fields.target.placeholder')}
                style={{ width: isMobile ? '80%' : '90%', marginRight: '8px' }}
                onChange={(event) => this.handleChange(index, event)}
              />
            )}
            {values.length >= 1 && index === 0 && (
              <Button onClick={this.handleAdd} className="btn-add-target">
                <PlusCircleOutlined />
              </Button>
            )}
            {values.length > 1 && (
              <MinusCircleOutlined
                className="btn-remove-target ml-1"
                onClick={() => this.handleRemove(index)} />
            )}
          </FormItem>
        </React.Fragment>
      );
    });
  }
}

export default withSizes(mapSizes)(withTranslation()(DynamicTargetList));
