export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLOSE_SESSION_TIME = 'AUTH_CLOSE_SESSION_TIME';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const UNIT_REPORT_DOWNLOAD_REQUEST = 'UNIT_REPORT_DOWNLOAD_REQUEST';
export const UNIT_REPORT_DOWNLOAD_SUCCESS = 'UNIT_REPORT_DOWNLOAD_SUCCESS';
export const UNIT_REPORT_DOWNLOAD_ERRORS = 'UNIT_REPORT_DOWNLOAD_ERRORS';

export const PASSWORD_RESET_VALIDATE_REQUEST =
  'PASSWORD_RESET_VALIDATE_REQUEST';
export const PASSWORD_RESET_VALIDATE_SUCCESS =
  'PASSWORD_RESET_VALIDATE_SUCCESS';
export const PASSWORD_RESET_VALIDATE_ERROR = 'PASSWORD_RESET_VALIDATE_ERROR';

export const PASSWORD_RESET_REQUEST_REQUEST = 'PASSWORD_RESET_REQUEST_REQUEST';
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_ERROR = 'PASSWORD_RESET_REQUEST_ERROR';

export const CHECK_NOTIFICATIONS_REQUEST = 'CHECK_NOTIFICATIONS_REQUEST';
export const CHECK_NOTIFICATIONS_SUCCESS = 'CHECK_NOTIFICATIONS_SUCCESS';
export const CHECK_NOTIFICATIONS_ERROR = 'CHECK_NOTIFICATIONS_ERROR';

export const ADD_NOTIFICATIONS_SUCCESS = 'ADD_NOTIFICATIONS_SUCCESS';

export const SIGN_OUT = 'SIGN_OUT';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR';

export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';

export const CHANGE_CURRENT_COMPANY_REQUEST = 'CHANGE_CURRENT_COMPANY_REQUEST';
export const CHANGE_CURRENT_COMPANY_SUCCESS = 'CHANGE_CURRENT_COMPANY_REQUEST';
export const CHANGE_CURRENT_COMPANY_ERROR = 'CHANGE_CURRENT_COMPANY_ERROR';

export const CHANGE_LANGUAGE_REQUEST = 'CHANGE_LANGUAGE_REQUEST';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_REQUEST';
export const CHANGE_LANGUAGE_ERROR = 'CHANGE_LANGUAGE_ERROR';

export const REFER_CUSTOMER_REQUEST = 'REFER_CUSTOMER_REQUEST';
export const REFER_CUSTOMER_SUCCESS = 'REFER_CUSTOMER_SUCCESS';
export const REFER_CUSTOMER_ERROR = 'REFER_CUSTOMER_ERROR';

export const FETCH_COMPANY_PROFILE_REQUEST = 'FETCH_COMPANY_PROFILE_REQUEST';
export const FETCH_COMPANY_PROFILE_SUCCESS = 'FETCH_COMPANY_PROFILE_SUCCESS';
export const FETCH_COMPANY_PROFILE_ERROR = 'FETCH_COMPANY_PROFILE_ERROR';

export const UPDATE_COMPANY_PROFILE_REQUEST = 'UPDATE_COMPANY_PROFILE_REQUEST';
export const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS';
export const UPDATE_COMPANY_PROFILE_ERROR = 'UPDATE_COMPANY_PROFILE_ERROR';

export const UPDATE_PROFILE_IMAGE_REQUEST = 'UPDATE_PROFILE_IMAGE_REQUEST';
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_PROFILE_IMAGE_ERROR = 'UPDATE_PROFILE_IMAGE_ERROR';

export const UPDATE_COMPANY_IMAGE_REQUEST = 'UPDATE_COMPANY_IMAGE_REQUEST';
export const UPDATE_COMPANY_IMAGE_SUCCESS = 'UPDATE_COMPANY_IMAGE_SUCCESS';
export const UPDATE_COMPANY_IMAGE_ERROR = 'UPDATE_COMPANY_IMAGE_ERROR';

export const FETCH_COMPANY_SECTORS_REQUEST = 'FETCH_COMPANY_SECTORS_REQUEST';
export const FETCH_COMPANY_SECTORS_SUCCESS = 'FETCH_COMPANY_SECTORS_SUCCESS';
export const FETCH_COMPANY_SECTORS_ERROR = 'FETCH_COMPANY_SECTORS_ERROR';

export const LIST_NOTIFICATIONS_REQUEST = 'LISTUPDATEATIONS_REQUEST';
export const LIST_NOTIFICATIONS_SUCCESS = 'LIST_NOTIFICATIONS_SUCCESS';
export const LIST_NOTIFICATIONS_ERROR = 'LIST_NOTIFICATIONS_ERROR';

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';

export const LIST_COMPANY_EMPLOYEES_REQUEST = 'LIST_COMPANY_EMPLOYEES_REQUEST';
export const LIST_COMPANY_EMPLOYEES_SUCCESS = 'LIST_COMPANY_EMPLOYEES_SUCCESS';
export const LIST_COMPANY_EMPLOYEES_ERROR = 'LIST_COMPANY_EMPLOYEES_ERROR';

export const LIST_INVITATIONS_EMPLOYEES_REQUEST =
  'LIST_INVITATIONS_EMPLOYEES_REQUEST';
export const LIST_INVITATIONS_EMPLOYEES_SUCCESS =
  'LIST_INVITATIONS_EMPLOYEES_SUCCESS';
export const LIST_INVITATIONS_EMPLOYEES_ERROR =
  'LIST_INVITATIONS_EMPLOYEES_ERROR';

export const INVITES_COMPANY_EMPLOYEES_REQUEST =
  'INVITES_COMPANY_EMPLOYEES_REQUEST';
export const INVITES_COMPANY_EMPLOYEES_SUCCESS =
  'INVITES_COMPANY_EMPLOYEES_SUCCESS';
export const INVITES_COMPANY_EMPLOYEES_ERROR =
  'INVITES_COMPANY_EMPLOYEES_ERROR';

export const REINVITE_COMPANY_EMPLOYEE_REQUEST =
  'REINVITE_COMPANY_EMPLOYEE_REQUEST';
export const REINVITE_COMPANY_EMPLOYEE_SUCCESS =
  'REINVITE_COMPANY_EMPLOYEE_SUCCESS';
export const REINVITE_COMPANY_EMPLOYEE_ERROR =
  'REINVITE_COMPANY_EMPLOYEE_ERROR';

export const EMPLOYEE_CREATE_REQUEST = 'EMPLOYEE_CREATE_REQUEST';
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS';
export const EMPLOYEE_CREATE_ERROR = 'EMPLOYEE_CREATE_ERROR';

export const EMPLOYEE_ACTIVATE_REQUEST = 'EMPLOYEE_ACTIVATE_REQUEST';
export const EMPLOYEE_ACTIVATE_SUCCESS = 'EMPLOYEE_ACTIVATE_SUCCESS';
export const EMPLOYEE_ACTIVATE_ERROR = 'EMPLOYEE_ACTIVATE_ERROR';

export const EMPLOYEE_DEACTIVATE_REQUEST = 'EMPLOYEE_DEACTIVATE_REQUEST';
export const EMPLOYEE_DEACTIVATE_SUCCESS = 'EMPLOYEE_DEACTIVATE_SUCCESS';
export const EMPLOYEE_DEACTIVATE_ERROR = 'EMPLOYEE_DEACTIVATE_ERROR';

export const EMPLOYEE_DELETE_REQUEST = 'EMPLOYEE_DELETE_REQUEST';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_ERROR = 'EMPLOYEE_DELETE_ERROR';

export const INVITATION_DELETE_REQUEST = 'INVITATION_DELETE_REQUEST';
export const INVITATION_DELETE_SUCCESS = 'INVITATION_DELETE_SUCCESS';
export const INVITATION_DELETE_ERROR = 'INVITATION_DELETE_ERROR';

export const TRANSFER_OWNER_REQUEST = 'TRANSFER_OWNER_REQUEST';
export const TRANSFER_OWNER_SUCCESS = 'TRANSFER_OWNER_SUCCESS';
export const TRANSFER_OWNER_ERROR = 'TRANSFER_OWNER_ERROR';

export const COMPANY_INVOICING_UPDATE_REQUEST =
  'COMPANY_INVOICING_UPDATE_REQUEST';
export const COMPANY_INVOICING_UPDATE_ERROR = 'COMPANY_INVOICING_UPDATE_ERROR';

export const PAYPAL_REMOVE_SUCCESS = 'PAYPAL_REMOVE_SUCCESS';
export const PAYPAL_REMOVE_ERROR = 'PAYPAL_REMOVE_ERROR';

export const SKILL_UPDATE_REQUEST = 'SKILL_UPDATE_REQUEST';
export const SKILL_UPDATE_ERROR = 'SKILL_UPDATE_ERROR';

export const CERTIFICATE_REMOVE_SUCCESS = 'CERTIFICATE_REMOVE_SUCCESS';
export const CERTIFICATE_REMOVE_ERROR = 'CERTIFICATE_REMOVE_ERROR';

export const PROFILE_RECEIVED = 'PROFILE_RECEIVED';
export const PUBLISH_STATUS_SUCCESS = 'PUBLISH_STATUS_SUCCESS';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const LIST_PROGRAMS_REQUEST = 'LIST_PROGRAMS_REQUEST';
export const LIST_PROGRAMS_SUCCESS = 'LIST_PROGRAMS_SUCCESS';
export const LIST_PROGRAMS_ERRORS = 'LIST_PROGRAMS_ERRORS';

export const LIST_ATTACK_TYPE_REQUEST = 'LIST_ATTACK_TYPE_REQUEST';
export const LIST_ATTACK_TYPE_SUCCESS = 'LIST_ATTACK_TYPE_SUCCESS';
export const LIST_ATTACK_TYPE_ERRORS = 'LIST_ATTACK_TYPE_ERRORS';

export const LIST_PROGRAMS_FILTERED_REQUEST = 'LIST_PROGRAMS_FILTERED_REQUEST';
export const LIST_PROGRAMS_FILTERED_SUCCESS = 'LIST_PROGRAMS_FILTERED_SUCCESS';
export const LIST_PROGRAMS_FILTERED_ERRORS = 'LIST_PROGRAMS_FILTERED_ERRORS';

export const GET_PROGRAM_REQUEST = 'GET_PROGRAM_REQUEST';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_ERROR = 'GET_PROGRAM_ERROR';

export const USER_LIST_PROGRAMS_REQUEST = 'USER_LIST_PROGRAMS_REQUEST';
export const USER_LIST_PROGRAMS_SUCCESS = 'USER_LIST_PROGRAMS_SUCCESS';
export const USER_LIST_PROGRAMS_ERRORS = 'USER_LIST_PROGRAMS_ERRORS';

export const USER_JOIN_PROGRAMS_REQUEST = 'USER_JOIN_PROGRAMS_REQUEST';
export const USER_JOIN_PROGRAMS_SUCCESS = 'USER_JOIN_PROGRAMS_SUCCESS';
export const USER_JOIN_PROGRAMS_ERRORS = 'USER_JOIN_PROGRAMS_ERRORS';

export const CREATE_PROGRAM_REQUEST = 'CREATE_PROGRAM_REQUEST';
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS';
export const CREATE_PROGRAM_ERROR = 'CREATE_PROGRAM_ERROR';

export const UPDATE_PROGRAM_REQUEST = 'UPDATE_PROGRAM_REQUEST';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_ERROR = 'UPDATE_PROGRAM_ERROR';

export const CREATE_PROGRAM_TARGET_REQUEST = 'CREATE_PROGRAM_TARGET_REQUEST';
export const CREATE_PROGRAM_TARGET_SUCCESS = 'CREATE_PROGRAM_TARGET_SUCCESS';
export const CREATE_PROGRAM_TARGET_ERROR = 'CREATE_PROGRAM_TARGET_ERROR';

export const REMOVE_PROGRAM_TARGET_REQUEST = 'REMOVE_PROGRAM_TARGET_REQUEST';
export const REMOVE_PROGRAM_TARGET_SUCCESS = 'REMOVE_PROGRAM_TARGET_SUCCESS';
export const REMOVE_PROGRAM_TARGET_ERROR = 'REMOVE_PROGRAM_TARGET_ERROR';

export const CREATE_PROGRAM_FILE_REQUEST = 'CREATE_PROGRAM_FILE_REQUEST';
export const CREATE_PROGRAM_FILE_SUCCESS = 'CREATE_PROGRAM_FILE_SUCCESS';
export const CREATE_PROGRAM_FILE_ERROR = 'CREATE_PROGRAM_FILE_ERROR';

export const REMOVE_PROGRAM_FILE_REQUEST = 'REMOVE_PROGRAM_FILE_REQUEST';
export const REMOVE_PROGRAM_FILE_SUCCESS = 'REMOVE_PROGRAM_FILE_SUCCESS';
export const REMOVE_PROGRAM_FILE_ERROR = 'REMOVE_PROGRAM_FILE_ERROR';

export const UPDATE_AVATAR_PROGRAM = 'UPDATE_AVATAR_PROGRAM';

export const UPDATE_AVATAR_PROGRAM_REQUEST = 'UPDATE_AVATAR_PROGRAM_REQUEST';
export const UPDATE_AVATAR_PROGRAM_SUCCESS = 'UPDATE_AVATAR_PROGRAM_SUCCESS';
export const UPDATE_AVATAR_PROGRAM_ERROR = 'UPDATE_AVATAR_PROGRAM_ERROR';

export const UPDATE_DESCRIPTION_PROGRAM_REQUEST =
  'UPDATE_DESCRIPTION_PROGRAM_REQUEST';
export const UPDATE_DESCRIPTION_PROGRAM_SUCCESS =
  'UPDATE_DESCRIPTION_PROGRAM_SUCCESS';
export const UPDATE_DESCRIPTION_PROGRAM_ERROR =
  'UPDATE_DESCRIPTION_PROGRAM_ERROR';

export const PROGRAM_FETCH_REQUEST = 'PROGRAM_FETCH_REQUEST';
export const PROGRAM_FETCH_SUCCESS = 'PROGRAM_FETCH_SUCCESS';
export const PROGRAM_FETCH_ERROR = 'PROGRAM_FETCH_ERROR';

export const GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';

export const LIST_PROGRAM_FEATURES_REQUEST = 'LIST_PROGRAM_FEATURES_REQUEST';
export const LIST_PROGRAM_FEATURES_SUCCESS = 'LIST_PROGRAM_FEATURES_SUCCESS';
export const LIST_PROGRAM_FEATURES_ERROR = 'LIST_PROGRAM_FEATURES_ERROR';

export const ADD_PROGRAM_FEATURE_SUCCESS = 'ADD_PROGRAM_FEATURE_SUCCESS';

export const GET_PROGRAM_FEATURE_REQUEST = 'GET_PROGRAM_FEATURE_REQUEST';
export const GET_PROGRAM_FEATURE_SUCCESS = 'GET_PROGRAM_FEATURE_SUCCESS';
export const GET_PROGRAM_FEATURE_ERROR = 'GET_PROGRAM_FEATURE_ERROR';

export const CREATE_PROGRAM_FEATURE_REQUEST = 'CREATE_PROGRAM_FEATURE_REQUEST';
export const CREATE_PROGRAM_FEATURE_SUCCESS = 'CREATE_PROGRAM_FEATURE_SUCCESS';
export const CREATE_PROGRAM_FEATURE_ERROR = 'CREATE_PROGRAM_FEATURE_ERROR';

export const DELETE_PROGRAM_FEATURE_REQUEST = 'DELETE_PROGRAM_FEATURE_REQUEST';
export const DELETE_PROGRAM_FEATURE_SUCCESS = 'DELETE_PROGRAM_FEATURE_SUCCESS';
export const DELETE_PROGRAM_FEATURE_ERROR = 'DELETE_PROGRAM_FEATURE_ERROR';

export const REMOVE_PROGRAM_FEATURE_REQUEST = 'REMOVE_PROGRAM_FEATURE_REQUEST';
export const REMOVE_PROGRAM_FEATURE_SUCCESS = 'REMOVE_PROGRAM_FEATURE_SUCCESS';
export const REMOVE_PROGRAM_FEATURE_ERROR = 'REMOVE_PROGRAM_FEATURE_ERROR';

export const LIST_REPORTS_REQUEST = 'LIST_REPORTS_REQUEST';
export const LIST_REPORTS_SUCCESS = 'LIST_REPORTS_SUCCESS';
export const LIST_REPORTS_ERRORS = 'LIST_REPORTS_ERRORS';

export const LIST_TARGETS_REQUEST = 'LIST_TARGETS_REQUEST';
export const LIST_TARGETS_SUCCESS = 'LIST_TARGETS_SUCCESS';
export const LIST_TARGETS_ERRORS = 'LIST_TARGETS_ERRORS';

export const LIST_PROGRAM_REQUEST = 'LIST_PROGRAM_REQUEST';
export const LIST_PROGRAM_SUCCESS = 'LIST_PROGRAM_SUCCESS';
export const LIST_PROGRAM_ERRORS = 'LIST_PROGRAM_ERRORS';

export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const UPDATE_CURRENT_REPORT_SUCCESS = 'UPDATE_CURRENT_REPORT_SUCCESS';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERRORS = 'GET_REPORT_ERRORS';

export const GET_REPORT_DOWNLOAD_SUCCESS = 'GET_REPORT_DOWNLOAD_SUCCESS';

export const SET_REPORTS_FILTERS = 'SET_REPORTS_FILTERS';

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERRORS = 'CREATE_REPORT_ERRORS';

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_ERRORS = 'UPDATE_REPORT_ERRORS';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_ERRORS = 'DELETE_REPORT_ERRORS';

export const DELETE_REPORT_FROM_STORE_SUCCESS =
  'DELETE_REPORT_FROM_STORE_SUCCESS';
export const UPDATE_REPORT_ON_STORE_SUCCESS = 'UPDATE_REPORT_ON_STORE_SUCCESS';

export const UPDATE_STATUS_REPORT_REQUEST = 'UPDATE_STATUS_REPORT_REQUEST';
export const UPDATE_STATUS_REPORT_SUCCESS = 'UPDATE_STATUS_REPORT_SUCCESS';
export const UPDATE_STATUS_REPORT_ERROR = 'UPDATE_STATUS_REPORT_ERROR';

export const CREATE_REPORT_STEP_REQUEST = 'CREATE_REPORT_STEP_REQUEST';
export const CREATE_REPORT_STEP_SUCCESS = 'CREATE_REPORT_STEP_SUCCESS';
export const CREATE_REPORT_STEP_ERRORS = 'CREATE_REPORT_STEP_ERRORS';

export const REMOVE_REPORT_STEP_REQUEST = 'REMOVE_REPORT_STEP_REQUEST';
export const REMOVE_REPORT_STEP_SUCCESS = 'REMOVE_REPORT_STEP_SUCCESS';
export const REMOVE_REPORT_STEP_ERRORS = 'REMOVE_REPORT_STEP_ERRORS';

export const REPORT_STATISTICS_REQUEST = 'REPORT_STATISTICS_REQUEST';
export const REPORT_STATISTICS_SUCCESS = 'REPORT_STATISTICS_SUCCESS';
export const REPORT_STATISTICS_ERRORS = 'REPORT_STATISTICS_ERRORS';

export const ADD_ATTACHMENT_STEP_REQUEST = 'ADD_ATTACHMENT_STEP_REQUEST';
export const ADD_ATTACHMENT_STEP_SUCCESS = 'ADD_ATTACHMENT_STEP_SUCCESS';
export const ADD_ATTACHMENT_STEP_ERRORS = 'ADD_ATTACHMENT_STEP_ERRORS';

export const REMOVE_ATTACHMENT_STEP_REQUEST = 'REMOVE_ATTACHMENT_STEP_REQUEST';
export const REMOVE_ATTACHMENT_STEP_SUCCESS = 'REMOVE_ATTACHMENT_STEP_SUCCESS';
export const REMOVE_ATTACHMENT_STEP_ERRORS = 'REMOVE_ATTACHMENT_STEP_ERRORS';

export const LIST_EMPTY_COMMENTS = 'LIST_EMPTY_COMMENTS';

export const LIST_COMMENTS_REQUEST = 'LIST_COMMENTS_REQUEST';
export const LIST_COMMENTS_SUCCESS = 'LIST_COMMENTS_SUCCESS';
export const LIST_COMMENTS_ERRORS = 'LIST_COMMENTS_ERRORS';

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_ERRORS = 'CREATE_COMMENT_ERRORS';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERRORS = 'DELETE_COMMENT_ERRORS';

export const CLEAR_PROGRAM = 'CLEAR_PROGRAM';
export const CLEAR_PROGRAM_UPDATE = 'CLEAR_PROGRAM_UPDATE';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const CLEAR_REPORTS_FILTERS = 'CLEAR_REPORTS_FILTERS';
export const CLEAR_REPORTS_FILTERS_PERSIST = 'CLEAR_REPORTS_FILTERS_PERSIST';
export const CLEAR_REPORT_STEP = 'CLEAR_REPORT_STEP';
export const CLEAR_COMMENT = 'CLEAR_COMMENT';
export const RESET_ERROR = 'RESET_ERROR';

export const NETWORK_OPERATION_START = 'NETWORK_OPERATION_START';
export const NETWORK_OPERATION_END = 'NETWORK_OPERATION_END';

export const LIST_RANKING_REQUEST = 'LIST_RANKING_REQUEST';
export const LIST_RANKING_SUCCESS = 'LIST_RANKING_SUCCESS';
export const LIST_RANKING_ERRORS = 'LIST_RANKING_ERRORS';

export const GET_RANKING_PROFILE_REQUEST = 'GET_RANKING_PROFILE_REQUEST';
export const GET_RANKING_PROFILE_SUCCESS = 'GET_RANKING_PROFILE_SUCCESS';
export const GET_RANKING_PROFILE_ERRORS = 'GET_RANKING_PROFILE_ERRORS';

export const LIST_PAYMENTS_REQUEST = 'LIST_PAYMENTS_REQUEST';
export const LIST_PAYMENTS_SUCCESS = 'LIST_PAYMENTS_SUCCESS';
export const LIST_PAYMENTS_ERROR = 'LIST_PAYMENTS_ERROR';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'CREATE_PAYMENT_ERROR';

export const INVITATION_AUTHENTICATE_REQUEST =
  'INVITATION_AUTHENTICATE_REQUEST';
export const INVITATION_AUTHENTICATE_SUCCESS =
  'INVITATION_AUTHENTICATE_SUCCESS';
export const INVITATION_AUTHENTICATE_ERROR = 'INVITATION_AUTHENTICATE_ERROR';

export const LIST_SCORES_REQUEST = 'LIST_SCORES_REQUEST';
export const LIST_SCORES_SUCCESS = 'LIST_SCORES_SUCCESS';
export const LIST_SCORES_ERROR = 'LIST_SCORES_ERROR';

export const LIST_ACTIVITIES_REQUEST = 'LIST_ACTIVITIES_REQUEST';
export const LIST_ACTIVITIES_SUCCESS = 'LIST_ACTIVITIES_SUCCESS';
export const LIST_ACTIVITIES_ERROR = 'LIST_ACTIVITIES_ERROR';

export const REPORTS_PDF_EXPORT_REQUEST = 'REPORTS_PDF_EXPORT_REQUEST';
export const REPORTS_PDF_EXPORT_SUCCESS = 'REPORTS_PDF_EXPORT_SUCCESS';
export const REPORTS_PDF_EXPORT_ERROR = 'REPORTS_PDF_EXPORT_ERROR';

export const PROGRAM_ACTIVITIES_REQUEST = 'PROGRAM_ACTIVITIES_REQUEST';
export const PROGRAM_ACTIVITIES_SUCCESS = 'PROGRAM_ACTIVITIES_SUCCESS';
export const PROGRAM_ACTIVITIES_ERROR = 'PROGRAM_ACTIVITIES_ERROR';

export const REPORT_ACTIVITIES_REQUEST = 'PROGRAM_ACTIVITIES_REQUEST';
export const REPORT_ACTIVITIES_SUCCESS = 'PROGRAM_ACTIVITIES_SUCCESS';
export const REPORT_ACTIVITIES_ERROR = 'PROGRAM_ACTIVITIES_ERROR';

export const PAYMENT_STATISTICS_REQUEST = 'PAYMENT_STATISTICS_REQUEST';
export const PAYMENT_STATISTICS_SUCCESS = 'PAYMENT_STATISTICS_SUCCESS';
export const PAYMENT_STATISTICS_ERRORS = 'PAYMENT_STATISTICS_ERRORS';

export const CREDIT_STATISTICS_REQUEST = 'CREDIT_STATISTICS_REQUEST';
export const CREDIT_STATISTICS_SUCCESS = 'CREDIT_STATISTICS_SUCCESS';
export const CREDIT_STATISTICS_ERRORS = 'CREDIT_STATISTICS_ERRORS';

export const GET_WALLET_STATUS_REQUEST = 'GET_WALLET_STATUS_REQUEST';
export const GET_WALLET_STATUS_SUCCESS = 'GET_WALLET_STATUS_SUCCESS';
export const GET_WALLET_STATUS_ERRORS = 'GET_WALLET_STATUS_ERRORS';

export const PUT_WALLET_FUNDS_REQUEST = 'PUT_WALLET_FUNDS_REQUEST';
export const PUT_WALLET_FUNDS_SUCCESS = 'PUT_WALLET_FUNDS_SUCCESS';
export const PUT_WALLET_FUNDS_ERRORS = 'PUT_WALLET_FUNDS_ERRORS';

export const ADD_WALLET_CREDITS_REQUEST = 'ADD_WALLET_CREDITS_REQUEST';
export const ADD_WALLET_CREDITS_SUCCESS = 'ADD_WALLET_CREDITS_SUCCESS';
export const ADD_WALLET_CREDITS_ERRORS = 'ADD_WALLET_CREDITS_ERRORS';

export const PUT_PROGRAM_FUNDS_REQUEST = 'PUT_PROGRAM_FUNDS_REQUEST';
export const PUT_PROGRAM_FUNDS_SUCCESS = 'PUT_PROGRAM_FUNDS_SUCCESS';
export const PUT_PROGRAM_FUNDS_ERRORS = 'PUT_PROGRAM_FUNDS_ERRORS';

export const GET_CONVERSION_REQUEST = 'GET_CONVERSION_REQUEST';
export const GET_CONVERSION_SUCCESS = 'GET_CONVERSION_SUCCESS';
export const GET_CONVERSION_ERRORS = 'GET_CONVERSION_ERRORS';

export const GET_PROGRAM_HISTORY_REQUEST = 'GET_PROGRAM_HISTORY_REQUEST';
export const GET_PROGRAM_HISTORY_SUCCESS = 'GET_PROGRAM_HISTORY_SUCCESS';
export const GET_PROGRAM_HISTORY_ERRORS = 'GET_PROGRAM_HISTORY_ERRORS';

export const GET_HISTORYWALLET_REQUEST = 'GET_HISTORYWALLET_REQUEST';
export const GET_HISTORYWALLET_SUCCESS = 'GET_HISTORYWALLET_SUCCESS';
export const GET_HISTORYWALLET_ERRORS = 'GET_HISTORYWALLET_ERRORS';

export const GET_HISTORY_WALLET_PTAAS_REQUEST =
  'GET_HISTORY_WALLET_PTAAS_REQUEST';
export const GET_HISTORY_WALLET_PTAAS_SUCCESS =
  'GET_HISTORY_WALLET_PTAAS_SUCCESS';
export const GET_HISTORY_WALLET_PTAAS_ERRORS =
  'GET_HISTORY_WALLET_PTAAS_ERRORS';

export const GET_CONVERSION_HISTORY_REQUEST = 'GET_CONVERSION_HISTORY_REQUEST';
export const GET_CONVERSION_HISTORY_SUCCESS = 'GET_CONVERSION_HISTORY_SUCCESS';
export const GET_CONVERSION_HISTORY_ERRORS = 'GET_CONVERSION_HISTORY_ERRORS';

export const GET_COMMISSION_REQUEST = 'GET_COMMISION_REQUEST';
export const GET_COMMISSION_SUCCESS = 'GET_COMMISION_SUCCESS';
export const GET_COMMISSION_ERRORS = 'GET_COMMISION_ERRORS';

export const SHOW_BAR_HISTORY = 'SHOW_BAR_HISTORY';
export const HIDE_BAR_HISTORY = 'HIDE_BAR_HISTORY';

export const PROGRAM_HISTORIES_REQUEST = 'PROGRAM_HISTORIES_REQUEST';
export const PROGRAM_HISTORIES_SUCCESS = 'PROGRAM_HISTORIES_SUCCESS';
export const PROGRAM_HISTORIES_ERROR = 'PROGRAM_HISTORIES_ERROR';

export const LIST_FINANCE_PROGRAMS_REQUEST = 'LIST_FINANCE_PROGRAMS_REQUEST';
export const LIST_FINANCE_PROGRAMS_SUCCESS = 'LIST_FINANCE_PROGRAMS_SUCCESS';
export const LIST_FINANCE_PROGRAMS_ERRORS = 'LIST_FINANCE_PROGRAMS_ERRORS';

export const LIST_FINANCE_PROGRAMS_PTAAS_REQUEST =
  'LIST_FINANCE_PROGRAMS_PTAAS_REQUEST';
export const LIST_FINANCE_PROGRAMS_PTAAS_SUCCESS =
  'LIST_FINANCE_PROGRAMS_PTAAS_SUCCESS';
export const LIST_FINANCE_PROGRAMS_PTAAS_ERRORS =
  'LIST_FINANCE_PROGRAMS_PTAAS_ERRORS';

export const EXPORT_FINANCE_PROGRAMS_REQUEST =
  'EXPORT_FINANCE_PROGRAMS_REQUEST';
export const EXPORT_FINANCE_PROGRAMS_SUCCESS =
  'EXPORT_FINANCE_PROGRAMS_SUCCESS';
export const EXPORT_FINANCE_PROGRAMS_ERRORS = 'EXPORT_FINANCE_PROGRAMS_ERRORS';

export const EXPORT_WALLET_HISTORY_REQUEST = 'EXPORT_WALLET_HISTORY_REQUEST';
export const EXPORT_WALLET_HISTORY_SUCCESS = 'EXPORT_WALLET_HISTORY_SUCCESS';
export const EXPORT_WALLET_HISTORY_ERRORS = 'EXPORT_WALLET_HISTORY_ERRORS';

export const EXPORT_PROGRAM_HISTORY_REQUEST = 'EXPORT_PROGRAM_HISTORY_REQUEST';
export const EXPORT_PROGRAM_HISTORY_SUCCESS = 'EXPORT_PROGRAM_HISTORY_SUCCESS';
export const EXPORT_PROGRAM_HISTORY_ERRORS = 'EXPORT_PROGRAM_HISTORY_ERRORS';

export const EXPORT_PAYMENTS_REQUEST = 'EXPORT_PAYMENTS_REQUEST';
export const EXPORT_PAYMENTS_SUCCESS = 'EXPORT_PAYMENTS_SUCCESS';
export const EXPORT_PAYMENTS_ERRORS = 'EXPORT_PAYMENTS_ERRORS';

export const EXPORT_INVOICE_REQUEST = 'EXPORT_INVOICE_REQUEST';
export const EXPORT_INVOICE_SUCCESS = 'EXPORT_INVOICE_SUCCESS';
export const EXPORT_INVOICE_ERRORS = 'EXPORT_INVOICE_ERRORS';

export const EXPORT_SUDOKU_REQUEST = 'EXPORT_SUDOKU_REQUEST';
export const EXPORT_SUDOKU_SUCCESS = 'EXPORT_SUDOKU_SUCCESS';
export const EXPORT_SUDOKU_ERRORS = 'EXPORT_SUDOKU_ERRORS';

export const CHANGE_PROGRAM_STATE_REQUEST = 'CHANGE_PROGRAM_STATE_REQUEST';
export const CHANGE_PROGRAM_STATE_SUCCESS = 'CHANGE_PROGRAM_STATE_SUCCESS';
export const CHANGE_PROGRAM_STATE_ERROR = 'CHANGE_PROGRAM_STATE_ERRORS';

export const CHANGE_PLAN_REQUEST = 'CHANGE_PLAN_REQUEST';
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_ERROR = 'CHANGE_PLAN_ERROR';

export const VALIDATE_QR_REQUEST = 'VALIDATE_QR_REQUEST';
export const VALIDATE_QR_SUCCESS = 'VALIDATE_QR_SUCCESS';
export const VALIDATE_QR_ERROR = 'VALIDATE_QR_ERROR';

export const DELETE_QR_REQUEST = 'DELETE_QR_REQUEST';
export const DELETE_QR_SUCCESS = 'DELETE_QR_SUCCESS';
export const DELETE_QR_ERROR = 'DELETE_QR_ERROR';
//2Fa validation
export const VALIDATE_SF_REQUEST = 'VALIDATE_SF_REQUEST';
export const VALIDATE_SF_SUCCESS = 'VALIDATE_SF_SUCCESS';
export const VALIDATE_SF_ERROR = 'VALIDATE_SF_ERROR';
