import React, { Component } from 'react';
import { Card } from 'antd';
import ActivityItem from './ActivityItem';
import * as _ from 'lodash';

import '../themes/loading-icon.less';
import { withTranslation } from 'react-i18next';
import LoaderComponent from './LoaderComponent';

class ActivityList extends Component {
  getActivityListContent(activities) {
    return;
  }

  render() {
    const { activities, loading, t } = this.props;

    return (
      <div className="activity-list">
        <div className="list-container">
          {loading && <LoaderComponent label={false} />}

          {_.isEmpty(activities) && !loading ? (
            <Card hoverable={true} className="top-hacker">
              <p className="text-center">{t('activities.no-activities')}</p>
            </Card>
          ) : (
            activities.map((activity, i) => (
              <ActivityItem notification={activity} key={i} />
            ))
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ActivityList);
