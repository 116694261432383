import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import withSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';
import RankingList from '../components/RankingList';
import RankingPodium from '../components/RankingPodium';
import RankingListActive from '../components/RankingListActive';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axiosInstance from '../axiosInstance';
import { isCompanyMode } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';
import '../styles/ranking.less';

class Ranking extends Component {
  state = {
    loading: false,
    ranking: [],

    loading90: false,
    ranking90: [],
  };

  fetchRanking() {
    this.setState({ loading: true });

    const params = {};

    axiosInstance
      .get(`/hackers/ranking/`, { params })
      .then((response) => {
        this.setState({
          ranking: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading: false });
      });
  }

  fetchRanking90() {
    this.setState({ loading90: true });

    const params = {};

    axiosInstance
      .get(`/hackers/ranking90/`, { params })
      .then((response) => {
        this.setState({
          ranking90: response.data,
          loading90: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading90: false });
      });
  }

  componentDidMount() {
    this.fetchRanking();
    this.fetchRanking90();
  }

  render() {
    const { t, isDesktop, currentUser } = this.props;
    const { ranking, loading, ranking90, loading90 } = this.state;

    return (
      <Row>
        <Col
          lg={{ span: 18, offset: 3 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <Row
            id="ranking-container"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <Col
              sm={{ span: 20, offset: 2 }}
              md={{ span: 18, offset: 3 }}
              lg={{ span: 18, offset: 3 }}
            >
              {loading ? (
                <LoaderComponent label={false} />
              ) : (
                <div id="ranking-podium">
                  {isDesktop && <RankingPodium ranking={ranking} />}
                </div>
              )}

              <div className="app-container">
                <div
                  id={
                    isCompanyMode(currentUser)
                      ? 'companyRanking'
                      : 'hackerRanking'
                  }
                >
                  {loading ? (
                    <LoaderComponent label={false} />
                  ) : (
                    <RankingList ranking={ranking} />
                  )}
                </div>
              </div>
              <div className="app-container">
                <div
                  id={
                    isCompanyMode(currentUser)
                      ? 'companyRanking'
                      : 'hackerRanking'
                  }
                  style={{ marginBottom: '3vh' }}
                >
                  {loading90 ? (
                    <LoaderComponent label={false} />
                  ) : (
                    <RankingListActive ranking={ranking90} />
                  )}
                </div>
              </div>
              <div style={{ marginBottom: '5vh' }}>
                {!loading90 && (
                  <Link className="pull-right" to={'/dashboard/ranking/guide'}>
                    {t('ranking.cyscope-score')}
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(Ranking))
);
