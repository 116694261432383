import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClockCircleOutlined, WalletOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  InputNumber,
  Modal,
  Progress,
  Table,
  Tooltip,
  Radio,
  Row,
  Image,
} from 'antd';
import withSizes from 'react-sizes';
import * as _ from 'lodash';

import '../themes/basic-list.less';
import { addProgramFunds } from '../redux/actions/wallet';
import {
  subscribeFinancePrograms,
  subscribeFinanceProgramsPtaas,
} from '../redux/actions/programs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import { getProgramStatus } from '../utils/ProgramHelper';
import { mapSizes } from '../mapSizes';
import { openNotification } from '../utils/General';
import LoaderComponent from './LoaderComponent';
import '../styles/finance.less';

class ProgramFinanceList extends Component {
  state = {
    visible: false,
    confirmLoading: false,
    payment: null,
    mode: 'add',
    amount: 0,
    withdraw: false,
    add_error: false,
    remove_error: false,
  };

  showModal = (payment) => {
    this.setState({
      visible: true,
      payment: payment,
    });
  };

  programFunds = (program) => {
    const { dispatch, t } = this.props;
    const { amount, mode } = this.state;
    this.setState({ confirmLoading: true });
    if (mode === 'remove') {
      program.available = parseInt(program.available) - amount;
      program.quote = parseInt(program.quote) - amount;
      dispatch(addProgramFunds({ amount: -amount }, program.id)).then(() => {
        if (this.props.hasError) {
          openNotification('error', 'Error', this.props.errorMessage);
          this.setState({
            confirmLoading: false,
          });
        } else {
          openNotification(
            'success',
            t('notifications.success'),
            t('notifications.budget.remove-funds')
          );
          setTimeout(() => {
            this.setState({
              visible: false,
              amount: 0,
              confirmLoading: false,
            });
          }, 500);
          this.handleStatus();
        }
      });
    } else {
      program.available = parseInt(program.available) + amount;
      program.quote = parseInt(program.quote) + amount;
      dispatch(addProgramFunds({ amount: amount }, program.id)).then(() => {
        if (this.props.hasError) {
          openNotification('error', 'Error', this.props.errorMessage);
          this.setState({
            confirmLoading: false,
          });
        } else {
          openNotification(
            'success',
            t('notifications.success'),
            t('notifications.budget.add-funds')
          );
          setTimeout(() => {
            this.setState({
              visible: false,
              confirmLoading: false,
            });
          }, 500);
          this.handleStatus();
        }
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  afterClose = () => {
    this.setState({ mode: 'add', amount: 0 });
  };

  handleStatus = () => {
    const { dispatch, isPtaas } = this.props;
    if (isPtaas) {
      dispatch(subscribeFinanceProgramsPtaas());
    } else {
      dispatch(subscribeFinancePrograms());
    }
  };

  getTable() {
    const { programs, isPtaas, isMobile, t } = this.props;

    const columns = [
      {
        title: '',
        dataIndex: 'image',
        width: '6%',
      },
      {
        title: t('budget.programs.name'),
        dataIndex: 'program',
        sorter: (a, b) => a.program.localeCompare(b.program),
        width: isMobile ? '18%' : '21%',
      },
      {
        title: t('budget.programs.status'),
        dataIndex: 'state',
        width: '12%',
        filters: [
          {
            text: 'En revisión',
            value: 'En revisión',
          },
          {
            text: 'Publicado',
            value: 'Publicado',
          },
          {
            text: 'Pausado',
            value: 'Pausado',
          },
          {
            text: 'Borrador',
            value: 'Borrador',
          },
          {
            text: 'Cerrado',
            value: 'Cerrado',
          },
        ],
        onFilter: (value, record) => record.state.indexOf(value) === 0,
        sorter: (a, b) => a.state.length - b.state.length,
      },
      ...(!isPtaas
        ? [
            {
              title: (
                <Tooltip title={t('budget.programs.total-budget-tooltip')}>
                  {t('budget.programs.total-budget')}
                </Tooltip>
              ),
              className: 'column-right',
              dataIndex: 'total_budget',
              width: '15%',
              sorter: (a, b) => b.total_budget - a.total_budget,
              render: (text) => <NumberDisplay number={text} />,
            },
            {
              title: (
                <Tooltip title={t('budget.programs.reserved-tooltip')}>
                  {t('budget.programs.reserved')}
                </Tooltip>
              ),
              dataIndex: 'locked',
              className: 'column-right',
              width: '10%',
              sorter: (a, b) => a.locked - b.locked,
              render: (text) => <NumberDisplay number={text} />,
            },
            {
              title: (
                <Tooltip title={t('budget.programs.paid-tooltip')}>
                  {t('budget.programs.paid')}
                </Tooltip>
              ),
              className: 'column-right',
              dataIndex: 'advance',
              width: '9%',
              sorter: (a, b) => b.advance - a.advance,
              render: (text) => <NumberDisplay number={text} />,
            },
            {
              title: (
                <Tooltip title={t('budget.programs.available-tooltip')}>
                  {t('budget.programs.available')}
                </Tooltip>
              ),
              className: 'column-right',
              dataIndex: 'available',
              width: '11%',
              sorter: (a, b) => b.available - a.available,
              render: (text) => <NumberDisplay number={text} />,
            },
          ]
        : [
            {
              title: (
                <Tooltip title={t('budget.programs.paid-tooltip')}>
                  {t('budget.ptaas.programs.total-credits')}
                </Tooltip>
              ), //AGREGAR DESCRIPCION AL TOOLTIP CORRESPONDIENTE A LOS CREDITOS TOTALES
              className: 'column-right',
              dataIndex: 'total_budget',
              width: '15%',
              sorter: (a, b) => b.total_budget - a.total_budget,
              render: (text) => <NumberDisplay number={text} />,
            },
          ]),
      {
        title: (
          <Tooltip title="Puedes consultar el historial de movimientos  y modificar tu presupuesto.">
            <span className="pull-right">{t('budget.programs.actions')}</span>
          </Tooltip>
        ),
        className: 'column-final',
        dataIndex: 'actions',
        width: '12%',
      },
    ];

    const dataSource = programs.map((payment, i) => {
      const { programState } = getProgramStatus(payment, t);
      const isAllowed = payment.status !== 'closed';

      return Object.assign(
        Object.assign({
          image: (
            <div className="program-item-avatar">
              <Image
                src={
                  payment.avatar_thumbnail_s_url ||
                  '/images/program-default.png'
                }
                preview={false}
                className="img-circle"
                width="35"
                height="35"
              />
            </div>
          ),
          key: i.toString(),
          program: payment.name,
          state: programState,
          locked: payment.locked,
          advance: payment.total_paid,
          available: payment.available,
          total_budget: payment.total_budget,
          actions: (
            <span className="pull-right">
              {(!isPtaas || isAllowed) && (
                <span onClick={() => this.showModal(payment)}>
                  <Tooltip title={t('budget.programs.budget-option')}>
                    <WalletOutlined
                      style={{
                        marginLeft: '5px',
                        color: 'black',
                        fontSize: '20px',
                        cursor: 'pointer',
                        fontWeight: '500',
                      }}
                    />
                  </Tooltip>
                </span>
              )}
              <span>
                <Tooltip title={t('budget.programs.history')}>
                  <Link
                    to={{
                      pathname: `/dashboard/programs/history/${payment.id}`,
                      state: { ptaas: isPtaas },
                    }}
                  >
                    <ClockCircleOutlined
                      style={{
                        marginLeft: '16px',
                        color: 'black',
                        fontSize: '18px',
                        fontWeight: '500',
                      }}
                    />
                  </Link>
                </Tooltip>
              </span>
            </span>
          ),
        })
      );
    });
    return (
      <div style={{ margin: '20px 0px' }}>
        <Table
          scroll={{ x: 950 }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
        <br />
        {!isPtaas && (
          <h1
            style={{
              fontSize: '12px',
              marginTop: '10px',
              color: 'gray',
              position: 'relative',
              bottom: '27px',
              width: '100%',
              height: '0',
            }}
          >
            {t('budget.programs.table-footer')}
          </h1>
        )}
      </div>
    );
  }

  handleModeChange = (e) => {
    const mode = e.target.value;
    this.setState({ mode });
  };

  onChangeNumber = (value) => {
    const { payment, mode } = this.state;
    const { companyCurrentBalance } = this.props;
    const available = parseInt(payment.available);
    if (mode === 'add' && companyCurrentBalance < value) {
      this.setState({ add_error: true });
    } else {
      this.setState({ add_error: false, amount: value });
    }
    if (mode === 'remove' && value > available) {
      this.setState({ remove_error: true });
    } else {
      this.setState({ remove_error: false, amount: value });
    }
  };

  getProgramInfo = () => {
    const { payment, mode, amount } = this.state;
    const { companyCurrentBalance, isPtaas, t } = this.props;
    const quote = parseInt(payment.quote);
    const available = parseInt(payment.available);
    const lockedMoney = parseInt(payment.locked_money);
    const availableMoney = parseInt(payment.quote);
    const percent =
      lockedMoney <= availableMoney
        ? (lockedMoney * 100) / availableMoney
        : 100;
    const canAdd =
      payment.status === 'inactive' ||
      payment.status === 'active' ||
      payment.status === 'paused';
    const canRemove = payment.status === 'inactive';

    return (
      <Row id="program-finance-list">
        <Col span={24}>
          <Row className="mt-3">
            <Col span={24}>
              {isPtaas ? (
                <Row justify="center">
                  <Col span={24}>
                    <Radio.Group
                      value={mode}
                      onChange={this.handleModeChange}
                      className="radio-group"
                    >
                      <Radio.Button
                        className="radio-button"
                        value="add"
                        disabled={!canAdd}
                      >
                        {t('budget.programs.add')}
                      </Radio.Button>
                      <Radio.Button
                        className="radio-button"
                        value="remove"
                        disabled={!canRemove}
                      >
                        {t('budget.programs.withdraw')}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              ) : (
                <Row justify="center">
                  <Col span={24}>
                    <Radio.Group
                      value={mode}
                      onChange={this.handleModeChange}
                      className="radio-group"
                    >
                      <Radio.Button className="radio-button" value="add">
                        {t('budget.programs.add')}
                      </Radio.Button>
                      <Radio.Button className="radio-button" value="remove">
                        {t('budget.programs.withdraw')}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="program-item-avatar flex-inline mt-3">
                <Image
                  src={payment.avatar_thumbnail_s_url}
                  preview={false}
                  className="img-circle"
                  width={45}
                  height={45}
                />
                <h2
                  className="program-title ml-3"
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  {payment.name ? payment.name : '-'} - {payment.company.name}
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {payment.is_mine && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '10px 0px 15px 31px',
                    width: '90%',
                  }}
                  className="percentage-bar"
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      height: '27px',
                    }}
                  >
                    <p
                      style={{
                        padding: '0',
                        fontSize: '12px',
                        color: 'gray',
                      }}
                    >
                      Presupuesto Total:
                    </p>
                    <p style={{ padding: '0' }}>
                      {quote.toLocaleString('es-CL')} USD
                    </p>
                  </div>
                  <Tooltip
                    placement="right"
                    title={`Utilizado ${Number(percent.toFixed(1))}%`}
                  >
                    <Progress
                      percent={Number(percent.toFixed(1))}
                      showInfo={false}
                    />
                  </Tooltip>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '5px',
                    }}
                  >
                    <p
                      style={{
                        padding: '0',
                        fontSize: '12px',
                        color: 'gray',
                      }}
                    >
                      Presupuesto Restante:
                    </p>
                    <p style={{ padding: '0' }}>
                      {available.toLocaleString('es-CL')} USD
                    </p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col span={24}>
              <p
                style={{
                  marginBottom: '20px',
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                {t('budget.programs.amount')}
              </p>
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <InputNumber
                  value={amount}
                  min={0}
                  formatter={(value) => `${value}`.toLocaleString('es-CL')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={this.onChangeNumber}
                  style={{
                    flex: '1',
                    height: '34px',
                    justifyContent: 'flex-start',
                  }}
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    margin: '5px',
                    padding: '0',
                  }}
                >
                  {isPtaas ? t('budget.ptaas.converter.credits') : 'USD'}
                </p>
              </div>
              {mode === 'add' && companyCurrentBalance < amount && (
                <p
                  style={{
                    fontSize: '15px',
                    marginLeft: '32px',
                    marginTop: '5px',
                    color: 'red',
                  }}
                >
                  {t('budget.programs.add-message')}
                </p>
              )}
              {mode === 'remove' && available < amount && (
                <p
                  style={{
                    fontSize: '15px',
                    marginLeft: '32px',
                    marginTop: '5px',
                    color: 'red',
                  }}
                >
                  {t('budget.programs.withdraw-message')}
                </p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const { programs, loading, t } = this.props;
    const { visible, confirmLoading, payment } = this.state;

    return (
      <div className="payment-list basic-list">
        {!loading && _.isEmpty(programs) ? (
          <Card hoverable={true} className="top-hacker">
            <p className="text-center">{t('budget.no-programs')}</p>
          </Card>
        ) : (
          <div className="list-container">
            {loading && <LoaderComponent label={false} />}

            {_.isEmpty(programs) ? '' : this.getTable()}

            <Modal
              style={{ marginBottom: '10px' }}
              visible={visible}
              onCancel={this.handleCancel}
              afterClose={this.afterClose}
              footer={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    key="submit"
                    type="primary"
                    size="large"
                    loading={confirmLoading}
                    className="btn-company"
                    style={{
                      width: '90%',
                      height: '40px',
                      marginBottom: '10px',
                    }}
                    onClick={() => this.programFunds(payment)}
                    disabled={this.state.add_error || this.state.remove_error}
                  >
                    {t('budget.programs.continue-button')}
                  </Button>
                </div>
              }
            >
              {payment && <p>{this.getProgramInfo()}</p>}
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  hasError: state.wallet.hasError,
  errorMessage: state.wallet.errorMessage,
});
export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(ProgramFinanceList))
);
