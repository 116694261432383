import * as Action from '../actions/ActionTypes';

const initialState = {
  list: [],
  maxLength: null,
  requesting: false,
  hasError: false,
  errorMessage: null,
};

class ActivitiesReducer {
  static reduce(state = initialState, action) {
    if (ActivitiesReducer[action.type]) {
      return ActivitiesReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_ACTIVITIES_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.LIST_ACTIVITIES_SUCCESS](state, action) {
    const {
      response: { results, count },
    } = action;
    const res = {
      ...state,
      list: results,
      count: count,
      hasError: false,
      requesting: false,
    };

    return res;
  }

  static [Action.LIST_ACTIVITIES_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default ActivitiesReducer.reduce;
