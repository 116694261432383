import React, { Component } from 'react';
import { WalletOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Modal,
  InputNumber,
  Input,
  Tooltip,
  notification,
  Spin,
  Image,
} from 'antd';
import { Link } from 'react-router-dom';
import { addWalletCredits } from '../redux/actions/wallet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
const { TextArea } = Input;

class WalletBalancePtaas extends Component {
  state = {
    visible: false,
    confirmLoading: false,
    amount: 1,
    comment: '',
    commission: 0,
    messageWarning: '',
    stepCont: 1,
    dollar: null,
  };

  showModal = () => {
    this.setState({
      visible: true,
      stepCont: 1,
    });
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const credit_to_dollar =
      currentUser &&
      currentUser.currentCompany &&
      currentUser.currentCompany.activePlan &&
      currentUser.currentCompany.activePlan.features &&
      currentUser.currentCompany.activePlan.features.credit_to_dollars;
    if (credit_to_dollar) {
      this.setState({ dollar: credit_to_dollar });
    }
  }

  openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  handleOk = () => {
    const { dispatch } = this.props;
    const { amount, comment, stepCont } = this.state;

    this.setState({
      confirmLoading: true,
    });

    setTimeout(() => {
      if (amount) {
        dispatch(
          addWalletCredits({
            amount: amount,
            comment: comment,
          })
        ).then(() => {
          if (!this.props.hasError) {
            this.setState({
              confirmLoading: false,
              stepCont: stepCont + 1,
              amount: 1,
            });
          } else {
            this.setState({
              confirmLoading: false,
              visible: false,
            });
            this.openNotification('error', 'Error', this.props.errorMessage);
          }
        });
      } else {
        dispatch(addWalletCredits({ amount: 1, comment: comment })).then(() => {
          if (!this.props.hasError) {
            this.setState({
              confirmLoading: false,
              stepCont: stepCont + 1,
              amount: 1,
            });
          } else {
            this.setState({
              confirmLoading: false,
              visible: false,
            });
            this.openNotification('error', 'Error', this.props.errorMessage);
          }
        });
      }
    }, 2000);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeNumber = (value) => {
    this.setState({
      amount: value,
    });
  };

  onChangeText = (event) => {
    let value = event.target.value;
    this.setState({
      comment: value,
    });
  };

  getTotal = (type = false) => {
    const { t } = this.props;

    let { amount, dollar } = this.state;

    return (
      <div width="100%">
        <table className="detailMount">
          <tbody>
            <tr>
              <td>
                <span style={{ marginRight: '100px' }}>
                  {t('budget.ptaas.funds.entered-amount')}
                </span>
              </td>
              <td>
                <div>
                  <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                    <NumberDisplay number={amount} />
                  </strong>
                  <span
                    style={{ fontSize: '11px', textTransform: 'uppercase' }}
                  >
                    {amount === 1
                      ? ' ' + t('budget.ptaas.converter.credit')
                      : ' ' + t('budget.ptaas.converter.credits')}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <hr />
              </th>
            </tr>
            <tr>
              <td>
                <span style={{ marginRight: '100px' }}>Total</span>
              </td>
              <td>
                <div>
                  <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                    <NumberDisplay number={amount} />
                  </strong>
                  <span
                    style={{ fontSize: '11px', textTransform: 'uppercase' }}
                  >
                    {amount === 1
                      ? ' ' + t('budget.ptaas.converter.credit')
                      : ' ' + t('budget.ptaas.converter.credits')}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  stepContent = (step) => {
    const { t } = this.props;
    const { amount } = this.state;
    let { accumulated, anual_refill_top } =
      this.props.currentUser.currentCompany.activePlan;
    const total = parseFloat(amount) + parseFloat(accumulated);

    if (step === 1) {
      return (
        <div>
          <Row
            style={{
              margin: '15px 20px 20px 20px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '15px',
              }}
            >
              {t('budget.ptaas.funds.credits')}
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '90%',
              }}
            >
              <InputNumber
                style={{
                  marginTop: '10px',
                  flex: '1',
                  marginLeft: '29px',
                  height: '34px',
                  fontSize: '14px',
                  color: 'black',
                }}
                value={amount}
                min={0}
                max={100000}
                formatter={(value) => `${value}`.toLocaleString('es-CL')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={this.onChangeNumber}
              />
            </div>
          </Row>
          <Row
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              margin: '15px 20px 0px 20px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              {t('budget.funds.comments')}
            </p>
            <TextArea
              style={{
                marginTop: '10px',
                width: '83%',
                marginLeft: '29px',
                backgroundColor: 'white',
                borderColor: '#d9d9d9',
              }}
              value={this.state.comment}
              rows={3}
              onChange={this.onChangeText}
            />
          </Row>
        </div>
      );
    }
    if (step === 2) {
      return (
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={24}
            md={24}
            lg={{ span: 24, offset: 1 }}
            style={{ width: '100%' }}
          >
            {this.getTotal()}

            {total > anual_refill_top && (
              <p style={{ color: 'red', marginTop: '20px' }}>
                {t('budget.funds.refill')}
              </p>
            )}
          </Col>
        </Row>
      );
    }
    if (step === 3) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Image src={`/images/wallet-add.png`} preview={false} width={50} />
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 600,
              padding: '30px 0px',
            }}
          >
            {t('budget.funds.message1')}
            <br /> {t('budget.funds.message2')}
          </p>
        </div>
      );
    }
  };

  getDetail() {
    const { stepCont } = this.state;

    this.setState({ stepCont: stepCont + 1 });
  }

  render() {
    const { visible, confirmLoading, amount, stepCont } = this.state;

    const { companyCurrentBalance, programTotalBalance, wallet, t, loading } =
      this.props;

    let { accumulated, anual_refill_top } =
      this.props.currentUser.currentCompany.activePlan;
    const total = parseFloat(amount) + parseFloat(accumulated);

    return (
      <div style={{ padding: '15px' }}>
        <h6
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            margin: 'auto 0',
            textTransform: 'uppercase',
          }}
        >
          {t('budget.balance')}
        </h6>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Col
            style={{ flex: '1', margin: '0' }}
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2, offset: 1 }}
          >
            <Image
              style={{
                width: '17px',
                height: '17px',
                marginTop: '-2px',
              }}
              preview={false}
              src={`/images/wallet.png`}
            />
          </Col>
          <Col style={{ flex: '3', margin: '0' }} lg={{ offset: 1, span: 3 }}>
            <Tooltip placement="right" title={t('budget.wallet-tooltip')}>
              <span style={{ fontSize: '16px' }}>{t('budget.wallet')}</span>
            </Tooltip>
          </Col>
          <Col
            style={{ flex: '3', textAlign: 'end', margin: '0' }}
            lg={{ offset: 2 }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {companyCurrentBalance && (
                <NumberDisplay number={companyCurrentBalance} />
              )}
            </span>
            <span style={{ fontSize: '11px' }}>
              {' '}
              {t('budget.ptaas.credits')}
            </span>
          </Col>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Col
            style={{ flex: '1', margin: '0' }}
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2, offset: 1 }}
          >
            <Image
              style={{
                width: '17px',
                height: '17px',
                marginTop: '-2px',
              }}
              preview={false}
              src={`/images/wallet.png`}
            />
          </Col>
          <Col style={{ flex: '3', margin: '0' }} lg={{ offset: 1, span: 3 }}>
            <Tooltip placement="right" title={t('budget.wallet-tooltip')}>
              <span style={{ fontSize: '16px' }}>
                {t('budget.ptaas.total')}
              </span>
            </Tooltip>
          </Col>
          <Col
            style={{ flex: '3', textAlign: 'end', margin: '0' }}
            lg={{ offset: 2 }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {programTotalBalance && (
                <NumberDisplay number={programTotalBalance} />
              )}
            </span>
            <span style={{ fontSize: '11px' }}>
              {' '}
              {t('budget.ptaas.credits')}
            </span>
          </Col>
        </div>
        <hr />
        <div style={{ margin: '0', textAlign: 'center', marginTop: '40px' }}>
          <Col span={24}>
            <Button
              type="primary"
              size="large"
              onClick={this.showModal}
              loading={this.state.loadingButton}
              className="btn-company"
              style={{
                height: '50px',
                width: '220px',
                fontSize: '20px',
              }}
            >
              <p className="p-button">{t('budget.ptaas.add-credits-button')}</p>
            </Button>

            <Modal
              visible={visible}
              onCancel={this.handleCancel}
              afterClose={() =>
                this.setState({ stepCont: 1, comment: '', amount: 1 })
              }
              footer={
                stepCont === 1
                  ? [
                      <Button
                        key="continue"
                        type="danger"
                        size="large"
                        className="btn-company"
                        onClick={() => {
                          this.getDetail();
                        }}
                        disabled={this.state.comment === ''}
                        style={{
                          width: '83%',
                          marginRight: '7px',
                          height: '40px',
                          marginBottom: '10px',
                        }}
                      >
                        <p
                          className={
                            this.state.comment === ''
                              ? 'p-button disabled'
                              : 'p-button'
                          }
                        >
                          {t('budget.funds.continue-button')}
                        </p>
                      </Button>,
                    ]
                  : stepCont === 2
                  ? [
                      <Button
                        key="cancel"
                        size="large"
                        className="btn-danger"
                        onClick={() =>
                          this.setState({
                            stepCont: stepCont - 1,
                            comment: this.state.comment,
                            amount,
                          })
                        }
                        style={{
                          width: '150px',
                          height: '40px',
                          marginBottom: '10px',
                          background: '#717982',
                          border: 'none',
                        }}
                      >
                        <p className="p-button">
                          {t('budget.funds.cancel-button')}
                        </p>
                      </Button>,
                      <Button
                        key="submit"
                        type="success"
                        size="large"
                        loading={confirmLoading}
                        className="btn-company"
                        onClick={this.handleOk}
                        disabled={total > anual_refill_top}
                        style={{
                          width: '150px',
                          marginRight: '7px',
                          height: '40px',
                          marginBottom: '10px',
                        }}
                      >
                        <p className="p-button">
                          {t('budget.funds.confirm-button')}
                        </p>
                      </Button>,
                    ]
                  : []
              }
            >
              <div
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  paddingTop: '15px',
                  margin: '10px',
                  marginBottom: '25px',
                }}
              >
                <Col
                  style={{
                    position: 'absolute',
                    left: '15%',
                    margin: '0',
                  }}
                  xs={{ span: 2 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2, offset: 1 }}
                >
                  {stepCont !== 3 && (
                    <WalletOutlined
                      style={{
                        fontSize: '1.43rem',
                        marginTop: '2px',
                      }}
                    />
                  )}
                </Col>
                <p
                  style={{
                    position: 'absolute',
                    left: '25%',
                    fontSize: '15px',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {stepCont === 1
                    ? t('budget.ptaas.funds.add-credits')
                    : stepCont === 2
                    ? t('budget.funds.title2')
                    : ''}
                </p>
              </div>

              {this.stepContent(stepCont)}
            </Modal>
          </Col>
        </div>
        <div style={{ textAlign: 'center', marginTop: '21px' }}>
          <Col style={{ margin: '0 auto' }}>
            <Link to={'/dashboard/finance/conversion-history'} wallet={wallet}>
              <Image
                style={{ width: '19px', height: '19px' }}
                preview={false}
                src={`/images/history.png`}
              />
              <span className="link-text">
                {t('budget.ptaas.conversion-history')}
              </span>
            </Link>
          </Col>
        </div>
        <div style={{ textAlign: 'center', marginTop: '21px' }}>
          <Col style={{ margin: '0 auto' }}>
            <Link to={'/dashboard/finance/history/ptaas'} wallet={wallet}>
              <Image
                style={{ width: '19px', height: '19px' }}
                preview={false}
                src={`/images/history.png`}
              />
              <span className="link-text">
                {t('budget.wallet-history.wallet-history')}
              </span>
            </Link>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  hasError: state.wallet.hasError,
  errorMessage: state.wallet.errorMessage,
});
export default withTranslation()(connect(mapStateToProps)(WalletBalancePtaas));
