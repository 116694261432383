import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

import axiosInstance from '../axiosInstance';

const FormItem = Form.Item;
const Option = Select.Option;

const ProgramManagersModal = (props) => {
  const { modalVisible, closeModal, updateUsers, form, users, id } = props;
  const { getFieldDecorator } = form;

  const [isLoading, setIsLoading] = useState(false);
  const [userFields, setUserFields] = useState([{ user: '', role: '' }]);

  const { t } = useTranslation();

  const close = () => {
    closeModal(false);
  };

  const sendData = async (data) => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/programs/${id}/users/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setTimeout(
        () =>
          openNotification(
            'success',
            t('notifications.title.success'),
            t('notifications.programs.add-user')
          ),
        0
      );
      closeModal(false);
      updateUsers();
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setTimeout(
        () => openNotification('error', 'Error', t('notifications.error')),
        0
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addUserField = () => {
    setUserFields([...userFields, { user: '', role: '' }]);
  };

  const removeUserField = (index) => {
    const fields = [...userFields];
    fields.splice(index, 1);
    setUserFields(fields);
  };

  const handleFieldChange = (index, field, value) => {
    const fields = [...userFields];
    fields[index][field] = value;
    setUserFields(fields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const attributes = userFields.map((field, index) => ({
          user: values[`user${index}`],
          role: values[`role_${index}`],
        }));
        sendData(attributes);
      }
    });
  };

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      onCancel={close}
      title={t('programs.tabs.users.modal.title')}
    >
      <Form onSubmit={handleSubmit}>
        {userFields.map((userField, index) => (
          <Row key={index} className="invitationSlot" gutter={15}>
            <Col xl={10}>
              <FormItem>
                {getFieldDecorator(`user${index}`, {
                  rules: [
                    {
                      required: true,
                      message: t('programs.tabs.users.modal.user.message'),
                    },
                  ],
                })(
                  <Select
                    placeholder={t(
                      'programs.tabs.users.modal.user.placeholder'
                    )}
                    onChange={(value) =>
                      handleFieldChange(index, 'user', value)
                    }
                  >
                    {users.map((user) => (
                      <Option
                        key={user.id}
                        value={user.user}
                        disabled={
                          user.isAdded ||
                          userFields.some((pet) => pet.user === user.user)
                        }
                      >
                        {user.user}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xl={10}>
              <FormItem>
                {getFieldDecorator(`role_${index}`, {
                  rules: [
                    {
                      required: true,
                      message: t('programs.tabs.users.modal.roles.message'),
                    },
                  ],
                })(
                  <Select
                    placeholder={t(
                      'programs.tabs.users.modal.roles.placeholder'
                    )}
                    onChange={(value) =>
                      handleFieldChange(index, 'role', value)
                    }
                  >
                    <Option value="admin">Admin</Option>
                    <Option value="viewer">Viewer</Option>
                    <Option value="analyst">Analyst</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col sm={4}>
              {userFields.length > 1 && index !== 0 ? (
                <Button
                  disabled={userFields.length === 1}
                  onClick={() => removeUserField(index)}
                >
                  <MinusCircleOutlined className="btn-remove-invitation" />
                </Button>
              ) : (
                <Button className="btn-add-invitation" onClick={addUserField}>
                  <PlusCircleOutlined />
                </Button>
              )}
            </Col>
          </Row>
        ))}
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          className="modal-confirm-btn btn-company btn-block"
        >
          {t('programs.tabs.users.modal.add-button')}
        </Button>
      </Form>
    </Modal>
  );
};

const WrappedProgramManagersModal = Form.create()(ProgramManagersModal);

export default WrappedProgramManagersModal;
