import React, { Component } from 'react';
import { Button } from 'antd';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

class CreateProgramButton extends Component {
  handleSelectProgramType(ptype) {
    if (ptype === 'ptaas' && !this.props.isPtaasAvailable) {
      this.showMessage('ptaas');
    } else if (ptype === 'bugbounty' && !this.props.isBugbountyAvailable) {
      this.showMessage('ptaas');
    } else {
      this.props.handleSelectProgramType(ptype);
    }
  }

  showMessage = (type) => {
    const { t } = this.props;
    let message;
    switch (type) {
      case 'ptaas':
        message = t('programs.create-modal.ptaas.not-available');
        break;
      case 'bugbounty':
        message = t('programs.create-modal.bugbounty.not-available');
        break;
    }
    openNotification('info', message, t('programs.create-modal.alert'));
  };

  render() {
    const { t } = this.props;

    return (
      <Button
        type="primary"
        size="large"
        onClick={this.props.onClick}
        className="btn-company"
      >
        <p className="p-button">{t('programs.create')}</p>
      </Button>
    );
  }
}

export default withTranslation()(withRouter(CreateProgramButton));
