import React, { Component } from 'react';
import { Layout, Row, Col, Card } from 'antd';

import CompanySignUpForm from '../components/CompanySignUpForm';
import HackerSignUpForm from '../components/HackerSignUpForm';
import { withTranslation } from 'react-i18next';
import { footerUrl } from '../constants/Data';
const { Content, Footer } = Layout;

class HackerSignUp extends Component {
  getLayoutStuff() {
    switch (this.props.match.params.type) {
      case 'company':
        return {
          id: 'companySignUpLayout',
          bgUrl:
            'url(' +
            process.env.PUBLIC_URL +
            '/images/bg-company-sign-up.jpeg)',
          logoUrl: process.env.PUBLIC_URL + '/images/logo-company.png',
          form: <CompanySignUpForm />,
        };
      case 'hacker':
        return {
          id: 'hackerSignUpLayout',
          bgUrl:
            'url(' + process.env.PUBLIC_URL + '/images/bg-hacker-sign-up.jpeg)',
          logoUrl: process.env.PUBLIC_URL + '/images/logo-hacker.png',
          form: <HackerSignUpForm />,
        };
    }
  }

  getInfoSignUp() {
    const { t } = this.props;
    switch (this.props.match.params.type) {
      case 'company':
        return (
          <Col
            sm={{ span: 24 }}
            md={{ span: 9, offset: 3 }}
            lg={{ span: 9, offset: 3 }}
            style={{ marginRight: '40px', marginTop: '250px' }}
          >
            <h1 className="sign-up-title">{t('signup.welcome')}</h1>
            <p className="sign-up-description">
              {t('signup.welcome-company-desc1')}
            </p>
            <p className="sign-up-description">
              {t('signup.welcome-company-desc2')}
            </p>
            <p className="sign-up-description">
              {t('signup.welcome-company-desc3')}
            </p>
          </Col>
        );
      default:
        return (
          <Col
            sm={{ span: 24 }}
            md={{ span: 9, offset: 3 }}
            lg={{ span: 9, offset: 3 }}
            style={{ marginRight: '40px', marginTop: '250px' }}
          >
            <h1 className="sign-up-title">{t('signup.welcome')}</h1>
            <p className="sign-up-description">
              {t('signup.welcome-hacker-desc1')}
            </p>
            <p className="sign-up-description">
              {t('signup.welcome-hacker-desc2')}
            </p>
            <p className="sign-up-description">
              {t('signup.welcome-hacker-desc3')}
            </p>
          </Col>
        );
    }
  }

  render() {
    const layoutStuff = this.getLayoutStuff();
    const { match, t } = this.props;
    return (
      <Layout
        id={layoutStuff.id}
        style={{ backgroundImage: layoutStuff.bgUrl }}
      >
        <Content>
          <div className="sign-up-container">
            <Row>
              {this.getInfoSignUp()}
              <Col sm={{ span: 24 }} md={{ span: 9 }} lg={{ span: 9 }}>
                <Card hoverable={false}>
                  <h2 className="block-center">
                    {[
                      match.params.type === 'company'
                        ? t('signup.registration-company')
                        : t('signup.registration-hacker'),
                    ]}
                  </h2>
                  {layoutStuff.form}
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
        <Row>
          <div className="siteFooter">
            <Col
              sm={{ span: 24 }}
              md={{ span: 16, offset: 4 }}
              lg={{ span: 16, offset: 4 }}
            >
              <Row>
                <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                  <p className="footer-title">
                    <strong>{t('dashboard.footer.companies')}</strong>
                  </p>

                  <p className="footer-links">
                    <a
                      href={footerUrl.companies.bugbounty}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bug Bounty
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      href={footerUrl.companies.ptaas}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pentest as a Service
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.companies.faq}
                    >
                      {t('dashboard.footer.faq')}
                    </a>
                  </p>
                </Col>
                <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                  <p className="footer-title">
                    <strong>Hackers</strong>
                  </p>

                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.hackers.hacktivities}
                    >
                      {t('dashboard.footer.hacktivities')}
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.companies.faq}
                    >
                      {t('dashboard.footer.faq')}
                    </a>
                  </p>
                </Col>
                <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                  <p className="footer-title">
                    <strong>Legal</strong>
                  </p>

                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.legal.TAC}
                    >
                      {t('dashboard.footer.terms')}
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.legal.agreement}
                    >
                      {t('dashboard.footer.agreement')}
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.legal.disclosure}
                    >
                      {t('dashboard.footer.disclosure')}
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.legal.privacy}
                    >
                      {t('dashboard.footer.privacy-policy')}
                    </a>
                  </p>
                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={footerUrl.legal.ranking}
                    >
                      {t('dashboard.footer.ranking-system')}
                    </a>
                  </p>
                </Col>
                <Col sm={{ span: 3 }} md={{ span: 3 }} lg={{ span: 3 }}>
                  <p className="footer-title">
                    <strong>{t('dashboard.footer.contacts')}</strong>
                  </p>

                  <p className="footer-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:info@cyscope.io"
                    >
                      info@cyscope.io
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
        <Footer>
          <Row gutter={24}>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <p className="pull-right">
                Copyright @{new Date().getFullYear()} Dreamlab Technologies{' '}
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    );
  }
}

export default withTranslation()(HackerSignUp);
