import React from 'react';
import { Card } from 'antd';
import HistoryProgramList from '../components/HistoryProgramList';
import { useTranslation } from 'react-i18next';
import LoaderComponent from './LoaderComponent';

const HistoryProgram = (props) => {
  const { t } = useTranslation();
  const {
    loading,
    activities,
    totalItems,
    totalPages,
    currentPage,
    handlePageChange,
  } = props;

  return (
    <Card hoverable={false}>
      <h4 className="section-subtitle">{t('programs.history')}</h4>
      {loading ? (
        <LoaderComponent label={false} />
      ) : (
        <div id="History">
          <HistoryProgramList
            activities={activities}
            loading={loading}
            currentPage={currentPage}
            totalItems={totalItems}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </Card>
  );
};
export default HistoryProgram;
