import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../styles/loader-component.less';

const LoaderComponent = ({ label = true, size = 'large', main = false }) => {
  const { t } = useTranslation();
  return (
    <div id="loader" className={main ? 'main-loader' : 'mb-3'}>
      <LoadingOutlined className={`loading ${size}`} />
      {label && <p className="loading-text text-center">{t('loading')}</p>}
    </div>
  );
};

export default LoaderComponent;
