import * as Action from '../actions/ActionTypes';
import { saveAs } from 'file-saver';
import moment from 'moment';

const initialState = {
  entities: [],
  fetching: [],
  isFetching: false,
  isPushing: false,
  hasError: false,
  errorMessage: null,
  filters: {
    bySearch: null,
    privatesOnly: null,
    byState: null,
  },
  filtered: {
    entities: [],
    currentPage: 1,
    numberOfPages: 1,
    numberOfElements: 0,
    numberOfReports: 0,
    filters: {
      bySearch: null,
      privatesOnly: null,
      byState: null,
    },
  },
  programInvitations: null,
  currentPage: 1,
  numberOfPages: 1,
  numberOfPagesInvitations: 1,
  numberOfElements: null,
  numberOfElementsInvitations: null,
  numberOfReports: 0,
  next: null,
  filterSiderOpened: false,
  hackerSiderOpened: false,
  programsFinance: [],
  available_wallet_amount: null,
  available_quote: null,
  available_total: null,
};

class ProgramsReducer {
  static reduce(state = initialState, action) {
    if (ProgramsReducer[action.type]) {
      return ProgramsReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_PROGRAMS_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.LIST_PROGRAMS_SUCCESS](state, action) {
    const params = action.response.params ? action.response.params : {};
    return {
      ...state,
      filters: {
        ...state.filters,
        bySearch:
          params['bySearch'] !== undefined
            ? params['bySearch']
            : state.filters.bySearch,
        privatesOnly:
          params['privatesOnly'] !== undefined
            ? params['privatesOnly']
            : state.filters.byAccess,
        byState:
          params['byState'] !== undefined
            ? params['byState']
            : state.filters.byState,
        byPayment:
          params['byPayment'] !== undefined
            ? params['byPayment']
            : state.filters.byPayment,
      },
      filtered: {
        ...state.filtered,
        filters: {
          bySearch: null,
          privatesOnly: null,
          byState: null,
        },
      },
      entities: action.response.results,
      currentPage: 1,
      numberOfPages: Math.ceil(action.response.count / 10),
      numberOfElements: action.response.count,
      numberOfReports: action.response.number_of_reports,
      next: action.response.next,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.LIST_PROGRAMS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }
  static [Action.LIST_PROGRAMS_FILTERED_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
      hasError: false,
    };
  }

  static [Action.LIST_PROGRAMS_FILTERED_SUCCESS](state, action) {
    return {
      ...state,
      programInvitations: action.response.results,
      numberOfPagesInvitations: Math.ceil(action.response.count / 10),
      numberOfElementsInvitations: action.response.count,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.LIST_PROGRAMS_FILTERED_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.PROGRAM_FETCH_REQUEST](state, action) {
    return {
      ...state,
      fetching: [...state.fetching, action.payload],
    };
  }

  static [Action.PROGRAM_FETCH_SUCCESS](state, action) {
    return {
      ...state,
      entities: {
        ...state.entities,
        [action.payload._key]: { ...action.payload },
      },
      fetching: state.fetching.filter((key) => key !== action.payload._key),
    };
  }

  static [Action.PROGRAM_FETCH_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }
  static [Action.LIST_FINANCE_PROGRAMS_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
    };
  }
  static [Action.LIST_FINANCE_PROGRAMS_SUCCESS](state, action) {
    const company_current_balance =
      parseFloat(action.response.company_current_balance) || 0;
    const program_total_balance =
      parseFloat(action.response.program_total_balance) || 0;

    const available_total = company_current_balance + program_total_balance;
    return {
      ...state,
      company_current_balance: company_current_balance,
      program_total_balance: program_total_balance,
      available_total: available_total,
      programsFinance: action.response.results,
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.LIST_FINANCE_PROGRAMS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.LIST_FINANCE_PROGRAMS_PTAAS_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
    };
  }
  static [Action.LIST_FINANCE_PROGRAMS_PTAAS_SUCCESS](state, action) {
    const company_current_balance =
      parseFloat(action.response.company_current_balance) || 0;
    const program_total_balance =
      parseFloat(action.response.program_total_balance) || 0;

    return {
      ...state,
      company_current_balance: company_current_balance,
      program_total_balance: program_total_balance,
      programsFinance: action.response.results,
      numberOfElements: action.response.count,
      numberOfPages: Math.ceil(action.response.count / 10),
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.LIST_FINANCE_PROGRAMS_PTAAS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }

  static [Action.EXPORT_FINANCE_PROGRAMS_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      isFetching: true,
    };
  }

  static [Action.EXPORT_FINANCE_PROGRAMS_SUCCESS](state, action) {
    const { response } = action;
    if (response.type === 'text/csv') {
      saveAs(
        response,
        `Program Finance - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
      );
    } else {
      saveAs(
        response,
        `Program Finance - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`
      );
    }

    return {
      ...state,
      hasError: false,
      isFetching: false,
    };
  }

  static [Action.EXPORT_FINANCE_PROGRAMS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isFetching: false,
      errorMessage: action.error,
    };
  }
}

export default ProgramsReducer.reduce;
