import React, { useState } from 'react';
import moment from 'moment';

const FileDownloader = ({
  children,
  url,
  filename,
  className,
  doAppendDatetime,
}) => {
  const [downloading, setDownloading] = useState(false);

  const downloadFile = () => {
    // Set downloading state to true to show loading indicator or disable button
    setDownloading(true);

    fetch(url, {
      method: 'GET',
      credentials: 'include', // Include credentials with the request
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = fileURL;
        let newFileName;
        newFileName = filename;
        if (doAppendDatetime) {
          const lastIndex = filename.lastIndexOf('.');
          if (lastIndex >= 0) {
            newFileName = filename.substring(0, lastIndex);
            const fileExtension = filename.substring(lastIndex + 1);
            newFileName = `${newFileName} - ${moment().format('YYYY-MM-DD HHmmss')}.${fileExtension}`;
          } else {
            newFileName = `${newFileName} - ${moment().format('YYYY-MM-DD HHmmss')}`;
          }
        }
        link.setAttribute('download', newFileName); // Set the default file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // Reset downloading state
        setDownloading(false);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        // Reset downloading state
        setDownloading(false);
      });
  };

  return (
    <React.Fragment>
      <button
        className={`${className}`}
        onClick={downloadFile}
        disabled={downloading}
      >
        {downloading ? 'Downloading...' : children}
      </button>
    </React.Fragment>
  );
};

FileDownloader.defaultProps = {
  doAppendDatetime: false,
};

export default FileDownloader;
