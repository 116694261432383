import _ from 'lodash';

export const MarkOptions = {
  html: false, // Enable HTML tags in source
  xhtmlOut: false, // Use '/' to close single tags (<br />)
  breaks: true, // Convert '\n' in paragraphs into <br>
  langPrefix: 'language-', // CSS language prefix for fenced blocks
  linkify: true, // autoconvert URL-like texts to links
  linkTarget: '_blank', // set target to open link in
  // Enable some language-neutral replacements + quotes beautification
  typographer: false,
  // Double + single quotes replacement pairs, when typographer enabled,
  // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
  quotes: '“”‘’',
};

export function MarkdownClean(str) {
  if (str) {
    let res = str.trim();

    if (res.substr(0, 3) === '```') {
      res = res.slice(3, res.length);
    }

    if (res.substr(res.length - 3, res.length) === '```') {
      res = res.slice(0, res.length - 3);
    }

    return res;
  }
}

export const MarkdownParse = (value) => {
  let str = value;
  let res = '';
  let onMark = false;
  let foundMark = null;

  while (!_.isEmpty(str)) {
    foundMark = str.indexOf('```');
    if (foundMark > -1) {
      foundMark += 3;
      let strFragment = str.slice(0, foundMark);
      if (!onMark) strFragment = strFragment.replace(/\n/g, '&nbsp;\n');
      res += strFragment;
      str = str.slice(foundMark, str.length);
      onMark = true;
    } else {
      res += str;
      str = '';
    }
  }

  return res;
};
