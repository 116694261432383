import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';

import { useTranslation } from 'react-i18next';

const AcademyDetail = (props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col lg={{ span: 18, offset: 3 }} sm={{ span: 22, offset: 1 }}>
        <Card style={{ margin: '5vh auto 10vh' }}>
          <div>
            <a onClick={props.history.goBack} className="link-text">
              <span style={{ fontSize: '15px' }}>
                <LeftOutlined style={{ fontSize: '15px' }} />
                {t('reports.back')}
              </span>
            </a>
          </div>
          {props.render()}
        </Card>
      </Col>
    </Row>
  );
};

export default AcademyDetail;
