import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'antd';

import ReportFormNew from '../components/ReportFormNew';
import { withTranslation } from 'react-i18next';
import axiosInstance from '../axiosInstance';
import { openNotification } from '../utils/General';
import LoaderComponent from '../components/LoaderComponent';

class Report extends Component {
  state = {
    report: null,
    reportLoading: false,

    program: null,
    programLoading: false,

    attackTypes: null,
    attackTypesLoading: false,

    initialValues: null,
  };

  componentDidMount() {
    if (this.props.isEditing) {
      this.fetchReport();
    } else {
      this.fetchProgram();
    }
    this.fetchAttackTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.report !== this.state.report) {
      this.fetchProgram();
    }
  }

  fetchProgram() {
    const queryParams = new URLSearchParams(window.location.search);
    const programId =
      this.state.report === null
        ? queryParams.get('program')
        : this.state.report.program.id;

    this.setState({ programLoading: true });

    const params = {};

    axiosInstance
      .get(`/programs/${programId}/`, { params })
      .then((response) => {
        this.setState({
          program: response.data,
          programLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ programLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchReport() {
    this.setState({ reportLoading: true });

    const params = {};

    axiosInstance
      .get(`/reports/${this.props.match.params.id}/`, { params })
      .then((response) => {
        this.setState({
          report: response.data,
          reportLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ reportLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchAttackTypes() {
    this.setState({ attackTypesLoading: true });

    const params = {};

    axiosInstance
      .get(`/reports/attack-types/`, { params })
      .then((response) => {
        this.setState({
          attackTypes: response.data,
          attackTypesLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ attackTypesLoading: false }); // Set loading to false even if there is an error
      });
  }

  createReport(payload) {
    const { t } = this.props;
    axiosInstance
      .post(`/reports/`, payload)
      .then((response) => {
        if (response.data.is_draft) {
          openNotification(
            'success',
            `${t('notifications.reports.create-draft')}`,
            t('notifications.reports.create-draft-message')
          );
        } else {
          openNotification(
            'success',
            `${t('notifications.reports.create')}`,
            t('notifications.reports.create-message')
          );
        }
        this.props.history.push(`/dashboard/reports/${response.data.id}`);
      })
      .catch((error) => {
        openNotification(
          'error',
          `${t('notifications.error')}`,
          this.props.errorMessage
        );
      });
  }

  updateReport(payload) {
    const { t, report } = this.props;
    axiosInstance
      .patch(`/reports/${report.id}/`, payload)
      .then((response) => {
        if (response.data.is_draft) {
          openNotification(
            'success',
            `${t('notifications.reports.create-draft')}`,
            `${t('notifications.reports.create-draft-message')}`
          );
        } else {
          openNotification(
            'success',
            `${t('notifications.reports.update')}`,
            t('notifications.reports.update-message')
          );
        }
        this.props.history.push(`/dashboard/reports/${response.data.id}`);
      })
      .catch((error) => {
        openNotification(
          'error',
          `${t('notifications.error')}`,
          this.props.errorMessage
        );
      });
  }

  onSubmit(payload) {
    const { isEditing } = this.props;
    if (isEditing) {
      this.updateReport(payload);
    } else {
      this.createReport(payload);
    }
  }

  render() {
    const { t, isEditing } = this.props;
    const {
      programLoading,
      reportLoading,
      report,
      program,
      attackTypes,
      attackTypesLoading,
    } = this.state;

    const loading =
      programLoading ||
      reportLoading ||
      attackTypesLoading ||
      program === null ||
      (isEditing && report === null);

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 16, offset: 4 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>
                {isEditing ? t('reports.form.edit') : t('reports.form.create')}
              </h1>
              <h4>
                {isEditing
                  ? t('reports.form.edit-desc')
                  : t('reports.form.create-desc')}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="report-form-container">
                <Card hoverable={false} bordered="false">
                  <Row>
                    <Col
                      sm={{ span: 20, offset: 2 }}
                      md={{ span: 18, offset: 3 }}
                      lg={{ span: 18, offset: 3 }}
                    >
                      {loading ? (
                        <LoaderComponent label={false} />
                      ) : (
                        <ReportFormNew
                          initialValues={report}
                          program={program}
                          attackTypes={attackTypes}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser.profile,
});
export default withTranslation()(connect(mapStateToProps)(Report));
