import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Avatar,
  Checkbox,
  Input,
  Button,
  Row,
  Col,
  Select,
  Upload,
} from 'antd';
import { Countries, Languages, PreferedLanguages } from '../constants/Data';

import ImageCrop from './ImageCrop';
import withSizes from 'react-sizes';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';
import { openNotification } from '../utils/General';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class HackerProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: props.initialValues || {},
      fileList: [],
      mayCropFile: false,
      tempAvatar: null,
      loadingButton: false,
      avatarRequesting: false,
      avatarLoading: false,
      avatarUploadContent: null,
    };
  }

  getCountries = () => {
    return Countries.map((p, index) => {
      return (
        <Option value={p.id} key={index.toString()}>
          {p.name}
        </Option>
      );
    });
  };

  getLanguages = () => {
    return Languages.map((p, index) => {
      return (
        <Col sm={6} key={index.toString()}>
          <Checkbox value={p.code} style={{ color: '#888888' }}>
            {p.name}
          </Checkbox>
        </Col>
      );
    });
  };

  getPreferedLanguages = () => {
    return PreferedLanguages.map((p, index) => {
      return (
        <Option value={p.id} key={index.toString()}>
          {p.name}
        </Option>
      );
    });
  };

  getUploader = () => {
    const { t } = this.props;
    const props = {
      customRequest: function () {
        return null;
      },
      multiple: false,
      onRemove: (file) => {
        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        this.setState({ tempAvatar: null });
        this.setState({ hasFile: false });
        this.props.form.resetFields(['avatar']);
      },
      beforeUpload: (file) => {
        if (this.state.fileList.length < 1) {
          this.setState(({ fileList }) => ({
            fileList: [...fileList, file],
            hasFile: true,
            mayCropFile: true,
          }));
        }
        return false;
      },
      fileList: this.state.fileList,
    };

    return (
      <Upload {...props}>
        <Button
          disabled={this.state.hasFile}
          className="button-primary"
          style={{
            height: 40,
            padding: '10px 40px',
          }}
        >
          <p>{t('profile.add-photo-button')}</p>
        </Button>
      </Upload>
    );
  };

  componentDidMount() {
    const { form } = this.props;
    form.setFieldsValue(this.state.initialValues);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, form, t } = this.props;
    const { fileList } = this.state;

    form.validateFieldsAndScroll({ force: true }, async (err, values) => {
      if (!err) {
        try {
          let response;
          this.setState({
            loadingButton: true,
          });
          response = await axiosInstance.patch(`/profile/`, values.profile);

          this.setState({
            loadingButton: false,
            initialValues: {
              profile: {
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                twitter_url: response.data.twitter_url,
                linkedin_url: response.data.linkedin_url,
                other_url: response.data.other_url,
                default_language: response.data.default_language,
                country: response.data.country,
                languages: response.data.languages,
              },
            },
          });
          openNotification(
            'success',
            `${t('notifications.profile.update')}`,
            `${t('notifications.profile.update-profile')}`
          );
        } catch (error) {
          handleAxiosError(error);
        } finally {
          this.setState({
            loadingButton: false,
          });
        }
      }
    });
  };

  cropImageDone = (croppedFile) => {
    if (!croppedFile) {
      this.setState({ mayCropFile: false, fileList: [], hasFile: false });
      return;
    }

    const { form } = this.props;

    let reader = new FileReader();
    let url = reader.readAsDataURL(croppedFile);
    reader.onloadend = function (e) {
      this.setState({
        tempAvatar: reader.result,
      });
    }.bind(this);

    this.setState({ mayCropFile: false, fileList: [croppedFile] });
    form.setFieldsValue({
      avatar: { file: croppedFile, fileList: [croppedFile] },
    });
  };

  render() {
    const {
      currentUser,
      form: { getFieldDecorator },
      isMobile,
      t,
    } = this.props;
    const {
      mayCropFile,
      fileList,
      avatarRequesting,
      avatarUploadContent,
      tempAvatar,
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <h4 className="profile-title">{t('profile.title')}</h4>
        <Row
          gutter={4}
          type="flex"
          justify="start"
          align="middle"
          className="avatar-row mb-3"
        >
          <Col xs={12} xl={6} className="avatar-col">
            <p className="subtitle-profile mt-3">{t('profile.add-photo')}</p>
            {tempAvatar ? (
              <Avatar size="large" src={tempAvatar ? tempAvatar : ''} />
            ) : (
              <Avatar
                size="large"
                src={
                  !avatarRequesting
                    ? avatarUploadContent || currentUser.avatarThumbnailSUrl
                    : ''
                }
              />
            )}
          </Col>
          <Col xs={12} xl={6} className="upload-col">
            <FormItem label="" className={isMobile ? null : 'pt-5'}>
              {getFieldDecorator('avatar', {
                rules: [{ required: false }],
              })(<React.Fragment>{this.getUploader()}</React.Fragment>)}
            </FormItem>
          </Col>
        </Row>

        <FormItem label={t('profile.username')}>
          {getFieldDecorator('username', {
            rules: [
              { required: true, message: 'Ingresa tu nombre de usuario' },
            ],
          })(<Input disabled />)}
        </FormItem>

        <FormItem label={t('profile.name')}>
          {getFieldDecorator('profile.first_name', {
            rules: [{ required: true, message: t('profile.name-placeholder') }],
          })(<Input placeholder={t('profile.name-placeholder')} />)}
        </FormItem>

        <FormItem label={t('profile.last-name')}>
          {getFieldDecorator('profile.last_name', {
            rules: [
              { required: true, message: t('profile.last-name-placeholder') },
            ],
          })(<Input placeholder={t('profile.last-name-placeholder')} />)}
        </FormItem>

        <FormItem label={t('profile.linkedin')}>
          {getFieldDecorator('profile.linkedin_url', {
            rules: [
              { required: false },
              { type: 'url', message: 'ej. https://www.linkedin.com' },
            ],
          })(<Input placeholder="LinkedIn" />)}
        </FormItem>

        <FormItem label={t('profile.twitter')}>
          {getFieldDecorator('profile.twitter_url', {
            rules: [{ required: false }],
          })(<Input placeholder="Twitter" />)}
        </FormItem>

        <FormItem label={t('profile.country')}>
          {getFieldDecorator('profile.country', {
            rules: [
              { required: false, message: t('profile.country-placeholder') },
            ],
          })(
            <Select placeholder={t('profile.country-placeholder')}>
              {this.getCountries()}
            </Select>
          )}
        </FormItem>

        <FormItem label={t('profile.main-language')}>
          {getFieldDecorator('profile.default_language', {
            rules: [{ required: true, message: t('profile.language-message') }],
          })(
            <Select placeholder={t('profile.language-message')}>
              {PreferedLanguages.map((p, index) => {
                return (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                );
              })}
            </Select>
          )}
        </FormItem>

        <FormItem label={t('profile.about-me')}>
          {getFieldDecorator('profile.about_me')(
            <TextArea
              placeholder={t('profile.about-me-placeholder')}
              rows={6}
            />
          )}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-hacker btn-block"
          loading={this.state.loadingButton}
        >
          <p className="p-button">{t('profile.save-button')}</p>
        </Button>

        {mayCropFile ? (
          <ImageCrop
            file={_.first(fileList)}
            cropDone={this.cropImageDone}
            profile={true}
          />
        ) : (
          ''
        )}
      </Form>
    );
  }
}

const NormalHackerProfileForm = Form.create()(HackerProfileForm);

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  avatarRequesting: state.currentUser.avatarRequesting,
});
export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(NormalHackerProfileForm))
);
