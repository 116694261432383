import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Slider } from 'antd';
import 'croppie/croppie.css';
import '../themes/image-crop.less';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/CropImage';
import {
  updateCompanyImage,
  updateProfileImage,
} from '../redux/actions/currentUser';
import { updateProgramAvatar } from '../redux/actions/program';
import { withTranslation } from 'react-i18next';
import { isCompanyMode } from '../utils/General';
import { openNotification } from '../utils/General';

class ImageCrop extends Component {
  state = {
    visible: true,
    imageSrc: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    croppedAreaPixels: null,
  };

  componentDidMount() {
    const { file } = this.props;
    const reader = new FileReader();

    reader.onload = function (e) {
      this.setState({ imageSrc: e.target.result });
    }.bind(this);
    reader.readAsDataURL(file);
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = (e) => {
    const { cropDone } = this.props;

    this.setState({ visible: false });
    cropDone(null);
  };

  handleOk = async () => {
    try {
      const { file, cropDone, profile, dispatch, program, company } =
        await this.props;
      const { croppedAreaPixels, imageSrc } = this.state;
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      let croppedFile = new File([croppedImage], file.name, {
        type: 'image/jpeg',
      });
      let formData = new FormData();
      if (profile) {
        formData.append('image', croppedFile);
        dispatch(updateProfileImage(formData)).then(() => {
          if (!this.props.hasError) {
            openNotification(
              'success',
              'Foto actualizada',
              'Se ha actualizado la foto del perfil exitosamente'
            );
          }
        });
      }
      if (program) {
        formData.append('image', croppedFile);
        dispatch(updateProgramAvatar(program, formData)).then(() => {
          if (!this.props.hasErrorProgram) {
            openNotification(
              'success',
              'Imagen actualizada',
              'Se ha actualizado la imagen del programa exitosamente'
            );
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            openNotification(
              'error',
              'El archivo al subir el archivo',
              this.props.errorMessage
            );
          }
        });
      }
      if (company) {
        formData.append('image', croppedFile);
        dispatch(updateCompanyImage(formData)).then(() => {
          if (!this.props.hasError) {
            openNotification(
              'success',
              'Imagen actualizada',
              'Se ha actualizado la imagen del programa exitosamente'
            );
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            openNotification(
              'error',
              'El archivo al subir el archivo',
              this.props.errorMessage
            );
          }
        });
      }
      croppedFile.uid = `rc-upload-${new Date().getTime().toString()}`;

      this.setState({ visible: false });
      cropDone(croppedFile);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { currentUser, t } = this.props;

    return (
      <div className="ImageCrop">
        <Modal
          className="ImageCropModal"
          title={t('crop.title')}
          closable={false}
          visible={this.state.visible}
          footer={
            <Row gutter={24}>
              <Col xs={12} sm={12}>
                <Button
                  key={2}
                  onClick={this.handleCancel}
                  className={'btn-block'}
                >
                  {t('crop.cancel-button')}
                </Button>
              </Col>

              <Col xs={12} sm={12}>
                <Button
                  key={1}
                  onClick={this.handleOk}
                  className={`btn-block btn-${
                    !isCompanyMode(currentUser) ? 'hacker' : 'company'
                  }`}
                >
                  {t('crop.cut-button')}
                </Button>
              </Col>
            </Row>
          }
        >
          <div>
            <div className="imageCroppie">
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                cropShape="round"
                showGrid={false}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
            <div className="controls">
              <Slider
                defaultValue={2}
                value={this.state.zoom}
                min={1}
                max={8}
                step={0.5}
                onChange={this.onZoomChange}
                disabled={false}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
  profile: ownProps.profile,
  program: ownProps.program,
  company: ownProps.company,
  hasError: state.currentUser.hasError,
  hasErrorProgram: state.program.hasError,
});

export default withTranslation()(connect(mapStateToProps)(ImageCrop));
