import * as Action from '../actions/ActionTypes';

const stateConstants = {
  isRequesting: null,
  isPushing: null,
  isInviting: null,
  reSendings: false,
  send: null,
  invitationsHasErrors: null,
  hasError: null,
  errorMessage: null,
  invitationAuth: null,
};
const initialState = {
  ...stateConstants,
  employees: [],
  invitations: [],
};

class companyEmployeesReducer {
  static reduce(state = initialState, action) {
    if (companyEmployeesReducer[action.type]) {
      return companyEmployeesReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_COMPANY_EMPLOYEES_REQUEST](state, action) {
    return {
      ...state,
      isRequesting: true,
    };
  }

  static [Action.LIST_COMPANY_EMPLOYEES_SUCCESS](state, action) {
    return {
      ...state,
      isRequesting: false,
      employees: action.response,
    };
  }

  static [Action.LIST_COMPANY_EMPLOYEES_ERROR](state, action) {
    return {
      ...state,
      isRequesting: false,
    };
  }

  static [Action.LIST_INVITATIONS_EMPLOYEES_REQUEST](state, action) {
    return {
      ...state,
      isRequesting: true,
    };
  }

  static [Action.LIST_INVITATIONS_EMPLOYEES_SUCCESS](state, action) {
    return {
      ...state,
      isRequesting: false,
      invitations: action.response.results,
    };
  }

  static [Action.LIST_INVITATIONS_EMPLOYEES_ERROR](state, action) {
    return {
      ...state,
      isRequesting: false,
    };
  }

  static [Action.INVITES_COMPANY_EMPLOYEES_REQUEST](state, action) {
    return {
      ...state,
      isInviting: true,
    };
  }

  static [Action.INVITES_COMPANY_EMPLOYEES_SUCCESS](state, action) {
    return {
      ...state,
      isInviting: false,
      hasError: false,
      employees: [],
    };
  }

  static [Action.INVITES_COMPANY_EMPLOYEES_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isInviting: false,
    };
  }

  static [Action.REINVITE_COMPANY_EMPLOYEE_REQUEST](state, action) {
    return {
      ...state,
      reSendings: true,
      hasError: false,
      sent: null,
    };
  }

  static [Action.REINVITE_COMPANY_EMPLOYEE_SUCCESS](state, action) {
    return {
      ...state,
      reSendings: false,
      sent: action.response.email,
      hasError: false,
    };
  }

  static [Action.REINVITE_COMPANY_EMPLOYEE_ERROR](state, action) {
    return {
      ...state,
      reSendings: false,
      hasError: true,
    };
  }

  static [Action.INVITATION_AUTHENTICATE_REQUEST](state, action) {
    return {
      ...state,
      isInviting: true,
      invitationAuth: null,
    };
  }

  static [Action.INVITATION_AUTHENTICATE_SUCCESS](state, action) {
    return {
      ...state,
      isInviting: false,
      invitationAuth: true,
    };
  }

  static [Action.INVITATION_AUTHENTICATE_ERROR](state, action) {
    return {
      ...state,
      isInviting: false,
      invitationAuth: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EMPLOYEE_CREATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.EMPLOYEE_CREATE_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
    };
  }

  static [Action.EMPLOYEE_CREATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EMPLOYEE_ACTIVATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.EMPLOYEE_ACTIVATE_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
      employees: action.response.result,
    };
  }

  static [Action.EMPLOYEE_ACTIVATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EMPLOYEE_DEACTIVATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.EMPLOYEE_DEACTIVATE_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
      employees: action.response.result,
    };
  }

  static [Action.EMPLOYEE_DEACTIVATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.EMPLOYEE_DELETE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.EMPLOYEE_DELETE_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
      employees: state.employees,
    };
  }

  static [Action.EMPLOYEE_DELETE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.INVITATION_DELETE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.INVITATION_DELETE_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
      invitations: action.response.invitaions,
    };
  }

  static [Action.INVITATION_DELETE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.TRANSFER_OWNER_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.TRANSFER_OWNER_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
    };
  }

  static [Action.TRANSFER_OWNER_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
}

export default companyEmployeesReducer.reduce;
