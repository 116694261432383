import React from 'react';
import { Col, Row, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import LoaderComponent from './LoaderComponent';

const HackerDashboardCards = (props) => {
  const { t } = useTranslation();

  return (
    <Row className={props.className}>
      <Col xs={8} lg={7}>
        {props.ranking === null ? (
          <LoaderComponent label={false} />
        ) : (
          <Card className="total-amount">
            <h5
              className="centered-content"
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('dashboard.position')}
            </h5>
            <h3 style={{ textAlign: 'center' }}>{'# ' + props.ranking}</h3>
          </Card>
        )}
      </Col>
      <Col xs={8} lg={{ span: 7, offset: 1 }}>
        {props.score === null ? (
          <LoaderComponent label={false} />
        ) : (
          <Card className="total-amount">
            <h5
              className="centered-content"
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('dashboard.points')}
            </h5>

            <h3 style={{ textAlign: 'center' }}>{props.score}</h3>
          </Card>
        )}
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 8, offset: 1 }}>
        {props.rewards === null ? (
          <LoaderComponent label={false} />
        ) : (
          <Card className="total-amount">
            <h5
              className="centered-content"
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('dashboard.rewards')}
            </h5>
            <h3 style={{ textAlign: 'center' }}>
              {Math.round(props.rewards).toLocaleString('es-CL')} {' USD'}
            </h3>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default HackerDashboardCards;
