import { combineReducers } from 'redux';

import persist from './persist';
import auth from './auth';
import currentUser from './currentUser';
import companyEmployees from './companyEmployees';
import programs from './programs';
import program from './program';
import programFeature from './programFeature';
import userReports from './reports';
import report from './report';
import comment from './comment';
import payments from './payments';
import reportComments from './reportComments';
import ranking from './ranking';
import scores from './scores';
import activities from './activities';
import history from './history';
import filesExport from './filesExport';
import wallet from './wallet';
import services from './services';
import filters from './filters';

import abilityReducer from './abilities';

const AppReducer = combineReducers({
  persist,
  auth,
  currentUser,
  ability: abilityReducer,

  companyEmployees,
  programs,
  userReports,
  reportComments,
  payments,
  program,
  programFeature,
  report,
  comment,
  ranking,
  scores,
  activities,
  filesExport,
  history,
  wallet,
  services,
  filters,
});

export default AppReducer;
