import React, { Component } from 'react';
import { Col, Pagination, Row } from 'antd';
import withSizes from 'react-sizes';
import '../themes/ant-pagination.less';
import { mapSizes } from '../mapSizes';

class AntPaginate extends Component {
  render() {
    const { isMobile } = this.props;

    return (
      <Row className="pagination">
        <Col sm={16}>
          <div style={{ display: 'flex' }}>
            <p style={{ marginTop: '12px' }}>{this.props.totalItems}</p>
            &nbsp;
            <p style={{ marginTop: '12px' }}>{this.props.verbose}</p>
          </div>
        </Col>

        <Col sm={8} style={{ marginTop: '10px' }}>
          <Pagination
            showSizeChanger={false}
            current={this.props.currentPage}
            total={this.props.totalItems}
            pageSize={this.props.pageSize}
            onChange={this.props.onPageChange}
            className={`Paginate${isMobile ? ' text-center' : ' pull-right'}`}
          />
        </Col>
      </Row>
    );
  }
}

export default withSizes(mapSizes)(AntPaginate);
