import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import store from './redux/store';
import esEs from 'antd/lib/locale-provider/es_ES';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import global_es from './translations/es/es.json';
import global_en from './translations/en/en.json';

import moment from 'moment';

const fallbackLanguage = 'en';
const local_lang = localStorage.getItem('selectedLanguage');

const lng =
  local_lang ||
  (navigator.language ? navigator.language.split('-')[0] : false) ||
  fallbackLanguage;

i18next.init({
  interpolation: { escapeValue: false },
  lng: lng,
  fallbackLng: fallbackLanguage,
  resources: {
    es: {
      translation: global_es,
    },
    en: {
      translation: global_en,
    },
  },
});

moment.locale(lng === 'en' ? 'en' : 'es');

const changeLanguage = (lng) => {
  i18next.changeLanguage(lng);
  moment.locale(lng === 'en' ? 'en' : 'es');
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConfigProvider locale={esEs}>
        <App changeLanguage={changeLanguage} />
      </ConfigProvider>
    </I18nextProvider>
  </Provider>
);
