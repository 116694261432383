import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperClipOutlined } from '@ant-design/icons';
import { Col, Image, Row } from 'antd';
import Marckdown from 'react-remarkable';
import { MarkOptions } from '../utils/MarkdownHelper';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { isCompanyMode } from '../utils/General';

const ReportStepsDetail = (props) => {
  const { t } = useTranslation();

  return props.steps.map((step, index) => {
    const stepIndicator = (
      <span>
        {index === 0 && (
          <span className="step-label">{t(`steps-to-reproduce`)}</span>
        )}
        {index === 0 && <br />}
        <div className="step-container mt-3">
          <span className="step-number">{index + 1}</span>
          <p className="step-label">
            {t('reports.form.step')} {index + 1}
          </p>
        </div>
      </span>
    );

    return (
      <div key={index}>
        <Row>
          <Col sm={24}>
            {stepIndicator}
            <div className="color-form-data text-overflow report-detail">
              <Marckdown
                source={step.content ? step.content : ''}
                options={MarkOptions}
              />
              {!_.isEmpty(step.media.media_files) && (
                <div>
                  <hr />
                  <p>{t('attached-files')}</p>
                  <ul>
                    {step.media.media_files.map((file, index) => {
                      if (
                        file.name.endsWith('.jpg') ||
                        file.name.endsWith('.png') ||
                        file.name.endsWith('.jpeg')
                      ) {
                        return (
                          <div key={index} style={{ marginBottom: '1vh' }}>
                            <Image src={file.url} width={'80%'} />
                          </div>
                        );
                      }
                      if (
                        file.name.endsWith('.mp4') ||
                        file.name.endsWith('.webm') ||
                        file.name.endsWith('.ogg')
                      ) {
                        return (
                          <video
                            key={index}
                            controls
                            style={{ width: '75%', marginBottom: '1vh' }}
                          >
                            <source src={file.url} type="video/mp4" />
                          </video>
                        );
                      } else {
                        return (
                          <li key={index}>
                            <a href={file.url} target="_blank" rel="noreferrer">
                              <PaperClipOutlined /> {file.name}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  });
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(ReportStepsDetail);
