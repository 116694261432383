import React from 'react';

import '../themes/ranking-podium.less';
import { Avatar, Card, Alert, Image } from 'antd';

const PodiumCard = ({ position, data }) => {
  let containerClass = '';

  if (position === 1) {
    containerClass = 'first';
  } else if (position === 2) {
    containerClass = 'second';
  } else if (position === 3) {
    containerClass = 'third';
  }

  return (
    <div className={`podium-step ${containerClass} ranking-text`}>
      <div className="label">
        <Avatar
          src={data.avatar_thumbnail_s_url}
          className="avatar-container"
        />
        <h5 style={{ fontWeight: 700 }}>{data.username}</h5>
        <div className="score-container">
          <p>{data.score}</p>
          <p>Points</p>
        </div>
      </div>
      <div className="bar">
        <Image
          width={60}
          preview={false}
          src={`/images/ranking/trophy-p${position}.png`}
        />
      </div>
    </div>
  );
};

const RankingPodium = (props) => {
  const { ranking } = props;

  if (ranking.length < 3) {
    return (
      <Alert
        message="There is not enough information to show the podium"
        type="info"
        showIcon
      />
    );
  }

  const [first, second, third] = ranking;
  return (
    <Card className="podium-card">
      <div className="podium">
        <PodiumCard position={2} data={second} />
        <PodiumCard position={1} data={first} />
        <PodiumCard position={3} data={third} />
      </div>
    </Card>
  );
};

export default RankingPodium;
