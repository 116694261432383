import React, { Component } from 'react';

import { Image, Spin } from 'antd';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { isCompanyMode } from '../utils/General';

class ProfileComp extends Component {
  render() {
    const { numberOfPrograms, currentUser, numberOfReports, t } = this.props;
    const backgroundImage = isCompanyMode(currentUser)
      ? 'url(' + process.env.PUBLIC_URL + '/images/bk-company.png'
      : 'url(' + process.env.PUBLIC_URL + '/images/bk-hacker.png';

    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-soft-primary">
            <Row>
              <Col xs="12">
                <div
                  style={{
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundImage: backgroundImage,
                  }}
                >
                  <h4
                    style={{
                      paddingTop: '15px',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    {currentUser.firstname + ' ' + currentUser.lastname}
                  </h4>
                  <p
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      paddingBottom: '20px',
                    }}
                  >
                    {!isCompanyMode(currentUser)
                      ? 'Hacker CyScope'
                      : currentUser.currentCompany.name}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody className="pt-1 pb-2">
            <Row>
              <Col xs="4" sm="4" style={{ padding: '0 0' }}>
                <div style={{ marginTop: '-50px' }}>
                  <Image
                    src={currentUser.avatarThumbnailSUrl}
                    preview={false}
                    className="img-thumbnail rounded-circle"
                    style={{
                      height: '85px',
                      width: '85px',
                      margin: '15px 15px',
                    }}
                  />
                </div>
              </Col>

              <Col xs="8" sm="8" style={{ padding: '0 5px' }}>
                <div className="container-counts">
                  <Row>
                    <Col xs="6">
                      <Spin spinning={this.props.loading}>
                        <h5
                          style={{
                            padding: '5px 0px',
                            height: '25%',
                            textAlign: 'center',
                            fontSize: '1.4rem',
                            fontWeight: '600',
                          }}
                        >
                          {numberOfPrograms}
                        </h5>
                      </Spin>
                      <p
                        style={{
                          padding: '0px 0px',
                          fontSize: '1rem',
                          textAlign: 'center',
                          fontWeight: '500',
                        }}
                      >
                        {numberOfPrograms === 1
                          ? t('programs.verbose_name')
                          : t('programs.verbose_name_plural')}
                      </p>
                    </Col>
                    <Col xs="6">
                      <Spin spinning={this.props.loading}>
                        <h5
                          style={{
                            padding: '5px 0px',
                            height: '25%',
                            textAlign: 'center',
                            fontSize: '1.4rem',
                            fontWeight: '600',
                          }}
                        >
                          {numberOfReports}
                        </h5>
                      </Spin>
                      <p
                        style={{
                          padding: '0px 0px',
                          height: '25%',
                          fontSize: '1rem',
                          textAlign: 'center',
                          fontWeight: '500',
                        }}
                      >
                        {numberOfReports === 1
                          ? t('reports.verbose_name')
                          : t('reports.verbose_name_plural')}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProfileComp);
