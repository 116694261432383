import { normalize } from 'normalizr';
import * as Action from '../redux/actions/ActionTypes';
import { routes } from '../constants/APIEndpoints';
import axios from 'axios';
import * as _ from 'lodash';
import { getCloseSessionTime } from '../redux/actions/auth';
import { reduxPersist } from '../redux/store';

export const API_ROOT = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_ROOT;
axios.defaults.timeout = 60000;

axios.defaults.withCredentials = true;

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const skip_token = [routes.SIGN_IN, routes.SIGN_UP];

const callApi = (url, config, store, responseType, params) => {
  let request = '';
  const { responseSchema = null, data, method } = config;
  if (_.includes(skip_token, url)) {
    request = {
      url,
      data,
      method,
      params,
    };
  } else {
    if (responseType !== undefined || params !== undefined) {
      request = {
        url,
        data,
        params,
        method,
        responseType,
        headers: {},
      };
    } else {
      request = {
        url,
        data,
        params,
        method,
        headers: {},
      };
    }
  }

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      const csrftoken = getCookie('csrftoken');
      config.headers['X-CSRFToken'] = csrftoken;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(getCloseSessionTime());
        reduxPersist.purge();
        window.location.href = '/login';
      } else {
        return Promise.reject({
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        });
      }
    }
  );

  return axiosInstance(request)
    .then((response) => {
      const json = response.data;
      if ([200, 201, 202, 204].indexOf(response.status) === -1) {
        return Promise.reject(json);
      }

      if (responseSchema === null) {
        return json;
      }

      const nextPageUrl = getNextPageUrl(json);
      return Object.assign({}, normalize(json, responseSchema), {
        nextPageUrl,
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getNextPageUrl = (json) => {
  if (!json.hasOwnProperty('meta')) {
    return null;
  }

  const { meta } = json;

  if (!meta.hasOwnProperty('pagination')) {
    return null;
  }

  if (meta.pagination.current_page >= meta.pagination.total_pages) {
    return null;
  }

  return meta.pagination.links.next || undefined;
};

export const CALL_API = 'Call API';

export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { schema, types, method = 'GET', data } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!data && !schema) {
    throw new Error('Specify one of the exported Schemas.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: Action.NETWORK_OPERATION_START }));
  next(actionWith({ type: requestType }));
  const config = { responseSchema: schema, data, method };
  if (callAPI.responseType !== undefined || callAPI.params !== undefined) {
    return callApi(
      endpoint,
      config,
      store,
      callAPI.responseType,
      callAPI.params
    ).then(
      (response) => {
        next(
          actionWith({
            response,
            type: successType,
          })
        );
        next(actionWith({ type: Action.NETWORK_OPERATION_END }));
      },
      (error) => {
        next(
          actionWith({
            type: failureType,
            error: error.message || 'Something bad happened',
          })
        );
        next(actionWith({ type: Action.NETWORK_OPERATION_END }));
      }
    );
  } else {
    return callApi(endpoint, config, store).then(
      (response) => {
        next(
          actionWith({
            response,
            type: successType,
          })
        );
        next(actionWith({ type: Action.NETWORK_OPERATION_END }));
      },
      (error) => {
        function getErrorContent(data, firstFieldName = null) {
          let message = [];
          if (typeof data === 'string') {
            message.push(firstFieldName ? `${firstFieldName}: ${data}` : data);
          } else if (Array.isArray(data)) {
            for (const item of data) {
              if (
                typeof item === 'object' &&
                !Array.isArray(item) &&
                Object.keys(item).length === 0
              ) {
                continue;
              }
              message.push(...getErrorContent(item, firstFieldName));
              if (firstFieldName && message.length > 0) {
                break;
              }
            }
          } else if (typeof data === 'object') {
            if (!firstFieldName) {
              for (const key in data) {
                if (key !== 'content') {
                  firstFieldName = key;
                  break;
                }
              }
            }
            for (const key in data) {
              if (Array.isArray(data[key])) {
                for (let i = 0; i < data[key].length; i++) {
                  const element = data[key][i];
                  if (
                    typeof element === 'object' &&
                    Object.keys(element).length === 0
                  ) {
                    continue;
                  }
                  message.push(...getErrorContent(element, firstFieldName));
                  if (firstFieldName && message.length > 0) {
                    break;
                  }
                }
              } else if (
                typeof data[key] === 'object' &&
                Object.keys(data[key]).length > 0
              ) {
                message.push(...getErrorContent(data[key], firstFieldName));
              } else if (typeof data[key] === 'string') {
                message.push(
                  firstFieldName ? `${firstFieldName}: ${data[key]}` : data[key]
                );
              }

              if (firstFieldName && message.length > 0) {
                break;
              }
            }
          }
          return message;
        }

        let errorMessages = [];
        if (error) {
          if (error.status === 400) {
            errorMessages = getErrorContent(error.data);
          }
          if (
            error.headers &&
            error.headers['content-type'].includes('text/html')
          ) {
            errorMessages = ['Something bad happened'];
          }
        }

        next(
          actionWith({
            type: failureType,
            error: errorMessages.join(','),
          })
        );
        next(actionWith({ type: Action.NETWORK_OPERATION_END }));
      }
    );
  }
};
