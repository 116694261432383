import { Button, Col, message, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import '../styles/profile.less';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { saveAs } from 'file-saver';
import {
  DownloadOutlined,
  CheckOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';
import { openNotification } from '../utils/General';

const TwoFactorBackupCodes = (props) => {
  const { t } = useTranslation();
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const [backupCodes, setBackupCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { history, location } = props;

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/two-factor/backup-codes/', {
          method: location.state.method,
        });
        setBackupCodes(response.data);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [location.method]);

  const handleDownload = () => {
    const blob = new Blob([backupCodes.join('\n')], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, 'backup-codes.txt');
    setIsDownloaded(true);
  };

  const handleCopy = () => {
    const code = document.getElementById('backup-code').innerText;
    navigator.clipboard.writeText(code);
    setIsCodeCopied(true);
    message.success(
      t('profile.security.2fa.setup.backup-codes.copy-message'),
      3
    );
  };

  const handleSetupDone = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.post('/two-factor/done/', {
        method: location.state.method,
      });
      openNotification(
        'success',
        t('registration.activation.activated.title'),
        t('registration.activation.activated.description')
      );
      window.location.href = '/dashboard';
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // if (isLoading || backupCodes.length === 0) {
  //   return <Spin size="large" />;
  // }

  return (
    <React.Fragment>
      <Row gutter={[8, 40]}>
        <Col>
          <h4 className="card-title-section">
            {t('profile.security.2fa.setup.backup-codes.title')}
          </h4>
          <p className="card-content">
            {t('profile.security.2fa.setup.backup-codes.description')}
          </p>
        </Col>
      </Row>
      <Row gutter={[8, 40]}>
        <Col span={18}>
          <p className="card-content mb-2">
            {t('profile.security.2fa.setup.backup-codes.codes')}
          </p>
          <div className="mb-3">
            <pre>
              <code id="backup-code">{backupCodes.join('\n')}</code>
            </pre>
          </div>
          <Button
            className="copy-button"
            onClick={handleDownload}
            disabled={isDownloaded}
          >
            {!isDownloaded ? (
              <div className="flex-inline">
                <DownloadOutlined className="mr-1" />
                <p>
                  {t('profile.security.2fa.setup.backup-codes.download-button')}
                </p>
              </div>
            ) : (
              <div className="flex-inline">
                <CheckOutlined className="mr-1" />
                <p>
                  {t(
                    'profile.security.2fa.setup.backup-codes.downloaded-button'
                  )}
                </p>
              </div>
            )}
          </Button>
          <Button
            className="copy-button ml-3"
            onClick={handleCopy}
            disabled={isCodeCopied}
          >
            {!isCodeCopied ? (
              <div className="flex-inline">
                <CopyOutlined className="mr-1" />
                <p>
                  {t('profile.security.2fa.setup.backup-codes.copy-button')}
                </p>
              </div>
            ) : (
              <div className="flex-inline">
                <CheckOutlined className="mr-1" />
                <p>
                  {t('profile.security.2fa.setup.backup-codes.copied-button')}
                </p>
              </div>
            )}
          </Button>
        </Col>
      </Row>
      <Row type="flex" justify="end" gutter={[8, 40]}>
        <Col>
          <Button
            className="button-primary"
            onClick={handleSetupDone}
            disabled={!(isDownloaded || isCodeCopied)}
          >
            <p>{t('profile.security.2fa.setup.backup-codes.continue')}</p>
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(TwoFactorBackupCodes);
