import React, { Component, useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu, Row, Col, Avatar, Dropdown, Image } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { footerUrl } from '../constants/Data';
import '../styles/dashboard.less';
import { connect } from 'react-redux';
import LoaderComponent from '../components/LoaderComponent';
import { isCompanyMode } from '../utils/General';
import CompanyChangeAdmin from '../components/CompanyChangeAdmin';
import Notifications from './Notifications';
import { getCompanies } from '../redux/actions/currentUser';
import axiosInstance from '../axiosInstance';
import WithSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';
import { getSignOut } from '../redux/actions/auth';

const { Header, Content, Footer, Sider } = Layout;

const LayoutContainer = (props) => {
  const { t } = useTranslation();
  const {
    currentUser,
    location,
    userAbility,
    history,
    avatarRequesting,
    avatarLoaded,
    dispatch,
    companies,
    isHeaderFull,
  } = props;

  const userType = currentUser.role;
  const [collapsed, setCollapsed] = useState(true);
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = () => {
    dispatch(getCompanies());
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleSignOutClick = async () => {
    try {
      axiosInstance.post('/logout/').then((response) => {
        if (response.status === 200) {
          dispatch(getSignOut()).then(() => {
            window.location.href = '/login';
          });
        }
      });
    } catch (error) {
      console.error('There was an error logging out!', error);
    }
  };

  const handleClickOutside = (event) => {
    setCollapsed(true);
  };

  const commonMenuItems = [
    { key: '/dashboard', label: 'Dashboard', path: '/dashboard' },
    {
      key: '/dashboard/programs',
      label: t('programs.title'),
      path: '/dashboard/programs',
    },
    {
      key: '/dashboard/reports',
      label: t('reports.title'),
      path: '/dashboard/reports',
    },
    { key: '/dashboard/logs', label: 'Logs', path: '/dashboard/logs' },
  ];

  const hackerMenuItems = [
    {
      key: '/dashboard/rewards',
      label: t('rewards.title'),
      path: '/dashboard/rewards',
    },
    {
      key: '/dashboard/ranking',
      label: 'Ranking',
      path: '/dashboard/ranking',
    },
    {
      key: '/dashboard/academy',
      label: t('academy.title'),
      path: '/dashboard/academy',
    },
    {
      key: '/dashboard/refer-customer',
      label: t('refer-customer.title'),
      path: '/dashboard/refer-customer',
    },
  ];

  const getMenu = (menuType) => {
    const roleMenuItems = userType === 'hacker' ? hackerMenuItems : [];

    const menuItems = [...commonMenuItems, ...roleMenuItems];

    return (
      <Menu
        mode={menuType}
        defaultSelectedKeys={[location.pathname]}
        onClick={menuType === 'inline' ? handleClickOutside : undefined}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key}>
            <NavLink activeClassName="active" to={item.path}>
              <p>{item.label}</p>
            </NavLink>
          </Menu.Item>
        ))}
        {userAbility.can('view_finance', 'Company') && (
          <Menu.Item key="/dashboard/finance">
            <NavLink activeClassName="active" to="/dashboard/finance">
              <p>{t('budget.title')}</p>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const getMenuTools = () => {
    const menu = (
      <Menu>
        <Menu.Item key="p1">
          <a
            onClick={() => {
              history.push('/user/profile');
            }}
          >
            {t('dashboard.header.profile-settings')}
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <a onClick={handleSignOutClick}>{t('dashboard.header.logout')}</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="flex-inline">
        {isCompanyMode(currentUser) && companies.length > 1 && (
          <React.Fragment>
            <span>
              {currentUser.currentCompany && isHeaderFull
                ? currentUser.currentCompany.name
                : ''}
            </span>
            <CompanyChangeAdmin companies={companies} />
          </React.Fragment>
        )}
        <Notifications currentUser={currentUser} dropdown={true} />
        <React.Fragment>
          <Avatar
            src={
              !avatarRequesting && avatarLoaded
                ? currentUser.avatarThumbnailSUrl
                : ''
            }
          />
          <span>{currentUser.username}</span>
        </React.Fragment>
        <Dropdown overlay={menu} trigger={['click']}>
          <a>
            <Image
              src={'/images/settings.png'}
              preview={false}
              className="notifications-icon"
            />
          </a>
        </Dropdown>
      </div>
    );
  };

  return currentUser.firstname === null ? (
    <LoaderComponent main={true} label={false} />
  ) : isHeaderFull ? (
    <Layout className="layout-container">
      <Header id="header-horizontal">
        <Row>
          <Col xs={24} lg={3} xl={3}>
            <Image
              src={'/images/logo-cyscope.svg'}
              preview={false}
              className="logo-container"
            />
          </Col>
          <Col xl={14}>{getMenu('horizontal')}</Col>
          <Col className="user-tools-menu">{getMenuTools()}</Col>
        </Row>
      </Header>
      <Content className="app-content">{props.children}</Content>
      <Footer id="footer">
        <Row className="footer-container">
          <Col
            sm={{ span: 24 }}
            md={{ span: 16, offset: 4 }}
            lg={{ span: 16, offset: 4 }}
          >
            <Row>
              <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                <p className="footer-title">
                  <strong>{t('dashboard.footer.companies')}</strong>
                </p>

                <p className="footer-links">
                  <a
                    href={footerUrl.companies.bugbounty}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bug Bounty
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    href={footerUrl.companies.ptaas}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Pentest as a Service
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.companies.faq}
                  >
                    {t('dashboard.footer.faq')}
                  </a>
                </p>
              </Col>
              <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                <p className="footer-title">
                  <strong>Hackers</strong>
                </p>

                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/dashboard/ranking"
                  >
                    Ranking
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.hackers.hacktivities}
                  >
                    {t('dashboard.footer.hacktivities')}
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={t('dashboard.footer.faq-url')}
                  >
                    {t('dashboard.footer.faq')}
                  </a>
                </p>
              </Col>
              <Col sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                <p className="footer-title">
                  <strong>Legal</strong>
                </p>

                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.legal.TAC}
                  >
                    {t('dashboard.footer.terms')}
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.legal.agreement}
                  >
                    {t('dashboard.footer.agreement')}
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.legal.disclosure}
                  >
                    {t('dashboard.footer.disclosure')}
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.legal.privacy}
                  >
                    {t('dashboard.footer.privacy-policy')}
                  </a>
                </p>
                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={footerUrl.legal.ranking}
                  >
                    {t('dashboard.footer.ranking-system')}
                  </a>
                </p>
              </Col>
              <Col sm={{ span: 3 }} md={{ span: 3 }} lg={{ span: 3 }}>
                <p className="footer-title">
                  <strong>{t('dashboard.footer.contacts')}</strong>
                </p>

                <p className="footer-links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:info@cyscope.io"
                  >
                    info@cyscope.io
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <p className="footer-text text-right">
              Copyright @{new Date().getFullYear()} Dreamlab Technologies
            </p>
          </Col>
        </Row>
      </Footer>
    </Layout>
  ) : (
    <Layout
      className={
        !collapsed ? 'layout-container sider-expanded' : 'layout-container'
      }
    >
      <div id="header-inline" className="header-inline">
        <Sider
          className={`sider ${!collapsed && 'expanded'}`}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <Image
            src={'/images/logo-cyscope.svg'}
            preview={false}
            className="logo-container mb-3"
          />
          {getMenu('inline')}
        </Sider>
      </div>
      <Layout>
        <Header>
          <Row>
            <Col xs={1} id="header-inline" style={{ right: '30px' }}>
              <LegacyIcon
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={toggle}
                style={{ color: 'white' }}
              />
            </Col>
            <Col xs={23} id="header-horizontal">
              <div className="user-tools-menu" style={{ right: '-20px' }}>
                {getMenuTools()}
              </div>
            </Col>
          </Row>
        </Header>
        <Content onClick={handleClickOutside}>{props.children}</Content>
        <Footer id="footer">
          <Row gutter={24}>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <p className="footer-text text-right">
                Copyright @{new Date().getFullYear()} Dreamlab Technologies
              </p>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapSizesToProps = ({ width }) => ({
  isHeaderFull: width > 1200,
});

const mapStateToProps = (state) => ({
  hasSession: state.auth.loggedIn,
  currentUser: state.currentUser,
  userAbility: state.ability,

  avatarRequesting: state.currentUser.avatarRequesting,
  avatarLoaded: state.currentUser.avatarLoaded,
  companies: state.currentUser.companies,
});

export default WithSizes(mapSizesToProps)(
  connect(mapStateToProps)(LayoutContainer)
);
