import {
  Row,
  Col,
  Button,
  Card,
  Avatar,
  Menu,
  Dropdown,
  notification,
  Popconfirm,
  Image,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import ProgramManagersModal from './ProgramManagersModal';
import ProgramInfo from './ProgramInfo';
import axiosInstance from '../axiosInstance';
import { connect } from 'react-redux';
import { subject } from '@casl/ability';
import { isCompanyMode } from '../utils/General';
import LoaderComponent from './LoaderComponent';

const ProgramManagers = (props) => {
  const [companyUsers, setCompanyUsers] = useState([]);
  const [isLoadingCompanyUsers, setIsLoadingCompanyUsers] = useState(false);

  const [programUsers, setProgramUsers] = useState([]);
  const [isLoadingProgramUsers, setIsLoadingProgramUsers] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  // Function to fetch data
  const fetchProgramUsers = async () => {
    try {
      setIsLoadingProgramUsers(true);

      const params = {
        role__in: ['viewer', 'admin', 'analyst'].join(','),
      };
      const response = await axiosInstance.get(
        `/programs/${props.match.params.id}/users/`,
        { params }
      );

      setProgramUsers(response.data.results);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setIsLoadingProgramUsers(false);
    }
  };

  const fetchCompanyUsers = async () => {
    try {
      setIsLoadingCompanyUsers(true);
      const response = await axiosInstance.get(
        `/programs/${props.match.params.id}/company-users/`
      );
      setCompanyUsers(response.data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setIsLoadingCompanyUsers(false);
    }
  };

  useEffect(() => {
    fetchProgramUsers();
    fetchCompanyUsers();
  }, []);

  const updateUsers = () => {
    fetchProgramUsers();
  };

  const openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const removeUser = async (id) => {
    try {
      await axiosInstance.delete(
        `/programs/${props.match.params.id}/users/${id}/`
      );
      setTimeout(
        () =>
          openNotification(
            'success',
            t('notifications.title.success'),
            t('notifications.programs.remove-user')
          ),
        0
      );
      updateUsers();
    } catch (error) {
      console.error('There was a problem with operation:', error);
    }
  };
  const companyUserList = companyUsers.map((companyUser) => {
    const isAdded = programUsers.some(
      (programUser) => programUser.user === companyUser.user
    );
    return { ...companyUser, isAdded };
  });

  return (
    <Row gutter={24}>
      <Col xs={24} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 10 }}>
        <ProgramInfo
          program={props.program}
          companyMode={isCompanyMode(props.currentUser)}
          showChangeStatus={props.ability.can(
            'update_status',
            subject('Program', props.program)
          )}
        />
      </Col>
      <Col xs={24} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 14 }}>
        <Card hoverable={false} id="CompanyEmployees">
          <Row style={{ marginBottom: '32px' }}>
            <Col xl={12}>
              <h4 className="section-subtitle">
                {t('programs.tabs.users.label')}
              </h4>
            </Col>
            <Col xl={12} className="text-right">
              <Button
                className="btn-hacker"
                onClick={() => setIsModalVisible(true)}
                hidden={!props.canAddUser}
                style={{
                  fontSize: 15,
                  padding: '0px 25px',
                  height: '40px',
                  backgroundColor: '#090036',
                  marginLeft: '15px',
                }}
              >
                <Image
                  width={20}
                  src="/images/add-user.png"
                  preview={false}
                  style={{ marginRight: '10px' }}
                />
                {t('programs.tabs.users.add')}
              </Button>

              {isModalVisible && (
                <ProgramManagersModal
                  id={props.match.params.id}
                  users={companyUserList.filter(
                    (item) => item.role === 'standard'
                  )}
                  programUsers={programUsers}
                  modalVisible={isModalVisible}
                  closeModal={() => setIsModalVisible(false)}
                  updateUsers={updateUsers}
                />
              )}
            </Col>
          </Row>
          {isLoadingProgramUsers ? (
            <Col span={24}>
              <LoaderComponent label={false} />
            </Col>
          ) : (
            <Row gutter={[2, 2]}>
              {programUsers.map((user, index) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="delete">
                      <Popconfirm
                        title={t('programs.tabs.users.delete-confirm')}
                        onConfirm={() => removeUser(user.id)}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <a>{t('programs.tabs.users.delete')}</a>
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                );

                return (
                  <Col span={24} key={index}>
                    <Card>
                      <Row align="middle">
                        <Col span={4}>
                          <Avatar
                            src={user.user.avatar_thumbnail_s_url}
                            size="large"
                            shape="circle"
                          />
                        </Col>
                        <Col span={8}>
                          <p style={{ fontWeight: 'bold' }}>{user.user}</p>
                        </Col>
                        <Col span={6}>
                          <p style={{ fontWeight: 'bold' }}>
                            {t(`roles.program.${user.role}`)}
                          </p>
                        </Col>
                        <Col span={6} className="text-right">
                          <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomCenter"
                            disabled={!props.canRemoveUser}
                          >
                            <Image
                              src="/images/employee-options.png"
                              style={{ cursor: 'pointer' }}
                              height={25}
                              preview={false}
                            />
                          </Dropdown>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
              {isLoadingCompanyUsers ? (
                <Col span={24}>
                  <LoaderComponent label={false} />
                </Col>
              ) : (
                companyUsers
                  .filter((item) => item.role === 'admin')
                  .map((user, index) => (
                    <Col key={`company-${index}`} span={24}>
                      <Card>
                        <Row align="middle">
                          <Col span={4}>
                            <Avatar
                              src={user.user.avatar_thumbnail_s_url}
                              size="large"
                              shape="circle"
                            />
                          </Col>
                          <Col span={8}>
                            <p style={{ fontWeight: 'bold' }}>{user.user}</p>
                          </Col>
                          <Col span={6}>
                            <p style={{ fontWeight: 'bold' }}>
                              {t(`roles.company.${user.role}`)}
                            </p>
                          </Col>
                          <Col span={6} className="text-right">
                            {/* Empty column for alignment */}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))
              )}
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

ProgramManagers.defaultProps = {};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  ability: state.ability,
});

export default connect(mapStateToProps)(ProgramManagers);
