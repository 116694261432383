import { Card, Col, Image, Row } from 'antd';
import React, { useState, useEffect } from 'react';

import '../styles/profile.less';
import { useTranslation } from 'react-i18next';

const TwoFactor = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row>
        <Image
          src={'/images/logo-cysteria-azul.svg'}
          preview={false}
          className="logo-container2"
        />
      </Row>
      <Row id="two-factor">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 18 }}
          md={{ span: 18 }}
          lg={{ span: 18 }}
          xl={{ span: 10 }}
        >
          <Card
            title={t('profile.security.2fa.setup.title')}
            className="centered-title-card"
          >
            {props.children}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TwoFactor;
