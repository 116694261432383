import React from 'react';
import '../../node_modules/react-vis/dist/style.css';
import { MONTHS } from '../constants/Data';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Crosshair,
} from 'react-vis';
import { ExceptionOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

class PaymentStatisticsBarSeries extends React.Component {
  state = {
    crosshairValues: [],
  };

  dataFormat() {
    const { t, data } = this.props;
    const { stats } = data;

    const months = MONTHS.map((item) => {
      let _item = t(`months.${item}`);
      return _item.charAt(0).toUpperCase() + _item.slice(1);
    });

    const collectionData2 = stats.map((item, index) => {
      let singleObj = {};
      singleObj['x'] = months[index];
      singleObj['y'] = item;
      return singleObj;
    });
    return {
      labels: months,
      data: collectionData2,
    };
  }

  calculateYMax = (value) => {
    const rules = [
      { threshold: 10, multiplier: 1 },
      { threshold: 100, multiplier: 10 },
      { threshold: 1000, multiplier: 50 },
      { threshold: 10000, multiplier: 500 },
      { threshold: Infinity, multiplier: 1000 },
    ];

    for (let i = 0; i < rules.length; i++) {
      if (value <= rules[i].threshold) {
        return Math.ceil(value / rules[i].multiplier) * rules[i].multiplier;
      }
    }
    return value;
  };
  handleNearestX = (data) => {
    if (data.y !== 0) {
      this.setState({ crosshairValues: [data] });
    } else {
      this.setState({ crosshairValues: [] });
    }
  };

  handleMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  render() {
    const { t } = this.props;
    const data = this.dataFormat();

    const BarSeries = VerticalBarSeries;
    const { crosshairValues } = this.state;
    const maxY = data['data'].reduce((max, object) => {
      return object.y > max ? object.y : max;
    }, 0);

    const yDomainMaxY = this.calculateYMax(maxY);
    const isPtaasLabel = this.props.isPtaas
      ? t('budget.ptaas.converter.credits')
      : 'USD';

    return (
      <React.Fragment>
        {maxY > 0 ? (
          <React.Fragment>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflowY: 'hidden',
                overflowX: 'auto',
              }}
            >
              <XYPlot
                width={650}
                height={250}
                xType="ordinal"
                yDomain={[0, yDomainMaxY]}
                margin={{ left: 70 }}
                onMouseLeave={this.handleMouseLeave}
              >
                <VerticalGridLines
                  style={{ strokeWidth: '0', stroke: 'gray' }}
                />
                <HorizontalGridLines
                  style={{ strokeWidth: '0.2', stroke: 'gray' }}
                />
                <XAxis style={{ fontSize: '10px' }} />
                <YAxis style={{ fontSize: '10px' }} />
                <BarSeries
                  data={data['data']}
                  onNearestX={this.handleNearestX}
                />
                {crosshairValues.length > 0 && (
                  <Crosshair
                    values={crosshairValues}
                    style={{ line: { background: 'none' } }}
                  >
                    <div
                      style={{
                        background: 'black',
                        padding: '5px',
                        borderRadius: '5px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <p style={{ color: 'white' }}>
                        {`${t('month')}: ${t(`all-months.${crosshairValues[0].x}`)}`}
                      </p>
                      <p style={{ color: 'white' }}>
                        {`${t('paid')}: ${crosshairValues[0].y} ${isPtaasLabel}`}
                      </p>
                    </div>
                  </Crosshair>
                )}
              </XYPlot>
            </div>
            <div style={{ position: 'absolute', top: '100px', left: '10px' }}>
              <p style={axisYTitleStyle}>{isPtaasLabel}</p>
            </div>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                bottom: '-1vh',
                zIndex: '1000',
              }}
            >
              <p style={axisXTitleStyle}>{t('month_plural')}</p>
            </div>
          </React.Fragment>
        ) : (
          <div className="text-center mt-3">
            <ExceptionOutlined style={{ fontSize: '32px', marginBottom: '20px' }} />
            <p style={{ fontWeight: '500' }}>{t('no-data')}</p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const axisYTitleStyle = {
  fontSize: '15px',
  fontWeight: 'bold',
  marginBottom: '8px',
  transform: 'rotate(-90deg)',
  transformOrigin: 'center',
  position: 'absolute',
  left: '-20px',
  top: '50%',
};

const axisXTitleStyle = {
  fontWeight: 'bold',
  fontSize: '15px',
  marginTop: '8px',
  position: 'absolute',
  left: '40%',
  bottom: '-15px',
};

export default withTranslation()(PaymentStatisticsBarSeries);
