import * as Action from '../actions/ActionTypes';

const initialState = {
  requesting: false,
  hasError: false,
};

class CommentReducer {
  static reduce(state = initialState, action) {
    if (CommentReducer[action.type]) {
      return CommentReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.CREATE_COMMENT_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.CREATE_COMMENT_SUCCESS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.CREATE_COMMENT_ERRORS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
    };
  }

  static [Action.CLEAR_COMMENT](state, action) {
    return initialState;
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default CommentReducer.reduce;
