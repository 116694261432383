import React from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import withSizes from 'react-sizes';
import { mapSizes } from '../mapSizes';
const FormItem = Form.Item;

class DynamicUrlList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values:
        !props.values || props.values.length === 0
          ? [{ url: '' }]
          : props.values,
    };
  }

  handleRemove = (index) => {
    const newValues = this.state.values.filter((_, i) => i !== index);
    this.setState({ values: newValues }, () => {
      this.props.form.setFieldsValue({ urls: newValues });
    });
  };

  handleAdd = () => {
    const newValues = [...this.state.values, { url: '' }];
    this.setState({ values: newValues }, () => {
      this.props.form.setFieldsValue({ urls: newValues });
    });
  };

  handleChange = (index, event) => {
    const newValues = this.state.values.map((input, i) =>
      i === index ? { ...input, url: event.target.value } : input
    );
    this.setState({ values: newValues }, () => {
      this.props.form.setFieldsValue({ urls: newValues });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, isMobile, label } = this.props;
    const { values } = this.state;

    return values.map((value, index) => (
      <Row key={index}>
        <Col span={24}>
          <FormItem>
            {getFieldDecorator(`urls[${index}].id`, {
              initialValue: value.id,
            })(<Input type="hidden" />)}
          </FormItem>
          <FormItem label={index === 0 ? label : ''}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {getFieldDecorator(`urls[${index}].url`, {
                ...this.props.fieldOptions,
                validateTrigger: ['onChange', 'onBlur'],
                initialValue: value.url,
              })(
                <Input
                  placeholder={t('programs.form.fields.target.placeholder')}
                  style={{
                    width: isMobile ? '80%' : '90%',
                    marginRight: '8px',
                  }}
                  onChange={(event) => this.handleChange(index, event)}
                />
              )}
              {values.length > 1 && index !== 0 ? (
                <MinusCircleOutlined
                  className="btn-remove-target"
                  disabled={values.length === 1}
                  onClick={() => this.handleRemove(index)} />
              ) : (
                <Button onClick={this.handleAdd} className="btn-add-target">
                  <PlusCircleOutlined />
                </Button>
              )}
            </div>
          </FormItem>
        </Col>
      </Row>
    ));
  }
}

export default withSizes(mapSizes)(withTranslation()(DynamicUrlList));
