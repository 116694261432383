import { Card, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import React, { useState, useEffect } from 'react';
import PaymentList from '../components/PaymentList';
import { connect } from 'react-redux';
import Pagination from '../components/Pagination';
import axiosInstance from '../axiosInstance';
import LoaderComponent from '../components/LoaderComponent';

const Payments = (props) => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(null);

  // Function to fetch data
  const fetchData = async (page = 1) => {
    try {
      setIsLoading(true);
      const params = { page: page };
      const response = await axiosInstance.get('/payments/', { params });
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openNotification = (type, title, message) => {
    notification &&
      notification[type]({
        message: title,
        description: message,
      });
  };

  useEffect(() => {
    if (props.hasError) {
      setTimeout(
        () => openNotification('error', 'Error', props.errorMessage),
        0
      );
    }
  }, [props.hasError]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  // Render the component
  if (isLoading || data === null) {
    return <LoaderComponent label={false} />;
  }

  return (
    <Card style={{ paddingBottom: '0' }}>
      <PaymentList
        key="paymentList"
        payments={data.results}
        numberOfElements={data.count}
        number_of_pages={Math.ceil(data.count / 10)}
        loading={isLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data.count / 10)}
        totalItems={data.count}
        verbose={
          data.count === 1
            ? t('paginate.payments.verbose_name')
            : t('paginate.payments.verbose_name_plural')
        }
        onPageChange={handlePageChange}
      />
    </Card>
  );
};

Payments.defaultProps = {};

const mapStateToProps = (state) => ({
  hasError: state.wallet.hasError,
  requesting: state.wallet.requesting,
  errorMessage: state.wallet.errorMessage,
  hasAddedFunds: state.wallet.hasAddedFunds,
});

export default connect(mapStateToProps)(Payments);
