import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import { userPasswordResetRequest } from '../redux/actions/auth';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

import { BACKEND_BASE_URL } from '../constants/APIEndpoints';

const FormItem = Form.Item;

class PasswordResetRequestForm extends Component {
  state = {
    loadingButton: false,
    requesting: false,
  };

  componentWillReceiveProps(nextProps) {
    const { history } = this.props;

    if (this.state.requesting) {
      this.setState({ requesting: false });

      switch (nextProps.success) {
        case true:
          let $timer = setTimeout(() => {
            clearTimeout($timer);
            $timer = null;
            openNotification(
              'success',
              'Felicitaciones!',
              'Se he enviado un correo electrónico para el reestablecimiento de su contraseña.'
            );
          }, 0);

          history.push('/login/');
          break;
        case false:
          $timer = setTimeout(() => {
            clearTimeout($timer);
            $timer = null;
            openNotification(
              'error',
              'Advertencia!',
              'Ese correo electrónico no se encuentra registrado en nuestro sistema.'
            );
          }, 0);

          break;
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, dispatch } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        this.setState({ requesting: true });
        const nextUrl = `${BACKEND_BASE_URL}/users/password_reset`;
        dispatch(userPasswordResetRequest(values, { next: nextUrl }));
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem label={t('login.add-email')}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                type: 'email',
                message: t('login.add-email-message'),
              },
            ],
          })(<Input />)}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button btn-hacker block-center"
          loading={this.state.loadingButton}
        >
          {t('login.send-button')}
        </Button>
      </Form>
    );
  }
}

const WrappedPasswordResetRequestForm = Form.create()(PasswordResetRequestForm);

const mapStateToProps = (state) => ({
  success: state.auth.success,
});
export default withTranslation()(
  connect(mapStateToProps)(WrappedPasswordResetRequestForm)
);
