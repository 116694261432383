import * as Action from '../actions/ActionTypes';
import * as _ from 'lodash';

const initialState = {
  entities: [],
  hasError: false,
};

class ReportCommentsReducer {
  static reduce(state = initialState, action) {
    if (ReportCommentsReducer[action.type]) {
      return ReportCommentsReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.LIST_COMMENTS_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.LIST_COMMENTS_SUCCESS](state, action) {
    return {
      ...state,
      entities: action.response.results,
    };
  }

  static [Action.ADD_COMMENT_SUCCESS](state, action) {
    const { data } = action;

    const comments = _.uniqBy(
      _.orderBy(
        _.concat(_.values(data.comments), state.entities),
        ['created_at'],
        ['asc']
      ),
      'id'
    );

    return {
      ...state,
      entities: comments,
    };
  }

  static [Action.LIST_COMMENTS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
    };
  }

  static [Action.LIST_EMPTY_COMMENTS](state, action) {
    return {
      ...state,
      hasError: true,
    };
  }

  static [Action.DELETE_COMMENT_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.DELETE_COMMENT_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default ReportCommentsReducer.reduce;
