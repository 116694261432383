import React, { Component, useState } from 'react';
import { Col, Row, Button, Modal, Checkbox } from 'antd';
import { connect } from 'react-redux';
import ProgramListResume from '../components/ProgramListResume';
import Pagination from '../components/Pagination';
import CreateProgramButton from '../components/CreateProgramButton';
import CompanyProgramsEmpty from '../components/CompanyProgramsEmpty';
import HackerProgramsEmpty from '../components/HackerProgramsEmpty';
import Activities from '../containers/Activities';
import ReportStatistics from '../components/ReportStatistics';
import ProfileComp from '../components/ProfileComp';
import HackerDashboardCards from '../components/HackerDashboardCards';
import FilterProgramForm from '../components/FilterProgramForm';
import AntPaginate from '../components/AntPaginate';

import { withTranslation, useTranslation } from 'react-i18next';

import debounce from 'lodash.debounce';
import axiosInstance from '../axiosInstance';
import CreateProgramModal from '../components/CreateProgramModal';
import { Can } from '@casl/react';
import { isCompanyMode } from '../utils/General';
import { footerUrl } from '../constants/Data';
import '../styles/overview.less';
import { handleAxiosError } from '../utils/Http';

const CustomCheckbox = (props) => {
  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => {
    setChecked(!checked);
    props.onClick(!checked);
  };

  return <Checkbox onClick={toggleCheckbox} />;
};

const JoinProgramModal = (props) => {
  const { t } = useTranslation();

  const [hasAgreed, setHasAgreed] = useState(false);

  const checkboxClicked = (checked) => {
    setHasAgreed(checked);
  };

  return (
    <Modal
      title={`${t('programs.warning.important')}`}
      open={props.visible}
      footer={null}
      onCancel={props.onCancel}
      className="custom-modal"
    >
      <Row>
        <Col span={22} offset={1}>
          <p className="p-danger">{t('programs.warning.remind')}</p>

          <p>
            {t('programs.warning.condition-join1')} <br />
            {t('programs.warning.condition-join2')} <br />
            {t('programs.warning.condition-join3')} <br />
            {t('programs.warning.condition-join4')} <br />
            {t('programs.warning.condition-join5')}
            <a href={footerUrl.legal.TAC} rel="noreferrer" target="_blank">
              {t('programs.warning.t&c')}
            </a>
          </p>

          <div className="mt-2">
            <p>{t('programs.warning.condition-join6')} </p>
            <a
              href={footerUrl.legal.disclosure}
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#ee4b4b',
                textDecoration: 'underline',
                wordBreak: 'break-all',
              }}
            >
              {t('programs.warning.disclosure')}
            </a>
          </div>

          <Row style={{ marginTop: 20 }}>
            <Col span={2}>
              <CustomCheckbox onClick={checkboxClicked} />
            </Col>
            <Col span={20}>
              <p>{t('programs.warning.condition-join7')}</p>
            </Col>
          </Row>

          <Button
            className="button-primary all-screen mt-3"
            disabled={!hasAgreed}
            onClick={() => props.onJoinProgram(props.programId)}
          >
            <span className="p-button">
              {t('programs.warning.continue-button')}
            </span>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfReports: 0,
      statistics: {
        currentUserYears: [],
        stats: {},
      },

      userPrograms: [],
      userProgramsLoading: false,
      userProgramsTotalItems: 0,

      userProgramsFilters: {
        page: 1,
        search: '',
        status: null,
      },

      activities: [],
      activitiesLoading: false,
      activitiesTotalItems: 0,
      activitiesFilters: {
        page: 1,
      },

      userProgramInvitations: [],
      userProgramInvitationsLoading: false,
      userProgramInvitationsTotalItems: 0,

      showCreateProgramModal: false,
      selectedProgramType: null,

      showJoinProgramModal: false,
      selectedProgramToJoin: null,
    };
    this.handleUserProgramsSearchChange = debounce(
      this.handleUserProgramsSearchChange,
      300
    );
  }

  static defaultProps = {
    itemsPerPage: 10,
  };

  handleUserProgramsFilterChange = (name, value) => {
    this.setState(
      (prevState) => ({
        userProgramsFilters: {
          ...prevState.userProgramsFilters,
          page: 1,
          [name]: value,
        },
      }),
      () => {}
    );
  };

  handleUserProgramsPageChange = (page) => {
    this.setState(
      (prevState) => ({
        userProgramsFilters: {
          ...prevState.userProgramsFilters,
          page: page,
        },
      }),
      () => {}
    );
  };

  handleActivitiesPageChange = (page) => {
    this.setState(
      (prevState) => ({
        activitiesFilters: {
          ...prevState.activitiesFilters,
          page: page,
        },
      }),
      () => {}
    );
  };

  openCreateProgramModal = () => {
    this.setState({ showCreateProgramModal: true });
  };

  closeCreateProgramModal = () => {
    this.setState({ showCreateProgramModal: false });
  };

  handleCreateProgramSelectProgramType = (ptype) => {
    this.setState({ selectedProgramType: ptype });
  };

  handleCreateProgram = () => {
    this.props.history.push({
      pathname: `/dashboard/program/${this.state.selectedProgramType}/create`,
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.userProgramsFilters.page !==
        prevState.userProgramsFilters.page ||
      this.state.userProgramsFilters.status !==
        prevState.userProgramsFilters.status ||
      this.state.userProgramsFilters.search !==
        prevState.userProgramsFilters.search
    ) {
      if (this.props.currentUser && !isCompanyMode(this.props.currentUser)) {
        this.fetchJoinedPrograms();
      } else {
        this.fetchPrograms();
      }
    }
    if (
      this.state.activitiesFilters.page !== prevState.activitiesFilters.page
    ) {
      this.fetchActivities();
    }
  }

  handleJoinProgram = (pid) => {
    const { history } = this.props;
    this.setState({ joinProgramLoading: true });

    const params = {};

    axiosInstance
      .post(`/programs/${pid}/join/`, params)
      .then((response) => {
        this.setState({ joinProgramLoading: false });
        history.push({ pathname: `/dashboard/programs/${pid}` });
      })
      .catch((error) => {
        handleAxiosError(error);
        this.setState({ joinProgramLoading: false });
      });
  };

  fetchActivities() {
    this.setState({ activitiesLoading: true });

    const { activitiesFilters } = this.state;
    const params = {
      ...activitiesFilters,
    };

    axiosInstance
      .get(`/activities/`, { params })
      .then((response) => {
        this.setState({
          activities: response.data.results,
          activitiesTotalItems: response.data.count,
          activitiesLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ activitiesLoading: false });
      });
  }

  fetchPrograms() {
    this.setState({ userProgramsLoading: true });

    const { userProgramsFilters } = this.state;
    const params = {
      ...userProgramsFilters,
    };

    axiosInstance
      .get(`/programs/`, { params })
      .then((response) => {
        this.setState({
          userPrograms: response.data.results,
          userProgramsTotalItems: response.data.count,
          userProgramsLoading: false,
          numberOfReports: response.data.number_of_reports,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ userProgramsLoading: false });
      });
  }

  fetchJoinedPrograms() {
    this.setState({ userProgramsLoading: true });

    const { userProgramsFilters } = this.state;
    const params = {
      joined: true,
      ...userProgramsFilters,
    };

    axiosInstance
      .get(`/programs/`, { params })
      .then((response) => {
        this.setState({
          userPrograms: response.data.results,
          userProgramsTotalItems: response.data.count,
          userProgramsLoading: false,
          numberOfReports: response.data.number_of_reports,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ userProgramsLoading: false });
      });
  }

  fetchUserProgramInvitations() {
    this.setState({ userProgramInvitationsLoading: true });

    const params = {
      pending_invitations: true,
    };

    axiosInstance
      .get(`/programs/`, { params })
      .then((response) => {
        this.setState({
          userProgramInvitations: response.data.results,
          userProgramInvitationsTotalItems: response.data.count,
          userProgramInvitationsLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ userProgramInvitationsLoading: false });
      });
  }

  fetchStatistics(params) {
    this.setState({ statisticsLoading: true });

    axiosInstance
      .get(`/statistics/`, { params })
      .then((response) => {
        this.setState({
          statistics: response.data,
          statisticsLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ statisticsLoading: false });
      });
  }

  fetchData() {
    const { currentUser } = this.props;

    if (currentUser && !isCompanyMode(currentUser)) {
      this.fetchJoinedPrograms();
      this.fetchUserProgramInvitations();
    } else {
      this.fetchPrograms();
      this.fetchStatistics();
    }
    this.fetchActivities();
  }

  createProgramDraft = () => {
    const { history } = this.props;
    history.push({ pathname: '/dashboard/program/create' });
  };

  handlePaginateData = (data) => {
    this.setState({ currentPage: data });
  };

  handleReportStatisticsFiltersChange = (params) => {
    this.fetchStatistics(params);
  };

  handleUserProgramsSearchChange = (value) => {
    this.handleUserProgramsFilterChange('search', value);
  };

  render() {
    const { currentUser, userAbility } = this.props;

    const canAddBugbounty = userAbility.can('add_bugbounty_program', 'Company');
    const canAddPtaas = userAbility.can('add_ptaas_program', 'Company');

    const { t } = this.props;

    let rewards = currentUser.profile.rewarded;
    let ranking = currentUser.profile.ranking;
    let score = currentUser.profile.score;

    const { search, status, page } = this.state.userProgramsFilters;
    const isFiltering = search !== '' || status !== null || page !== 1;

    return (
      <React.Fragment>
        <Row>
          <Col
            xl={{ span: 18, offset: 3 }}
            lg={{ span: 22, offset: 1 }}
            xs={{ span: 22, offset: 1 }}
          >
            <Row className="section-title">
              <Col span={24}>
                <h1>
                  <b>Dashboard</b>
                </h1>
                <h4>
                  {!isCompanyMode(currentUser)
                    ? t('dashboard.hacker-title')
                    : t('dashboard.company-title')}{' '}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col lg={8} sm={24} xs={24}>
                <ProfileComp
                  loading={this.state.userProgramsLoading}
                  currentUser={currentUser}
                  numberOfPrograms={this.state.userProgramsTotalItems}
                  numberOfReports={this.state.numberOfReports}
                />
                <div className="d-none d-lg-block">
                  <h6 style={{ fontSize: '16px', marginTop: '2vh' }}>
                    <b>{t('dashboard.activities')}</b>{' '}
                  </h6>
                  <Activities
                    loading={this.state.activitiesLoading}
                    activities={this.state.activities}
                    renderPagination={() => (
                      <AntPaginate
                        currentPage={this.state.activitiesFilters.page}
                        totalItems={this.state.activitiesTotalItems}
                        pageSize={this.props.itemsPerPage}
                        onPageChange={this.handleActivitiesPageChange}
                        verbose={t('paginate.activities')}
                      />
                    )}
                  />
                </div>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                lg={{ span: 14, offset: 2 }}
                className="pt-4 pt-lg-0"
              >
                {!isCompanyMode(currentUser) ? (
                  <HackerDashboardCards
                    ranking={ranking}
                    score={score}
                    rewards={rewards}
                  />
                ) : (
                  <ReportStatistics
                    loading={this.state.statisticsLoading}
                    programs={this.state.userPrograms}
                    companyMode={isCompanyMode(this.props.currentUser)}
                    statistics={this.state.statistics}
                    onFiltersChange={this.handleReportStatisticsFiltersChange}
                  />
                )}
                {this.state.userProgramInvitations &&
                  this.state.userProgramInvitations.length > 0 && (
                    <div className="mt-5">
                      <h6>
                        <b>{t('dashboard.programs.pending')}</b>
                      </h6>
                      <div className="app-container">
                        <ProgramListResume
                          companyMode={isCompanyMode(currentUser)}
                          loading={this.state.userProgramInvitationsLoading}
                          programs={this.state.userProgramInvitations}
                          totalItems={
                            this.state.userProgramInvitationsTotalItems
                          }
                          itemsPerPage={this.props.itemsPerPage}
                          onItemClick={(programId) => {
                            this.setState({
                              showJoinProgramModal: true,
                              selectedProgramToJoin: programId,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                <br />

                <div className="app-container">
                  <ProgramListResume
                    companyMode={isCompanyMode(currentUser)}
                    loading={this.state.userProgramsLoading}
                    programs={this.state.userPrograms}
                    filtering={isFiltering}
                    renderFilters={() => (
                      <FilterProgramForm
                        showInactive={isCompanyMode(this.props.currentUser)}
                        showClosed={isCompanyMode(this.props.currentUser)}
                        filters={this.state.userProgramsFilters}
                        onSearchChange={this.handleUserProgramsSearchChange}
                        onFilterChange={this.handleUserProgramsFilterChange}
                        renderCreateButton={() => (
                          <Can
                            I="add_program"
                            on="Company"
                            ability={this.props.userAbility}
                          >
                            {() => (
                              <CreateProgramButton
                                onClick={this.openCreateProgramModal}
                              />
                            )}
                          </Can>
                        )}
                      />
                    )}
                    renderPagination={() =>
                      this.state.userPrograms &&
                      this.state.userPrograms.length > 0 && (
                        <Pagination
                          title=""
                          currentPage={this.state.userProgramsFilters.page}
                          totalPages={Math.ceil(
                            this.state.userProgramsTotalItems /
                              this.props.itemsPerPage
                          )}
                          totalItems={this.state.userProgramsTotalItems}
                          verbose={
                            this.state.userProgramsTotalItems === 1
                              ? t('programs.verbose_name')
                              : t('programs.verbose_name_plural')
                          }
                          onPageChange={this.handleUserProgramsPageChange}
                        />
                      )
                    }
                    renderEmpty={() =>
                      this.props.currentUser &&
                      isCompanyMode(this.props.currentUser) ? (
                        <CompanyProgramsEmpty
                          onClick={this.openCreateProgramModal}
                        />
                      ) : (
                        <HackerProgramsEmpty />
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <CreateProgramModal
          closeModal={this.closeCreateProgramModal}
          openModal={this.openCreateProgramModal}
          visible={this.state.showCreateProgramModal}
          selectedProgramType={this.state.selectedProgramType}
          isPtaasAvailable={canAddPtaas}
          isBugbountyAvailable={canAddBugbounty}
          handleSelectProgramType={this.handleCreateProgramSelectProgramType}
          handleCreateProgram={this.handleCreateProgram}
        />
        <JoinProgramModal
          visible={this.state.showJoinProgramModal}
          programId={this.state.selectedProgramToJoin}
          onCancel={() => this.setState({ showJoinProgramModal: false })}
          onJoinProgram={this.handleJoinProgram}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  userAbility: state.ability,
});
export default withTranslation()(connect(mapStateToProps)(Overview));
