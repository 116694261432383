import { Button, Tooltip, Row, Card, Col } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import React from 'react';
import Markdown from 'react-remarkable';
import { MarkOptions } from '../utils/MarkdownHelper';

const ProgramDescription = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div
        className="description"
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div className="markdown-input">
          <Markdown
            source={props.program.description ? props.program.description : ''}
            options={MarkOptions}
          />
        </div>
      </div>

      <br />
      {props.editable && (
        <Row>
          <Col span={24}>
            <Link
              to={{
                pathname: `/dashboard/program/${props.program.id}/update`,
                state: {
                  isEditing: true,
                  program: props.program,
                  is_ptaas: props.program.is_ptaas,
                },
              }}
            >
              <Button
                className="btn-default btn-program-item btn-block pull-right"
                size="large"
              >
                {t('programs.edit-button')}
              </Button>
            </Link>
          </Col>
        </Row>
      )}
    </Card>
  );
};

ProgramDescription.defaultProps = {};

export default ProgramDescription;
