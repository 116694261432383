import { openNotification } from './General';

export const handleAxiosError = (error) => {
  if (error.response && error.response.status === 400) {
    const data = error.response.data;
    let errorMessage = '';

    if (data.non_field_errors) {
      errorMessage = data.non_field_errors.join(', ');
      openNotification('error', 'Error', errorMessage);
    } else {
      Object.keys(data).forEach((field) => {
        if (typeof data[field] === 'string') {
          errorMessage += `${field}: ${data[field]}\n`;
        } else if (Array.isArray(data[field])) {
          errorMessage += `${field}: ${data[field].join(', ')}\n`;
        }
      });
      openNotification('error', 'Error', errorMessage);
    }
  } else if (error.response) {
    openNotification(
      'error',
      `Error ${error.response.status}`,
      error.response.statusText
    );
  } else {
    openNotification('error', 'Error', error.message);
  }
};
