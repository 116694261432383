import * as Action from '../actions/ActionTypes';

import { reduxPersist } from '../store';

const initialState = {
  loggedIn: false,
  secondFactorEnabled: false,
  backup: false,
  success: null,
  secondValidated: false,
  resetPasswordPermit: null,
  passwordChanged: null,
  requesting: false,
  requested: false,
  hasError: false,
  errorMessage: null,
  isSaved: false,
  secondFactor: { id: null, uri: null },
};

class Auth {
  static reduce(state = initialState, action) {
    if (Auth[action.type]) {
      return Auth[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.AUTH_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.AUTH_SUCCESS](state, action) {
    return {
      ...state,
      loggedIn: action.response.result,
      requesting: false,
    };
  }

  static [Action.AUTH_ERROR](state, action) {
    return {
      ...state,
      loggedIn: true,
      requesting: false,
    };
  }

  static [Action.AUTH_CLOSE_SESSION_TIME](state, action) {
    return {
      ...state,
      loggedIn: false,
    };
  }

  static [Action.SIGN_IN_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.SIGN_IN_SUCCESS](state, action) {
    return {
      ...state,
      loggedIn: true,
      secondFactorEnabled: action.response.verify,
      backup: action.response.backup,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.SIGN_IN_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      errorMessage: action.error,
      hasError: true,
    };
  }

  static [Action.SIGN_UP_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.SIGN_UP_SUCCESS](state, action) {
    const { result } = action.response;
    return {
      ...state,
      loggedIn: false, // true
      requesting: false,
      hasError: false,
      errorMessage: result,
    };
  }

  static [Action.SIGN_UP_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      errorMessage: action.error,
      hasError: true,
    };
  }

  static [Action.SIGN_OUT_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.SIGN_OUT_SUCCESS](state, action) {
    reduxPersist.purge();
    return initialState;
  }

  static [Action.SIGN_OUT_ERROR](state, action) {
    reduxPersist.purge();
    return initialState;
  }

  static [Action.PASSWORD_RESET_REQUEST](state, action) {
    return {
      ...state,
      passwordChanged: null,
    };
  }

  static [Action.PASSWORD_RESET_SUCCESS](state, action) {
    return {
      ...state,
      passwordChanged: true,
      success: true,
    };
  }

  static [Action.PASSWORD_RESET_ERROR](state, action) {
    return {
      ...state,
      passwordChanged: false,
      errorMessage: action.error,
      success: false,
    };
  }

  static [Action.PASSWORD_RESET_REQUEST_REQUEST](state, action) {
    return {
      ...state,
      requested: false,
      success: null,
    };
  }

  static [Action.PASSWORD_RESET_REQUEST_SUCCESS](state, action) {
    return {
      ...state,
      requested: true,
      success: true,
    };
  }

  static [Action.PASSWORD_RESET_REQUEST_ERROR](state, action) {
    return {
      ...state,
      requested: true,
      success: true,
      errorMessage: action.error,
    };
  }

  static [Action.PASSWORD_RESET_VALIDATE_REQUEST](state, action) {
    return {
      ...state,
      resetPasswordPermit: null,
      passwordChanged: null,
    };
  }

  static [Action.PASSWORD_RESET_VALIDATE_SUCCESS](state, action) {
    return {
      ...state,
      resetPasswordPermit: true,
    };
  }

  static [Action.PASSWORD_RESET_VALIDATE_ERROR](state, action) {
    return {
      ...state,
      resetPasswordPermit: false,
      errorMessage: action.error,
    };
  }

  static [Action.VALIDATE_QR_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      success: null,
    };
  }

  static [Action.VALIDATE_QR_SUCCESS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.VALIDATE_QR_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      success: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
  static [Action.DELETE_QR_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      success: null,
    };
  }

  static [Action.DELETE_QR_SUCCESS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.DELETE_QR_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      success: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
  static [Action.VALIDATE_SF_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      success: null,
    };
  }

  static [Action.VALIDATE_SF_SUCCESS](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: false,
      secondValidated: true,
    };
  }

  static [Action.VALIDATE_SF_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      success: false,
      hasError: true,
      errorMessage: action.error,
    };
  }
}

export default Auth.reduce;
