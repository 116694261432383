import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ChangeLanguage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { defaultLanguage, changeLanguage } = this.props;

    if (
      defaultLanguage !== prevProps.defaultLanguage &&
      defaultLanguage !== null
    ) {
      localStorage.setItem('selectedLanguage', defaultLanguage);
      changeLanguage(defaultLanguage);
    }
  }

  handleLanguageChange = (e) => {
    this.props.handleLanguageChange(e.target.value);
    localStorage.setItem('selectedLanguage', e.target.value);
  };

  render() {
    const { i18n } = this.props;

    let language = i18n.language || 'en';

    return (
      <select
        style={styles.transparentSelect}
        onChange={this.handleLanguageChange}
        defaultValue={language}
      >
        <option value="es" style={styles.option}>
          Español
        </option>
        <option value="en" style={styles.option}>
          English
        </option>
      </select>
    );
  }
}

const styles = {
  appContainer: {
    position: 'relative',
  },
  container: {
    position: 'fixed',
    bottom: '10px',
    left: '10px',
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Negro con opacidad
    padding: '5px', // Ajusta el relleno según tus preferencias
    borderRadius: '5px', // Ajusta el radio de la esquina según tus preferencias
  },
  transparentSelect: {
    width: '100%', // O ajusta según tus preferencias
    opacity: '0.7', // Ajusta la opacidad según tus preferencias
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
  },
  option: {
    backgroundColor: 'white',
    color: 'black', // Cambia el color del texto dentro de las opciones
  },
};

export default withTranslation()(ChangeLanguage);
