import { AbilityBuilder, Ability } from '@casl/ability';

const initialState = new Ability();

export default function abilityReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ABILITY':
      return action.payload;
    default:
      return state;
  }
}

export const setAbility = (user, companyRole) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);
  if (
    (user.role === 'company' && companyRole === 'admin') ||
    user.role === 'backoffice'
  ) {
    let [is_company_ptaas, is_company_bugbounty] = [false, false];

    if (
      user &&
      user.current_company &&
      user.current_company.active_plan &&
      user.current_company.active_plan.features
    ) {
      const features = user.current_company.active_plan.features;

      is_company_ptaas = features.is_ptaas === true;
      is_company_bugbounty = features.is_bugbounty === true;
    }

    if (!is_company_ptaas && !is_company_bugbounty) {
      is_company_bugbounty = true; // fallback to bugbounty
    }

    can('add_program', 'Company');
    can('view_finance', 'Company');
    can('update', 'Company');
    can('list_users', 'Company');

    if (is_company_bugbounty) {
      can('add_bugbounty_program', 'Company');
    }

    if (is_company_ptaas) {
      can('add_ptaas_program', 'Company');
    }

    can('read', 'Program');
    can('add_user', 'Program', {
      status: {
        $in: ['active', 'paused'],
      },
    });

    can('update', 'Program', {
      status: {
        $in: ['inactive', 'active', 'paused'],
      },
    });

    can('update_status', 'Program', { is_draft: false, is_approved: true });

    can('read', 'Report', { is_draft: false });

    can('update', 'Report', {
      is_draft: false,
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
    });

    can('change_retest_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_mitigation_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_status', 'Report', {
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
      is_draft: false,
      'program.is_ptaas': false,
    });

    can('change_status', 'Report', {
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('add_comment', 'Report', {
      is_draft: false,
    });

    can('export', 'Report', { is_draft: false });

    if (user.role === 'backoffice') {
      can('download_templates', 'Program', {
        is_draft: false,
        is_ptaas: true,
        status: {
          $in: ['active', 'paused'],
        },
      });
    }
  } else if (user.role === 'company' && companyRole === 'standard') {
    can('read', 'Program');
  } else if (user.role === 'hacker') {
    can('download_invoice', 'Payment', { status: 'paid' });

    can('read', 'Program', { status: 'active' });
    can('read', 'Program', { status: 'paused' });
    can('download_templates', 'Program', {
      is_draft: false,
      is_ptaas: true,
      status: {
        $in: ['active', 'paused'],
      },
    });

    can('add_report', 'Program', {
      is_draft: false,
      status: {
        $in: ['active', 'paused'],
      },
    });

    can('update', 'Report', {
      is_draft: true,
      'user.username': user.username,
    });

    can('destroy', 'Report', { is_draft: true });

    can('update', 'Report', {
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('update', 'Report', {
      is_draft: false,
      'program.is_ptaas': false,
      'user.username': user.username,
      status: 'missing-info',
    });

    can('change_status', 'Report', {
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('change_mitigation_status', 'Report', {
      status: {
        $in: [
          'new',
          'triage',
          'missing-info',
          'info',
          'does-not-apply',
          'duplicated',
          'out-of-scope',
          'spam',
          'quarantine',
          'valid',
        ],
      },
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('change_retest_status', 'Report', {
      status: {
        $in: [
          'new',
          'triage',
          'missing-info',
          'info',
          'does-not-apply',
          'duplicated',
          'out-of-scope',
          'spam',
          'quarantine',
          'valid',
        ],
      },
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('add_comment', 'Report', {
      is_draft: false,
    });
  }

  const ability = build();
  return {
    type: 'SET_ABILITY',
    payload: ability,
  };
};
