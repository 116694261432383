import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Input, Button, Row, Col } from 'antd';
import { authenticate2Fa } from '../redux/actions/auth';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const FormItem = Form.Item;
class SecondFactor extends Component {
  goBack() {
    localStorage.clear();
    window.location.href = '/login';
  }

  handleSubmit2FA = (e) => {
    e.preventDefault();

    const {
      dispatch,
      form: { validateFields },
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const attributes = { otp_token: values.otp_token };

        dispatch(authenticate2Fa(attributes)).then(() => {
          if (!this.props.hasErrorOtp) {
            this.setState({ isLoading: false });
            window.location.href = '/dashboard';
          } else {
            openNotification('error', 'Error', this.props.errorMessage);
          }
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <span style={{ width: '300px' }}>
        <Form onSubmit={this.handleSubmit2FA} className="SuperAdminsForm">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <div className="logo-login-container">
              <img
                src={process.env.PUBLIC_URL + '/images/logo-cysteria-azul.svg'}
                alt=""
                className="logo-login block-center"
              />
            </div>
            <Card
              title={
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginLeft: '10px',
                  }}
                >
                  {t('login.2fa.title')}
                </span>
              }
              bordered
              style={{
                width: 400,
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
              }}
              bodyStyle={{ padding: '20px' }}
            >
              <MailOutlined
                style={{
                  fontSize: '40px',
                  marginBottom: '15px',
                  color: '#1890ff',
                }} />
              <p style={{ fontSize: '16px', marginBottom: '15px' }}>
                {t('login.2fa.message')}
              </p>
              <FormItem style={{ marginTop: '20px' }}>
                {getFieldDecorator(
                  `otp_token`,
                  {}
                )(
                  <Input
                    placeholder={t('login.2fa.token')}
                    style={{
                      width: '100%',
                      maxWidth: '300px',
                      margin: '0 auto',
                      borderRadius: '5px',
                    }}
                    maxLength={8}
                  />
                )}
              </FormItem>
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    type="secondary"
                    className="btn-block"
                    style={{
                      width: '100%',
                      marginTop: '15px',
                      borderRadius: '5px',
                    }}
                    onClick={() => this.goBack()}
                  >
                    {t('login.2fa.back-button')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-company btn-block"
                    style={{
                      width: '100%',
                      marginTop: '15px',
                      borderRadius: '5px',
                    }}
                  >
                    {t('login.2fa.send-button')}
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
        </Form>
      </span>
    );
  }
}
const SecondFactorValidatorForm = Form.create()(SecondFactor);

const mapStateToProps = (state) => ({
  hasErrorOtp: state.auth.hasError,
  errorMessage: state.auth.errorMessage,
});
export default withTranslation()(
  connect(mapStateToProps)(SecondFactorValidatorForm)
);
