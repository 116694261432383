import React, { Component } from 'react';
import ActivityList from '../components/ActivityList';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';

class Activities extends Component {
  static defaultProps = {
    renderPagination: () => {},
  };

  render() {
    const { t } = this.props;
    return (
      <div className={this.props.className}>
        <div id="Activities">
          <ActivityList
            activities={this.props.activities}
            loading={this.props.loading}
          />
        </div>

        {this.props.renderPagination()}
      </div>
    );
  }
}

export default withTranslation()(Activities);
