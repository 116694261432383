import React, { Component } from 'react';
import * as _ from 'lodash';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import { withTranslation } from 'react-i18next';
import { Card } from 'antd';

class CommentList extends Component {
  renderNoComments = () => {
    const { t } = this.props;
    return (
      <p className="no-comments text-center">
        {t('reports.comments.no-comments')}
      </p>
    );
  };

  renderComments = () => {
    const { comments } = this.props;
    return comments.map((comment, index) => {
      return (
        <CommentItem
          comment={comment}
          key={index}
          reportUID={this.props.report.id}
        />
      );
    });
  };

  showModal = () => {
    this.setState({
      visibleModal: true,
    });
  };

  hideModal = (e) => {
    this.setState({
      visibleModal: false,
    });
  };

  render() {
    let { report, t } = this.props;
    const { comments } = this.props;

    return (
      <Card
        hoverable={false}
        id="commentsReports"
        style={{ marginBottom: '40px' }}
      >
        <h3 style={{ fontSize: '16px', marginBottom: '25px' }}>
          {t('reports.comments.title')}
        </h3>
        {this.props.canAdd && (
          <CommentForm
            report={report}
            activeComment={this.props.activeComment}
            onAddComment={this.props.onAddComment}
          />
        )}
        {_.isEmpty(comments) ? this.renderNoComments() : this.renderComments()}
      </Card>
    );
  }
}

export default withTranslation()(CommentList);
