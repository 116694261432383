import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Tabs, Input, Button, Select, Image } from 'antd';
import { addPaypalAccount } from '../redux/actions/currentUser';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

const PaymentTypeForm = (props) => {
  const { t } = useTranslation();

  const [isUpdated, setIsUpdated] = useState(null);

  useEffect(() => {
    if (isUpdated) {
      if (props.hasError) {
        setTimeout(
          () => openNotification('error', 'Error', props.errorMessage),
          0
        );
        setIsUpdated(false);
      } else {
        setTimeout(
          () =>
            openNotification(
              'success',
              t('notifications.title.success'),
              t('notifications.profile.update')
            ),
          0
        );
        setIsUpdated(false);
      }
    }
  }, [isUpdated]);

  const onLoadForm = (values, isPaypal) => {
    if (isPaypal) {
      return {
        paypal_pin: values.paypal_pin,
        paypal_address_1: values.paypal_address_1,
        paypal_address_2: values.paypal_address_2,
        paypal_city: values.paypal_city,
        paypal_country: values.paypal_country,
        paypal_email: values.paypal_email,
      };
    } else {
      return {
        bank_account_dni: values.bank_account_dni,
        bank_account_name: values.bank_account_name,
        bank_account_number: values.bank_account_number,
        bank_account_type: values.bank_account_type,
        bank_name: values.bank_name,
      };
    }
  };

  const handleSubmit = (e, isPaypal) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const attributes = onLoadForm(values, isPaypal);
        props.dispatch(addPaypalAccount(attributes)).then(() => {
          setIsUpdated(true);
        });
      }
    });
  };

  const form = props.form;

  return (
    <div style={{ marginTop: '50px' }}>
      <h4 className="profile-title">{t('profile.payments.method')}</h4>
      <Tabs tabPosition="top">
        <TabPane tab="Paypal" key="paypal">
          <Form onSubmit={(e) => handleSubmit(e, true)} layout="vertical">
            <Row>
              <Col xs={24} sm={24}>
                <FormItem label={t('profile.payments.paypal.fields.id.label')}>
                  {form.getFieldDecorator('paypal_pin', {
                    initialValue: props.profile.paypal_pin || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.id.placeholder'
                      )}
                    />
                  )}
                </FormItem>

                <FormItem
                  label={t('profile.payments.paypal.fields.address.label')}
                >
                  {form.getFieldDecorator('paypal_address_1', {
                    initialValue: props.profile.paypal_address_1 || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.address.placeholder'
                      )}
                    />
                  )}
                </FormItem>

                <FormItem
                  label={t(
                    'profile.payments.paypal.fields.address-detail.label'
                  )}
                >
                  {form.getFieldDecorator('paypal_address_2', {
                    initialValue: props.profile.paypal_address_2 || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.address-detail.placeholder'
                      )}
                    />
                  )}
                </FormItem>

                <FormItem
                  label={t('profile.payments.paypal.fields.city.label')}
                >
                  {form.getFieldDecorator('paypal_city', {
                    initialValue: props.profile.paypal_city || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.city.placeholder'
                      )}
                    />
                  )}
                </FormItem>

                <FormItem
                  label={t('profile.payments.paypal.fields.country.label')}
                >
                  {form.getFieldDecorator('paypal_country', {
                    initialValue: props.profile.paypal_country || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.country.placeholder'
                      )}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row className="paypal-container" gutter={5}>
              <Col xs={4} sm={2} md={2} lg={2}>
                <Image
                  className="paypalIcon"
                  src="/images/paypal_copyrighted.png"
                  preview={false}
                />
              </Col>

              <Col xs={16} sm={18} md={18} lg={18}>
                <FormItem>
                  {form.getFieldDecorator('paypal_email', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'profile.payments.paypal.fields.email.message2'
                        ),
                      },
                      {
                        type: 'email',
                        message: t(
                          'profile.payments.paypal.fields.email.message'
                        ),
                      },
                    ],
                    initialValue: props.profile.paypal_email || '',
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.paypal.fields.email.placeholder'
                      )}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {form.getFieldDecorator('id')(<Input type="hidden" />)}
                </FormItem>
              </Col>
            </Row>
            <Button
              loading={props.isPushing}
              type="primary"
              htmlType="submit"
              className="btn-hacker btn-block"
            >
              <p className="p-button">{t('profile.payments.save-button')}</p>
            </Button>
          </Form>
        </TabPane>
        <TabPane
          tab={t('profile.payments.bank.fields.bank-name.label')}
          key="bank"
        >
          <Form onSubmit={(e) => handleSubmit(e, false)} layout="vertical">
            <Row>
              <Col xs={16} sm={18} md={24} lg={24}>
                <FormItem
                  label={t('profile.payments.bank.fields.bank-account.label')}
                >
                  {form.getFieldDecorator('bank_account_number', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'profile.payments.bank.fields.bank-account.message'
                        ),
                      },
                      {
                        message: t(
                          'profile.payments.bank.fields.bank-account.message2'
                        ),
                      },
                    ],
                    initialValue: props.profile.bank_account_number,
                  })(
                    <Input
                      type="number"
                      placeholder={t(
                        'profile.payments.bank.fields.bank-account.placeholder'
                      )}
                    />
                  )}
                </FormItem>
                <FormItem
                  label={t('profile.payments.bank.fields.bank-name.label')}
                >
                  {form.getFieldDecorator('bank_name', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'profile.payments.bank.fields.bank-name.message'
                        ),
                      },
                      {
                        message: t(
                          'profile.payments.bank.fields.bank-name.message2'
                        ),
                      },
                    ],
                    initialValue: props.profile.bank_name,
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.bank.fields.bank-name.placeholder'
                      )}
                    />
                  )}
                </FormItem>
                <FormItem label={t('profile.payments.bank.fields.name.label')}>
                  {form.getFieldDecorator('bank_account_name', {
                    rules: [
                      {
                        required: false,
                        message: t('profile.payments.bank.fields.name.message'),
                      },
                      {
                        message: t(
                          'profile.payments.bank.fields.name.message2'
                        ),
                      },
                    ],
                    initialValue: props.profile.bank_account_name,
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.bank.fields.name.placeholder'
                      )}
                    />
                  )}
                </FormItem>
                <FormItem
                  label={t('profile.payments.bank.fields.id-number.label')}
                >
                  {form.getFieldDecorator('bank_account_dni', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'profile.payments.bank.fields.id-number.message'
                        ),
                      },
                      {
                        message: t(
                          'profile.payments.bank.fields.id-number.message2'
                        ),
                      },
                    ],
                    initialValue: props.profile.bank_account_dni,
                  })(
                    <Input
                      placeholder={t(
                        'profile.payments.bank.fields.id-number.placeholder'
                      )}
                    />
                  )}
                </FormItem>
                <FormItem
                  label={t('profile.payments.bank.fields.type-account.label')}
                >
                  {form.getFieldDecorator('bank_account_type', {
                    rules: [
                      {
                        required: false,
                        message: t(
                          'profile.payments.bank.fields.type-account.label'
                        ),
                      },
                      {
                        message: t(
                          'profile.payments.bank.fields.type-account.label'
                        ),
                      },
                    ],
                    initialValue: props.profile.bank_account_type,
                  })(
                    <Select
                      placeholder={t(
                        'profile.payments.bank.fields.type-account.label'
                      )}
                    >
                      <Option value="corriente">
                        {t(
                          'profile.payments.bank.fields.type-account.options.current'
                        )}
                      </Option>
                      <Option value="vista">
                        {t(
                          'profile.payments.bank.fields.type-account.options.credits'
                        )}
                      </Option>
                      <Option value="ahorros">
                        {t(
                          'profile.payments.bank.fields.type-account.options.savings'
                        )}
                      </Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  {form.getFieldDecorator('bank_id')(<Input type="hidden" />)}
                </FormItem>
              </Col>
            </Row>
            <Button
              loading={props.isPushing}
              type="primary"
              htmlType="submit"
              className="btn-hacker btn-block"
            >
              <p className="p-button">{t('profile.payments.save-button')}</p>
            </Button>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.currentUser.profile,
  hasError: state.currentUser.hasError,
  errorMessage: state.currentUser.errorMessage,
  isPushing: state.currentUser.isPushing,
});

const WrappedPaymentTypeForm = Form.create()(PaymentTypeForm);
export default connect(mapStateToProps)(WrappedPaymentTypeForm);
