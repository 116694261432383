export const programDefaultDescriptionUS = `
 * Program example *


The following description is an example to guide you in the creation of your program. Feel free to customize it by modifying, removing and / or adding rules, tests and other information that you consider relevant. Remember that you can also guide the hacker on the infrastructure or application to review, to look for vulnerabilities of interest.

> * About the {company} *
>
Brief description of the company. It is recommended to include the vision and mission of the organization.
Additionally, you are invited to present your company's security philosophy - for example: the importance of bug bounty to your organization, the way you want to collaborate with the CyScope Hacker community, and the expected benefits.

----------

> * Program objectives *
>
Indicate the specific objectives of this bug bounty program in relation to the test targets.
Examples:
Look for vulnerabilities that generate a security IMPACT for the business.
Verify that the hacker cannot impersonate or access another user's account.
Verify that your clients' sensitive data is protected.
Etc.


----------

> *Target(s)*
>
 https://www.{company}.com

> **Testing Credentials:**
>
User: user1
Password : Password1

> ** Target(s) out of scope**
>
https://otherdomainn.{companny}.com

----------
> * Disclosure & Confidentiality Policy *
>
By participating in this program, the hacker accepts the following list of NOT allowed actions:
> - Provide information related to the program and the results obtained to third parties that are not directly involved in the program or its management.
> - Report a vulnerability without the written authorization of CyScope and {company}.
> - Disclose a vulnerability to third parties, or in other ways, even when it has been validated and mitigated by {company}.
> - Use informal and inappropriate language when writing the vulnerability report.
> - Violate the confidentiality rules.
>
For the report to be accepted and rewarded, the hacker must meet a series of requirements:
> - Be aligned with the rules of the program.
> - Be within the defined test scope (targets) in the program.
> - Include a clear, detailed, concise and illustrated description of the vulnerability, the affected system, its impact, risk level, parameters and a relevant proof of concept. Proofs of concept can be through screenshots or videos.
> - Include clear and detailed information such as the different steps to follow to reproduce the error. The vulnerabilities must be executable and actionable, that is, reveal security flaws, and not theoretical.
> - The payment of the report is reserved for the first hacker who has reported a valid vulnerability. Duplicate reports are not paid, but the hacker earns points that allow him to increase his ranking on the CyScope platform.
> - Create a report by type of unique vulnerability and indicate the affected system (s).
> - If the program administrator team requires additional information about a report, it will communicate with the hacker via email or through the program chat.
>
Important: Incomplete reports do not comply with the platform's disclosure policy and will not be accepted by the program administrator team. Reported vulnerabilities, which are outside the scope of the program, will not be analyzed or rewarded.
>


----------

> * Ineligible tests *
>
The following activities are strictly prohibited:
> - Carry out tests on the system in an uncontrolled manner, affecting the availability, confidentiality and integrity of {company} data.
> - Make changes to the evaluated system and share access to the system with third parties.
> - Perform brute force, social engineering, physical intrusion and spam attacks.
> - Integrate the use of malware in the tests.
> - Upload vulnerability or content related to {company} to third party utilities.
> - Harm {company} through unscheduled or agreed activities.
> - Exploit access to systems, accounts, users or user data obtained. In this case, the hacker must stop their tests and prepare a report to notify the company. He won't be able to investigate further using this weakness.
> - Perform DoS or DDoS attacks without having received prior written authorization from CyScope and {company}. If the hacker suspects an application layer DoS, he should contact CyScope for permission: info@cyscope.io
> - Attempting one of these activities could result in a disqualification from the program and immediate removal from the platform. Depending on the severity of the offense, legal action may be taken.
>
The following types of tests and vulnerabilities will not be rewarded and reports of this type will not be accepted:
> - Unhandled exceptions (error 500).
> - UUID enumeration.
> - CSV injection.
> - Cross-Site Request Forgery (CSRF) with limited impact.
> - SSL / TLS scan reports.
> - Speculative reports with theoretical impact without proof of concept.
> - Automated scan reports without manual validation.
> - Extract the system version.
> - Debug errors or system path disclosure (Path disclosure).
> - Absence of security headers.

----------

> * Eligible tests *
> Vulnerabilities with a real impact are searched, such as:
>
> | Severity | Name | Exemple                                       |
> | --------- | ------------ | --------------------------------------------- |
> | Criticall   | RCE          | Remote Code Execution                         |
> | Critical   | XXE          | XML External Entity                           |
> | High      | SQLi         | SQL Injection                                 |
> | High      | IDOR         | Insecure Direct Object Reference              |
> | High      | SSRF         | Server-Side Request Forgery                   |
> | High      | ---          | Authentication or Authorization Bypass        |
> | High      | ATO          | Account Takeover                              |
> | High      | SSI          | Server-Side Inclusions Injection              |
> | Medium     | ---          | S3 Bucket Upload                              |
> | Medium     | XSS          | Cross-Site Scripting                          |
> | Medium     | UE           | PII User Enumeration                          |
> | Medium     | CSRF         | Cross-Site Request Forgery                    |
> | Medium     | CRLFi        | CRLF Injection                                |
> | Medium     | SDTO         | Subdomain Takeover                            |
> | Medium     | ---          | Information Exposure                          |
> | Low      | ---          | Missing Encryption of Sensitive Data          |
> | Low      | ---          | Open Redirect                                 |
> | Low      | ---          | Improper Restriction in form with captcha.    |
>

----------

By accepting this program, the hacker declares to have read and understood the comprehensive description of the program.
The hacker's participation is regulated by the General Terms and Conditions (and respective annexes) of CyScope that you can consult here here: https://cyscope.io/terms-and-conditions/

`;
export const programDefaultPtaasDescriptionUS = `
Welcome to CyScope, the Pentest-as-a-Service platform powered by Dreamlab Technologies.

[Name_Customer] is requesting the support of an external cybersecurity team to evaluate the security posture of its [target type] in a [black/grey/white box] mode.

> * Objectives *
The objectives of this new program are:
> - Ex: Compliance.
> - Ex: Special event.
> - Ex: Need to evaluate a new feature.
> - Ex: New release.
> - Ex: Concerns about some components of the target.
> - Etc.
> * SCOPE *
(if Web App)
> | NAME      | URL          | VERSION          |
(if External infrastructure) 
> | IP List      |          | 
> | Or IP Range      |          |
(if mobile App)
> | NAME      | OS (Android/iOS)        | URL           | VERSION          |
[Name_Customer] has given some additional information about the scope: [technologies/infrastructure]

The cybersecurity team will perform the Pentest-as-a-service in a [productive/staging/development] environment. 
> * ACCESS *
The cybersecurity team is invited to conduct the exercise with credentials:
> | URL      | Role          | Username/Pass          |
> | URL      | Role          | Username/Pass          |


> * RESTRICTIONS AND SPECIFICATIONS * 
The customer wants to apply some restrictions and specifications to the program: 
Ex: Hour/days when the team should NOT test
Ex: The team has the authorization to exploit vulnerabilities, or the team can exploit vulnerabilities under certain circumstances.
Ex: Exclusions (subdomains, IPs, etc. out of scope).

Remember that the Pentest-as-a-Service should focus only on the scope delivered by the customer. All the security issues that affect targets that are out-of-scope will not be accepted during the technical QA. 

If you have any question about the platform, please contact info@cyscope.io

Happy Hacking!

`;
export const headerHistoryUS = ['Date', 'User', 'Detail', 'Amount', 'Balance'];
export const headerPaymentUS = [
  'Date',
  'Program',
  'ID',
  'Hacker',
  'Severity',
  'State',
  'Amount',
];

export const headerReportUS = [
  'ID',
  'Created',
  'Updated',
  'Hacker',
  'Paid',
  'Amount',
  'Link',
  'Program',
  'Report Name',
  'Attack type',
  'CVSS',
  'Severity',
  'Report State',
  'Target',
  'URL',
  'Description',
  'Impact',
  'Request',
  'Vulnerable parameters',
  'Payload',
  'Concept proof',
  'Reference',
  'Countermeasures',
  'Step 1',
  'Step 2',
  'Step 3',
];

export const headerProgramUS = [
  'Name',
  'State',
  'Total Budget',
  'Locked',
  'Paid',
  'Available',
];
