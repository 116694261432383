import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import {
  deleteComments,
  getCommentsReports,
} from '../redux/actions/reportComments';
import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class ReportCommentDelete extends Component {
  state = {
    removing: false,
  };

  removeComment(id, reportId) {
    const { dispatch, t } = this.props;

    confirm({
      title: t(`confirmation`),
      content: t(`reports.dialogs.delete-report-comment.confirm.title`),
      okText: t(`confirm`),
      cancelText: t(`cancel`),

      onOk: () => {
        dispatch(deleteComments(id, reportId)).then(() => {
          if (this.props.hasError) {
            this.notification(
              'Error',
              t(`reports.notifications.delete-report-comment.error`),
              ''
            );
          }
        });

        setTimeout(() => {
          dispatch(getCommentsReports(reportId));
        }, 1200);
      },
    });
  }

  render() {
    const { comment, reportUID } = this.props;
    const { removing } = this.state;

    return (
      <Button
        className=""
        style={{ padding: '4px', marginLeft: '5px' }}
        loading={removing}
        onClick={() => this.removeComment(comment.id, reportUID)}
      >
        <DeleteOutlined style={{ fontSize: 16, color: '#888' }} />
      </Button>
    );
  }
}

const mapStateToProps = (state) => ({
  requesting: state.programFeature.requesting,
  hasError: state.programFeature.hasError,
  errorMessage: state.programFeature.message,
});
export default withTranslation()(
  withRouter(connect(mapStateToProps)(ReportCommentDelete))
);
