import * as Action from '../actions/ActionTypes';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import moment from 'moment';

const initialState = {
  entities: [],
  entitiesForDownload: [],
  statistics: {},
  fetching: false,
  readyForDownload: false,
  hasMore: false,
  isPushing: false,
  isSaved: false,
  isRemoved: false,
  isDeleted: false,
  filters: {
    bySearch: null,
    byStatus: null,
    byProgram: null,
    bySeverity: null,
    byDraft: null,
    byActions: null,
  },
  currentPage: 1,
  numberOfPages: 1,
  numberOfElements: 0,
  filtersPersist: false,
  hasError: false,
  errorMessage: null,
  targets: [],
  allPrograms: [],
};

class ReportsReducer {
  static reduce(state = initialState, action) {
    if (ReportsReducer[action.type]) {
      return ReportsReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.DELETE_REPORT_FROM_STORE_SUCCESS](state, action) {
    const reports = state.entities;
    _.remove(reports, (report) => {
      return report.uuid === action.uuid;
    });
    return {
      ...state,
      entities: reports,
      isDeleted: true,
      filtersPersist: true,
    };
  }

  static [Action.LIST_REPORTS_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.LIST_REPORTS_SUCCESS](state, action) {
    const results = action.response.results;
    let entities,
      currentPage,
      filters = _.cloneDeep(state.filters),
      resetEntities;

    if (resetEntities) {
      entities = results;
      currentPage = 1;
    } else {
      entities = results;
    }

    return {
      ...state,
      entities: entities,
      hasMore: false,
      readyForDownload: false,
      isPushing: false,
      hasError: false,
      filters: filters,
      currentPage: 1,
      numberOfPages: Math.ceil(action.response.count / 10),
      numberOfElements: action.response.count,
    };
  }

  static [Action.LIST_REPORTS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }
  static [Action.GET_REPORT_DOWNLOAD_SUCCESS](state, action) {
    const { response } = action;
    saveAs(response, `Reportes - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`);

    return {
      ...state,
      entitiesForDownload: response.reports,
      readyForDownload: true,
      isPushing: false,
      hasError: false,
    };
  }
  static [Action.ADD_REPORT_SUCCESS](state, action) {
    const { data } = action;

    const reports = _.uniqBy(
      _.orderBy(
        _.concat(_.values(data.reports), state.entities),
        ['created_at'],
        ['desc']
      ),
      'uuid'
    );

    return {
      ...state,
      entities: reports,
    };
  }

  static [Action.SET_REPORTS_FILTERS](state, action) {
    const { params } = action;

    return {
      ...state,
      filters: {
        ...state.filters,
        bySearch:
          params['bySearch'] !== undefined
            ? params['bySearch']
            : state.filters.bySearch,
        byStatus:
          params['byStatus'] !== undefined
            ? params['byStatus']
            : state.filters.byStatus,
        byProgram:
          params['byProgram'] !== undefined
            ? params['byProgram']
            : state.filters.byProgram,
        bySeverity:
          params['bySeverity'] !== undefined
            ? params['bySeverity']
            : state.filters.bySeverity,
        byActions:
          params['byActions'] !== undefined
            ? params['byActions']
            : state.filters.byActions,
      },
    };
  }

  static [Action.REPORT_STATISTICS_REQUEST](state, action) {
    return {
      ...state,
      fetching: true,
    };
  }

  static [Action.REPORT_STATISTICS_SUCCESS](state, action) {
    return {
      ...state,
      fetching: false,
      hasError: false,
      statistics: action.response,
    };
  }

  static [Action.REPORT_STATISTICS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      fetching: false,
    };
  }

  static [Action.UPDATE_REPORT_ON_STORE_SUCCESS](state, action) {
    const { report } = action;

    state.entities.map((entitie, i) => {
      if (entitie.uuid === report.uuid) {
        state.entities[i] = report;
        return true;
      }
    });

    return {
      ...state,
      entities: state.entities,
    };
  }

  static [Action.CLEAR_REPORTS](state, action) {
    return {
      ...state,
      hasMore: false,
      isPushing: false,
      isSaved: false,
      isRemoved: false,
      isDeleted: false,
      hasError: false,
      errorMessage: null,
    };
  }

  static [Action.CLEAR_REPORTS_FILTERS](state, action) {
    return {
      ...state,
      filters: {
        ...state.filters,
        bySearch: null,
        byStatus: null,
        byProgram: null,
        bySeverity: null,
        byDraft: null,
        byActions: null,
      },
    };
  }

  static [Action.CLEAR_REPORTS_FILTERS_PERSIST](state, action) {
    return {
      ...state,
      filtersPersist: false,
    };
  }

  static [Action.CLEAR_REPORT_STEP](state, action) {
    return {
      ...state,
      isSaved: false,
      isRemoved: false,
      hasError: false,
      errorMessage: null,
      isPushing: false,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }

  static [Action.UNIT_REPORT_DOWNLOAD_REQUEST](state, action) {
    return {
      ...state,
      isDownloadLightPushing: true,
    };
  }
  static [Action.UNIT_REPORT_DOWNLOAD_SUCCESS](state, action) {
    const { response } = action;
    if (response.type === 'text/csv') {
      saveAs(
        response,
        `Reporte programa - ${moment().format('YYYY-MM-DD HHmmss')}.csv`
      );
    } else {
      saveAs(
        response,
        `Reporte programa - ${moment().format('YYYY-MM-DD HHmmss')}.xlsx`
      );
    }

    return {
      ...state,
      entitiesForDownloadLight: response.reports,
      readyForDownloadLight: true,
      isDownloadLightPushing: false,
      hasError: false,
    };
  }
  static [Action.UNIT_REPORT_DOWNLOAD_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isDownloadLightPushing: false,
    };
  }

  static [Action.LIST_TARGETS_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.LIST_TARGETS_SUCCESS](state, action) {
    const { response } = action;
    return {
      ...state,
      targets: response,
    };
  }

  static [Action.LIST_TARGETS_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }

  static [Action.LIST_PROGRAM_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.LIST_PROGRAM_SUCCESS](state, action) {
    const { response } = action;
    return {
      ...state,
      allPrograms: response,
    };
  }

  static [Action.LIST_PROGRAM_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }
}

export default ReportsReducer.reduce;
