import { Row, Col, Button, Card, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

import React from 'react';

import ProgramFeatureView from './ProgramFeatureView';
import ProgramFeatureForm from './ProgramFeatureForm';
import LoaderComponent from './LoaderComponent';

const ProgramFeatures = (props) => {
  const { t } = useTranslation();

  const handleDelete = (id) => {
    Modal.confirm({
      title: t(`programs.dialogs.delete-feature.confirm.title`),
      content: t(`programs.dialogs.delete-feature.confirm.content`),
      okText: t(`confirm`),
      cancelText: t(`cancel`),
      onOk: () => {
        props.handleDelete(id);
      },
    });
  };

  // Render the component
  if (props.loading) {
    return <LoaderComponent label={false} />;
  }

  return (
    <Card hoverable={false}>
      <h4 className="section-subtitle">{t('programs.tabs.updates')}</h4>
      <div>
        <div id="program-features">
          <div className="features">
            {props.features.map((item, i) => {
              return (
                <ProgramFeatureView
                  key={i}
                  feature={item}
                  canDelete={props.canDelete}
                  handleDelete={handleDelete}
                />
              );
            })}

            {props.features.length < props.totalItems && (
              <Row>
                <Col sm={24} className="ver-mas-container">
                  <Button
                    className="ver-mas"
                    loading={props.featuresLoading}
                    onClick={props.handleGetMore}
                  >
                    {t('programs.features.old-updates-button')}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
        {props.formEnabled && (
          <div className="new-feature">
            <ProgramFeatureForm handleSubmit={props.handleSubmit} />
          </div>
        )}
      </div>
    </Card>
  );
};

ProgramFeatures.defaultProps = {};

export default ProgramFeatures;
