import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select } from 'antd';
import {
  companyEmployees,
  invitationsEmployees,
  invitesCompanyEmployees,
} from '../redux/actions/companyEmployees';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const FormItem = Form.Item;
const Option = Select.Option;

class CompanyInvitationsForm extends Component {
  state = {
    loadingButton: false,
    invitations: [0],
  };

  componentWillReceiveProps(nextProps) {
    const { isInviting } = this.props;

    if (isInviting && !nextProps.isInviting) {
      if (nextProps.hasError) {
        this.setState({ loadingButton: false });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification('error', 'Ha ocurrido un error inesperado.');
        }, 0);
      } else {
        this.setState({ loadingButton: false, invitations: [{ email: '' }] });
        let $timer = setTimeout(() => {
          clearTimeout($timer);
          $timer = null;
          openNotification(
            'success',
            'Tus datos han sido actualizados exitosamente',
            ''
          );
        }, 0);

        this.props.form.resetFields();
        this.props.closeModal();
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { dispatch, form } = this.props;
    this.setState({ loadingButton: true });

    form.validateFields((err, values) => {
      if (!err) {
        const { invitations } = this.state;
        const data = invitations.map((p, i) => {
          const emailFieldName = `email-${i}`;
          const roleFieldName = `role-${i}`;
          return {
            email: values[emailFieldName], // Obtiene el valor del campo de email
            role: values[roleFieldName], // Obtiene el valor del campo de rol
          };
        });

        dispatch(invitesCompanyEmployees(data)).then(() => {
          dispatch(invitationsEmployees());
          dispatch(companyEmployees());
        });
      } else {
        this.setState({ loadingButton: false });
      }
    });
  };

  addInvitation = () => {
    this.setState(({ invitations }) => ({
      invitations: [...invitations, invitations[invitations.length - 1] + 1],
    }));
  };

  removeInvitation = (p) => {
    this.setState(({ invitations }) => {
      const index = invitations.indexOf(p);
      const newInvitations = invitations.slice();
      newInvitations.splice(index, 1);
      return {
        invitations: newInvitations,
      };
    });
  };

  getInvitations() {
    const { t } = this.props;
    const { invitations } = this.state;
    const { getFieldDecorator } = this.props.form;
    return invitations.map((p, i) => {
      const emailFieldName = `email-${i}`;
      const roleFieldName = `role-${i}`;
      return (
        <Row className="invitationSlot" key={i} gutter={15}>
          <Col sm={11}>
            <FormItem>
              {getFieldDecorator(emailFieldName, {
                rules: [{ type: 'email', message: 'Invalid email format' }],
              })(<Input placeholder="Enter a email" />)}
            </FormItem>
          </Col>
          <Col sm={10}>
            <FormItem>
              {getFieldDecorator(roleFieldName, {
                rules: [{ required: true, message: 'User role is required' }],
              })(
                <Select placeholder="Select role">
                  <Option value={'admin'}>Admin</Option>
                  <Option value={'standard'}>Member</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col sm={3}>
            {invitations.length > 1 && i !== 0 ? (
              <Button
                disabled={invitations.length === 1}
                onClick={() => this.removeInvitation(p)}
              >
                <MinusCircleOutlined className="btn-remove-invitation" />
              </Button>
            ) : (
              <Button
                className="btn-add-invitation"
                onClick={this.addInvitation}
              >
                <PlusCircleOutlined />
              </Button>
            )}
          </Col>
        </Row>
      );
    });
  }

  render() {
    const { t } = this.props;
    const invitations = this.getInvitations();

    return (
      <Form onSubmit={this.handleSubmit} id="CompanyInvitationsForm">
        <Row className="invitationsTitles" gutter={15}>
          <Col sm={11}>
            <p>Email</p>
          </Col>
          <Col sm={10}>
            <p>Role</p>
          </Col>
          <Col sm={3}></Col>
        </Row>

        {invitations}

        <Button
          type="primary"
          htmlType="submit"
          className="modal-confirm-btn btn-company btn-block"
          loading={this.state.loadingButton}
        >
          <span className="p-button">{t('profile.employees.send-button')}</span>
        </Button>
      </Form>
    );
  }
}

const NormalCompanyInvitationsForm = Form.create()(CompanyInvitationsForm);

const mapStateToProps = (state) => ({
  isInviting: state.companyEmployees.isInviting,
});

export default withTranslation()(
  connect(mapStateToProps)(NormalCompanyInvitationsForm)
);
