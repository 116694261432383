import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { SwapOutlined, WalletOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  InputNumber,
  Input,
  Tooltip,
  notification,
  Spin,
  Image,
} from 'antd';
import { Link } from 'react-router-dom';
import { addWalletFunds, getCommission } from '../redux/actions/wallet';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';
import ModalConverter from './ModalConverter';
import { isNumber } from 'lodash';
import LoaderComponent from './LoaderComponent';
const { TextArea } = Input;

class WalletBalance extends Component {
  state = {
    visible: false,
    confirmLoading: false,
    amount: 1000,
    comment: '',
    commission: 0,
    messageWarning: '',
    stepCont: 1,
    modalVisible: false,
  };

  constructor(props) {
    super(props);
  }

  showModal = () => {
    this.setState({
      visible: true,
      stepCont: 1,
    });
  };

  openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  handleOk = () => {
    const { dispatch } = this.props;
    const { amount, comment, stepCont } = this.state;

    this.setState({
      confirmLoading: true,
    });

    setTimeout(() => {
      const commision = this.getTotal(true);
      if (amount) {
        dispatch(
          addWalletFunds({
            amount: amount,
            comment: comment,
          })
        ).then(() => {
          if (!this.props.hasError) {
            this.setState({
              confirmLoading: false,
              stepCont: stepCont + 1,
              amount: 1000,
            });
          } else {
            this.setState({
              confirmLoading: false,
              visible: false,
            });
            this.openNotification('error', 'Error', this.props.errorMessage);
          }
        });
      } else {
        dispatch(addWalletFunds({ amount: 1000, comment: comment })).then(
          () => {
            if (!this.props.hasError) {
              this.setState({
                confirmLoading: false,
                stepCont: stepCont + 1,
                amount: 1000,
              });
            } else {
              this.setState({
                confirmLoading: false,
                visible: false,
              });
              this.openNotification('error', 'Error', this.props.errorMessage);
            }
          }
        );
      }
    }, 2000);
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeNumber = (value) => {
    this.setState({
      amount: value,
    });
  };

  onChangeText = (event) => {
    let value = event.target.value;
    this.setState({
      comment: value,
    });
  };

  getTotal = (type = false) => {
    const { t } = this.props;
    let { accumulated, refill_no_commission, commission, anual_refill_top } =
      this.props.currentUser.currentCompany.activePlan;

    let { amount } = this.state;
    amount = amount || 1000;

    const accumulate = parseFloat(accumulated);
    const res = accumulate + amount;

    let percent = 0;

    let amountRest = Math.abs(refill_no_commission - res);

    if (accumulate < refill_no_commission) {
      if (res > refill_no_commission) {
        percent = (commission * Math.abs(refill_no_commission - res)) / 100;
      } else {
        percent = 0;
      }
    } else {
      percent = (commission * Math.abs(amount)) / 100;
      amountRest = amount;
    }
    const result = percent + amount;

    const commissionAmount = this.props.fundsInfo.commission.toFixed(2);
    const affected = this.props.fundsInfo.affected.toFixed(2);
    const total = (
      parseFloat(this.props.fundsInfo.amount) +
      parseFloat(this.props.fundsInfo.commission.toFixed(2))
    ).toFixed(2);
    const commission_percentage = this.props.fundsInfo.commission_percentage;
    if (type) {
      return percent;
    }
    return (
      <div width="100%">
        <table className="detailMount">
          <tbody>
            <tr>
              <td>{t('budget.funds.subscription')}&nbsp;</td>
              <td className="priceColumn">
                <NumberDisplay number={this.props.fundsInfo.amount} />
              </td>

              <td>USD</td>
            </tr>
            <tr>
              <td>{t('budget.funds.affected')}&nbsp;</td>
              <td className="priceColumn">
                <NumberDisplay number={affected} />
              </td>
              <td>USD</td>
            </tr>
            <tr>
              <td>
                {t('budget.funds.commission')}&nbsp;({commission_percentage}%)
              </td>
              <td className="priceColumn">
                <NumberDisplay number={commissionAmount} />
              </td>
              <td>USD</td>
            </tr>
            <tr>
              <th>
                <hr />
              </th>
            </tr>
            <tr>
              <td>
                <span style={{ marginRight: '100px' }}>Total</span>
              </td>
              <td className="priceColumn" style={{ fontSize: '20px' }}>
                <NumberDisplay number={total} />
              </td>
              <td>USD</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  stepContent = (step) => {
    const { t } = this.props;
    const { amount } = this.state;
    let { accumulated, anual_refill_top } =
      this.props.currentUser.currentCompany.activePlan;
    const total = parseFloat(amount) + parseFloat(accumulated);

    if (step === 1) {
      return (
        <div>
          <Row
            style={{
              margin: '15px 20px 20px 20px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '15px',
              }}
            >
              {t('budget.funds.amount')} (USD)
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '90%',
              }}
            >
              <InputNumber
                style={{
                  marginTop: '10px',
                  flex: '1',
                  marginLeft: '29px',
                  height: '34px',
                  fontSize: '14px',
                  color: 'black',
                }}
                value={this.state.amount}
                min={0}
                max={100000}
                formatter={(value) => `${value}`.toLocaleString('es-CL')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={this.onChangeNumber}
              />
            </div>
          </Row>
          <Row
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              margin: '15px 20px 0px 20px',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                marginBottom: '15px',
              }}
            >
              {t('budget.funds.comments')}
            </p>
            <TextArea
              style={{
                marginTop: '10px',
                width: '83%',
                marginLeft: '29px',
                backgroundColor: 'white',
                borderColor: '#d9d9d9',
              }}
              value={this.state.comment}
              rows={3}
              onChange={this.onChangeText}
            />
          </Row>
        </div>
      );
    }
    if (step === 2) {
      return (
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={24}
            md={24}
            lg={{ span: 24, offset: 1 }}
            style={{ width: '100%' }}
          >
            {this.getTotal()}

            {total > anual_refill_top && (
              <p style={{ color: 'red', marginTop: '20px' }}>
                {t('budget.funds.refill')}
              </p>
            )}
          </Col>
        </Row>
      );
    }
    if (step === 3) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Image src={`/images/wallet-add.png`} preview={false} width={50} />
          <p
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 600,
              padding: '30px 0px',
            }}
          >
            {t('budget.funds.message1')}
            <br /> {t('budget.funds.message2')}
          </p>
        </div>
      );
    }
  };

  getCommission() {
    const { dispatch } = this.props;
    const { stepCont, amount } = this.state;

    dispatch(getCommission({ amount: amount })).then(() => {
      if (this.props.hasError) {
        this.openNotification('error', 'Error', this.props.errorMessage);
      } else {
        this.setState({ stepCont: stepCont + 1 });
      }
    });
  }

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const { visible, confirmLoading, amount, comment, stepCont, modalVisible } =
      this.state;

    const {
      companyCurrentBalance,
      programTotalBalance,
      currentUser,
      wallet,
      dispatch,
      t,
      loading,
      ability,
    } = this.props;

    const availableTotal =
      isNumber(companyCurrentBalance) && isNumber(programTotalBalance)
        ? companyCurrentBalance + programTotalBalance
        : null;

    let { accumulated, anual_refill_top } =
      currentUser.currentCompany.activePlan;
    const total = parseFloat(amount) + parseFloat(accumulated);

    const canAddPtaas = ability.can('add_ptaas_program', 'Company');

    return canAddPtaas === null ? (
      <LoaderComponent label={false} />
    ) : (
      <div style={{ padding: '15px' }}>
        <h6
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            fontWeight: 'bold',
            margin: 'auto 0',
            textTransform: 'uppercase',
            fontSize: '16px',
          }}
        >
          {t('budget.balance')}
        </h6>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Col
            style={{ flex: '1', margin: '0' }}
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2, offset: 1 }}
          >
            <Image
              style={{
                width: '17px',
                height: '17px',
                marginTop: '-2px',
              }}
              preview={false}
              src={`/images/wallet.png`}
            />
          </Col>
          <Col style={{ flex: '3', margin: '0' }} lg={{ offset: 1, span: 3 }}>
            <Tooltip placement="right" title={t('budget.wallet-tooltip')}>
              <span style={{ fontSize: '16px' }}>{t('budget.wallet')}</span>
            </Tooltip>
          </Col>
          <Col
            style={{ flex: '3', textAlign: 'end', margin: '0' }}
            lg={{ offset: 2 }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {companyCurrentBalance && (
                <NumberDisplay number={companyCurrentBalance} />
              )}
            </span>
            <span style={{ fontSize: '11px' }}>{'   USD'}</span>
          </Col>
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: '15px' }}>
          <Col
            style={{ flex: '1', margin: '0' }}
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2, offset: 1 }}
          >
            <Image
              style={{
                width: '17px',
                height: '17px',
                marginTop: '-2px',
              }}
              preview={false}
              src={`/images/customize.png`}
            />
          </Col>
          <Col style={{ flex: '3', margin: '0' }} lg={{ offset: 1, span: 3 }}>
            <Tooltip placement="right" title={t('budget.program-tooltip')}>
              <span style={{ fontSize: '16px' }}>{t('programs.title')}</span>
            </Tooltip>
          </Col>
          <Col
            style={{ flex: '3', textAlign: 'end', margin: '0' }}
            lg={{ offset: 1 }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {programTotalBalance && (
                <NumberDisplay number={programTotalBalance} />
              )}
            </span>
            <span style={{ fontSize: '11px' }}>{'   USD'}</span>
          </Col>
        </div>
        <br />
        <hr />
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <Col style={{ margin: '0', flex: '1' }} lg={{ offset: 2, span: 2 }}>
            <span style={{ fontSize: '16px' }}>{t('budget.total')}</span>
          </Col>
          <Col
            style={{ flex: '1', margin: '0', textAlign: 'end' }}
            lg={{ offset: 1 }}
          >
            <Spin spinning={loading}></Spin>
            <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
              {availableTotal && <NumberDisplay number={availableTotal} />}
            </span>
            <span style={{ fontSize: '11px' }}>{'   USD'}</span>
          </Col>
        </div>
        <div style={{ margin: '0', textAlign: 'center', marginTop: '20px' }}>
          <Col span={24}>
            <Button
              type="primary"
              size="large"
              onClick={this.showModal}
              loading={this.state.loadingButton}
              className="btn-company"
              style={{
                height: '50px',
                width: '220px',
              }}
            >
              <p className="p-button">{t('budget.funds.add-funds-button')}</p>
            </Button>

            <Modal
              visible={visible}
              onCancel={this.handleCancel}
              afterClose={() =>
                this.setState({ stepCont: 1, comment: '', amount: 1000 })
              }
              footer={
                stepCont === 1
                  ? [
                      <Button
                        key="continue"
                        type="danger"
                        size="large"
                        className="btn-company"
                        onClick={() => {
                          this.getCommission();
                        }}
                        disabled={this.state.comment === ''}
                        style={{
                          width: '83%',
                          marginRight: '7px',
                          height: '40px',
                          marginBottom: '10px',
                        }}
                      >
                        <p
                          className={
                            this.state.comment === ''
                              ? 'p-button disabled'
                              : 'p-button'
                          }
                        >
                          {t('budget.funds.continue-button')}
                        </p>
                      </Button>,
                    ]
                  : stepCont === 2
                  ? [
                      <Button
                        key="cancel"
                        size="large"
                        onClick={() =>
                          this.setState({
                            stepCont: stepCont - 1,
                            comment: comment,
                            amount,
                          })
                        }
                        style={{
                          width: '150px',
                          height: '40px',
                          marginBottom: '10px',
                          background: '#717982',
                        }}
                      >
                        <p className="p-button">
                          {t('budget.funds.cancel-button')}
                        </p>
                      </Button>,
                      <Button
                        key="submit"
                        type="success"
                        size="large"
                        loading={confirmLoading}
                        className="btn-company"
                        onClick={this.handleOk}
                        disabled={total > anual_refill_top}
                        style={{
                          width: '150px',
                          marginRight: '7px',
                          height: '40px',
                          marginBottom: '10px',
                        }}
                      >
                        <p className="p-button">
                          {t('budget.funds.confirm-button')}
                        </p>
                      </Button>,
                    ]
                  : []
              }
            >
              <div
                style={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  paddingTop: '15px',
                  margin: '10px',
                  marginBottom: '25px',
                }}
              >
                <Col
                  style={{
                    position: 'absolute',
                    left: '15%',
                    margin: '0',
                  }}
                  xs={{ span: 2 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2, offset: 1 }}
                >
                  {stepCont !== 3 && (
                    <WalletOutlined
                      style={{
                        fontSize: '1.43rem',
                        marginTop: '2px',
                      }}
                    />
                  )}
                </Col>
                <p
                  style={{
                    position: 'absolute',
                    left: '25%',
                    fontSize: '15px',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {stepCont === 1
                    ? t('budget.funds.title1')
                    : stepCont === 2
                    ? t('budget.funds.title2')
                    : ''}
                </p>
              </div>

              {this.stepContent(stepCont)}
            </Modal>
          </Col>
        </div>
        <div style={{ textAlign: 'center', marginTop: '21px' }}>
          <Col style={{ margin: '0 auto' }}>
            <SwapOutlined style={{ color: 'gray' }} />
            <a
              className="link-text"
              disabled={!canAddPtaas}
              style={{
                cursor: 'cursor',
                textDecoration: 'none',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={this.openModal}
            >
              {t('budget.ptaas.converter-label')}
            </a>
          </Col>
          {modalVisible && (
            <ModalConverter
              currentUser={currentUser}
              modalVisible={modalVisible}
              onCancel={this.closeModal}
              companyCurrentBalance={companyCurrentBalance}
              dispatch={dispatch}
            />
          )}
        </div>
        <div style={{ textAlign: 'center', marginTop: '21px' }}>
          <Col style={{ margin: '0 auto' }}>
            <Link to={'/dashboard/finance/conversion-history'} wallet={wallet}>
              <Image
                style={{ width: '19px', height: '19px' }}
                preview={false}
                src={`/images/history.png`}
              />
              <span className="link-text">
                {t('budget.ptaas.conversion-history')}
              </span>
            </Link>
          </Col>
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '21px',
            textDecoration: 'none',
          }}
        >
          <Col style={{ margin: '0 auto' }}>
            <Link
              to={'/dashboard/finance/history/bugbounty'}
              style={{ textDecoration: 'none' }}
              wallet={wallet}
              className="link-text"
            >
              <Image
                style={{ width: '19px', height: '19px' }}
                preview={false}
                src={`/images/history.png`}
              />
              {t('budget.wallet-history.wallet-history')}
            </Link>
          </Col>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  fundsInfo: state.wallet.fundsInfo,
  hasError: state.wallet.hasError,
  errorMessage: state.wallet.errorMessage,
  ability: state.ability,
});
export default withTranslation()(connect(mapStateToProps)(WalletBalance));
