import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Countries } from '../constants/Data';
import axiosInstance from '../axiosInstance';
import { handleAxiosError } from '../utils/Http';
import { openNotification } from '../utils/General';

const InvoiceDetailsForm = ({ form }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  // Simulating existing user data
  const existingUserData = {
    username: 'john_doe',
    email: 'john.doe@example.com',
    password: 'password123',
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(
          '/current-company/billing-info/'
        );
        const userData = response.data;
        setFieldsValue(userData);
        setLoading(false);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [setFieldsValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await axiosInstance.patch('/current-company/billing-info/', values);
          openNotification(
            'success',
            `${t('notifications.profile.update')}`,
            `${t('notifications.profile.billing')}`
          );
        } catch (error) {
          handleAxiosError(error);
        }
      }
    });
  };

  return (
    <div>
      <h4 className="profile-title">{t('profile.billing.details')}</h4>
      <Form onSubmit={handleSubmit} id="CompanyInvoicingForm" layout="vertical">
        <Form.Item label={t('profile.billing.invoice.business-name.label')}>
          {getFieldDecorator('corporate_name', {
            rules: [
              {
                required: true,
                message: t('profile.billing.invoice.business-name.message'),
              },
            ],
          })(
            <Input
              placeholder={t(
                'profile.billing.invoice.business-name.placeholder'
              )}
            />
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.address.label')}>
          {getFieldDecorator('address', {
            rules: [
              {
                required: true,
                message: t('profile.billing.invoice.address.message'),
              },
            ],
          })(
            <Input
              placeholder={t('profile.billing.invoice.address.placeholder')}
            />
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.country.label')}>
          {getFieldDecorator('country', {
            rules: [
              {
                required: true,
                message: t('profile.billing.invoice.country.message'),
              },
            ],
          })(
            <Select
              placeholder={t('profile.billing.invoice.country.placeholder')}
            >
              {Countries.map((p, i) => {
                return (
                  <Select.Option value={p.id} key={i.toString()}>
                    {p.name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.phone.label')}>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                message: t('profile.billing.invoice.phone.message'),
              },
            ],
          })(
            <Input
              placeholder={t('profile.billing.invoice.phone.placeholder')}
            />
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.email.label')}>
          {getFieldDecorator('contact_email', {
            rules: [
              {
                type: 'email',
                required: true,
                message: t('profile.billing.invoice.email.message'),
              },
            ],
          })(
            <Input
              placeholder={t('profile.billing.invoice.email.placeholder')}
            />
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.commercial-number.label')}>
          {getFieldDecorator('commercial_number', {
            rules: [
              {
                required: true,
                message: t(
                  'profile.billing.invoice.commercial-number.placeholder'
                ),
              },
            ],
          })(
            <Input
              placeholder={t(
                'profile.billing.invoice.commercial-number.message'
              )}
            />
          )}
        </Form.Item>

        <Form.Item label={t('profile.billing.invoice.business-activity.label')}>
          {getFieldDecorator('business_activity', {
            rules: [
              {
                required: true,
                message: t('profile.billing.invoice.business-activity.message'),
              },
            ],
          })(
            <Input
              placeholder={t(
                'profile.billing.invoice.business-activity.placeholder'
              )}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-company btn-block"
          >
            <p className="p-button">{t('profile.save-button')}</p>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const WrappedInvoiceDetailsForm = Form.create({ name: 'invoice_details' })(
  InvoiceDetailsForm
);

export default WrappedInvoiceDetailsForm;
