import React, { Component } from 'react';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import { SeverityList } from '../constants/Data';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';
import { isCompanyMode } from '../utils/General';
import { Link } from 'react-router-dom';
import '../styles/reports.less';

class ReportItemDefault extends Component {
  render() {
    const { report, currentUser, isMobile, t } = this.props;

    const createdAt = moment(report.created_at).format('DD/MM/YYYY HH:mm');
    const score = report.severity_score;

    const severityAttr =
      report.severity in SeverityList
        ? SeverityList[report.severity]
        : { color: '#000000' };

    const color = severityAttr.color;
    return (
      <Row>
        <Col span={24}>
          <div
            className={'report-item'}
            id="report-item-default"
            style={{
              borderLeft: report.program.is_ptaas
                ? '3px solid #090036'
                : '3px solid #108EE9',
            }}
          >
            {!isMobile ? (
              <Row>
                <Col md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 2 }}>
                  <div
                    className="severity-container"
                    style={{ border: `2px solid ${color}` }}
                  >
                    <p className="severity-text" style={{ color: color }}>
                      {t(`reports.filters.severity.${report.severity}`)}
                    </p>
                  </div>
                </Col>
                <Col span={1} className="centered-content">
                  <p>{score}</p>
                </Col>
                <Col span={2} className="left-content">
                  <object>
                    <Link
                      to={`/dashboard/ranking/${report.user.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => e.stopPropagation()}
                      className="highlighted-text overflow"
                    >
                      <Tooltip title={report.user.username}>
                        {report.user.username}
                      </Tooltip>
                    </Link>
                  </object>
                </Col>
                <Col span={4} className="centered-content">
                  <div className="status-container">
                    {t(
                      `reports.status.${
                        report.is_draft ? 'draft' : report.status
                      }`
                    )}
                  </div>
                </Col>
                <Col span={3}>
                  {report.program.id ? (
                    <object>
                      <Link
                        to={`/dashboard/programs/${report.program.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#000' }}
                        onClick={(e) => e.stopPropagation()}
                        className="highlighted-text overflow text-left ml-3"
                      >
                        <Tooltip title={report.program.name}>
                          {report.program.name}
                        </Tooltip>
                      </Link>
                    </object>
                  ) : (
                    '-'
                  )}
                </Col>
                <Col span={7}>
                  <div className="flex-inline">
                    <span style={{ margin: '0px 10px' }}>-</span>
                    <p className="overflow" style={{ fontWeight: '300' }}>
                      <Tooltip title={report.name}>
                        {report.name ? report.name : '-'}
                      </Tooltip>
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 1 }}>
                  <div>
                    <p>{createdAt}</p>
                  </div>
                </Col>
              </Row>
            ) : (
              <div style={{ textDecoration: 'none' }}>
                <Row>
                  <Col xs={{ span: 6 }}>
                    <div
                      className="severity-container"
                      style={{ border: `2px solid ${color}`, width: '90px' }}
                    >
                      <p className="severity-text" style={{ color: color }}>
                        {t(`reports.filters.severity.${report.severity}`)}
                      </p>
                    </div>
                  </Col>
                  <Col xs={{ span: 2 }}>
                    <p>{score}</p>
                  </Col>
                  <Col xs={{ span: 8, offset: 8 }}>
                    <div className="status-container">
                      {t(
                        `reports.status.${
                          report.is_draft ? 'draft' : report.status
                        }`
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={{ span: 14 }}>
                    {isCompanyMode(currentUser) && (
                      <object>
                        <Link
                          to={`/dashboard/ranking/${report.user.username}`}
                          style={{ color: '#000', fontWeight: 'bold' }}
                          onClick={(e) => e.stopPropagation()}
                          className="highlighted-text overflow text-left"
                        >
                          {report.user.username}
                        </Link>
                      </object>
                    )}
                  </Col>

                  <Col xs={{ span: 10 }}>
                    <p>{createdAt}</p>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col xs={{ span: 24 }} className="left-content">
                    {report.program.id ? (
                      <object>
                        <Link
                          to={`/dashboard/programs/${report.program.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#000' }}
                          onClick={(e) => e.stopPropagation()}
                          className="highlighted-text overflow text-left"
                        >
                          {report.program.name}
                        </Link>
                      </object>
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 24 }}>
                    <p className="overflow text-left">
                      {report.name ? report.name : '-'}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});
export default withTranslation()(
  withSizes(mapSizes)(connect(mapStateToProps)(ReportItemDefault))
);
