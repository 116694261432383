import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentTotalAmountWallet from '../components/PaymentTotalAmountWallet';
import { Col, Row, Table } from 'antd';
import '../themes/statistics.less';
import {
  getWalletHistory,
  getWalletHistoryPtaas,
} from '../redux/actions/wallet';
import DownloadHistoryForm from '../components/DownloadHistoryForm';
import moment from 'moment';
import Paginate from '../components/Paginate';
import { withTranslation } from 'react-i18next';
import NumberDisplay from '../utils/NumberDisplay';

import { API_ROOT } from '../middleware/api';
import { routes } from '../constants/APIEndpoints';
import LoaderComponent from '../components/LoaderComponent';

class WalletHistory extends Component {
  state = {
    currentTab: '2',
    loading: true,
    currentPage: 1,
  };

  isPtaas() {
    const url = window.location.href;
    return url.endsWith('ptaas');
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { currentPage } = this.state;
    const attributes = { page: currentPage };

    if (this.isPtaas()) {
      dispatch(getWalletHistoryPtaas(attributes));
    } else {
      dispatch(getWalletHistory(attributes));
    }
  }

  onRow(record, rowIndex) {
    return {
      onClick: () => {},
    };
  }

  convertDate(date) {
    const fechaHora = moment(date);
    return fechaHora.format('DD/MM/YYYY HH:mm');
  }

  handlePaginateData = (data) => {
    this.setState({ loading: true, currentPage: data });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    const {
      isFetching,
      wallet: { available, expended, requested, list },
      numberOfElements,
      numberOfPages,
      t,
    } = this.props;

    const { currentPage } = this.state;

    const columns = [
      {
        title: t('budget.wallet-history.date'),
        className: 'column-initial',
        dataIndex: 'date',
        width: '15%',
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: t('budget.wallet-history.user'),
        dataIndex: 'user',
        width: '15%',
        sorter: (a, b) => a.user.localeCompare(b.user),
      },
      {
        title: t('budget.wallet-history.detail'),
        dataIndex: 'description',
        width: '20%',
        sorter: (a, b) => a.description.localeCompare(b.description),
        render: (text, record) => {
          return <span dangerouslySetInnerHTML={{ __html: text }} />;
        },
      },
      {
        title: t('budget.wallet-history.amount'),
        className: 'column-right',
        dataIndex: 'amount',
        width: '14%',
        sorter: (a, b) => a.amount - b.amount,
        render: (text) => <NumberDisplay number={text} />,
      },
      {
        title: t('budget.wallet-history.balance'),
        className: 'column-final',
        dataIndex: 'balance',
        width: '14%',
        sorter: (a, b) => a.balance - b.balance,
        render: (text) => <NumberDisplay number={text} />,
      },
    ];

    const data = [];
    for (let indice in list) {
      const element = list[indice];
      const createdAt = this.convertDate(element.created_at);

      data.push({
        key: indice,
        date: createdAt,
        user: element.username ? element.username : '',
        description: element.description_html || '',
        amount: element.value,
        balance: element.running_balance,
        uuid: element.uuid,
        related_object: element.related_object,
      });
    }

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>{t('budget.wallet-history.title')}</h1>
              <h4>{t('budget.wallet-history.desc')}</h4>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col lg={8}>
              <p
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  left: '5%',
                  top: '2.65rem',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                {t('budget.wallet-history.available').toUpperCase()}
              </p>
              <PaymentTotalAmountWallet
                style={{ width: '100%' }}
                value={Math.round(available)}
                is_ptaas={this.isPtaas()}
                paid={true}
              />
            </Col>
            <Col lg={8}>
              <p
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  left: '5%',
                  top: '2.65rem',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                {t('budget.wallet-history.deposit').toUpperCase()}
              </p>
              <PaymentTotalAmountWallet
                value={Math.round(requested)}
                is_ptaas={this.isPtaas()}
              />
            </Col>
            <Col lg={8}>
              <p
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  left: '5%',
                  top: '2.65rem',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                {t('budget.wallet-history.paid').toUpperCase()}
              </p>
              <PaymentTotalAmountWallet
                value={Math.round(expended)}
                is_ptaas={this.isPtaas()}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  marginTop: '20px',
                  backgroundColor: 'white',
                  marginBottom: '30px',
                  padding: '0px 10px  ',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0',
                    paddingTop: '5px',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '16px',
                      marginLeft: '0px',
                      marginBottom: '0',
                    }}
                  >
                    {t('budget.wallet-history.history')}
                  </h1>
                  {this.isPtaas() ? (
                    <DownloadHistoryForm
                      baseFilename="PTaaS WalletHistory"
                      doAppendDatetime={true}
                      baseUrl={`${API_ROOT}${routes.GET_HISTORY_WALLET_PTAAS}`}
                    />
                  ) : (
                    <DownloadHistoryForm
                      baseFilename="Bugbounty WalletHistory"
                      doAppendDatetime={true}
                      baseUrl={`${API_ROOT}${routes.GET_HISTORYWALLET}`}
                    />
                  )}
                </div>
                {isFetching ? (
                  <LoaderComponent label={false} />
                ) : (
                  <div style={{ marginTop: '20px' }}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{ x: 750 }}
                      pagination={false}
                    />
                    <h1
                      style={{
                        fontSize: '12px',
                        color: 'gray',
                        position: 'relative',
                        bottom: '37px',
                        width: '100%',
                        height: '0',
                        marginTop: '6vh',
                      }}
                    >
                      {t('budget.programs.table-footer')}
                    </h1>
                  </div>
                )}
                <div style={{ marginBottom: '10px' }}>
                  <Paginate
                    currentPage={currentPage}
                    numberOfElements={numberOfElements}
                    numberOfPages={numberOfPages}
                    dispatcher={getWalletHistory}
                    onData={this.handlePaginateData}
                    elementName={
                      numberOfElements === 1
                        ? t('registers.verbose_name')
                        : t('registers.verbose_name_plural')
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  wallet: {
    available: state.wallet.wallet.available,
    expended: state.wallet.wallet.expended,
    list: state.wallet.wallet.list,
    requested: state.wallet.wallet.requested,
  },
  currentUser: state.currentUser.profile,
  secondFactorValidated: state.auth.secondValidated,
  secondFactorEnabled: state.auth.secondFactorEnabled,
  numberOfElements: state.wallet.numberOfElements,
  numberOfPages: state.wallet.numberOfPages,
  isFetching: state.wallet.isFetching,
});
export default withTranslation()(connect(mapStateToProps)(WalletHistory));
