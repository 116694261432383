import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Select, Row, Tooltip } from 'antd';
import PaymentStatisticsBarSeries from './PaymentStatisticsBarSeries';
import * as _ from 'lodash';
import '../themes/filter-report-statistics-form.less';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;

class FilterPaymentStatisticsForm extends Component {
  static defaultProps = {
    isPtaas: false,
  };

  onYearChange = (value) => {
    const { handleFilterChange } = this.props;
    handleFilterChange('year', value);
  };

  getYearOptions = () => {
    const { currentUserYears } = this.props;

    return currentUserYears.map((year, i) => {
      return (
        <Option key={year} value={year}>
          {` ${year}`}
        </Option>
      );
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      t,
      collection,
    } = this.props;
    const currentYear = collection.year;
    return (
      <Form onSubmit={this.handleSubmit}>
        <h6
          style={{
            textTransform: 'uppercase',
            flex: '1',
            marginBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          <Tooltip
            placement="right"
            title={
              this.props.isPtaas
                ? t('budget.ptaas.chart-tooltip')
                : t('budget.rewards.paid-tooltip')
            }
          >
            {this.props.isPtaas
              ? t('budget.ptaas.chart')
              : t('budget.rewards.paid')}
          </Tooltip>
        </h6>
        <Row gutter={10}>
          <Col xs={24} sm={6}>
            <FormItem>
              {getFieldDecorator('year', {
                initialValue: currentYear,
              })(
                <Select
                  className="placeholderGraphics"
                  placeholder={`${currentYear}`}
                  onChange={this.onYearChange}
                >
                  {this.getYearOptions()}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <PaymentStatisticsBarSeries
              isPtaas={this.props.isPtaas}
              data={collection}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedFilterPaymentStatisticsForm = Form.create()(
  FilterPaymentStatisticsForm
);

export default withTranslation()(WrappedFilterPaymentStatisticsForm);
