import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import ProgramView from './ProgramView';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Tabs, Dropdown, Menu } from 'antd';

import '../themes/statistics.less';
import { withTranslation } from 'react-i18next';
import ProgramManagers from '../components/ProgramManagers';
import axiosInstance from '../axiosInstance';
import { isCompanyMode } from '../utils/General';

import qs from 'qs';
import { combineAbilities, defineProgramAbilityFor } from '../ability';
import { subject } from '@casl/ability';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { isArray } from 'lodash';
import { openNotification } from '../utils/General';
import { handleAxiosError } from '../utils/Http';
import '../styles/program-container.less';
import LoaderComponent from '../components/LoaderComponent';

const TabPane = Tabs.TabPane;

class ProgramContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      program: null,
      templatesLoading: false,
      templates: null,

      role: null,
      roleLoading: false,
      roleReady: false,

      finalAbility: props.userAbility,
    };
  }

  tabClickHandler = (key) => {
    this.props.history.push(key);
  };

  async fetchRole() {
    this.setState({ roleLoading: true });

    const params = {};

    try {
      const response = await axiosInstance.get(
        `/programs/${this.state.program.id}/role/`,
        { params }
      );

      const finalAbility = combineAbilities(
        this.props.userAbility,
        defineProgramAbilityFor(this.props.currentUser, response.data.role)
      );

      this.setState({
        role: response.data.role,
        finalAbility: finalAbility,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error 404: Resource not found');
      } else {
        console.error('Error fetching data', error);
      }
    } finally {
      this.setState({ roleLoading: false, roleReady: true });
    }
  }

  async fetchTemplates() {
    this.setState({ templatesLoading: true });

    const params = {};
    try {
      const response = await axiosInstance.get(
        `/programs/${this.state.program.id}/templates/`,
        { params }
      );
      this.setState({
        templates: response.data,
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error 404: Resource not found');
      } else {
        console.error('Error fetching data', error);
      }
    } finally {
      this.setState({ templatesLoading: false });
    }
  }

  componentDidMount() {
    this.fetchData();
    this.setState({ finalAbility: this.props.userAbility });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.program !== this.state.program && this.state.program) {
      if (isCompanyMode(this.props.currentUser)) {
        this.fetchRole();
      } else {
        this.setState({ roleReady: true });
      }
    }

    if (
      prevState.roleReady !== this.state.roleReady &&
      this.state.roleReady === true
    ) {
      if (
        this.state.finalAbility.can(
          'download_templates',
          subject('Program', this.state.program)
        )
      ) {
        this.fetchTemplates();
      }
    }

    if (prevProps.userAbility !== this.props.userAbility) {
      this.setState({ finalAbility: this.props.userAbility });
    }
  }

  async fetchData() {
    this.setState({ loading: true });

    const params = {};
    try {
      const response = await axiosInstance.get(
        `/programs/${this.props.match.params.id}/`,
        { params }
      );
      this.setState({
        program: response.data,
      });
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangeProgramStatus = async (status) => {
    const { t } = this.props;
    try {
      const response = await axiosInstance.patch(
        `/programs/${this.props.match.params.id}/`,
        { status: status }
      );

      this.setState({
        program: {
          ...this.state.program,
          status: response.data.status,
        },
      });

      openNotification(
        'success',
        `${t('notifications.programs.update')}`,
        `${t('notifications.programs.status-change')}`
      );
    } catch (error) {
      handleAxiosError(error);
    }
  };

  createReport = () => {
    const { history } = this.props;
    const { program } = this.state;

    const queryParams = qs.stringify({ program: program.id });

    history.push({
      pathname: `/dashboard/reports/create`,
      state: { program: program, isEditing: false },
      search: `?${queryParams}`,
    });
  };

  downloadReport = () => {
    const { program } = this.state;
    let url =
      program &&
      program.pentest_as_a_service &&
      program.pentest_as_a_service.report_file_url;
    window.open(url, '_blank');
  };

  downloadSudoku = (slug) => {
    const { program } = this.state;
    this.setState({ loadingButton: true });
    axiosInstance
      .post(
        `/programs/${this.props.match.params.id}/generate-draft-document/`,
        { slug: slug },
        { responseType: 'blob' }
      )
      .then((response) => {
        saveAs(
          response.data,
          `${program.name} - ${moment().format('YYYY-MM-DD HHmmss')}.docx`
        );
        this.setState({ loadingButton: false });
      })
      .catch((error) => {
        openNotification('error', 'Error', 'Something bad happened');
        this.setState({ loadingButton: false });
      });
  };

  handleDownloadReportTemplateClick = (e) => {
    this.downloadSudoku(e.key);
  };

  getJoinProgramButton = () => {
    const { t, currentUser } = this.props;
    const { program, templates } = this.state;

    const allowToDownloadPtaasReport =
      (isCompanyMode(currentUser) || !isCompanyMode(currentUser)) &&
      program.is_ptaas === true &&
      program.pentest_as_a_service &&
      program.pentest_as_a_service.report_file_url &&
      program.pentest_as_a_service.report_file_url !== null;

    const isTemplates = isArray(templates) && templates.length > 0;

    if (program.status === 'active' || isCompanyMode(currentUser)) {
      if (program.i_am_joined || isCompanyMode(currentUser)) {
        return (
          <div id="program-container">
            {program.is_ptaas && (
              <Tooltip
                title={
                  !allowToDownloadPtaasReport &&
                  t('programs.final-report-tooltip')
                }
              >
                <Button
                  className="btn-hacker"
                  loading={this.state.loadingButton}
                  disabled={!allowToDownloadPtaasReport}
                  onClick={this.downloadReport}
                  style={{
                    fontSize: 15,
                    padding: '0px 25px',
                    height: '40px',
                    backgroundColor: allowToDownloadPtaasReport
                      ? '#090036'
                      : '#717982',
                    color: 'white',
                  }}
                >
                  {t(`programs.final_report`)}
                </Button>
              </Tooltip>
            )}
            {this.state.finalAbility.can(
              'add_report',
              subject('Program', this.state.program)
            ) && (
              <Button
                className="btn-hacker"
                loading={this.state.loadingButton}
                onClick={this.createReport}
                style={{
                  fontSize: 15,
                  padding: '0px 25px',
                  height: '40px',
                  backgroundColor: '#090036',
                  marginLeft: '15px',
                }}
              >
                {t('programs.report-finding')}
              </Button>
            )}

            {this.state.finalAbility.can(
              'download_templates',
              subject('Program', this.state.program)
            ) &&
              this.state.templates && (
                <Dropdown
                  overlay={
                    <Menu onClick={this.handleDownloadReportTemplateClick}>
                      {this.state.templates.map((tpl, index) => (
                        <Menu.Item key={tpl.slug}>
                          <span style={{ fontSize: 15 }}>{tpl.name}</span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  style={{
                    backgroundColor: isTemplates ? '#090036' : '#717982',
                  }}
                  trigger={['click']}
                >
                  <Tooltip
                    title={!isTemplates && t('programs.generate-tooltip')}
                  >
                    <Button
                      className={`custom-button ${
                        isTemplates ? 'enabled' : 'disabled'
                      }`}
                      loading={this.state.loadingButton}
                    >
                      <p className="text-button">{t('programs.generate')}</p>
                      <CaretDownOutlined className="icon-button" />
                    </Button>
                  </Tooltip>
                </Dropdown>
              )}
          </div>
        );
      } else {
        return (
          <Button
            className="btn-hacker block-center btn-unirme"
            loading={this.state.loadingButton}
            onClick={this.joinToProgram}
            style={{
              fontSize: 15,
              padding: '0px 25px',
              height: '40px',
            }}
          >
            Unirme
          </Button>
        );
      }
    }
  };

  render() {
    if (
      this.props.location.pathname ===
      `/dashboard/programs/${this.props.match.params.id}`
    ) {
      this.props.history.push(
        `/dashboard/programs/${this.props.match.params.id}/details`
      );
    }

    const { currentUser, t } = this.props;

    if (this.state.loading || this.state.program === null) {
      return <LoaderComponent label={false} />;
    }

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <div>
            <Row className="section-title">
              <Col span={24}>
                <h1>{t('programs.detail')}</h1>
                <h4>{t('programs.detail-desc')}</h4>
              </Col>
            </Row>
            <Row align="right" justify="end">
              <Col span={24} className="right-content">
                {this.getJoinProgramButton()}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs
                  activeKey={
                    this.props.location.pathname + this.props.location.hash
                  }
                  onTabClick={this.tabClickHandler}
                  className="custom-ant-tabs"
                >
                  <TabPane
                    tab={<span>{t('programs.tabs.detail')}</span>}
                    key={`/dashboard/programs/${this.props.match.params.id}/details`}
                  />
                  <TabPane
                    tab={
                      <span>
                        {`${t('programs.tabs.reports')} (${
                          this.state.program.number_of_reports
                        })`}
                      </span>
                    }
                    key={`/dashboard/reports?program=${this.props.match.params.id}`}
                  />

                  {!this.state.program.is_ptaas &&
                    this.state.finalAbility.can('view_finance', 'Company') && (
                      <TabPane
                        tab={<span>{t('programs.tabs.budget')}</span>}
                        key={`/dashboard/finance`}
                      />
                    )}

                  <TabPane
                    tab={<span>{t('programs.tabs.updates')}</span>}
                    key={`/dashboard/programs/${this.props.match.params.id}/details#updates`}
                  />

                  {isCompanyMode(currentUser) && (
                    <TabPane
                      tab={<span>{t('programs.tabs.users.label')}</span>}
                      key={`/dashboard/programs/${this.props.match.params.id}/users`}
                    />
                  )}
                </Tabs>

                <div>
                  <Switch>
                    <Route
                      path="/dashboard/programs/:id/users"
                      render={(props) => (
                        <ProgramManagers
                          {...props}
                          program={this.state.program}
                          canAddUser={this.state.finalAbility.can(
                            'add_user',
                            subject('Program', this.state.program)
                          )}
                          canRemoveUser={this.state.finalAbility.can(
                            'add_user',
                            subject('Program', this.state.program)
                          )}
                        />
                      )}
                    />
                    <Route
                      path="/dashboard/programs/:id/details"
                      render={(props) => (
                        <ProgramView
                          {...props}
                          program={this.state.program}
                          ability={this.state.finalAbility}
                          onProgramStatusChange={this.handleChangeProgramStatus}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  userAbility: state.ability,
});
export default withRouter(
  withTranslation()(connect(mapStateToProps)(ProgramContainer))
);
