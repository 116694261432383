import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Dropdown, Image, Menu } from 'antd';
import { withTranslation } from 'react-i18next';
import FileDownloader from './FileDownloader';

class DownloadHistoryForm extends Component {
  static defaultProps = {
    doAppendDatetime: true,
  };

  render() {
    const { t } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <FileDownloader
            className="reports-exports-menu-btn"
            doAppendDatetime={this.props.doAppendDatetime}
            filename={`${this.props.baseFilename}.xlsx`}
            url={`${this.props.baseUrl}?format=xlsx`}
          >
            Download XLSX
          </FileDownloader>
        </Menu.Item>
        <Menu.Item key="2">
          <FileDownloader
            className="reports-exports-menu-btn"
            doAppendDatetime={this.props.doAppendDatetime}
            filename={`${this.props.baseFilename}.csv`}
            url={`${this.props.baseUrl}?format=csv`}
          >
            Download CSV
          </FileDownloader>
        </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <div
          style={{
            display: 'block',
            width: 'calc(100% - 20px)',
            margin: '0 15px',
            textAlign: 'center',
            fontSize: 14,
            borderRadius: 5,
            cursor: 'pointer',
            marginTop: '25px',
            color: 'white',
          }}
        >
          <Dropdown overlay={menu} disabled={this.props.isFetching}>
            <Button
              style={{ width: '100%', height: '50%', background: '#717982' }}
            >
              <div className="centered-content">
                <Image
                  src={process.env.PUBLIC_URL + '/images/icn_download.png'}
                  preview={false}
                  style={{ margin: '10px 1px' }}
                />
                <p className="p-button">{t('exports.download-button')}</p>
              </div>
            </Button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

const WrappedDownloadPaymentsForm = Form.create()(DownloadHistoryForm);

export default withTranslation()(WrappedDownloadPaymentsForm);
