import React, { Component } from 'react';
import ProgramItem from './ProgramItem';
import { Card, Row, Col } from 'antd';
import '../themes/loading-icon.less';

import { withTranslation } from 'react-i18next';
import LoaderComponent from './LoaderComponent';
import { Link } from 'react-router-dom';

class ProgramListResume extends Component {
  static defaultProps = {
    onPageChange: null,
    loading: false,
    renderPagination: () => {},
    renderFilters: () => {},
    renderEmpty: () => {},
  };

  handlePaginateData = (data) => {
    this.updatePage(data);
  };

  updatePage(page) {
    this.props.updatePage(page);
  }

  onSearchChange = (value) => {
    this.props.onFilterChange('search', value);
  };

  render() {
    const { programs, loading, t } = this.props;

    const userHasNoPrograms = programs.length === 0 && !this.props.filtering;

    return (
      <span>
        {!userHasNoPrograms && this.props.renderFilters()}
        <div id="companyProgramList">
          {loading ? (
            <LoaderComponent label={false} />
          ) : programs.length === 0 ? (
            this.props.filtering ? (
              <Row className="no-filters">
                <Col sm={{ offset: 6, span: 12 }}>
                  <Card>
                    <h3 className="text-center">
                      {t('dashboard.programs-list')}
                    </h3>
                  </Card>
                </Col>
              </Row>
            ) : (
              this.props.renderEmpty()
            )
          ) : (
            programs.map((program, index) => {
              if (this.props.onItemClick) {
                return (
                  <ProgramItem
                    companyMode={this.props.companyMode}
                    program={program}
                    onClick={this.props.onItemClick}
                    dashboard={true}
                  />
                );
              } else {
                return (
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                    key={index}
                    to={`/dashboard/programs/${program.id}`}
                  >
                    <ProgramItem
                      companyMode={this.props.companyMode}
                      program={program}
                      dashboard={true}
                    />
                  </Link>
                );
              }
            })
          )}
        </div>
        {this.props.renderPagination()}
      </span>
    );
  }
}

export default withTranslation()(ProgramListResume);
