import React, { Component } from 'react';
import { Col, Row } from 'antd';
import DownloadProgramsForm from '../components/DownloadProgramsForm';

import ProgramFinanceList from '../components/ProgramFinanceList';
import Pagination from './Pagination';
import { withTranslation } from 'react-i18next';

class CompanyPrograms extends Component {
  render() {
    const {
      programs,
      currentPage,
      totalPages,
      totalItems,
      isPtaas,
      loading,
      companyCurrentBalance,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <h1 style={{ fontSize: '20px' }}>{t('programs.title')}</h1>
          </Col>
          <Col
            xs={{ span: 8, offset: 4 }}
            lg={{ span: 6, offset: 6 }}
            xl={{ span: 6, offset: 6 }}
            style={{ marginTop: '-5px', marginBottom: '20px' }}
          >
            <DownloadProgramsForm
              payments={programs}
              path={'/programs/export_my_programs'}
              fileName={'programs'}
              loading={loading}
            />
          </Col>
        </Row>

        <div id="Payments" style={{ paddingBottom: '0' }}>
          <ProgramFinanceList
            programs={programs}
            loading={loading}
            isPtaas={isPtaas}
            companyCurrentBalance={companyCurrentBalance}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            verbose={
              totalItems === 1
                ? t('registers.verbose_name')
                : t('registers.verbose_name_plural')
            }
            onPageChange={this.props.handlePageChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(CompanyPrograms);
