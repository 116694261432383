import * as Action from '../actions/ActionTypes';
import * as _ from 'lodash';

const initialState = {
  report: null,
  isPushing: false,
  isFetching: false,
  isSaved: false,
  isUpdated: false,
  uuid: null,
  hasError: false,
  errorMessage: null,
  isSuccess: false,
  mediaSteps: [],
  attackTypeList: null,
};

class ReportReducer {
  static reduce(state = initialState, action) {
    if (ReportReducer[action.type]) {
      return ReportReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.GET_REPORT_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
      report: null,
    };
  }

  static [Action.GET_REPORT_SUCCESS](state, action) {
    const { response } = action;

    return {
      ...state,
      isFetching: false,
      hasError: false,
      report: response,
    };
  }

  static [Action.GET_REPORT_ERRORS](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CREATE_REPORT_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.CREATE_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      report: action.response,
      uuid: action.response.id,
      hasError: false,
    };
  }

  static [Action.CREATE_REPORT_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      isPushing: false,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_REPORT_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      isSaved: false,
    };
  }

  static [Action.UPDATE_REPORT_SUCCESS](state, action) {
    const user = state.report && state.report.user ? state.report.user : null;
    const program =
      state.report && state.report.program ? state.report.program : null;
    return {
      ...state,
      report: {
        ...action.response,
        program: program,
        user: user,
      },
      isPushing: false,
      isSaved: true,
      hasError: false,
    };
  }

  static [Action.UPDATE_REPORT_ERRORS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_CURRENT_REPORT_SUCCESS](state, action) {
    const {
      data: { report },
    } = action;

    return {
      ...state,
      report:
        state.report && state.report.id === report.id
          ? Object.assign(_.clone(state.report), report)
          : state.report,
    };
  }

  static [Action.UPDATE_STATUS_REPORT_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      isUpdated: false,
      hasError: false,
    };
  }

  static [Action.UPDATE_STATUS_REPORT_SUCCESS](state, action) {
    const user = state.report && state.report.user ? state.report.user : null;
    const program =
      state.report && state.report.program ? state.report.program : null;
    return {
      ...state,
      report: {
        ...action.response,
        user: user,
        program: program,
      },
      isPushing: false,
      isUpdated: true,
    };
  }

  static [Action.UPDATE_STATUS_REPORT_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.DELETE_REPORT_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.DELETE_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: false,
    };
  }

  static [Action.DELETE_REPORT_ERRORS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.ADD_ATTACHMENT_STEP_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.ADD_ATTACHMENT_STEP_SUCCESS](state, action) {
    const result = action.response;
    const updatedSteps = [...state.mediaSteps];

    updatedSteps.push({
      label: result.label,
      media_id: result.media_id,
      original_image: result.original_image,
      size: result.size,
      thumbnail_180: result.thumbnail_180,
    });
    return {
      ...state,
      mediaSteps: updatedSteps,
      isPushing: false,
      isSaved: true,
      isSuccess: true,
    };
  }

  static [Action.ADD_ATTACHMENT_STEP_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
      isSuccess: false,
    };
  }

  static [Action.REMOVE_ATTACHMENT_STEP_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.REMOVE_ATTACHMENT_STEP_SUCCESS](state, action) {
    const { result } = action.response;
    const { report } = state;

    const step = _.filter(report.steps, (step) => {
      return step.id === result.step_id;
    });

    _.remove(step[0].files, (file) => {
      return file.id.toString() === result.id;
    });

    return {
      ...state,
      isPushing: false,
      hasError: false,
    };
  }

  static [Action.REMOVE_ATTACHMENT_STEP_ERRORS](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CREATE_REPORT_STEP_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.CREATE_REPORT_STEP_SUCCESS](state, action) {
    const { result } = action.response;
    const { report } = state;

    const newStep = {
      id: result.id,
      description: result.description,
      files: result.files,
    };

    return {
      ...state,
      isPushing: false,
      hasError: false,
      report: {
        ...report,
        steps: _.unionBy([...report.steps], [newStep]),
      },
    };
  }

  static [Action.CREATE_REPORT_STEP_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }

  static [Action.REMOVE_REPORT_STEP_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.REMOVE_REPORT_STEP_SUCCESS](state, action) {
    const { result } = action.response;
    const { report } = state;

    const steps = _.clone(report.steps);
    _.remove(steps, (step) => {
      return step.id.toString() === result.id;
    });

    return {
      ...state,
      isPushing: false,
      hasError: false,
      report: {
        ...report,
        steps: steps,
      },
    };
  }

  static [Action.REMOVE_REPORT_STEP_ERRORS](state, action) {
    return {
      ...state,
      hasError: true,
      errorMessage: action.error,
      isPushing: false,
    };
  }

  static [Action.CREATE_PAYMENT_REQUEST](state, action) {
    return {
      ...state,
      rewarding: true,
    };
  }

  static [Action.CREATE_PAYMENT_SUCCESS](state, action) {
    return {
      ...state,
      rewarding: false,
      hasError: false,
      report: {
        ...state.report,
        can_be_rewarded: false,
        is_paid: false,
      },
    };
  }

  static [Action.CREATE_PAYMENT_ERROR](state, action) {
    return {
      ...state,
      rewarding: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.LIST_ATTACK_TYPE_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
      report: null,
    };
  }

  static [Action.LIST_ATTACK_TYPE_SUCCESS](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      attackTypeList: action.response,
    };
  }

  static [Action.LIST_ATTACK_TYPE_ERRORS](state, action) {
    return {
      ...state,
      isFetching: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CLEAR_REPORT](state, action) {
    return initialState;
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default ReportReducer.reduce;
