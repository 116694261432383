import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import HistoryProgram from '../components/HistoryProgram';
import { Avatar, Card, Col, Image, Row } from 'antd';
import ProgramFeatures from '../components/ProgramFeatures';

import { Link, withRouter } from 'react-router-dom';

import { joinProgram } from '../redux/actions/program';
import ProgramServicesForm from '../components/ProgramServicesForm';
import ProgramDescription from '../components/ProgramDescription';
import NumberDisplay from '../utils/NumberDisplay';
import { withTranslation } from 'react-i18next';
import ProgramInfo from '../components/ProgramInfo';
import axiosInstance from '../axiosInstance';
import { subject } from '@casl/ability';
import { isCompanyMode } from '../utils/General';
import { openNotification } from '../utils/General';
import '../styles/program-view.less';
import LoaderComponent from '../components/LoaderComponent';

class ProgramView extends Component {
  state = {
    loadingButton: false,
    isJoining: false,
    i_am_joined: false,
    visibleModal: false,
    isMouseInside: false,
    terms: false,

    program: null,
    loading: false,
    services: [],
    servicesLoading: false,

    activities: [],
    activitiesLoading: false,
    activitiesPage: 1,
    activitiesTotalItems: 0,

    features: [],
    featuresLoading: false,
    featuresPage: 1,
    featuresTotalItems: 0,
  };

  itemsPerPage = 10;

  observeDOMChangesAndScroll = (elementId) => {
    if (this.observer) {
      this.observer.disconnect();
    }

    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.observer = new MutationObserver(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          this.observer.disconnect(); // Stop observing once the element is found
        }
      });
      this.observer.observe(document.body, { childList: true, subtree: true });
    }
  };

  fetchActivities() {
    this.setState({ activitiesLoading: true });

    const params = {
      page: this.state.activitiesPage || 1,
    };

    axiosInstance
      .get(`/programs/${this.props.match.params.id}/activities/`, { params })
      .then((response) => {
        this.setState({
          activities: response.data.results,
          activitiesLoading: false,
          activitiesTotalItems: response.data.count,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ activitiesLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchServices() {
    this.setState({ servicesLoading: true });

    const params = {};

    axiosInstance
      .get(`/current-company/services/`, { params })
      .then((response) => {
        this.setState({
          services: response.data.results,
          servicesLoading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ servicesLoading: false }); // Set loading to false even if there is an error
      });
  }

  fetchFeatures() {
    this.setState({ featuresLoading: true });

    const params = {
      page: this.state.featuresPage || 1,
    };

    axiosInstance
      .get(`/programs/${this.props.match.params.id}/features/`, { params })
      .then((response) => {
        this.setState({
          features: response.data.results,
          featuresLoading: false,
          featuresTotalItems: response.data.count,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ featuresLoading: false }); // Set loading to false even if there is an error
      });
  }

  componentDidMount() {
    this.fetchActivities();
    this.fetchFeatures();
    if (isCompanyMode(this.props.currentUser)) {
      this.fetchServices();
    }

    const { hash } = this.props.location;
    if (hash) this.observeDOMChangesAndScroll(hash.substring(1));
  }

  componentDidUpdate(prevProps, prevState) {
    const { hash } = this.props.location;
    if (hash) this.observeDOMChangesAndScroll(hash.substring(1));

    if (prevProps.program != this.props.program && this.props.program) {
      if (
        !this.props.program.is_ptaas &&
        isCompanyMode(this.props.currentUser)
      ) {
        this.fetchServices();
      }
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  joinToProgram = () => {
    const { dispatch } = this.props;
    const { program } = this.state;

    this.setState({
      loadingButton: true,
      isJoining: true,
    });

    dispatch(joinProgram(program.id)).then(() => {
      window.location.reload();
    });
  };

  showModal = () => {
    this.setState({
      visibleModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      visibleModal: false,
    });
  };

  verifyQuote() {
    const { program } = this.props;

    if (program.is_paused) {
      return true;
    }

    return program.available_quote_percent >= 100;
  }

  handleFeaturesGetMore = () => {
    if (
      this.state.featuresPage <
      Math.ceil(this.state.featuresTotalItems / this.itemsPerPage)
    ) {
      this.setState(
        {
          featuresPage: this.state.featuresPage + 1,
        },
        () => {
          this.fetchFeatures();
          this.fetchActivities();
        }
      );
    }
  };

  handleProgramHistoryPageChange = (page) => {
    this.setState(
      {
        activitiesPage: page,
      },
      () => {
        this.fetchActivities();
      }
    );
  };

  handleFeatureSubmit = (values) => {
    const params = {
      ...values,
    };

    axiosInstance
      .post(`/programs/${this.props.match.params.id}/features/`, params)
      .then((response) => {
        this.setState(
          {
            featuresPage: 1,
          },
          () => {
            this.fetchFeatures();
            this.fetchActivities();
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleFeatureDelete = (id) => {
    const { t } = this.props;

    axiosInstance
      .delete(`/programs/${this.props.match.params.id}/features/${id}/`)
      .then((response) => {
        openNotification(
          'success',
          t(`programs.notifications.delete-feature.success`)
        );
        this.setState(
          {
            featuresPage: 1,
            activitiesPagePage: 1,
          },
          () => {
            this.fetchFeatures();
            this.fetchActivities();
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  renderProgram = () => {
    const { currentUser, t, program } = this.props;

    const rewardListItem = (name, amount, color) => {
      return (
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 5 }}
          >
            <div className="flex-inline">
              <div
                className="reward-bullet"
                style={{ backgroundColor: color }}
              />
              <p>{name}</p>
            </div>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 10 }}
            lg={{ span: 8 }}
          >
            <p>
              <NumberDisplay number={amount} showUSD={true} />
            </p>
          </Col>
        </Row>
      );
    };

    const fileList = _.isEmpty(program.files) ? (
      <p>{t('programs.no-files')}</p>
    ) : (
      program.files.map((file, index) => {
        return (
          <li key={index}>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              {file.name}
            </a>
          </li>
        );
      })
    );

    const targetList = _.isEmpty(program.targets) ? (
      <p>No targets</p>
    ) : (
      program.targets.map((target, index) => {
        return (
          <li key={index}>
            <p>{target.target}</p>
          </li>
        );
      })
    );

    const hackerList = program.hackers.map((hacker, index) => {
      const { username, avatar_thumbnail_s_url } = hacker;
      return (
        <Row key={index}>
          <Col
            xs={{ span: 6 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 4 }}
            style={{ paddingLeft: '15px', paddingTop: '10px' }}
          >
            <Link to={`/dashboard/ranking/${username}`} target="_blank">
              <Avatar
                src={avatar_thumbnail_s_url || '/images/profile-default.png'}
                size={'large'}
              />
            </Link>
          </Col>
          <Col
            xs={{ span: 10 }}
            sm={{ span: 10 }}
            md={{ span: 12 }}
            lg={{ span: 18 }}
          >
            <Link to={`/dashboard/ranking/${username}`} target="_blank">
              <div style={{ marginTop: '10px' }}>{username}</div>
            </Link>
          </Col>
        </Row>
      );
    });

    return (
      <Row gutter={24}>
        <Col xs={24} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 10 }}>
          {this.props.isFetching ? (
            <LoaderComponent label={false} />
          ) : (
            <ProgramInfo
              program={program}
              companyMode={isCompanyMode(this.props.currentUser)}
              editable={this.props.ability.can(
                'update',
                subject('Program', program)
              )}
              showChangeStatus={this.props.ability.can(
                'update_status',
                subject('Program', program)
              )}
              onProgramStatusChange={this.props.onProgramStatusChange}
            />
          )}
          {!program.is_ptaas && (
            <Card id="program-rewards" style={{ marginTop: '3vh' }}>
              <h2>
                <Image
                  className="icon"
                  preview={false}
                  src={`/images/rewards.png`}
                />
                {t('rewards.title')}
              </h2>
              {rewardListItem(
                t('severities.critical'),
                program.rewards_info.bounty_critical,
                '#91243E'
              )}
              {rewardListItem(
                t('severities.high'),
                program.rewards_info.bounty_high,
                '#DD4B50'
              )}
              {rewardListItem(
                t('severities.medium'),
                program.rewards_info.bounty_medium,
                '#F18C43'
              )}
              {rewardListItem(
                t('severities.low'),
                program.rewards_info.bounty_low,
                '#F8C851'
              )}
            </Card>
          )}
          <Card id="program-details" style={{ marginTop: '3vh' }}>
            <h2>
              <Image
                className="icon"
                preview={false}
                src={`/images/targuet.png`}
              />
              Targets
            </h2>
            <ul>
              <li>{targetList}</li>
            </ul>
          </Card>
          <Card id="program-details" style={{ marginTop: '3vh' }}>
            <h2>
              <Image
                className="icon"
                preview={false}
                src={`/images/files.png`}
              />
              {t('programs.files')}
            </h2>
            {fileList}
          </Card>

          <Card id="program-details" style={{ marginTop: '3vh' }}>
            <h2>Hackers ({program.hackers.length})</h2>
            {hackerList}
          </Card>
          {!program.is_ptaas && isCompanyMode(currentUser) && (
            <Card id="program-details" style={{ marginTop: '3vh' }}>
              <h2>{t('programs.services')}</h2>
              <ProgramServicesForm
                services={this.state.services}
                program={program}
              />
            </Card>
          )}
        </Col>

        <Col
          xs={24}
          sm={{ span: 24 }}
          md={{ span: 16 }}
          lg={{ span: 14 }}
          xl={{ span: 14 }}
        >
          <Row>
            <Col span={24}>
              <ProgramDescription
                editable={this.props.ability.can(
                  'update',
                  subject('Program', program)
                )}
                program={program}
              />
            </Col>
          </Row>
          <Row id="updates" style={{ marginTop: '3vh' }}>
            <Col span={24}>
              <ProgramFeatures
                handleSubmit={this.handleFeatureSubmit}
                canDelete={this.props.ability.can(
                  'update',
                  subject('Program', program)
                )}
                handleDelete={this.handleFeatureDelete}
                features={this.state.features}
                totalItems={this.state.featuresTotalItems}
                totalPages={Math.ceil(
                  this.state.featuresTotalItems / this.itemsPerPage
                )}
                handleGetMoe={this.handleFeaturesGetMore}
                loading={this.state.featuresLoading}
                formEnabled={this.props.ability.can(
                  'update',
                  subject('Program', program)
                )}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '3vh' }}>
            <Col span={24}>
              <HistoryProgram
                activities={this.state.activities}
                loading={this.state.activitiesLoading}
                currentPage={this.state.activitiesPage}
                totalItems={this.state.activitiesTotalItems}
                totalPages={Math.ceil(
                  this.state.activitiesTotalItems / this.itemsPerPage
                )}
                handlePageChange={this.handleProgramHistoryPageChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    const { program } = this.props;
    const { loading } = this.state;

    if (loading || program === null) return <LoaderComponent />;

    let content;
    if (this.props.program) {
      content = this.renderProgram();
    } else {
      content = <LoaderComponent label={false} />;
    }

    return content;
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
});
export default withRouter(
  withTranslation()(connect(mapStateToProps)(ProgramView))
);
