import React, { useState } from 'react';
import {
  CameraOutlined,
  CloseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Tooltip,
  Progress,
  Upload,
  Image,
} from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useProgramDetails from '../hooks/useProgramDetails';
import { getProgramStatus } from '../utils/ProgramHelper';
import ImageCrop from './ImageCrop';
import ProgramStatusForm from './ProgramStatusForm';
import NumberDisplay from '../utils/NumberDisplay';
import '../styles/program-view.less';

const ProgramInfo = (props) => {
  const { program } = props;
  const { t } = useTranslation();
  const [programAvatarUpload, setProgramAvatarUpload] = useState(null);
  const [mayCropFile, setMayCropFile] = useState(null);

  const { budgetPercent, creationDate, updateDate, startDate, endDate } =
    useProgramDetails(program);
  const { programState, program_class } = getProgramStatus(program, t);

  const updateAvatar = () => {
    const props = {
      customRequest: function () {
        return null;
      },
      accept: 'image/*',
      showUploadList: false,
      multiple: false,
      beforeUpload: (file) => {
        const isLt60M = file.size / 1024 / 1024 < 25;
        if (isLt60M) {
          setProgramAvatarUpload(file);
          setMayCropFile(true);
        }
        return isLt60M;
      },
    };
    return (
      <div style={{ marginTop: '-70px', marginLeft: '-5px' }}>
        <Upload {...props} className="avatar-uploader">
          <Button
            style={{ paddingTop: '-245px' }}
            shape="circle"
            icon={<CameraOutlined />}
          />
        </Upload>
      </div>
    );
  };

  const cropImageDone = (croppedFile) => {
    setProgramAvatarUpload(croppedFile);
    setMayCropFile(true);
  };

  return (
    <Card id="program-info">
      <div className="card-container">
        <Row style={{ margin: 0 }}>
          <Col xs={{ span: 4 }} lg={{ span: 4 }}>
            <div className="avatar-container">
              <Avatar
                className="img-thumbnail rounded-circle"
                size="large"
                src={program.avatar_thumbnail_s_url || null}
              />

              {props.companyMode && updateAvatar()}
              {mayCropFile && (
                <ImageCrop
                  file={programAvatarUpload}
                  cropDone={cropImageDone}
                  program={program.id}
                />
              )}
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 20 }}>
            <div className="program-details">
              <h2>
                {program.name
                  ? program.name.length > 61
                    ? program.name.substring(0, 61) + '...'
                    : program.name
                  : '-'}
              </h2>
            </div>
          </Col>
          <Col className="program-button-container">
            <Button
              className={`btn-${program_class} btn-program-item program-button`}
            >
              {programState}
            </Button>
          </Col>
          {program.is_ptaas ? (
            <Col className="program-ptaas-label-container">
              <div className="program-ptaas-label program-ptaas-label-rounded ">
                <span className="p-program-info">
                  {t(`ptaa_programs.verbose_name`)}
                </span>
              </div>
            </Col>
          ) : (
            <Col className="program-ptaas-label-container">
              <div className="program-bb-label program-bb-label-rounded ">
                <span className="p-program-info">
                  {t(`bb_programs.verbose_name`)}
                </span>
              </div>
            </Col>
          )}
        </Row>
      </div>

      <Row className="program-details-container-row">
        <Col span={17} className="program-details-container-col">
          <Row>
            <Col span={24}>
              <Row className="program-details-items">
                <p>{program.company.name}</p>
              </Row>
              <Row className="program-details-items">
                <ul>
                  {program.program_types.map((p, index) => (
                    <li key={index}>{t(`programs.program-types.${p.code}`)}</li>
                  ))}
                </ul>
              </Row>
              {!program.is_ptaas && (
                <Row className="program-details-items">
                  {props.companyMode && (
                    <Col span={10}>
                      <p style={{ fontSize: '11px' }}>
                        <Tooltip title={t('programs.create-date')}>
                          <Image
                            className="date-img"
                            preview={false}
                            src={`/images/creation-date.png`}
                          />
                          {creationDate}
                        </Tooltip>
                      </p>
                    </Col>
                  )}
                  <Col span={10}>
                    <p style={{ fontSize: '11px' }}>
                      <Tooltip title={t('programs.update-date')}>
                        <Image
                          className="date-img"
                          preview={false}
                          src={`/images/update-date.png`}
                        />
                        {updateDate}
                      </Tooltip>
                    </p>
                  </Col>
                </Row>
              )}

              {program.is_ptaas && (
                <Row
                  style={{
                    padding: '5px 0px',
                  }}
                >
                  <Col span={24}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ fontSize: '14px' }}>
                        {t('programs.form.fields.test-type.name')}: &nbsp;
                      </p>
                      <p>
                        {' '}
                        {t(
                          `programs.form.fields.test-type.${program.pentest_as_a_service.test_type}`
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
              )}

              {program.is_ptaas && (
                <Row className="program-details-items">
                  <Col>
                    <div style={{ display: 'flex' }}>
                      <p style={{ fontSize: '14px' }}>
                        {t('programs.form.credits')}: &nbsp;
                      </p>
                      &nbsp;
                      <p> {program.pentest_as_a_service.credits}</p>
                    </div>
                  </Col>
                </Row>
              )}
              {program.is_ptaas && (
                <Row className="program-details-items">
                  <Col span={11} className="flex-inline">
                    <PlayCircleOutlined />
                    <p style={{ fontSize: '11px' }}>&nbsp; {startDate}</p>
                  </Col>
                  <Col span={11} className="flex-inline">
                    <CloseCircleOutlined />
                    <p style={{ fontSize: '11px' }}>&nbsp; {endDate}</p>
                  </Col>
                </Row>
              )}
              {props.companyMode && !program.is_ptaas ? (
                <Row>
                  <div style={{ display: 'flex' }}>
                    <p style={{ fontSize: '14px' }}>
                      {t('programs.total-budget')}:{' '}
                    </p>
                    &nbsp;
                    <p>
                      {program.status === 'inactive' ? (
                        <NumberDisplay
                          number={program.budget || 0}
                          showUSD={true}
                        />
                      ) : (
                        <NumberDisplay
                          number={program.total_budget}
                          showUSD={true}
                        />
                      )}
                    </p>
                  </div>
                  <Tooltip
                    placement="right"
                    title={`Utilizado ${Number(budgetPercent.toFixed(1))}%`}
                  >
                    <Progress
                      percent={Number(budgetPercent.toFixed(1))}
                      showInfo={false}
                    />
                  </Tooltip>
                </Row>
              ) : (
                !program.is_ptaas && (
                  <Row style={{ marginLeft: '10px' }}>
                    <p>
                      <NumberDisplay number={program.rewards_info.bounty_low} />{' '}
                      -{' '}
                      <NumberDisplay
                        number={program.rewards_info.bounty_critical}
                      />
                    </p>
                  </Row>
                )
              )}
              {props.companyMode && (
                <div style={{ paddingLeft: '10px' }}>
                  {props.showChangeStatus && (
                    <div>
                      <p style={{ marginTop: '2vh' }}>{t('programs.state')}</p>
                      <ProgramStatusForm
                        program={program}
                        disabled={!props.canChangeStatus}
                        onChangeStatus={props.onProgramStatusChange}
                      />
                    </div>
                  )}
                  {props.editable && (
                    <div style={{ marginTop: '2vh' }}>
                      <Link
                        to={{
                          pathname: `/dashboard/program/${program.id}/update`,
                        }}
                      >
                        <Button
                          className="btn-default btn-program-item btn-block pull-right"
                          size="large"
                        >
                          {t('programs.edit-button')}
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ProgramInfo;
