import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Avatar, Card, Row, Col, Typography } from 'antd';
import moment from 'moment';
import '../themes/notification-item.less';
import { activityTranslation } from '../utils/ActivitiesHelper';
import { Link } from 'react-router-dom';
const { Paragraph } = Typography;

class NotificationItem extends Component {
  constructor(props) {
    super(props);
  }

  getLinkFor(action, target) {
    if (action === null || action === undefined) return '#';

    if (action.type === 'program') {
      return '/dashboard/programs/' + action.id;
    } else if (action.type === 'report') {
      return '/dashboard/report/' + action.id;
    } else if (action.type === 'reportcomment') {
      return '/dashboard/report/' + target.id;
    } else if (action.type === 'programfeature') {
      return '/dashboard/programs/' + target.id;
    }
    return '#';
  }

  getTimeSince(timestamp) {
    return moment(timestamp).fromNow();
  }

  render() {
    const {
      notification: { icon_url, actor, verb, action_object, target, timestamp },
      notify,
      t,
      closeDropdown,
    } = this.props;

    const timeSince = this.getTimeSince(timestamp);

    const { title, description } = activityTranslation(t, verb, {
      actor,
      action_object,
      target,
    });

    const item = (
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
        to={this.getLinkFor(action_object, target)}
        onClick={() => closeDropdown()}
      >
        <Card style={{ cursor: 'pointer' }}>
          <Row gutter={15}>
            <Col xs={3} sm={3}>
              <Avatar src={icon_url} className="notification-logo"></Avatar>
            </Col>

            <Col xs={20} sm={20}>
              <Row>
                <Col xs={notify ? 19 : 15} sm={notify ? 20 : 16}>
                  <p className="title">{title}</p>
                </Col>

                {notify ? (
                  ''
                ) : (
                  <Col xs={9} sm={8}>
                    <p className="date pull-right">{timeSince}</p>
                  </Col>
                )}
              </Row>

              <Row>
                <Col xs={24} sm={24}>
                  <Paragraph ellipsis>{description}</Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Link>
    );
    return <span className="notificationItem">{notify ? item : item}</span>;
  }
}

export default withRouter(NotificationItem);
