const initialState = {
  reportFilters: null,
  programFilters: null,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REPORT':
      return { ...state, reportFilters: action.payload };
    case 'PROGRAM':
      return { ...state, programFilters: action.payload };
    default:
      return state;
  }
};

export default filterReducer;
