import React from 'react';
import '../../node_modules/react-vis/dist/style.css';
import { FilterReportStatusList, MONTHS } from '../constants/Data';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair,
} from 'react-vis';
import { withTranslation } from 'react-i18next';

class StatisticsBarSeries extends React.Component {
  state = {
    data: null,
    yValue: 0,
    crosshairValues: [],
  };

  componentWillMount() {
    const { data } = this.props;
    const parsedData = this.dataFormat(data);
    this.setState({ data: parsedData, yValue: parsedData.yValue });
  }

  componentWillReceiveProps(nextProps) {
    const {
      data: { collection },
    } = this.props;
    const { styles } = this.state;

    if (collection !== nextProps.data.collection) {
      const parsedData = this.dataFormat(nextProps.data, styles);
      this.setState({ data: parsedData, yValue: parsedData.yValue });
    }
  }

  handleNearestX = (data) => {
    if (data.y !== 0) {
      this.setState({ crosshairValues: [data] });
    } else {
      this.setState({ crosshairValues: [] });
    }
  };

  dataFormat(data) {
    const { t } = this.props;
    const {
      collection: { stats },
    } = data;

    const months = MONTHS.map((item) => {
      let _item = t(`months.${item}`);
      return _item.charAt(0).toUpperCase() + _item.slice(1);
    });

    const monthIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const statusIds = ['valid', 'not-valid', 'review'];

    const statusValidIds = Object.keys(stats).map((item) => {
      return item.substring(item.indexOf(' ') + 1, item.indexOf(']'));
    });

    const collectionData = statusIds.map((statusId) => {
      const variable = monthIds.map((monthId) => {
        const variable = `[${monthId}, '${statusId}']`;
        return stats[variable] ? stats[variable] : 0;
      });
      return variable;
    });

    let maxYvalue = 0;
    const collectionData2 = collectionData.map((stateArray) => {
      return stateArray.map((item, index) => {
        if (item > maxYvalue) {
          maxYvalue = item;
        }

        var singleObj = {};
        singleObj['x'] = months[index];
        singleObj['y'] = item;
        return singleObj;
      });
    });
    return {
      labels: months,
      data: collectionData2,
      yValue: maxYvalue,
      validStatusIds: new Set(statusValidIds),
    };
  }
  render() {
    const BarSeries = VerticalBarSeries;
    const { data, yValue, crosshairValues } = this.state;
    const { t } = this.props;

    const statusNames2 = Object.entries(FilterReportStatusList).map(
      ([key, value]) => ({
        title: t(`report-statuses.${key}`),
        color: value.color,
      })
    );
    const statusColors2 = Object.entries(FilterReportStatusList).map(
      ([key, value]) => value.color
    );
    const listItems = data['data'].map((arrayData, index) => (
      <BarSeries
        key={index}
        data={arrayData}
        style={{ fill: statusColors2[index], stroke: 'none' }}
        onNearestX={this.handleNearestX}
      />
    ));
    return (
      <div>
        <DiscreteColorLegend items={statusNames2} orientation="horizontal" />

        <XYPlot
          width={830}
          height={300}
          xType="ordinal"
          stackBy="y"
          yDomain={[0, yValue + 4]}
        >
          <VerticalGridLines style={{ strokeWidth: '0.2', stroke: 'gray' }} />
          <HorizontalGridLines style={{ strokeWidth: '0.2', stroke: 'gray' }} />
          <XAxis />
          <YAxis />
          {listItems}
          {crosshairValues.length > 0 && (
            <Crosshair
              values={crosshairValues}
              style={{ line: { background: 'none' } }}
            >
              <div
                style={{
                  background: 'black',
                  padding: '5px',
                  borderRadius: '5px',
                  whiteSpace: 'nowrap',
                }}
              >
                <p style={{ color: 'white' }}>
                  {`${t('month')}: ${t(`all-months.${crosshairValues[0].x}`)}`}
                </p>
                <p style={{ color: 'white' }}>
                  {`${t('reports.title')}: ${crosshairValues[0].y}`}
                </p>
              </div>
            </Crosshair>
          )}
        </XYPlot>
      </div>
    );
  }
}

export default withTranslation()(StatisticsBarSeries);
