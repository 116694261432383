import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';

import ProgramBugbountyForm from './ProgramBugbountyForm';
import ProgramPtaasForm from './ProgramPtaasForm';

import '../themes/default.less';
import { withTranslation } from 'react-i18next';
import axiosInstance from '../axiosInstance';
import LoaderComponent from '../components/LoaderComponent';

class Program extends Component {
  state = {
    program: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.isEditing) this.fetchProgram();
  }

  fetchProgram() {
    this.setState({ loading: true });

    const params = {};

    axiosInstance
      .get(`/programs/${this.props.match.params.id}/`, { params })
      .then((response) => {
        this.setState({
          program: {
            ...response.data,
            services: response.data.services.map((item) => ({
              id: item.id,
              service: item.service.code,
            })),
          },
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data', error);
        this.setState({ loading: false }); // Set loading to false even if there is an error
      });
  }

  render() {
    const { currentUser, t, isEditing, isPtaas } = this.props;

    if (currentUser === null || (isEditing && this.state.program === null))
      return <LoaderComponent />;

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <div className="programs-container section-title">
            <h1>
              {isEditing
                ? t('programs.form.edit-title')
                : t('programs.form.create-title')}
            </h1>
            {isPtaas || (isEditing && this.state.program.is_ptaas) ? (
              <ProgramPtaasForm initialValues={this.state.program} />
            ) : (
              <ProgramBugbountyForm initialValues={this.state.program} />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
});
export default withTranslation()(withRouter(connect(mapStateToProps)(Program)));
