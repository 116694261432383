import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

class AccountConfirmation extends Component {
  componentDidMount(nextProps) {
    const { history, t } = this.props;

    const currentPath = this.props.location.pathname;

    if (currentPath === '/account/activated') {
      let $timer = setTimeout(() => {
        clearTimeout($timer);
        $timer = null;
        openNotification(
          'success',
          t('registration.activation.activated.title'),
          t('registration.activation.activated.description')
        );
      }, 0);
      history.push('/login/');
    } else {
      let $timer = setTimeout(() => {
        clearTimeout($timer);
        $timer = null;
        openNotification(
          'error',
          t('registration.activation.noactivated.title'),
          t('registration.activation.noactivated.description')
        );
      }, 0);
      history.push('/login/');
    }
  }

  render() {
    return <div></div>;
  }
}

export default withTranslation()(withRouter(AccountConfirmation));
