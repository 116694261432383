import React, { Component } from 'react';
import Login from '../containers/Login';

class PasswordReset extends Component {
  render() {
    return (
      <Login
        resetPasswordForm={true}
        history={this.props.history}
        token={this.props.match.params.token}
      ></Login>
    );
  }
}

export default PasswordReset;
