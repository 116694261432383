import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Button,
  Dropdown,
  Row,
  Col,
  Menu,
  Modal,
  Card,
  Image,
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import CompanyInvitationsForm from './CompanyInvitationsForm';
import {
  companyEmployees,
  employeeActivate,
  employeeDeactivate,
  employeeDelete,
  invitationDelete,
  invitationsEmployees,
  transferOwner,
} from '../redux/actions/companyEmployees';
import { openNotification } from '../utils/General';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
const confirm = Modal.confirm;

class CompanyEmployees extends Component {
  state = {
    visibleModal: false,
  };

  showModal = () => {
    this.setState({
      visibleModal: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visibleModal: false,
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(companyEmployees());
    dispatch(invitationsEmployees());
  }

  employeeOptions(p) {
    const iAmTheOwner = this.props.user.isCurrentCompanyOwner;
    const { t } = this.props;

    const menu = (
      <Menu>
        {iAmTheOwner && (
          <Menu.Item key="1">
            <a onClick={() => this.transferOwnership(p.id)}>
              {t('profile.employees.transfer')}
            </a>
          </Menu.Item>
        )}
        <Menu.Item key="2">
          <a onClick={() => this.deleteEmployee(p.id)}>{t(`delete`)}</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
        <Button style={{ border: 'none' }}>
          <Image
            src="/images/employee-options.png"
            height={25}
            preview={false}
          />
        </Button>
      </Dropdown>
    );
  }

  invitationsOptions(p) {
    const { t } = this.props;
    const invitationId = p.id;
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.deleteInvitations(invitationId)}>
            {t('delete')}
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
        <Button style={{ border: 'none' }}>
          <Image
            src="/images/employee-options.png"
            height={25}
            preview={false}
          />
        </Button>
      </Dropdown>
    );
  }

  transferOwnership = (id) => {
    const { dispatch, t } = this.props;

    dispatch(transferOwner(id)).then(() => {
      if (this.props.hasError) {
        openNotification('error', 'Error', this.props.errorMessage);
      } else {
        openNotification(
          'success',
          t('notifications.profile.update'),
          t('notifications.profile.update-transfer')
        );
      }
    });
  };

  getEmployees() {
    const { employees, currentUser, t } = this.props;

    return employees.map((p, i) => {
      let is_me = p.user.username === currentUser.username;

      return (
        <Card className="company-users-list" key={i.toString()}>
          <Row>
            <Col xs={5} sm={3}>
              <Avatar
                src={p.user.avatar_thumbnail_s_url || `/images/person.png`}
              ></Avatar>
            </Col>

            <Col xs={19} sm={15}>
              <Row>
                <Col xs={24} sm={24}>
                  <h6>{p.user.username}</h6>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24}>
                  <p>{p.user.email}</p>
                </Col>
              </Row>
            </Col>

            <Col xs={20} sm={4}>
              <Row>
                <Col xs={24} sm={24}>
                  <h3 className="text-left" style={{ fontSize: 11 }}>
                    {p.state}
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24}>
                  <p className="text-left">
                    {p.is_owner
                      ? 'Owner & Admin'
                      : t(`roles.company.${p.role}`)}
                  </p>
                </Col>
              </Row>
            </Col>

            <Col xs={3} sm={2}>
              {!p.is_owner && !is_me && this.employeeOptions(p)}
            </Col>
          </Row>
        </Card>
      );
    });
  }

  getInvitations() {
    const { invitations, t } = this.props;
    if (!invitations) {
      return [];
    }

    return invitations.map((p, i) => {
      return (
        <Card className="company-users-list" key={i.toString()}>
          <Row>
            <Col xs={5} sm={3}>
              <Avatar src={`/images/person.png`}></Avatar>
            </Col>

            <Col xs={19} sm={15}>
              <Row>
                <Col xs={24} sm={24}>
                  <h6 style={{ fontSize: '14px' }}>{p.email}</h6>
                </Col>
              </Row>
            </Col>

            <Col xs={20} sm={4}>
              <Row>
                <Col xs={24} sm={24}>
                  <p>{t('profile.employees.pending')}</p>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24}>
                  <p>{t(`roles.company.${p.role}`)}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={3} sm={2}>
              {this.invitationsOptions(p)}
            </Col>
          </Row>
        </Card>
      );
    });
  }

  activateEmployee(invitationID) {
    const { dispatch } = this.props;

    dispatch(employeeActivate(invitationID));
  }

  deactivateEmployee(invitationID) {
    const { dispatch } = this.props;

    dispatch(employeeDeactivate(invitationID));
  }

  deleteEmployee(id) {
    const { dispatch, t } = this.props;
    confirm({
      title: t(`profile.employees.dialogs.delete-employee.confirm.title`),
      content: t(`profile.employees.dialogs.delete-employee.confirm.content`),
      onOk: () => {
        dispatch(employeeDelete(id)).then(() => {
          if (this.props.hasError) {
            openNotification('error', 'Error', this.props.errorMessage);
          } else {
            openNotification(
              'success',
              t('notifications.profile.delete'),
              t('notifications.profile.delete-employee')
            );
            dispatch(companyEmployees());
          }
        });
      },
    });
  }

  deleteInvitations(id) {
    const { dispatch, t } = this.props;
    confirm({
      title: t(`profile.invitations.dialogs.delete-invitation.confirm.title`),
      content: t(
        `profile.invitations.dialogs.delete-invitation.confirm.content`
      ),
      onOk: () => {
        dispatch(invitationDelete(id)).then(() => {
          if (this.props.hasError) {
            openNotification('error', 'Error', this.props.errorMessage);
          } else {
            openNotification(
              'success',
              t('notifications.profile.delete'),
              t('notifications.profile.delete-invitation')
            );
            dispatch(invitationsEmployees());
          }
        });
      },
    });
  }

  render() {
    const { currentUser, employees, t } = this.props;
    const { visibleModal } = this.state;
    const employeesList = this.getEmployees();
    const invitationsList = this.getInvitations();
    const employeesCount = employees.length;
    const userCount = currentUser.currentCompany.activePlan.user_limit;

    const isAllowAddEmployees = employeesCount >= userCount;
    return (
      <div>
        <h4 className="profile-title">{t('profile.employees.title')}</h4>
        <p className="p-description">{t('profile.employees.desc')}</p>

        <Row justify={'end'}>
          <Col>
            <Button
              className="p-button"
              disabled={isAllowAddEmployees}
              style={{
                backgroundColor: isAllowAddEmployees ? '#888888' : '#090036',
                borderColor: 'transparent',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={this.showModal}
            >
              <UserAddOutlined />
              {t('profile.employees.invite-button')}
            </Button>
          </Col>
        </Row>

        <Modal
          title={t('profile.employees.title')}
          visible={visibleModal}
          onCancel={this.handleCancel}
          footer={null}
        >
          <p>{t('profile.employees.invitation-desc')}</p>
          <CompanyInvitationsForm closeModal={this.handleCancel} />
        </Modal>

        <Row>
          <Col span={24}>
            {invitationsList.length > 0 ? (
              <React.Fragment>
                <h4 className="profile-title">
                  {t('profile.employees.invitations')}
                </h4>
                {invitationsList}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h4 className="profile-title">
                  {t('profile.employees.invitations')}
                </h4>
                <p className="p-description">
                  {t('profile.employees.no-invitations')}
                </p>
                <br></br>
              </React.Fragment>
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {employeesList.length > 0 ? (
              <React.Fragment>
                <h4 className="profile-title">{t('profile.employees.list')}</h4>
                {employeesList}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h4 className="profile-title">{t('profile.employees.list')}</h4>
                <p className="p-descripttion">
                  {t('profile.employees.no-list')}
                </p>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  isRequesting: state.companyEmployees.isRequesting,
  isPushing: state.companyEmployees.isPushing,
  employees: state.companyEmployees.employees,
  invitations: state.companyEmployees.invitations,
  sent: state.companyEmployees.sent,
  hasError: state.companyEmployees.hasError,
  errorMessage: state.companyEmployees.errorMessage,
});
export default withTranslation()(connect(mapStateToProps)(CompanyEmployees));
