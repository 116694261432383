import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import withSizes from 'react-sizes';

import { Link } from 'react-router-dom';
import { Menu, Collapse } from 'antd';
import { withTranslation } from 'react-i18next';
import { isCompanyMode } from '../utils/General';
import { connect } from 'react-redux';
import User from '../containers/User';
import '../styles/profile.less';
import { Route } from 'react-router-dom';
import HackerProfileForm from '../components/HackerProfileForm';
import CompanyProfileForm from '../components/CompanyProfileForm';
import CompanyEmployees from '../components/CompanyEmployees';
import CompanySelectedPlan from '../components/CompanySelectedPlan';
import HackerPaymentsFormNew from '../components/HackerPaymentsFormNew';
import SkillsForm from '../components/SkillsForm';
import SecurityForm from '../components/SecurityForm';
import WrappedInvoiceDetailsForm from '../components/InvoiceDetailsForm';

const Panel = Collapse.Panel;

class UserProfile extends Component {
  render() {
    const {
      secondFactorValidated,
      secondFactorEnabled,
      currentUser,
      userAbility,
      location,
      t,
    } = this.props;
    if (!secondFactorValidated && secondFactorEnabled) {
      this.props.history.push('/authenticator');
      return null;
    }

    return (
      <React.Fragment>
        <Row id="profile-header">
          <Col span={24} className="sub-menu">
            <Row>
              <Col span={18} offset={4}>
                {isCompanyMode(currentUser) ? (
                  <Menu
                    selectedKeys={[location.pathname]}
                    mode="horizontal"
                    className="sub-menu-container"
                  >
                    <Menu.Item key="/user/profile">
                      <Link to="/user/profile">
                        <p>{t('profile.menu.profile')}</p>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/company/profile">
                      <Link to="/company/profile">
                        <p>{t('profile.menu.company')}</p>
                      </Link>
                    </Menu.Item>
                    {this.props.userAbility.can('list_users', 'Company') && (
                      <Menu.Item key="/company/users">
                        <Link to="/company/users">
                          <p>{t('profile.menu.users')}</p>
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item key="/user/security">
                      <Link to="/user/security">
                        <p>{t('profile.menu.security')}</p>
                      </Link>
                    </Menu.Item>
                    {this.props.userAbility.can('update', 'Company') && (
                      <Menu.Item key="/user/payments">
                        <Link to="/user/payments">
                          <p>{t('profile.menu.billing')}</p>
                        </Link>
                      </Menu.Item>
                    )}
                    {!this.props.userAbility.can(
                      'add_ptaas_program',
                      'Company'
                    ) && (
                      <Menu.Item key="/user/subscription">
                        <Link to="/user/subscription">
                          <p>{t('profile.billing.subscription')}</p>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu>
                ) : (
                  <Menu
                    selectedKeys={[location.pathname]}
                    mode="horizontal"
                    className="sub-menu-container"
                  >
                    <Menu.Item key="/user/profile">
                      <Link to="/user/profile">
                        <p>{t('profile.menu.profile')}</p>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/user/skills">
                      <Link to="/user/skills">
                        <p>{t('profile.menu.skills')}</p>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/user/security">
                      <Link to="/user/security">
                        <p>{t('profile.menu.security')}</p>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="/user/payments">
                      <Link to="/user/payments">
                        <p>{t('profile.menu.payments')}</p>
                      </Link>
                    </Menu.Item>
                  </Menu>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <User>
          <Route
            path="/company/profile"
            render={(props) => {
              const initialValues = {
                name: currentUser.currentCompany.name,
                sector: currentUser.currentCompany.sector,
                website: currentUser.currentCompany.website,
                description: currentUser.currentCompany.description,
                corporate_name: currentUser.currentCompany.corporate_name,
                address: currentUser.currentCompany.address,
                phone: currentUser.currentCompany.phone,
                contact_email: currentUser.currentCompany.contact_email,
                country: currentUser.currentCompany.country,
                commercial_number: currentUser.currentCompany.commercial_number,
                business_activity: currentUser.currentCompany.business_activity,
              };
              return (
                <CompanyProfileForm
                  initialValues={initialValues}
                  readonly={!userAbility.can('update', 'Company')}
                  user={currentUser}
                  company={currentUser.currentCompany}
                />
              );
            }}
          />
          <Route
            path="/company/users"
            render={(props) => <CompanyEmployees user={currentUser} />}
          />
          <Route
            path="/user/profile"
            render={(props) => {
              const initialValues = {
                username: currentUser.username,
                profile: {
                  first_name: currentUser.firstname,
                  last_name: currentUser.lastname,
                  linkedin_url: currentUser.profile.linkedin_url,
                  twitter_url: currentUser.profile.twitter_url,
                  other_url: currentUser.profile.other_url,
                  default_language: currentUser.profile.default_language,
                  country: currentUser.profile.country,
                  about_me: currentUser.profile.about_me,
                },
              };
              return <HackerProfileForm initialValues={initialValues} />;
            }}
          />
          <Route
            path="/user/skills"
            render={(props) => <SkillsForm profile={currentUser.profile} />}
          />
          <Route
            path="/user/security"
            render={(props) => <SecurityForm />}
            exact
          />
          <Route
            exact
            path="/user/payments"
            render={(props) => {
              if (isCompanyMode(currentUser)) {
                return <WrappedInvoiceDetailsForm />;
              } else {
                return <HackerPaymentsFormNew />;
              }
            }}
          />
          <Route
            exact
            path="/user/subscription"
            render={(props) => {
              return <CompanySelectedPlan user={currentUser} />;
            }}
          />
        </User>
      </React.Fragment>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isHeaderCollapsed: width < 992,
  isHeaderCompressed: width >= 992 && width < 1502,
  isHeaderFull: width >= 1502,
});

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  userAbility: state.ability,
});

export default withTranslation()(
  withRouter(withSizes(mapSizesToProps)(connect(mapStateToProps)(UserProfile)))
);
