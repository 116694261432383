import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HackerProgramsEmpty = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={24}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 14, offset: 5 }}
        lg={{ span: 14, offset: 5 }}
      >
        <Card hoverable={true}>
          <h4 className="text-center">
            {t('dashboard.programs.dialogs.no-programs')}
          </h4>
          <p className="text-center">
            {t('dashboard.programs.dialogs.no-programs-desc')}
          </p>
          <Row gutter={24}>
            <Col span={24}>
              <div className="btn-programs-view" style={{ paddingTop: '20px' }}>
                <Link to="/dashboard/programs">
                  <Button
                    className="btn-hacker block-center"
                    style={{
                      height: '40px',
                      paddingLeft: '35px',
                      paddingRight: '35px',
                      fontSize: '18px',
                    }}
                  >
                    {t('dashboard.programs.dialogs.no-programs-button')}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default HackerProgramsEmpty;
