import { notification } from 'antd';

export const sanitizeString = (value) => {
  let sanitizedValue;

  sanitizedValue = replaceSpecialCharecter(value, '');

  return sanitizedValue;
};

export const sanitizedCSV = (value) => {
  if (!value) {
    return '';
  }
  return value.replaceAll(/"/g, '""');
};

export const replaceSpecialCharecter = (value, replaceValue) => {
  return value.replace(/[^a-zA-Z0-9]/g, replaceValue);
};

export const convertToDate = (value) => {
  const d = value.split('T')[0].split('-');
  return `${d[2]}/${d[1]}/${d[0]}`;
};

export const isCompanyMode = (user) => {
  return ['company', 'backoffice'].includes(user.role);
};

export const openNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};
