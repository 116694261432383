import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row, Table } from 'antd';
import '../themes/statistics.less';
import {
  getConversionHistory,
  getWalletHistory,
} from '../redux/actions/wallet';
import DownloadHistoryForm from '../components/DownloadHistoryForm';
import moment from 'moment';
import Paginate from '../components/Paginate';
import { withTranslation } from 'react-i18next';

import { API_ROOT } from '../middleware/api';
import { routes } from '../constants/APIEndpoints';
import NumberDisplay from '../utils/NumberDisplay';
import LoaderComponent from '../components/LoaderComponent';

class ConversionHistory extends Component {
  state = {
    currentTab: '2',
    loading: true,
    currentPage: 1,
    is_ptaas: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { currentPage } = this.state;
    const attributes = { page: currentPage };

    dispatch(getConversionHistory(attributes));
  }

  onRow(record, rowIndex) {
    return {
      onClick: () => {},
    };
  }

  convertDate(date) {
    const fechaHora = moment(date);

    return fechaHora.format('DD/MM/YYYY HH:mm');
  }

  handlePaginateData = (data) => {
    this.setState({ loading: true, currentPage: data });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render() {
    const { isFetching, conversion, numberOfElements, numberOfPages, t } =
      this.props;
    const { currentPage } = this.state;

    const columns = [
      {
        title: (
          <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>
            {t('budget.conversion-history.table.date')}
          </span>
        ),
        className: 'column-initial',
        dataIndex: 'date',
        width: '20%',
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: (
          <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>
            {t('budget.conversion-history.table.user')}
          </span>
        ),
        dataIndex: 'user',
        width: '20%',
        sorter: (a, b) => a.user.localeCompare(b.user),
      },
      {
        title: (
          <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>
            {t('budget.conversion-history.table.amount')}
          </span>
        ),
        className: 'column-right',
        dataIndex: 'amount',
        width: '20%',
        render: (text, record) => (
          <span>
            <NumberDisplay number={record.amount} /> {record.amountCurrency}
          </span>
        ),
      },
      {
        title: (
          <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>
            {t('budget.conversion-history.table.action')}
          </span>
        ),
        className: 'column-right',
        dataIndex: 'action',
        width: '20%',
      },
      {
        title: (
          <span style={{ fontWeight: 'bold', fontSize: 'larger' }}>
            {t('budget.conversion-history.table.conversion')}
          </span>
        ),
        className: 'column-final',
        dataIndex: 'conversion',
        width: '20%',
        render: (text, record) => (
          <span>
            <NumberDisplay number={record.conversion} />{' '}
            {record.conversionCurrency}
          </span>
        ),
      },
    ];

    const data = [];
    for (let indice in conversion) {
      const element = conversion[indice];
      const createdAt = this.convertDate(element.created_at);
      const amountCurrency =
        element.from_wtype === 'dollar'
          ? 'USD'
          : parseInt(element.from_value) === 1
          ? t('budget.ptaas.converter.credit')
          : parseInt(element.from_value) > 1 &&
            t('budget.ptaas.converter.credits');

      const conversionCurrency =
        element.to_wtype === 'dollar'
          ? 'USD'
          : parseInt(element.to_value) === 1
          ? t('budget.ptaas.converter.credit')
          : parseInt(element.to_value) > 1 &&
            t('budget.ptaas.converter.credits');

      data.push({
        key: indice,
        date: createdAt,
        user: element.username ? element.username : '',
        amount: element.from_value,
        amountCurrency: amountCurrency,
        action: (
          <span>
            {element.from_wtype} <ArrowRightOutlined /> {element.to_wtype}
          </span>
        ),
        conversion: element.to_value,
        conversionCurrency: conversionCurrency,
      });
    }

    if (isFetching) return <LoaderComponent label={false} />;
    else
      return (
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            lg={{ span: 18, offset: 3 }}
          >
            <Row className="section-title">
              <Col span={24}>
                <h1>{t('budget.conversion-history.title')}</h1>
                <h4>{t('budget.conversion-history.description')}</h4>
              </Col>
            </Row>

            <Row
              style={{
                marginTop: '2.25rem',
                marginBottom: '1.5rem',
                marginLeft: '5px',
              }}
            >
              <Col span={24}>
                <div
                  style={{
                    marginTop: '20px',
                    backgroundColor: 'white',
                    marginBottom: '30px',
                    padding: '0px 10px  ',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '0',
                      paddingTop: '5px',
                    }}
                  >
                    <h1
                      style={{
                        fontSize: '16px',
                        marginLeft: '1vh',
                        marginBottom: '0',
                      }}
                    >
                      {t('budget.wallet-history.history')}
                    </h1>
                    <DownloadHistoryForm
                      baseFilename="ConversionHistory"
                      doAppendDatetime={true}
                      baseUrl={`${API_ROOT}${routes.GET_CONVERSION_HISTORY}`}
                    />
                  </div>
                  <div style={{ marginTop: '20px', marginBottom: '1vh' }}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      scroll={{ x: 750 }}
                      pagination={false}
                    />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <Paginate
                      currentPage={currentPage}
                      numberOfElements={numberOfElements}
                      numberOfPages={numberOfPages}
                      dispatcher={getWalletHistory}
                      onData={this.handlePaginateData}
                      elementName={
                        numberOfElements === 1
                          ? t('registers.verbose_name')
                          : t('registers.verbose_name_plural')
                      }
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
  }
}
const mapStateToProps = (state) => ({
  conversion: state.wallet.conversion,
  numberOfElements: state.wallet.numberOfElements || 0,
  numberOfPages: state.wallet.numberOfPages,
  isFetching: state.wallet.isFetching,
});
export default withTranslation()(connect(mapStateToProps)(ConversionHistory));
