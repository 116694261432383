export const mapSizes = ({ width }) => ({
  isMobile: width < 768,
  isTablet: width >= 768 && width <= 1024,
  isDesktop: width >= 1024,
});

// ANT DESIGN GRID RESOLUTIONS
// xs: < 576px
// sm: >= 576px
// md: >= 768px
// lg: >= 992px
// xl: >= 1200px
// xxl: >= 1600px
