import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox, Row, Col, Select, Alert } from 'antd';
import { signUpHacker } from '../redux/actions/auth';
import { Publicidad, Countries, PreferedLanguages } from '../constants/Data';
import * as _ from 'lodash';
import { sanitizeString } from '../utils/General';
import { withTranslation } from 'react-i18next';
import { validateURL } from '../validators';

const FormItem = Form.Item;
const Option = Select.Option;

class HackerSignUpForm extends Component {
  state = {
    signingUp: false,
    message: false,
    confirmDirty: false,
    welcomeMessage: null,
    errorMessage: null,
    redirectLogin: false,
  };

  componentWillReceiveProps(nextProps) {
    const { dispatch, isRequesting, hasError } = this.props;
    const { signingUp } = this.state;

    if (isRequesting && !nextProps.isRequesting) {
      if (signingUp) {
        this.showMessage(nextProps.hasError, nextProps.errorMessage);

        if (!nextProps.hasError) {
          this.setState({
            message: true,
            welcomeMessage: nextProps.errorMessage,
          });
        }

        window.scrollTo(0, 0);
      }
    }
  }

  showMessage = (hasError, message) => {
    this.setState({
      signingUp: !this.state.signingUp,
    });

    if (hasError) {
      this.setState({
        errorMessage: <span dangerouslySetInnerHTML={{ __html: message }} />,
      });
    }
  };

  closeMessage = () => {
    this.setState({ errorMessage: null });
  };

  welcomeMessage = () => {
    const { welcomeMessage } = this.state;

    return (
      <div
        style={{ border: '1px solid #4a4a4a', padding: '14px 14px 14px 14px' }}
      >
        <h2 className="text-center">¡Gracias por registrarte!</h2>
        <h5 className="text-center">Se te redirigirá al Inicio de Sesión</h5>
        <p
          className="text-center"
          style={{ color: '#000000', fontSize: '14px' }}
        >
          {welcomeMessage}
        </p>
      </div>
    );
  };

  handleConfirmPassword = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password1')) {
      callback(t('signup.hacker.pass-check2'));
    } else {
      callback();
    }
  };

  checkConfirmPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    const expression =
      '^(?:(?=.|\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\\$%\\^&=+\\-_*]).*)$';
    const match = !value ? null : value.match(expression);

    if (value && match === null) {
      callback(t('signup.hacker.pass-check1'));
    } else if (value && this.state.confirmDirty) {
      form.validateFieldsAndScroll(['password2'], { force: true });
    }
    callback();
  };

  checkAgreementCheckBox = (rule, value, callback) => {
    if (!value) {
      callback('Debes aceptar los términos y condiciones');
    } else {
      callback();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    this.setState({ signingUp: true });

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch(signUpHacker(values)).then(() => {
          if (!this.props.hasError) {
            setTimeout(() => {
              this.setState({ redirectLogin: true });
            }, 2000);
          }
        });
      } else {
        this.setState({ signingUp: false });
      }
    });
  };
  getLanguages = () => {
    return PreferedLanguages.map((p, index) => {
      return (
        <Option value={p.id} key={index.toString()}>
          {p.name}
        </Option>
      );
    });
  };
  getCountries = () => {
    return Countries.map((p, index) => {
      return (
        <Option value={p.id} key={index.toString()}>
          {p.name}
        </Option>
      );
    });
  };

  sanitizeUrl = (e) => {
    /** 

        const {value,name}=e.target

        setTimeout(() => {

            this.props.form.setFieldsValue({
                [name]: sanitizeString(value)
            });
            
        }, 1);

        */
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;

    const urlPreview = this.props.form.getFieldValue('email');

    const publicidad = Publicidad.map((p, index) => {
      return (
        <Option value={p.id} key={index}>
          {p.name}
        </Option>
      );
    });
    if (this.state.redirectLogin) {
      window.location.href = '/login';
    }

    return this.state.message ? (
      this.welcomeMessage()
    ) : (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {!_.isEmpty(this.state.errorMessage) ? (
          <Alert
            message=""
            description={this.state.errorMessage}
            type="error"
            closable
            onClose={this.closeMessage}
          />
        ) : (
          ''
        )}

        <FormItem label={t('signup.hacker.name')}>
          {getFieldDecorator('first_name', {
            rules: [
              { required: true, message: t('signup.hacker.name-placeholder') },
            ],
          })(<Input placeholder="Eddard" />)}
        </FormItem>

        <FormItem label={t('signup.hacker.last-name')}>
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: t('signup.hacker.last-name') }],
          })(<Input placeholder="Stark" />)}
        </FormItem>

        <FormItem label={t('signup.hacker.username')}>
          {getFieldDecorator('username', {
            rules: [
              { required: true, message: t('signup.hacker.username-message') },
              {
                min: 5,
                message: t('signup.hacker.username-message-check'),
                pattern: new RegExp(/^[a-zA-Z0-9]+$/g),
              },
            ],
          })(
            <Input
              placeholder="nedstark"
              name="username"
              onChange={this.sanitizeUrl}
            />
          )}
        </FormItem>

        <FormItem label={t('signup.hacker.email')}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: t('signup.hacker.email-message'),
                type: 'email',
              },
            ],
          })(<Input placeholder="Correo electrónico" />)}
          <span className="url-preview">
            {'https://www.cyscope.io/' +
              (urlPreview ? sanitizeString(urlPreview) : '')}
          </span>
        </FormItem>

        <FormItem label="Twitter URL">
          {getFieldDecorator('twitter_url', {
            rules: [{ validator: validateURL }],
          })(<Input placeholder="https://twitter.com/cy_scope" />)}
        </FormItem>

        <FormItem label="Linkedin URL">
          {getFieldDecorator('linkedin_url', {
            rules: [{ validator: validateURL }],
          })(<Input placeholder="https://www.linkedin.com/hacker" />)}
        </FormItem>

        <FormItem label="Other (GitHub, Blog, Sitio Web):" colon={false}>
          {getFieldDecorator('other_url', {
            rules: [{ validator: validateURL }],
          })(<Input placeholder="https://github.com/nedstark" />)}
        </FormItem>

        <FormItem label={t('signup.hacker.country')}>
          {getFieldDecorator('country', {
            rules: [
              {
                required: true,
                message: t('signup.hacker.country-placeholder'),
              },
            ],
          })(
            <Select placeholder={t('signup.hacker.country-placeholder')}>
              {this.getCountries()}
            </Select>
          )}
        </FormItem>

        <FormItem label={t('signup.hacker.language')}>
          {getFieldDecorator('languages', {
            rules: [
              { required: true, message: t('signup.hacker.language-message') },
            ],
          })(
            <Checkbox.Group>
              <Row>
                <Col sm={8} xs={12}>
                  <Checkbox value="en">
                    {t('programs.form.languages.english')}
                  </Checkbox>
                </Col>
                <Col sm={8} xs={12}>
                  <Checkbox value="es">
                    {t('programs.form.languages.spanish')}
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          )}
        </FormItem>
        <FormItem label={t('signup.hacker.main-language')}>
          {getFieldDecorator('default_language', {
            rules: [
              {
                required: true,
                message: t('signup.hacker.main-language-message'),
              },
            ],
          })(
            <Select placeholder={t('signup.hacker.main-language-message')}>
              {this.getLanguages()}
            </Select>
          )}
        </FormItem>

        <Row gutter={24}>
          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <FormItem label={t('signup.hacker.pass')}>
              {getFieldDecorator('password1', {
                rules: [
                  { required: true, message: t('signup.hacker.pass-message') },
                  { min: 8, message: t('signup.hacker.pass-check3') },
                  { validator: this.checkConfirmPassword },
                ],
              })(<Input type="password" autoComplete="new-password" />)}
            </FormItem>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <FormItem label={t('signup.hacker.new-pass2')}>
              {getFieldDecorator('password2', {
                rules: [
                  {
                    required: true,
                    message: t('signup.hacker.new-pass2-message'),
                  },
                  { validator: this.checkPassword },
                ],
              })(
                <Input
                  type="password"
                  onBlur={this.handleConfirmPassword}
                  autoComplete="new-password"
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <hr className="separator-form" />

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-sign-up btn-hacker pull-right"
            loading={this.state.signingUp}
          >
            {t('signup.hacker.save-button')}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const NormalSignUpForm = Form.create()(HackerSignUpForm);

const mapStateToProps = (state) => ({
  isRequesting: state.auth.requesting,
  hasError: state.auth.hasError,
  errorMessage: state.auth.errorMessage,
});
export default withTranslation()(connect(mapStateToProps)(NormalSignUpForm));
