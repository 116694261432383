import { AbilityBuilder, Ability } from '@casl/ability';

export const defineProgramAbilityFor = (user, role) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (role === 'admin') {
    can('read', 'Program', { is_draft: false, is_approved: true });

    can('update', 'Program', {
      status: {
        $in: ['inactive', 'active', 'paused'],
      },
    });

    can('read', 'Report', { is_draft: false });

    can('update', 'Report', {
      is_draft: false,
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
    });

    can('change_retest_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_mitigation_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_status', 'Report', {
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
      is_draft: false,
      'program.is_ptaas': false,
    });

    can('change_status', 'Report', {
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('add_comment', 'Report', {
      is_draft: false,
    });

    can('export', 'Report', { is_draft: false });
  } else if (role === 'analyst') {
    can('read', 'Program', { is_draft: false, is_approved: true });
    can('read', 'Report', { is_draft: false });

    can('update', 'Report', {
      is_draft: false,
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
    });

    can('change_retest_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_mitigation_status', 'Report', {
      is_draft: false,
      status: { $in: ['valid'] },
    });

    can('change_status', 'Report', {
      status: { $in: ['new', 'triage', 'missing-info', 'quarantine'] },
      is_draft: false,
      'program.is_ptaas': false,
    });

    can('change_status', 'Report', {
      is_draft: false,
      'program.is_ptaas': true,
    });

    can('add_comment', 'Report', {
      is_draft: false,
    });

    can('export', 'Report', { is_draft: false });
  } else if (role === 'viewer') {
    can('read', 'Program', { is_draft: false, is_approved: true });
    can('read', 'Report', { is_draft: false });
  }

  return build();
};

export const combineAbilities = (ability1, ability2) => {
  const combinedRules = [...ability1.rules, ...ability2.rules];
  return new Ability(combinedRules);
};
