import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DeleteOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Upload, Popconfirm } from 'antd';
import { fetchProfile, updateUser } from '../redux/actions/currentUser';
import * as _ from 'lodash';
import { addMediaProgram } from '../redux/actions/program';
import { useTranslation } from 'react-i18next';
import PaymentTypeForm from './PaymentTypeForm';
import { openNotification } from '../utils/General';

const HackerPaymentsFormNew = (props) => {
  const [isUpdated, setIsUpdated] = useState(null);
  const [documentId, setDocumentId] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.profile && _.size(props.profile.id_document) > 0) {
      setDocumentId(props.profile.id_document);
    }
  }, [props.profile]);

  useEffect(() => {
    if (isUpdated) {
      if (props.hasError && !props.isPushingFile) {
        setTimeout(
          () =>
            openNotification(
              'error',
              t(`error-uploading-file`),
              props.errorMessage
            ),
          0
        );
        setIsUpdated(false);
      } else if (!props.hasError && !props.isPushingFile) {
        props
          .dispatch(updateUser({ id_document_id: props.files.media_id }))
          .then(() => {
            setTimeout(
              () =>
                openNotification(
                  'success',
                  t('notifications.title.success'),
                  t('notifications.files.upload-save-message')
                ),
              0
            );
            props.dispatch(fetchProfile());
          });
        setIsUpdated(false);
      }
    }
  }, [isUpdated]);

  const handleDeleteDocumentId = () => {
    props.dispatch(updateUser({ id_document_id: null })).then(() => {
      setDocumentId(null);
      setTimeout(
        () =>
          openNotification(
            'success',
            t('notifications.title.success'),
            t('notifications.profile.update')
          ),
        0
      );
    });
  };

  const uploadProps = {
    customRequest: () => {},
    multiple: false,
    beforeUpload: (file) => {
      const isLt60M = file.size / 1024 / 1024 < 25;
      if (!isLt60M) {
        openNotification(
          'warning',
          t('notifications.warning'),
          t('file-less-than-size', { size: '60MB' })
        );
      }
    },
    onChange: (info) => {
      let formData = new FormData();
      formData.append('file', info.file.originFileObj);
      props.dispatch(addMediaProgram(formData)).then(() => {
        setIsUpdated(true);
      });
    },
  };

  const identityForm = () => {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <h4 className="profile-title">
              {t('profile.payments.identity-verification')}
            </h4>
            <p className="p-description mb-3">
              {t('profile.payments.identity-description')}
            </p>
            <Row>
              <Col span={24}>
                <Upload {...uploadProps} fileList={[]}>
                  <div className="flex-inline">
                    <Button disabled={documentId}>
                      {props.isPushingFile ? (
                        <div>
                          <LoadingOutlined /> {t('uploading-file')}
                        </div>
                      ) : (
                        <div>
                          <UploadOutlined /> {t(`upload-file`)}
                        </div>
                      )}
                    </Button>
                    <p
                      className="p-description ml-1"
                      style={{ fontSize: '10px' }}
                    >
                      Png, Jpg, Jpeg Max. 20 mb.
                    </p>
                  </div>
                </Upload>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24}>
                {_.size(documentId) > 0 && (
                  <div className="flex-inline mt-2">
                    <span style={{ color: '#007BFF', marginRight: '1vh' }}>
                      <PaperClipOutlined />
                    </span>
                    <a
                      href={documentId.url || documentId.original_image}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('profile.payments.view-identity-doc')}
                    </a>
                    <span style={{ marginLeft: '15px' }}>
                      <Popconfirm
                        title={t('notifications.files.remove-file')}
                        onConfirm={handleDeleteDocumentId}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div>
      {identityForm()}
      <PaymentTypeForm />
    </div>
  );
};

const WrappedHackerPaymentsFormNew = Form.create()(HackerPaymentsFormNew);

const mapStateToProps = (state) => ({
  hasError: state.program.hasError,
  errorMessage: state.program.errorMessage,
  isPushingFile: state.program.isPushingFile,
  profile: state.currentUser.profile,
  files: state.program.files,
});

export default connect(mapStateToProps)(WrappedHackerPaymentsFormNew);
