import React, { Component } from 'react';
import { Select } from 'antd';
import { ReportStatusList } from '../constants/Data';
import { withTranslation } from 'react-i18next';

const Option = Select.Option;

class ChangeStatusReport extends Component {

  onChangeStatus = (status) => {
    this.props.onChangeStatus(status)
  };

  render() {
    let { report, t } = this.props;
    const StatusTypeList = Object.keys(ReportStatusList).map((k) => (
      <Option key={k} value={k}>
        {t(`reports.status.${k}`)}
      </Option>
    ));

    return (
      <div className="edit-report">
        <Select
          className="select-status-report"
          value={report.status}
          disabled={this.props.disabled}
          onChange={this.onChangeStatus}
        >
          {StatusTypeList}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(ChangeStatusReport);
