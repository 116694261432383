import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Col, Input, Modal, Row, Spin, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { referCustomer } from '../redux/actions/currentUser';

const FormItem = Form.Item;

class ReferCustomer extends Component {
  state = {
    isLoading: false,
    visible: false,
    submitted: false,
    isSending: false,
    formData: {
      client_name: '',
      client_surname: '',
      client_email: '',
      client_title: '',
      company_name: '',
    },
  };

  openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
      submitted: false,
      isSending: false,
      formData: {
        referred_first_name: '',
        referred_last_name: '',
        referred_email: '',
        referred_title: '',
        referred_company: '',
      },
    });
  };

  handleSubmit = (e) => {
    const { dispatch, t } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isSending: true });
        const { hacker_email, ...formValues } = values;
        dispatch(referCustomer(formValues)).then(() => {
          if (this.props.success) {
            setTimeout(() => {
              this.setState({ loading: false, submitted: true });
            }, 2000);
          } else {
            this.setState({ loading: false, isSending: false });
            this.openNotification(
              'error',
              t('notifications.title.error', this.props.errorMessage)
            );
            this.props.form.setFieldsValue(formValues);
          }
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { submitted, isSending, formData } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Row>
        <Col
          lg={{ span: 18, offset: 3 }}
          sm={{ span: 22, offset: 1 }}
          xs={{ span: 22, offset: 1 }}
        >
          <Card style={{ margin: '5vh auto 10vh' }}>
            <Row>
              <Col xl={{ span: 9, offset: 2 }} lg={{ span: 9, offset: 2 }}>
                <div
                  style={{
                    padding: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      borderLeft: '6px solid #0018F5',
                      paddingLeft: '20px',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '40px',
                        fontWeight: 'bold',
                        color: '#000d76',
                      }}
                    >
                      {t('refer-customer.detail.section1')}
                    </p>
                    <p style={{ marginTop: '3vh', fontSize: '19px' }}>
                      {t('refer-customer.detail.section2')}
                    </p>
                  </div>
                </div>
                <div style={{ padding: '40px', marginTop: '5vh' }}>
                  <Button
                    style={{
                      fontSize: 20,
                      padding: '0px 25px',
                      height: '80px',
                      width: '300px',
                      borderRadius: '50px',
                      background: '#0018f5',
                      border: 'none',
                      color: '#ffffff',
                    }}
                    onClick={this.showModal}
                  >
                    {t('refer-customer.refer-button')}
                  </Button>
                </div>
              </Col>
              <Col xl={{ span: 9, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                <div
                  style={{
                    background: '#F5F5F5',
                    borderRadius: '20px',
                    padding: '20px',
                    width: 'fit-content',
                    marginTop: '3vh',
                  }}
                >
                  <div style={{ fontSize: '19px' }}>
                    <p
                      style={{
                        display: 'inline',
                        fontWeight: 'bold',
                        fontSize: 'inherit',
                        color: '#000d76',
                      }}
                    >
                      {t('refer-customer.detail.refer.sub-title')}
                    </p>
                    <p style={{ display: 'inline', fontSize: 'inherit' }}>
                      {t('refer-customer.detail.refer.description')}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    background: '#F5F5F5',
                    borderRadius: '20px',
                    padding: '20px',
                    width: 'fit-content',
                    marginTop: '3vh',
                  }}
                >
                  <div style={{ fontSize: '19px' }}>
                    <p
                      style={{
                        display: 'inline',
                        fontWeight: 'bold',
                        fontSize: 'inherit',
                        color: '#000d76',
                      }}
                    >
                      {t('refer-customer.detail.connect.sub-title')}
                    </p>
                    <p
                      style={{
                        display: 'inline',
                        fontSize: 'inherit',
                        textAlign: 'justify',
                      }}
                    >
                      {t('refer-customer.detail.connect.description')}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    background: '#F5F5F5',
                    borderRadius: '20px',
                    padding: '20px',
                    width: 'fit-content',
                    marginTop: '3vh',
                  }}
                >
                  <div style={{ fontSize: '19px' }}>
                    <p
                      style={{
                        display: 'inline',
                        fontWeight: 'bold',
                        fontSize: 'inherit',
                        color: '#000d76',
                      }}
                    >
                      {t('refer-customer.detail.earn-rewards.sub-title')}
                    </p>
                    <p
                      style={{
                        display: 'inline',
                        fontSize: 'inherit',
                        textAlign: 'justify',
                      }}
                    >
                      {t('refer-customer.detail.earn-rewards.description')}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Modal
              title={!submitted && t('refer-customer.refer-button')}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={[
                submitted && (
                  <Button key="cancel" onClick={this.handleCancel}>
                    {t('refer-customer.close-button')}
                  </Button>
                ),
              ]}
              width={'80%'}
            >
              {submitted ? (
                <div style={{ padding: '10%', textAlign: 'center' }}>
                  <h4>{t('refer-customer.form.success-message.text1')}</h4>
                  <h4>{t('refer-customer.form.success-message.text2')}</h4>
                </div>
              ) : isSending ? (
                <div style={{ padding: '10%', textAlign: 'center' }}>
                  <Spin
                    size="large"
                    tip={t('refer-customer.form.success-message.spin')}
                  />
                </div>
              ) : (
                <Form layout="horizontal" onSubmit={this.handleSubmit}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormItem
                        label={t('refer-customer.form.hacker-email.label')}
                        extra={t('refer-customer.form.hacker-email.extra')}
                      >
                        {getFieldDecorator('hacker_email', {
                          rules: [
                            {
                              required: true,
                            },
                          ],
                          initialValue: this.props.currentUser.email,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.hacker-email.label'
                            )}
                            disabled
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <h6>{t('refer-customer.form.client-data')}</h6>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <FormItem
                        label={t('refer-customer.form.client-name.label')}
                        name="client_name"
                      >
                        {getFieldDecorator('referred_first_name', {
                          rules: [
                            {
                              required: true,
                              message: t(
                                'refer-customer.form.client-name.message'
                              ),
                            },
                          ],
                          initialValue: formData.referred_first_name,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.client-name.placeholder'
                            )}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <FormItem
                        label={t('refer-customer.form.client-surname.label')}
                        name="client_surname"
                      >
                        {getFieldDecorator('referred_last_name', {
                          rules: [
                            {
                              required: true,
                              message: t(
                                'refer-customer.form.client-surname.message'
                              ),
                            },
                          ],
                          initialValue: formData.referred_last_name,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.client-surname.placeholder'
                            )}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <FormItem
                        label={t('refer-customer.form.client-email.label')}
                        name="client_email"
                      >
                        {getFieldDecorator('referred_email', {
                          rules: [
                            {
                              required: true,
                              message: t(
                                'refer-customer.form.client-email.message'
                              ),
                            },
                            {
                              type: 'email',
                              message: t(
                                'refer-customer.form.client-email.type-message'
                              ),
                            },
                          ],
                          initialValue: formData.referred_email,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.client-email.placeholder'
                            )}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <FormItem
                        label={t('refer-customer.form.client-title.label')}
                      >
                        {getFieldDecorator('referred_title', {
                          rules: [
                            {
                              required: true,
                              message: t(
                                'refer-customer.form.client-title.message'
                              ),
                            },
                          ],
                          initialValue: formData.referred_title,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.client-title.placeholder'
                            )}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <FormItem
                        label={t('refer-customer.form.company-name.label')}
                        name="company_name"
                      >
                        {getFieldDecorator('referred_company', {
                          rules: [
                            {
                              required: true,
                              message: t(
                                'refer-customer.form.company-name.message'
                              ),
                            },
                          ],
                          initialValue: formData.referred_company,
                        })(
                          <Input
                            placeholder={t(
                              'refer-customer.form.company-name.placeholder'
                            )}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5vh' }}>
                    <Col span={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-hacker btn-block"
                      >
                        {t('refer-customer.send-button')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Modal>
          </Card>
        </Col>
      </Row>
    );
  }
}

const ReferForm = Form.create()(ReferCustomer);

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.currentUser,
  success: state.currentUser.success,
  errorMessage: state.currentUser.errorMessage,
});
export default withTranslation()(
  withRouter(connect(mapStateToProps)(ReferForm))
);
