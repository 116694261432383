import React from 'react';

import { EditOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';

import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Popconfirm,
  Modal,
  Spin,
  Image,
} from 'antd';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';

import ChangeStatusReport from './ChangeStatusReport';
import Marckdown from 'react-remarkable';
import { MarkOptions } from '../utils/MarkdownHelper';
import { SeverityList } from '../constants/Data';
import CommentList from './CommentList';
import ReportsExport from './ReportsExport';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import ReportStepsDetail from './ReportStepsDetail';

import HistoryList from './HistoryList';
import LoaderComponent from './LoaderComponent';

const ReportDetail = (props) => {
  const { t } = useTranslation();

  const handleMitigate = (e) => {
    const status = e.key;
    props.handleMitigate(status);
  };

  const handleRetest = (e) => {
    const status = e.key === undefined ? 'pending' : e.key;
    props.handleRetest(status);
  };

  const getEditButton = () => {
    return (
      <Link
        to={{
          pathname: `/dashboard/reports/${props.report.id}/update`,
          state: {
            report: { ...props.report, targets: props.targets },
            program: props.report.program,
            isEditing: true,
          },
        }}
        style={{ textDecoration: 'none' }}
      >
        <span style={{ width: '100%', fontSize: '18px' }}>
          {t('reports.edit')}
        </span>
      </Link>
    );
  };

  const getDeleteButton = () => {
    return (
      <span
        style={{ width: '100%', fontSize: '18px', marginBottom: '5px' }}
        onClick={confirmReportDeletion}
      >
        {t('reports.remove')}
      </span>
    );
  };

  const confirmReportDeletion = () => {
    Modal.confirm({
      title: t(`reports.dialogs.delete-report.confirm.title`),
      okText: t(`yes`),
      okType: 'danger',
      cancelText: t(`no`),
      onOk() {
        deleteReport();
      },
      onCancel() {},
    });
  };

  const deleteReport = () => {
    props.handleDeleteReport();
  };

  const mitigateMenu = (
    <Menu onClick={handleMitigate}>
      <Menu.Item key="pending">{t('reports.mitigation.pending')}</Menu.Item>
      <Menu.Item key="can-not-verify">
        {t('reports.mitigation.can-not-verify')}
      </Menu.Item>
      <Menu.Item key="not-fixed">{t('reports.mitigation.not-fixed')}</Menu.Item>
      <Menu.Item key="fixed">{t('reports.mitigation.fixed')}</Menu.Item>
      <Menu.Item key="partially-fixed">
        {t('reports.mitigation.partially-fixed')}
      </Menu.Item>
    </Menu>
  );

  const retestMenu = (
    <Menu onClick={handleRetest}>
      <Menu.Item key="ongoing">{t('reports.retest.ongoing')}</Menu.Item>
      <Menu.Item key="completed">{t('reports.retest.completed')}</Menu.Item>
    </Menu>
  );

  const getOptionsMenu = () => {
    const items = [];
    if (props.canEdit) {
      items.push({
        key: '1',
        label: getEditButton(),
      });
    }
    if (props.canDelete) {
      items.push({
        key: '2',
        label: getDeleteButton(),
      });
    }
    if (props.canExport) {
      items.push({
        key: '3',
        label: <ReportsExport report={props.report} />,
      });
    }
    return items;
  };

  const severityAttr = SeverityList[props.report.severity];

  const createdAt = moment(props.report.created_at).format('DD/MM/YYYY HH:mm');

  const mitigationStatus =
    props.report.mitigation_status !== null
      ? props.report.mitigation_status
      : 'pending';

  if (props.loading) return <LoaderComponent label={false} />;

  const itemsPerPage = 10;

  return (
    <div id="report-detail">
      <Card hoverable={false}>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }}>
            <p className="report-name ">
              {props.report.name ? props.report.name : '-'}
            </p>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 2, offset: 9 }}
            lg={{ span: 1, offset: 10 }}
          >
            <ChangeStatusReport
              report={props.report}
              onChangeStatus={props.handleChangeStatus}
              disabled={!props.canChangeStatus}
            />
          </Col>
          <Col
            xs={{ span: 3, offset: 9 }}
            sm={{ span: 1, offset: 4 }}
            lg={{ span: 1, offset: 4 }}
          >
            <Dropdown
              disabled={getOptionsMenu().length === 0}
              menu={{ items: getOptionsMenu() }}
              trigger={['click']}
            >
              <Button style={styleButtonDrop}>
                <Image
                  style={{ width: '5px', height: '20px' }}
                  preview={false}
                  src="/images/employee-options.png"
                />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Card>

      <Card hoverable={false}>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 3 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="vertical-container">
              <Avatar
                className="custom-avatar"
                size="large"
                icon={<UserOutlined />}
                src={props.report.user.avatar_thumbnail_s_url}
              />
              <Link
                to={`/dashboard/ranking/${props.report.user.username}`}
                style={{
                  color: '#11c1ee',
                  fontSize: props.isMobile ? '16px' : '14px',
                  fontWeight: 'bold',
                }}
              >
                {props.report.user.username}
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 16 }}
            md={{ span: 16 }}
            lg={{ span: 11 }}
          >
            <div className="flex-inline mb-3">
              <p className="report-label">{t('reports.form.attack-type')}:</p>
              <p className="report-detail">
                {props.report.attack_type
                  ? t(`reports.attack-list.${props.report.attack_type}`)
                  : t('reports.not-entered')}
              </p>
            </div>
            <div className="flex-inline mb-3">
              <p className="report-label">{t('reports.form.severity')}:</p>
              <p
                className="severity-container"
                style={{
                  border: `2px solid ${severityAttr.color}`,
                  color: severityAttr.color,
                  marginRight: '1vh',
                }}
              >
                {t(`reports.filters.severity.${props.report.severity}`)}
              </p>
              <p className="report-detail">({props.report.severity_score})</p>
            </div>
            {!props.companyMode && (
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.status.label')}:</p>
                <p className="report-detail">
                  {props.report.is_draft
                    ? t('reports.status.draft')
                    : t(`reports.status.${props.report.status}`)}
                </p>
              </div>
            )}
            <div className="flex-inline mb-3">
              <p className="report-label">Target:</p>
              <Spin spinning={props.programLoading || !props.program}>
                <p className="report-detail">
                  {props.report.targets.length > 0 ? (
                    props.report.targets.map((targetId, index) => {
                      const target =
                        props.program &&
                        props.program.targets &&
                        props.program.targets.find(
                          (item) => item.id === targetId
                        );

                      if (target) {
                        return <div key={index}>{target.target}</div>;
                      }
                    })
                  ) : (
                    <span>{t('other')}</span>
                  )}
                </p>
              </Spin>
            </div>
            <div className="flex-inline-start">
              <p className="report-label">
                {t('reports.form.error-location')}:
              </p>
              {props.report.urls.length ? (
                props.report.urls.map((url, index) => (
                  <p className="report-detail-list report-detail" key={index}>
                    {url.url}
                  </p>
                ))
              ) : (
                <span>No url</span>
              )}
            </div>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 16, offset: 3 }}
            md={{ span: 16, offset: 3 }}
            lg={{ span: 9, offset: 0 }}
          >
            <div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.program')}:</p>
                {props.report.program.name && (
                  <Link
                    to={`/dashboard/programs/${props.report.program.id}`}
                    className="link-text"
                  >
                    {props.report.program.name}
                    {props.report.program.is_closed &&
                      t('programs.status.closed')}
                  </Link>
                )}
              </div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.friendly-id')}:</p>
                <p className="report-detail">{props.report.friendly_id}</p>
              </div>
              <div className="flex-inline mb-3">
                <p className="report-label">{t('reports.form.date')}:</p>
                <p className="report-detail">{createdAt}&nbsp;Hrs.</p>
              </div>
              {props.canChangeMitigationStatus && (
                <div className="flex-inline mb-3">
                  <p className="report-label">
                    {t('reports.mitigation.status')}:
                  </p>
                  <p className="report-detail">
                    {t(`reports.mitigation.${mitigationStatus}`)}
                    {props.report.status === 'valid' && (
                      <Dropdown overlay={mitigateMenu} trigger={['click']}>
                        <EditOutlined
                          style={{ color: 'black', marginLeft: '5px' }}
                          onMouseEnter={(e) => (e.target.style.color = 'blue')}
                          onMouseLeave={(e) => (e.target.style.color = 'black')}
                        />
                      </Dropdown>
                    )}
                  </p>
                </div>
              )}
              {props.canChangeRetestStatus && (
                <div className="flex-inline mb-3">
                  <p className="report-label">Retest:</p>
                  <p className="report-detail">
                    {props.report.retest_status !== null ? (
                      <span>
                        {t(`reports.retest.${props.report.retest_status}`)}
                        {props.report.status === 'valid' && (
                          <Dropdown overlay={retestMenu} trigger={['click']}>
                            <EditOutlined
                              style={{ color: 'black', marginLeft: '5px' }}
                              onMouseEnter={(e) =>
                                (e.target.style.color = 'blue')
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.color = 'black')
                              }
                            />
                          </Dropdown>
                        )}
                      </span>
                    ) : (
                      <span style={{ cursor: 'pointer' }}>
                        {props.report.status === 'valid' ? (
                          <span
                            style={{ color: 'blue', textDecoration: 'none' }}
                            onMouseOver={(e) =>
                              (e.target.style.textDecoration = 'underline')
                            }
                            onMouseOut={(e) =>
                              (e.target.style.textDecoration = 'none')
                            }
                          >
                            <Popconfirm
                              title={t('reports.retest.confirm')}
                              onConfirm={handleRetest}
                              okText="Sí"
                              cancelText="No"
                            >
                              {t('reports.retest.request')}
                            </Popconfirm>
                          </span>
                        ) : (
                          t('reports.retest.not-available')
                        )}
                        {props.report.status === 'valid' && (
                          <RightOutlined
                            style={{ color: 'blue', marginLeft: '5px' }}
                          />
                        )}
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Card>

      <Card hoverable={false}>
        <h3 style={{ fontSize: '16px', marginBottom: '25px' }}>
          {t('reports.attack-detail')}
        </h3>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.description')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.description ? props.report.description : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>
        <div className="mb-3">
          <p className="report-label">{t('reports.form.impact')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.impact ? props.report.impact : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.counter')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={
                props.report.additional_information
                  ? props.report.additional_information
                  : ''
              }
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        <div className="mb-3">
          <p className="report-label">{t('reports.form.references.label')}</p>
          <div
            className="color-form-data report-detail"
            style={{ wordWrap: 'break-word' }}
          >
            <Marckdown
              source={props.report.references ? props.report.references : ''}
              options={MarkOptions}
              className="markdown-input"
            />
          </div>
        </div>

        {!props.report.program.is_ptaas && (
          <React.Fragment>
            <div className="mb-3">
              <p className="report-label">Request</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.request ? props.report.request : ''}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>

            <div className="mb-3">
              <p className="report-label">{t('reports.form.vulnerable')}</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.affected_parameters}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>

            <div className="mb-3">
              <p className="report-label">{t('reports.form.payload')}</p>
              <div
                className="color-form-data report-detail"
                style={{ wordWrap: 'break-word' }}
              >
                <Marckdown
                  source={props.report.payload}
                  options={MarkOptions}
                  className="markdown-input"
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {props.report.proof_of_concept && (
          <div className="mb-3">
            <p className="report-label">{t('reports.form.poc')}</p>
            <div
              className="attribute-container color-form-data report-detail"
              style={{ wordWrap: 'break-word' }}
            >
              <Marckdown
                source={
                  props.report.proof_of_concept
                    ? props.report.proof_of_concept
                    : ''
                }
                options={MarkOptions}
                className="markdown-input"
              />
            </div>
          </div>
        )}
      </Card>
      {!_.isEmpty(props.report.steps) && (
        <Card hoverable={false}>
          <ReportStepsDetail steps={props.report.steps} />
        </Card>
      )}

      {!props.report.is_draft && (
        <CommentList
          canAdd={props.canAddComment}
          comments={props.comments}
          report={props.report}
          onAddComment={props.onAddComment}
          activeComment={props.activeComment}
        />
      )}
      <Card hoverable={false}>
        <h4 className="section-subtitle">
          <Image
            style={{ width: '17px', height: '17px' }}
            preview={false}
            src={`/images/history.png`}
          />
          {t('reports.history')}
        </h4>
        <br />
        {props.report ? (
          <div id="History">
            <HistoryList
              activities={props.activities}
              loading={props.activitiesLoading}
              totalItems={props.activitiesTotalItems}
              filters={props.activitiesFilters}
              totalPages={Math.ceil(props.activitiesTotalItems / itemsPerPage)}
              currentPage={props.activitiesFilters.page || 1}
              handlePageChange={props.handleActivitiesPageChange}
            />
          </div>
        ) : (
          <LoaderComponent />
        )}
      </Card>
    </div>
  );
};

const styleButtonDrop = {
  height: '40px',
  marginLeft: '15px',
  position: 'relative',
  bottom: '5px',
  border: 'none',
  padding: '10px',
};

export default withRouter(ReportDetail);
