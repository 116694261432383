import * as Action from '../actions/ActionTypes';

const initialState = {
  requesting: false,
  hasError: false,
  errorMessage: null,
};

class filesExport {
  static reduce(state = initialState, action) {
    if (filesExport[action.type]) {
      return filesExport[action.type](state, action);
    } else {
      return state;
    }
  }

  static [Action.REPORTS_PDF_EXPORT_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
      hasError: false,
    };
  }

  static [Action.REPORTS_PDF_EXPORT_SUCCESS](state, action) {
    const {
      response: { result },
    } = action;

    return {
      ...state,
      requesting: false,
      hasError: false,
    };
  }

  static [Action.REPORTS_PDF_EXPORT_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }
}

export default filesExport.reduce;
