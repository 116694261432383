import React, { Component } from 'react';
import moment from 'moment';
import { Avatar, Row, Col } from 'antd';

import ProgramFeatureDelete from './ProgramFeatureDelete';
import { MarkOptions } from '../utils/MarkdownHelper';
import '../themes/program-feature-view.less';
import Marckdown from 'react-remarkable';

class ProgramFeatureView extends Component {
  render() {
    const { feature } = this.props;
    return (
      <div className="comment-item">
        <Row>
          <Col
            xs={{ span: 5 }}
            sm={{ span: 5 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Avatar
              size="large"
              src={
                this.props.feature.user.avatar_thumbnail_s_url ||
                `public/images/person.png`
              }
              style={{ height: '80px', width: '80px', borderRadius: '40px' }}
            />
          </Col>
          <Col
            xs={{ span: 15 }}
            sm={{ span: 15 }}
            md={{ span: 15 }}
            lg={{ span: 15 }}
          >
            <div className="comment-content">
              <p style={{ fontSize: '11px' }}>
                {this.props.feature.user.username}
              </p>
              <div className="date">
                {moment(this.props.feature.created_at).format(
                  'DD-MM-YYYY HH:mm'
                )}{' '}
                hrs
              </div>
              <Marckdown
                source={this.props.feature.text}
                options={MarkOptions}
              />
            </div>
          </Col>
          <Col
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2, offset: 1 }}
          >
            <ProgramFeatureDelete
              handleDelete={this.props.handleDelete}
              feature={feature}
              hidden={!this.props.canDelete}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProgramFeatureView;
