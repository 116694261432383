import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { userPasswordReset } from '../redux/actions/auth';
import { withTranslation } from 'react-i18next';
import { openNotification } from '../utils/General';

const FormItem = Form.Item;

class PasswordResetForm extends Component {
  state = {
    loadingButton: false,
    confirmDirty: false,
  };

  handleConfirmPassword = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const { t } = this.props;
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password1')) {
      callback(t('profile.security.pass-check2'));
    } else {
      callback();
    }
  };

  checkConfirmPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['new_password2'], { force: true });
    }
    callback();
  };

  getUrlParams() {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    const uid = params.get('uid');

    return uid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, form, token, t } = this.props;
    this.setState({ loadingButton: true });

    form.validateFields((err, values) => {
      if (!err) {
        const attributes = values;

        const uid = this.getUrlParams();
        attributes.uid = uid;
        dispatch(userPasswordReset(attributes)).then(() => {
          if (this.props.success) {
            openNotification(
              'success',
              t('notifications.title.congratulations'),
              t('notifications.password-reset.success')
            );
            this.setState({ loadingButton: false });
            this.props.history.push('/login');
          } else {
            openNotification(
              'error',
              t('notifications.title.warning'),
              this.props.errorMessage
            );
            this.setState({ loadingButton: false });
          }
        });
        form.resetFields();
      } else {
        this.setState({ loadingButton: false });
      }
    });
  };

  getForm() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} id="SecurityForm">
        <FormItem label={t('profile.security.new-pass')}>
          {getFieldDecorator('new_password1', {
            rules: [
              {
                required: true,
                message: t('profile.security.new-pass-message'),
              },
              { validator: this.checkConfirmPassword },
            ],
          })(
            <Input
              type="password"
              placeholder={t('profile.security.new-pass-placeholder')}
              autoComplete="new-password"
            />
          )}
        </FormItem>

        <FormItem label={t('profile.security.new-pass2')}>
          {getFieldDecorator('new_password2', {
            rules: [
              {
                required: true,
                message: t('profile.security.new-pass2-message'),
              },
              { validator: this.checkPassword },
            ],
          })(
            <Input
              type="password"
              onBlur={this.handleConfirmPassword}
              placeholder={t('profile.security.new-pass2-placeholder')}
              autoComplete="new-password"
            />
          )}
        </FormItem>

        <Button
          type="primary"
          htmlType="submit"
          className="btn-hacker btn-block"
          loading={this.state.loadingButton}
        >
          {t('profile.save-button')}
        </Button>
      </Form>
    );
  }

  render() {
    return <div className="ResetPasswordForm">{this.getForm()}</div>;
  }
}

const NormalPasswordResetForm = Form.create()(PasswordResetForm);

const mapStateToProps = (state) => ({
  resetPasswordPermit: state.auth.resetPasswordPermit,
  passwordChanged: state.auth.passwordChanged,
  errorMessage: state.auth.errorMessage,
  success: state.auth.success,
});

export default withTranslation()(
  connect(mapStateToProps)(NormalPasswordResetForm)
);
