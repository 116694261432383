import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Radio, Row } from 'antd';
import Markdown from 'react-remarkable';
import 'codemirror/lib/codemirror.css';
import 'highlight.js/styles/github.css';
import { MarkOptions } from '../utils/MarkdownHelper';
import * as _ from 'lodash';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { TextArea } = Input;

class ProgramFeatureForm extends Component {
  state = {
    loading: false,
    markdownButton: 'a',
    text: '',
  };

  editMarkdown = (e) => {
    const id = e;
    const name = _.split(id, '-', 1);
    const input = document.getElementById(name[0]);
    const markdown = document.getElementsByClassName(name[0] + '-p')[0];

    input.classList.add('ant-input');
    input.classList.remove('hidden');
    markdown.classList.add('hidden');
  };

  showPreview = (e) => {
    const { form } = this.props;
    const id = e;
    const name = _.split(id, '-', 1);
    const value = form.getFieldValue(name[0]);
    const input = document.getElementById(name[0]);
    const markdown = document.getElementsByClassName(id)[0];

    this.setState({ text: value });

    input.classList.add('hidden');
    markdown.classList.remove('hidden');
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { form } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values);
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="program-feature-form">
        <Row>
          <Col sm={2}>{/* missing avatar? */}</Col>
          <Col sm={24}>
            <FormItem>
              <div className="markdown">
                <div className="markdown-header">
                  <div className="pull-right">
                    <RadioGroup
                      defaultValue="write"
                      className="markdown-buttons"
                    >
                      <RadioButton value="write">
                        <EditOutlined id="text-e" onClick={() => this.editMarkdown('text-e')} />
                      </RadioButton>

                      <RadioButton value="show">
                        <EyeOutlined id="text-p" onClick={() => this.showPreview('text-p')} />
                      </RadioButton>
                    </RadioGroup>
                  </div>
                </div>

                {getFieldDecorator('text', {
                  rules: [
                    { required: true, message: 'Este campo es requerido.' },
                  ],
                })(
                  <TextArea
                    placeholder={t('programs.features.text')}
                    rows={6}
                  />
                )}
                <div className="tui-editor-contents text-p hidden  mt-2">
                  <Markdown source={this.state.text} options={MarkOptions} />
                </div>
              </div>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Button
            htmlType="submit"
            loading={loading}
            className="btn-block btn-company pull-right"
          >
            {t('programs.features.post-button')}
          </Button>
        </Row>
      </Form>
    );
  }
}

const WrappedProgramFeatureForm = Form.create()(ProgramFeatureForm);

const mapStateToProps = (state) => ({
  isPushing: state.program.isPushing,
  hasError: state.program.hasError,
  errorMessage: state.program.errorMessage,
});

export default withTranslation()(
  connect(mapStateToProps)(WrappedProgramFeatureForm)
);
