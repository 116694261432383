import { applyMiddleware, createStore } from 'redux';
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk';
import api from '../middleware/api';
import { persistStore, autoRehydrate } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import AppReducer from './reducers';

const middlewares = [thunk, api];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
}

const store = createStore(
  AppReducer,
  composeWithDevTools(applyMiddleware(...middlewares), autoRehydrate())
);

export const reduxPersist = persistStore(store, { whitelist: ['auth'] }); // 'currentUser', 'userEvents', 'user'

export default store;
