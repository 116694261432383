export const getProgramStatus = (program, t) => {
  let programState = null;
  let program_class = program.status;

  if (program.is_draft) {
    programState = t('programs.status.draft');
    program_class = 'draft';
  } else if (!program.is_approved) {
    programState = t('programs.status.review');
    program_class = 'review';
  } else if (program.status === 'closed') {
    programState = t('programs.status.closed');
  } else if (program.status === 'paused') {
    programState = t('programs.status.paused');
  } else if (program.status === 'active') {
    programState = t('programs.status.active');
  } else {
    programState = t('programs.status.inactive');
  }

  return { programState, program_class };
};
