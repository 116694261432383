import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const CompanyProgramsEmpty = (props) => {
  const { t } = useTranslation();

  return (
    <Row gutter={24}>
      <Col
        xs={{ span: 22, offset: 1 }}
        sm={{ span: 24 }}
        md={{ span: 14, offset: 5 }}
        lg={{ span: 14, offset: 5 }}
      >
        <Card hoverable={true}>
          <h2 className="text-center">
            {t('dashboard.programs.dialogs.first-program')}
          </h2>
          <p className="text-center">
            {t('dashboard.programs.dialogs.first-program-desc')}
          </p>
          <Row gutter={24}>
            <Col span={24}>
              <div className="block-center" style={{ paddingTop: '20px' }}>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="large"
                  onClick={props.onClick}
                  className="btn-company block-center"
                  style={{
                    height: '40px',
                    paddingLeft: '35px',
                    paddingRight: '35px',
                    fontSize: '18px',
                  }}
                >
                  {t('dashboard.programs.dialogs.first-program-button')}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyProgramsEmpty;
