import * as Action from './ActionTypes';
import { routes } from '../../constants/APIEndpoints';
import { CALL_API } from '../../middleware/api';

const getAllReports = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_REPORTS_REQUEST,
      Action.LIST_REPORTS_SUCCESS,
      Action.LIST_REPORTS_ERRORS,
    ],
    endpoint: routes.GET_REPORTS,
    method: 'GET',
    data: {},
  },
});
const getReportsForDownload = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_REPORTS_REQUEST,
      Action.GET_REPORT_DOWNLOAD_SUCCESS,
      Action.LIST_REPORTS_ERRORS,
    ],
    endpoint: routes.GET_REPORTS_DOWNLOAD,
    method: 'GET',
    data: {},
    params: params,
    responseType: 'blob',
  },
});

const getReportsWithFilters = (params) => ({
  [CALL_API]: {
    types: [
      Action.LIST_REPORTS_REQUEST,
      Action.LIST_REPORTS_SUCCESS,
      Action.LIST_REPORTS_ERRORS,
    ],
    endpoint: routes.GET_REPORTS,
    method: 'GET',
    data: {},
    params: params,
  },
});

const getReport = (id) => ({
  [CALL_API]: {
    types: [
      Action.GET_REPORT_REQUEST,
      Action.GET_REPORT_SUCCESS,
      Action.GET_REPORT_ERRORS,
    ],
    endpoint: routes.GET_REPORT + id + '/',
    method: 'GET',
    data: {},
  },
});

const saveReport = (program_slug) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_REPORT_REQUEST,
      Action.CREATE_REPORT_SUCCESS,
      Action.CREATE_REPORT_ERRORS,
    ],
    endpoint: routes.CREATE_REPORT,
    method: 'POST',
    data: { program_slug: program_slug },
  },
});

const editReport = (params, uid) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_REPORT_REQUEST,
      Action.UPDATE_REPORT_SUCCESS,
      Action.UPDATE_REPORT_ERRORS,
    ],
    endpoint: routes.UPDATE_REPORT + uid + '/',
    method: 'PATCH',
    data: params,
  },
});

const deleteReport = (uid) => ({
  [CALL_API]: {
    types: [
      Action.DELETE_REPORT_REQUEST,
      Action.DELETE_REPORT_SUCCESS,
      Action.DELETE_REPORT_ERRORS,
    ],
    endpoint: routes.DELETE_REPORT.replace(':id', uid),
    method: 'DELETE',
    data: {},
  },
});

const editStatusReport = (report_id, status_id, params) => ({
  [CALL_API]: {
    types: [
      Action.UPDATE_STATUS_REPORT_REQUEST,
      Action.UPDATE_STATUS_REPORT_SUCCESS,
      Action.UPDATE_STATUS_REPORT_ERROR,
    ],
    endpoint: routes.CHANGE_STATUS_REPORT.replace(':id', report_id),
    method: 'PATCH',
    data: { status: status_id },
  },
});

const addStep = (uid) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_REPORT_STEP_REQUEST,
      Action.CREATE_REPORT_STEP_SUCCESS,
      Action.CREATE_REPORT_STEP_ERRORS,
    ],
    endpoint: routes.CREATE_REPORT_STEP + uid,
    method: 'POST',
    data: {},
  },
});

const deleteStep = (id) => ({
  [CALL_API]: {
    types: [
      Action.REMOVE_REPORT_STEP_REQUEST,
      Action.REMOVE_REPORT_STEP_SUCCESS,
      Action.REMOVE_REPORT_STEP_ERRORS,
    ],
    endpoint: routes.DELETE_REPORT_STEP + id,
    method: 'DELETE',
    data: {},
  },
});

const saveAttachmentStep = (id, file) => ({
  [CALL_API]: {
    types: [
      Action.ADD_ATTACHMENT_STEP_REQUEST,
      Action.ADD_ATTACHMENT_STEP_SUCCESS,
      Action.ADD_ATTACHMENT_STEP_ERRORS,
    ],
    endpoint: routes.ADD_ATTACHMENT_STEP + id,
    method: 'POST',
    data: file,
  },
});

const deleteAttachmentStep = (id) => ({
  [CALL_API]: {
    types: [
      Action.REMOVE_ATTACHMENT_STEP_REQUEST,
      Action.REMOVE_ATTACHMENT_STEP_SUCCESS,
      Action.REMOVE_ATTACHMENT_STEP_ERRORS,
    ],
    endpoint: routes.REMOVE_ATTACHMENT_STEP + id,
    method: 'DELETE',
    data: {},
  },
});

const rewardOrder = (uuid, data) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_PAYMENT_REQUEST,
      Action.CREATE_PAYMENT_SUCCESS,
      Action.CREATE_PAYMENT_ERROR,
    ],
    endpoint: routes.CREATE_PAYMENT.replace(':report_uuid', uuid),
    method: 'POST',
    data: data,
  },
});

const callReportStatistics = (params) => ({
  [CALL_API]: {
    types: [
      Action.REPORT_STATISTICS_REQUEST,
      Action.REPORT_STATISTICS_SUCCESS,
      Action.REPORT_STATISTICS_ERRORS,
    ],
    endpoint: routes.REPORT_STATISTICS,
    method: 'GET',
    params: params,
    data: {},
  },
});

const getdownloadReport = (id, format) => ({
  [CALL_API]: {
    types: [
      Action.UNIT_REPORT_DOWNLOAD_REQUEST,
      Action.UNIT_REPORT_DOWNLOAD_SUCCESS,
      Action.UNIT_REPORT_DOWNLOAD_ERRORS,
    ],
    endpoint: routes.GET_UNIT_REPORT.replace(':id', id),
    method: 'GET',
    data: {},
    params: format,
    responseType: 'blob',
  },
});

// NEW BACKEND
const createNewReport = (params) => ({
  [CALL_API]: {
    types: [
      Action.CREATE_REPORT_REQUEST,
      Action.CREATE_REPORT_SUCCESS,
      Action.CREATE_REPORT_ERRORS,
    ],
    endpoint: routes.CREATE_REPORT,
    method: 'POST',
    data: params,
  },
});

const addStepMedia = (file) => ({
  [CALL_API]: {
    types: [
      Action.ADD_ATTACHMENT_STEP_REQUEST,
      Action.ADD_ATTACHMENT_STEP_SUCCESS,
      Action.ADD_ATTACHMENT_STEP_ERRORS,
    ],
    endpoint: routes.ADD_MEDIA,
    method: 'POST',
    data: file,
  },
});

const callTargets = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_TARGETS_REQUEST,
      Action.LIST_TARGETS_SUCCESS,
      Action.LIST_TARGETS_ERRORS,
    ],
    endpoint: routes.GET_TARGETS,
    method: 'GET',
    data: {},
  },
});

const callPrograms = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_PROGRAMS_REQUEST,
      Action.LIST_PROGRAM_SUCCESS,
      Action.LIST_PROGRAM_ERRORS,
    ],
    endpoint: routes.GET_ALL_PROGRAMS,
    method: 'GET',
    data: {},
  },
});

const callAttackList = () => ({
  [CALL_API]: {
    types: [
      Action.LIST_ATTACK_TYPE_REQUEST,
      Action.LIST_ATTACK_TYPE_SUCCESS,
      Action.LIST_ATTACK_TYPE_ERRORS,
    ],
    endpoint: routes.GET_ATTACK_TYPE_LIST,
    method: 'GET',
    data: {},
  },
});

export const subscribeAllReports = () => (dispatch, getState) => {
  return dispatch(getAllReports());
};

export const subscribeReportsWithFilters = (params) => (dispatch, getState) => {
  return dispatch(getReportsWithFilters(params));
};
export const subscribeReportsDownload =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(getReportsForDownload(params));
  };

export const downloadReport = (id, format) => (dispatch, getState) => {
  return dispatch(getdownloadReport(id, format));
};

export const getReportStatistics =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callReportStatistics(params));
  };

export const subscribeReport = (uuid) => (dispatch, getState) => {
  return dispatch(getReport(uuid));
};

export const createReport = (program_slug) => (dispatch, getState) => {
  return dispatch(saveReport(program_slug));
};

export const updateReport = (params, uid) => (dispatch, getState) => {
  return dispatch(editReport(params, uid));
};

export const removeReport = (uid) => (dispatch, getState) => {
  return dispatch(deleteReport(uid));
};

export const updateStatusReport =
  (report_id, status_id, params) => (dispatch, getState) => {
    return dispatch(editStatusReport(report_id, status_id, params));
  };

export const createReportStep = (uid) => (dispatch, getState) => {
  return dispatch(addStep(uid));
};

export const removeReportStep = (id) => (dispatch, getState) => {
  return dispatch(deleteStep(id));
};
export const removeAttachmentStep = (id) => (dispatch, getState) => {
  return dispatch(deleteAttachmentStep(id));
};

export const addAttachmentStep = (id, file) => (dispatch, getState) => {
  return dispatch(saveAttachmentStep(id, file));
};

export const subscribeRewardOrder = (uuid, data) => (dispatch, getState) => {
  return dispatch(rewardOrder(uuid, data));
};

// NEW BACKEND
export const newReport = (params) => (dispatch, getState) => {
  return dispatch(createNewReport(params));
};

export const addMedia = (params) => (dispatch, getState) => {
  return dispatch(addStepMedia(params));
};

export const getTargets =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callTargets(params));
  };

export const getAllPrograms =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callPrograms(params));
  };

export const getAttackTypeList =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(callAttackList(params));
  };

export const updateReportOnArray = (report) => (dispatch, getState) => {
  dispatch({
    type: Action.UPDATE_REPORT_ON_STORE_SUCCESS,
    report: report,
  });
};

export const removeReportFromArray = (uuid) => (dispatch, getState) => {
  dispatch({
    type: Action.DELETE_REPORT_FROM_STORE_SUCCESS,
    uuid: uuid,
  });
};

export const clearReport = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_REPORT,
  });
};

export const clearReports = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_REPORTS,
  });
};

export const clearReportsFilters = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_REPORTS_FILTERS,
  });
};

export const clearReportsFiltersPersist = () => (dispatch, getState) => {
  dispatch({
    type: Action.CLEAR_REPORTS_FILTERS_PERSIST,
  });
};

export const setFilters = (params) => (dispatch, getState) => {
  dispatch({
    type: Action.SET_REPORTS_FILTERS,
    params: params,
  });
};

export const addReport = (params) => (dispatch) => {
  return dispatch({
    type: Action.ADD_REPORT_SUCCESS,
    payload: false,
    data: params,
  });
};

export const updateCurrentReport = (params) => (dispatch) => {
  return dispatch({
    type: Action.UPDATE_CURRENT_REPORT_SUCCESS,
    payload: false,
    data: params,
  });
};
