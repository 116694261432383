import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Image, Row, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import { createProgram } from '../redux/actions/program';
import { withTranslation } from 'react-i18next';
import { mapSizes } from '../mapSizes';

class UserDetail extends PureComponent {
  state = {
    loadingButton: false,
  };

  getNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  createProgramDraft = () => {
    const { dispatch } = this.props;

    this.setState({
      loadingButton: true,
    });
    dispatch(createProgram());
  };

  verifyUrl = (url) => {
    const res = url.match(
      /^(?:https?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/g
    );
    return res !== null;
  };

  getUserDetails = () => {
    const { currentUser, avatarLoaded, onRanking, isMobile, t } = this.props;
    if (onRanking) {
      return (
        <div id="hackerDetails">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 18, offset: 3 }}
          >
            <Row gutter={12}>
              <Col xs={{ span: 8 }} sm={4} md={4}>
                <div className="user-avatar-container">
                  <Avatar
                    size="large"
                    src={
                      avatarLoaded || onRanking
                        ? currentUser.avatar_thumbnail_s_url
                        : ''
                    }
                  />
                </div>
                <div className="social-icons">
                  {currentUser.twitter_url ? (
                    <a href={`${currentUser.twitter_url}`} target="blank">
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          '/images/twitter-dashboard.png'
                        }
                        preview={false}
                        width={30}
                      />
                    </a>
                  ) : (
                    ''
                  )}
                  {currentUser.linkedin_url ? (
                    <a
                      href={
                        this.verifyUrl(currentUser.linkedin_url)
                          ? currentUser.linkedin_url
                          : '/not-found'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={
                          process.env.PUBLIC_URL +
                          '/images/linkedin-dashboard.png'
                        }
                        preview={false}
                        width={30}
                      />
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </Col>
              <Col
                xs={16}
                sm={12}
                md={{ span: 10, offset: 2 }}
                style={{ marginTop: !isMobile && '60px' }}
              >
                <h2>
                  <b>{currentUser.username}</b>
                </h2>
                {onRanking ? (
                  ''
                ) : (
                  <p className="country">
                    {currentUser.country
                      ? currentUser.country.untranslated_name
                      : ''}
                  </p>
                )}
                <p className="description">{currentUser.about}</p>
              </Col>

              {onRanking ? (
                <Col xs={12} sm={8} md={8}>
                  {
                    currentUser.is_verified ? (
                      <Card className="identified">
                        {t('ranking.verified')}
                      </Card>
                    ) : (
                      ''
                    )
                    // <Card className='no-identified'>ID No Verificada</Card>
                  }
                </Col>
              ) : (
                <span style={{ padding: 0 }}>
                  <Col xs={8} sm={4} md={4}>
                    <h5 className="subTitle">Ranking</h5>
                    <p className="ranking">{currentUser.ranking}</p>
                  </Col>
                  <Col xs={8} sm={4} md={4}>
                    <h5 className="subTitle">Puntos</h5>
                    <p className="score">{currentUser.score}</p>
                  </Col>
                </span>
              )}
            </Row>
          </Col>
        </div>
      );
    }

    return (
      <div id="companyDetails">
        {isMobile ? (
          <Col xs={24}>
            <Row>
              <Col xs={24} sm={10} md={10}>
                <div className="companyAvatar">
                  <Avatar
                    size="large"
                    src={
                      currentUser.company.avatar_thumbnail_s_url ||
                      '/images/profile-default.png'
                    }
                  />
                </div>
              </Col>
              <Col xs={24} sm={14} md={14}>
                <div className="buttonContainer">
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="large"
                    onClick={this.createProgramDraft}
                    loading={this.state.loadingButton}
                    className="block-center btn-company btn-create-company"
                  >
                    {t('programs.create')}
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24}>
                <h2>{currentUser.company.name}</h2>
                <p className="description">{currentUser.company.description}</p>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col
            sm={{ span: 20, offset: 2 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 18, offset: 3 }}
          >
            <Row gutter={24}>
              <Col sm={5} md={5}>
                <div className="companyAvatar">
                  <Avatar
                    size="large"
                    src={currentUser.company.avatar_thumbnail_s_url}
                  />
                </div>
              </Col>
              <Col sm={13} md={13}>
                <h2>{currentUser.company.name}</h2>
                <p className="description">{currentUser.company.description}</p>
              </Col>
              <Col sm={6} md={6}>
                <div className="buttonContainer">
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="large"
                    onClick={this.createProgramDraft}
                    loading={this.state.loadingButton}
                    className="block-center btn-company btn-create-company"
                  >
                    Crear Programa
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </div>
    );
  };

  render() {
    return this.getUserDetails();
  }
}

const mapStateToProps = (state, ownProps) => ({
  avatarLoaded: state.currentUser.avatarLoaded,
});
export default withTranslation()(
  withRouter(withSizes(mapSizes)(connect(mapStateToProps)(UserDetail)))
);
