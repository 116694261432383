import * as Action from '../actions/ActionTypes';
import * as _ from 'lodash';

const stateConstants = {
  isPushing: false,
  isFetching: false,
  isSuccess: false,
  isChanged: false,
  requesting: false,
  hasError: false,
  errorMessage: null,
  isUpdatingPassword: false,
  isPasswordUpdated: false,
  passwordUpdateError: null,
  avatarLoaded: true,
  avatarRequesting: false,
  avatarNotFound: null,
  companyAvatarLoaded: true,
  companyAvatarRequesting: false,
  companyAvatarNotFound: null,
  notifications: {
    entities: [],
    maxLength: 0,
    unreadedNotifications: 0,
    notify: false,
    unreaded: 0,
  },
};
const initialState = {
  firstname: null,
  lastname: null,
  username: null,
  email: null,

  role: null,
  defaultLanguage: null,

  avatarUrl: null,
  avatarThumbnailXsUrl: null,
  avatarThumbnailSUrl: null,

  currentCompany: {
    sector: null,
  },

  profile: {
    name: null,
    lastName: null,
    nickName: null,
    slug: null,
    email: null,
    country: null,
    avatar: null,
    avatar_url: null,
    avatar_thumbnail_s_url: null,
    avatar_thumbnail_xs_url: null,
    linkedin: null,
    is_public_profile: false,
    linkedinShortUrl: null,
    twitter: null,
    score: 0,
    rewarded: 0,
    ranking_position: 0,
    preferedLanguage: null,
    company: {
      sector: null,
    },
    languages: [],
    skills: [],
    id_document: null,
    paypal_account: null,
    bank_account: null,
    role: null,
    certifications: [],
    verify: false,
    verifyMethod: null,
    backup: false,
    backupRemainingCodes: null,
    passport_dni_file: null,
    second_factor: false,
    paypal_address_1: null,
    paypal_address_2: null,
    paypal_city: null,
    paypal_country: null,
    paypal_email: null,
    paypal_pin: null,
    bank_account_dni: null,
    bank_account_name: null,
    bank_account_number: null,
    bank_account_type: null,
    bank_name: null,
  },
  sectors: [],
  companies: [],
  success: false,

  ...stateConstants,
};

class CurrentUserReducer {
  static reduce(state = initialState, action) {
    if (CurrentUserReducer[action.type]) {
      return CurrentUserReducer[action.type](state, action);
    } else {
      return state;
    }
  }

  static 'persist/REHYDRATE'(state, action) {
    return {
      ...action.payload.currentUser,
      ...initialState,
    };
  }

  static [Action.SET_CURRENT_USER](state, action) {
    return {
      ...state,
    };
  }

  static [Action.FETCH_PROFILE_REQUEST](state, action) {
    return {
      ...state,
      isFetching: true,
    };
  }

  static [Action.SKILL_UPDATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.PROFILE_UPDATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.PROFILE_UPDATE_SUCCESS](state, action) {
    const currentUser = action.response;
    return {
      ...state,
      isPushing: false,
      isFetching: false,
      hasError: false,
      success: true,
      profile: {
        ...state.profile,
        name: currentUser.first_name,
        lastName: currentUser.last_name ? currentUser.last_name : null,
        email: currentUser.email,
        role: currentUser.role,
        twitter_url: currentUser.twitter_url,
        linkedin_url: currentUser.linkedin_url,
        avatar_url: currentUser.avatar_url,
        default_language: currentUser.default_language,
        languages: currentUser.languages,
        about_me: currentUser.about_me,
        country: currentUser.country ? currentUser.country : null,
        paypal_address_1: currentUser.paypal_address_1,
        paypal_address_2: currentUser.paypal_address_2,
        paypal_city: currentUser.paypal_city,
        paypal_country: currentUser.paypal_country,
        paypal_email: currentUser.paypal_email,
        paypal_pin: currentUser.paypal_pin,
        bank_account_dni: currentUser.bank_account,
        bank_account_name: currentUser.bank_account_number,
        bank_account_number: currentUser.bank_account_number,
        bank_account_type: currentUser.bank_account_type,
        bank_name: currentUser.bank_account_name,
        skills: currentUser.skills,
        certifications: currentUser.certifications,
        id_document: currentUser.id_document,
        company: {
          name: action.response.current_company
            ? action.response.current_company.name
            : null,
          active_plan: action.response.current_company
            ? action.response.current_company.active_plan
            : null,
          description: action.response.current_company
            ? action.response.current_company.description
            : null,
          avatar_url: action.response.current_company
            ? action.response.current_company.avatar_url
            : null,
          avatar_thumbnail_xs_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_xs_url
            : null,
          avatar_thumbnail_s_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_s_url
            : null,
          corporate_name: action.response.current_company
            ? action.response.current_company.corporate_name
            : null,
          address: action.response.current_company
            ? action.response.current_company.address
            : null,
          phone: action.response.current_company
            ? action.response.current_company.phone
            : null,
          contact_email: action.response.current_company
            ? action.response.current_company.contact_email
            : null,
          country: action.response.current_company
            ? action.response.current_company.country
            : null,
          commercial_number: action.response.current_company
            ? action.response.current_company.commercial_number
            : null,
          business_activity: action.response.current_company
            ? action.response.current_company.business_activity
            : null,
        },
      },
    };
  }

  static [Action.PROFILE_UPDATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      isFetching: false,
      hasError: true,
      success: false,
      errorMessage: action.error,
      notifications: {
        ...state.notifications,
      },
    };
  }

  static [Action.GET_USER_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: false,
    };
  }

  static [Action.GET_USER_SUCCESS](state, action) {
    const currentUser = action.response;
    return {
      ...state,
      isPushing: false,
      isFetching: false,
      hasError: false,
      success: true,

      firstname: currentUser.first_name,
      lastname: currentUser.last_name,
      username: currentUser.username,
      email: currentUser.email,

      role: currentUser.role,
      defaultLanguage: currentUser.default_language,

      avatarUrl: currentUser.avatar_url,
      avatarThumbnailXsUrl: currentUser.avatar_thumbnail_xs_url,
      avatarThumbnailSUrl: currentUser.avatar_thumbnail_s_url,

      currentCompany: {
        ...state.currentCompany,
        name: action.response.current_company
          ? action.response.current_company.name
          : null,
        activePlan: action.response.current_company
          ? action.response.current_company.active_plan
          : null,
        description: action.response.current_company
          ? action.response.current_company.description
          : null,
        avatarUrl: action.response.current_company
          ? action.response.current_company.avatar_url
          : null,
        avatarThumbnailXsUrl: action.response.current_company
          ? action.response.current_company.avatar_thumbnail_xs_url
          : null,
        avatarThumbnailSUrl: action.response.current_company
          ? action.response.current_company.avatar_thumbnail_s_url
          : null,
        corporateName: action.response.current_company
          ? action.response.current_company.corporate_name
          : null,
        address: action.response.current_company
          ? action.response.current_company.address
          : null,
        phone: action.response.current_company
          ? action.response.current_company.phone
          : null,
        contactEmail: action.response.current_company
          ? action.response.current_company.contact_email
          : null,
        country: action.response.current_company
          ? action.response.current_company.country
          : null,
        commercialNumber: action.response.current_company
          ? action.response.current_company.commercial_number
          : null,
        businessActivity: action.response.current_company
          ? action.response.current_company.business_activity
          : null,
        sector: action.response.current_company
          ? action.response.current_company.sector
          : null,
        website: action.response.current_company
          ? action.response.current_company.website
          : null,
      },

      profile: {
        ...state.profile,
        name: currentUser.first_name,
        lastName: currentUser.last_name ? currentUser.last_name : null,
        nickName: currentUser.username ? currentUser.username : null,
        email: currentUser.email,
        role: currentUser.role,
        twitter_url: currentUser.twitter_url,
        linkedin_url: currentUser.linkedin_url,
        avatar_url: currentUser.avatar_url,
        avatar_thumbnail_xs_url: currentUser.avatar_thumbnail_xs_url,
        avatar_thumbnail_s_url: currentUser.avatar_thumbnail_s_url,
        default_language: currentUser.default_language,
        languages: currentUser.languages,
        about_me: currentUser.about_me,
        country: currentUser.country ? currentUser.country : null,
        ranking: currentUser.ranking ? currentUser.ranking : null,
        score: currentUser.score,
        rewarded: currentUser.rewarded,
        paypal_address_1: currentUser.paypal_address_1,
        paypal_address_2: currentUser.paypal_address_2,
        paypal_city: currentUser.paypal_city,
        paypal_country: currentUser.paypal_country,
        paypal_email: currentUser.paypal_email,
        paypal_pin: currentUser.paypal_pin,
        bank_account_dni: currentUser.bank_account_dni,
        bank_account_name: currentUser.bank_account_name,
        bank_account_number: currentUser.bank_account_number,
        bank_account_type: currentUser.bank_account_type,
        bank_name: currentUser.bank_name,
        certifications: currentUser.certifications,
        verify: currentUser.verify,
        verifyMethod: currentUser.verify_method,
        backup: currentUser.backup,
        backupRemainingCodes: currentUser.backup_remaining_codes,
        company: {
          name: action.response.current_company
            ? action.response.current_company.name
            : null,
          active_plan: action.response.current_company
            ? action.response.current_company.active_plan
            : null,
          description: action.response.current_company
            ? action.response.current_company.description
            : null,
          avatar_url: action.response.current_company
            ? action.response.current_company.avatar_url
            : null,
          avatar_thumbnail_xs_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_xs_url
            : null,
          avatar_thumbnail_s_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_s_url
            : null,
          corporate_name: action.response.current_company
            ? action.response.current_company.corporate_name
            : null,
          address: action.response.current_company
            ? action.response.current_company.address
            : null,
          phone: action.response.current_company
            ? action.response.current_company.phone
            : null,
          contact_email: action.response.current_company
            ? action.response.current_company.contact_email
            : null,
          country: action.response.current_company
            ? action.response.current_company.country
            : null,
          commercial_number: action.response.current_company
            ? action.response.current_company.commercial_number
            : null,
          business_activity: action.response.current_company
            ? action.response.current_company.business_activity
            : null,
        },
      },
      notifications: {
        ...state.notifications,
      },
    };
  }

  static [Action.GET_USER_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      isFetching: false,
      hasError: false,
      profile: null,
      notifications: {
        ...state.notifications,
      },
    };
  }

  static [Action.SKILL_UPDATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      refreshAvatar: false,
      hasError: true,
      errorMessage: action.error,
      success: false,
    };
  }

  static [Action.UPDATE_PROFILE_IMAGE_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.UPDATE_PROFILE_IMAGE_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.UPDATE_PROFILE_IMAGE_ERROR](state, action) {
    return {
      ...state,
      errorMessage: action,
      hasError: true,
    };
  }

  static [Action.UPDATE_COMPANY_IMAGE_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.UPDATE_COMPANY_IMAGE_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.UPDATE_COMPANY_IMAGE_ERROR](state, action) {
    return {
      ...state,
      errorMessage: action,
      hasError: true,
    };
  }

  static [Action.COMPANY_UPDATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
    };
  }

  static [Action.COMPANY_UPDATE_SUCCESS](state, action) {
    const { currentUser } = action.response;
    return {
      ...state,
      isPushing: false,
      hasError: false,
      isSuccess: true,

      currentCompany: {
        ...state.currentCompany,
        name: currentUser.company.name ? currentUser.company.name : null,
        corporateName: action.response.current_company
          ? action.response.current_company.corporate_name
          : null,
        address: action.response.current_company
          ? action.response.current_company.address
          : null,
        phone: action.response.current_company
          ? action.response.current_company.phone
          : null,
        contactEmail: action.response.current_company
          ? action.response.current_company.contact_email
          : null,
        country: action.response.current_company
          ? action.response.current_company.country
          : null,
        commercialNumber: action.response.current_company
          ? action.response.current_company.commercial_number
          : null,
        businessActivity: action.response.current_company
          ? action.response.current_company.business_activity
          : null,
        description: currentUser.company.description
          ? currentUser.company.description
          : null,
        website: currentUser.company.website
          ? currentUser.company.website
          : null,
        giro: currentUser.company.giro ? currentUser.company.giro : null,
        paymentMethod: currentUser.company.payment_method
          ? currentUser.company.payment_method
          : null,
        avatarUrl: currentUser.company.avatar_url
          ? currentUser.company.avatar_url
          : null,
        activePlan: currentUser.company.active_plan
          ? currentUser.company.active_plan
          : null,
        sector: currentUser.company.sector,
      },

      profile: {
        ...state.profile,
        company: {
          name: currentUser.company.name ? currentUser.company.name : null,
          corporate_name: action.response.current_company
            ? action.response.current_company.corporate_name
            : null,
          address: action.response.current_company
            ? action.response.current_company.address
            : null,
          phone: action.response.current_company
            ? action.response.current_company.phone
            : null,
          contact_email: action.response.current_company
            ? action.response.current_company.contact_email
            : null,
          country: action.response.current_company
            ? action.response.current_company.country
            : null,
          commercial_number: action.response.current_company
            ? action.response.current_company.commercial_number
            : null,
          business_activity: action.response.current_company
            ? action.response.current_company.business_activity
            : null,
          description: currentUser.company.description
            ? currentUser.company.description
            : null,
          domain: currentUser.company.domain
            ? currentUser.company.domain
            : null,
          giro: currentUser.company.giro ? currentUser.company.giro : null,
          payment_method: currentUser.company.payment_method
            ? currentUser.company.payment_method
            : null,
          avatar_url: currentUser.company.avatar_url
            ? currentUser.company.avatar_url
            : null,
          active_plan: currentUser.company.active_plan
            ? currentUser.company.active_plan
            : null,
          sector: currentUser.company.sector
            ? currentUser.company.sector
            : null,
        },
      },
    };
  }

  static [Action.COMPANY_UPDATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      isSuccess: false,
      errorMessage: action.error,
    };
  }

  static [Action.COMPANY_INVOICING_UPDATE_REQUEST](state, action) {
    return {
      ...state,
      isPushing: true,
      hasError: true,
    };
  }

  static [Action.COMPANY_INVOICING_UPDATE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
    };
  }

  static [Action.PAYPAL_REMOVE_SUCCESS](state, action) {
    return {
      ...state,
      profile: {
        ...state.profile,
        paypal_account: null,
      },
      isPushing: false,
      hasError: false,
    };
  }

  static [Action.PAYPAL_REMOVE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CERTIFICATE_REMOVE_SUCCESS](state, action) {
    const certificate_removed = action.response.result;
    _.remove(state.profile.certificates, (certificate) => {
      return certificate.id === certificate_removed;
    });

    return {
      ...state,
      isPushing: false,
      profile: {
        ...state.profile,
        certificates: state.profile.certificates,
      },
    };
  }

  static [Action.CERTIFICATE_REMOVE_ERROR](state, action) {
    return {
      ...state,
      isPushing: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.PROFILE_RECEIVED](state, action) {
    return {
      ...state,
      profile: action.payload,
      isFetching: false,
    };
  }

  static [Action.PUBLISH_STATUS_SUCCESS](state, action) {
    return {
      ...state,
      current_status: action.payload.post,
    };
  }

  static [Action.UPDATE_PASSWORD_REQUEST](state, action) {
    return {
      ...state,
      isUpdatingPassword: true,
      passwordUpdateError: null,
    };
  }

  static [Action.UPDATE_PASSWORD_SUCCESS](state, action) {
    return {
      ...state,
      isPasswordUpdated: true,
      isUpdatingPassword: false,
      passwordUpdateError: null,
    };
  }

  static [Action.UPDATE_PASSWORD_ERROR](state, action) {
    return {
      ...state,
      isPasswordUpdated: false,
      isUpdatingPassword: false,
      passwordUpdateError: action.payload,
      hasError: true,
    };
  }

  static [Action.RESET_ERROR](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.SIGN_OUT](state, action) {
    return initialState;
  }

  static [Action.CHECK_NOTIFICATIONS_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.CHECK_NOTIFICATIONS_SUCCESS](state, action) {
    const { result } = action.response;

    return {
      ...state,
      hasError: false,
      requesting: false,
      notifications: {
        ...state.notifications,
        unreaded: 0,
      },
    };
  }

  static [Action.CHECK_NOTIFICATIONS_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.LIST_NOTIFICATIONS_REQUEST](state, action) {
    return {
      ...state,
      requesting: true,
    };
  }

  static [Action.LIST_NOTIFICATIONS_SUCCESS](state, action) {
    const { results, unreaded, count } = action.response;
    return {
      ...state,
      notifications: {
        ...state.notifications,
        entities: results,
        maxLength: count,
        unreaded: unreaded,
      },
      hasError: false,
      requesting: false,
    };
  }

  static [Action.LIST_NOTIFICATIONS_ERROR](state, action) {
    return {
      ...state,
      requesting: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.ADD_NOTIFICATIONS_SUCCESS](state, action) {
    const { maxLength } = state.notifications;
    const {
      data,
      data: { unreadedNotifications },
    } = action;
    const notifications = _.values(data.notifications);
    const newUnreadedNotification =
      state.notifications.unreadedNotifications === 0
        ? 1
        : unreadedNotifications + 1;

    return {
      ...state,
      notifications: {
        ...state.notification,
        entities: notifications,
        maxLength: maxLength + 1,
        unreadedNotifications: newUnreadedNotification,
      },
    };
  }

  static [Action.CHANGE_PLAN_REQUEST](state, action) {
    return {
      ...state,
      isChanged: true,
    };
  }

  static [Action.CHANGE_PLAN_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
      isChanged: false,
    };
  }

  static [Action.CHANGE_PLAN_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  // NEW ENDOPOINTS
  static [Action.GET_PROFILE_REQUEST](state, action) {
    return {
      ...state,
    };
  }

  static [Action.FETCH_PROFILE_SUCCESS](state, action) {
    const currentUser = action.response;
    return {
      ...state,
      isPushing: false,
      isFetching: false,
      hasError: false,
      success: true,
      profile: {
        ...state.profile,
        name: currentUser.first_name,
        lastName: currentUser.last_name ? currentUser.last_name : null,
        email: currentUser.email,
        role: currentUser.role,
        twitter_url: currentUser.twitter_url,
        linkedin_url: currentUser.linkedin_url,
        avatar_url: currentUser.avatar_url,
        default_language: currentUser.default_language,
        languages: currentUser.languages,
        about_me: currentUser.about_me,
        country: currentUser.country ? currentUser.country : null,
        paypal_address_1: currentUser.paypal_address_1,
        paypal_address_2: currentUser.paypal_address_2,
        paypal_city: currentUser.paypal_city,
        paypal_country: currentUser.paypal_country,
        paypal_email: currentUser.paypal_email,
        paypal_pin: currentUser.paypal_pin,
        bank_account_dni: currentUser.bank_account,
        bank_account_name: currentUser.bank_account_number,
        bank_account_number: currentUser.bank_account_number,
        bank_account_type: currentUser.bank_account_type,
        bank_name: currentUser.bank_account_name,
        skills: currentUser.skills,
        certifications: currentUser.certifications,
        id_document: currentUser.id_document,
        company: {
          name: action.response.current_company
            ? action.response.current_company.name
            : null,
          active_plan: action.response.current_company
            ? action.response.current_company.active_plan
            : null,
          description: action.response.current_company
            ? action.response.current_company.description
            : null,
          avatar_url: action.response.current_company
            ? action.response.current_company.avatar_url
            : null,
          avatar_thumbnail_xs_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_xs_url
            : null,
          avatar_thumbnail_s_url: action.response.current_company
            ? action.response.current_company.avatar_thumbnail_s_url
            : null,
          corporate_name: action.response.current_company
            ? action.response.current_company.corporate_name
            : null,
          address: action.response.current_company
            ? action.response.current_company.address
            : null,
          phone: action.response.current_company
            ? action.response.current_company.phone
            : null,
          contact_email: action.response.current_company
            ? action.response.current_company.contact_email
            : null,
          country: action.response.current_company
            ? action.response.current_company.country
            : null,
          commercial_number: action.response.current_company
            ? action.response.current_company.commercial_number
            : null,
          business_activity: action.response.current_company
            ? action.response.current_company.business_activity
            : null,
        },
      },
    };
  }

  static [Action.FETCH_PROFILE_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.FETCH_COMPANY_PROFILE_REQUEST](state, action) {
    return {
      ...state,
    };
  }

  static [Action.FETCH_COMPANY_PROFILE_SUCCESS](state, action) {
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        owner: action.response.owner,
        sector: action.response.sector,
        website: action.response.website,
      },
      hasError: false,
    };
  }

  static [Action.FETCH_COMPANY_PROFILE_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.UPDATE_COMPANY_PROFILE_REQUEST](state, action) {
    return {
      ...state,
    };
  }

  static [Action.UPDATE_COMPANY_PROFILE_SUCCESS](state, action) {
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        name: action.response.name,
        activePlan: action.response.active_plan,
        description: action.response.description,
        avatarUrl: action.response.avatar_url,
        avatarThumbnailXsUrl: action.response.avatar_thumbnail_xs_url,
        avatarThumbnailSUrl: action.response.avatar_thumbnail_s_url,
        sector: action.response.sector,
        website: action.response.website,
        corporateName: action.response.corporate_name,
        address: action.response.address,
        phone: action.response.phone,
        contactEmail: action.response.contact_email,
        country: action.response.country,
        commercialNumber: action.response.commercial_number,
        businessActivity: action.response.business_activity,
      },
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          description: action.response.description,
          name: action.response.name,
          sector: action.response.sector,
          website: action.response.website,
          corporate_name: action.response.corporate_name,
          address: action.response.address,
          phone: action.response.phone,
          contact_email: action.response.contact_email,
          country: action.response.country,
          commercial_number: action.response.commercial_number,
          business_activity: action.response.business_activity,
        },
      },
      hasError: false,
      success: true,
    };
  }

  static [Action.UPDATE_COMPANY_PROFILE_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      success: false,
      errorMessage: action.error,
    };
  }

  static [Action.FETCH_COMPANY_SECTORS_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.FETCH_COMPANY_SECTORS_SUCCESS](state, action) {
    return {
      ...state,
      sectors: action.response,
      hasError: false,
      success: true,
    };
  }

  static [Action.FETCH_COMPANY_SECTORS_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.FETCH_COMPANIES_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
    };
  }

  static [Action.FETCH_COMPANIES_SUCCESS](state, action) {
    return {
      ...state,
      companies: action.response,
      hasError: false,
    };
  }

  static [Action.FETCH_COMPANIES_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      errorMessage: action.error,
    };
  }

  static [Action.CHANGE_CURRENT_COMPANY_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      success: false,
    };
  }

  static [Action.CHANGE_CURRENT_COMPANY_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
      success: true,
    };
  }

  static [Action.CHANGE_CURRENT_COMPANY_ERROR](state, action) {
    return {
      ...state,
      isChanged: false,
      hasError: true,
      success: false,
      errorMessage: action.error,
    };
  }

  static [Action.CHANGE_LANGUAGE_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      success: false,
    };
  }

  static [Action.CHANGE_LANGUAGE_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
      success: true,
    };
  }

  static [Action.CHANGE_LANGUAGE_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      success: false,
      errorMessage: action.error,
    };
  }

  static [Action.REFER_CUSTOMER_REQUEST](state, action) {
    return {
      ...state,
      hasError: false,
      success: false,
    };
  }

  static [Action.REFER_CUSTOMER_SUCCESS](state, action) {
    return {
      ...state,
      hasError: false,
      success: true,
    };
  }

  static [Action.REFER_CUSTOMER_ERROR](state, action) {
    return {
      ...state,
      hasError: true,
      success: false,
      errorMessage: action.error,
    };
  }
}

export default CurrentUserReducer.reduce;
