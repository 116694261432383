import React, { Component } from 'react';
import { Col, Row } from 'antd';

import '../themes/statistics.less';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Payments from '../containers/Payments';
import PaymentTotalAmount from '../components/PaymentTotalAmount';

class Rewards extends Component {
  render() {
    const { t, totalPaidAmount, totalPendingAmount } = this.props;

    return (
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Row className="section-title">
            <Col span={24}>
              <h1>{t('rewards.title')}</h1>
              <h4>{t('rewards.desc')}</h4>
            </Col>
          </Row>
          <Row id="Statistics">
            <Col span={24}>
              <div className="app-container">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={12} sm={12} md={12} lg={{ span: 12 }}>
                    <PaymentTotalAmount
                      title={t('rewards.total-rewards')}
                      value={Math.round(totalPaidAmount)}
                      currency={'Dolares'}
                      paid={true}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={{ span: 12 }}>
                    <PaymentTotalAmount
                      title={t('rewards.paid-rewards')}
                      value={Math.round(totalPendingAmount)}
                      currency={'Dolares'}
                    />
                  </Col>
                </Row>
                <Payments />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  totalPaidAmount: state.payments.totalPaidAmount,
  totalPendingAmount: state.payments.totalPendingAmount,
});

export default withTranslation()(connect(mapStateToProps)(Rewards));
