import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, Button, Row, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { getSignIn } from '../redux/actions/auth';
import * as _ from 'lodash';

//Traducciones
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;
class LoginForm extends Component {
  state = {
    loadingButton: false,
    loginError: false,
    messageError: null,
  };

  componentDidUpdate(prevProps) {
    const { isRequesting, auth, errorMessage } = this.props;

    if (prevProps.isRequesting !== isRequesting) {
      if (isRequesting) {
        this.setState({ loadingButton: true });
      } else {
        this.setState({ loadingButton: false });
        this.showMessage(auth.hasError, errorMessage);
      }
    }
  }

  showMessage = (hasError, message) => {
    if (hasError) {
      this.setState({
        message: message,
      });
    }
  };

  closeMessage = () => {
    this.setState({ message: null });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    this.closeMessage();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(getSignIn(values.email, values.password));
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col xs={24}>
          <Form
            onSubmit={this.handleSubmit}
            className="login-form"
            layout="vertical"
          >
            {!_.isEmpty(this.state.message) ? (
              <Alert
                message=""
                description={this.state.message}
                type="error"
                closable
                onClose={this.closeMessage}
              />
            ) : (
              ''
            )}
            <FormItem label={t('login.email')}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: t('signup.hacker.email-message'),
                    type: 'email',
                  },
                ],
              })(<Input />)}
            </FormItem>
            <FormItem label={t('login.pass')}>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: t('signup.hacker.pass-message') },
                ],
              })(<Input type="password" />)}
            </FormItem>
            <Row justify="center" align="middle">
              <Col>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button btn-hacker "
                    loading={this.state.loadingButton}
                  >
                    {t('login.login-button')}
                  </Button>
                </FormItem>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Link to={'/passwords/reset/request'}>
                {t('login.forgot-pass')}
              </Link>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(LoginForm);

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  currentUser: state.currentUser,
  isRequesting: state.auth.requesting,
  hasUser: state.currentUser !== null,
  errorMessage: state.auth.errorMessage,
});

export default withTranslation()(
  connect(mapStateToProps)(WrappedNormalLoginForm)
);
