import React, { useEffect, useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { Modal, Input, Button, Card, Spin, Image } from 'antd';
import NumberDisplay from '../utils/NumberDisplay';
import { getConversion } from '../redux/actions/wallet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { subscribeFinancePrograms } from '../redux/actions/programs';
import { openNotification } from '../utils/General';

const ModalConverter = ({
  currentUser,
  modalVisible,
  companyCurrentBalance,
  onCancel,
  isFetchingConversion,
  hasError,
  errorMessage,
  dispatch,
}) => {
  // States del componente
  const [credit, setCredit] = useState(1);
  const [creditInput, setCreditInput] = useState(1);
  const [dollarValue, setDollarValue] = useState(null);
  const [dollarInput, setDollarInput] = useState(null);
  const [creditToDollars, setCreditToDollars] = useState('');
  const [balanceError, setBalanceError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation();

  const fetchData = () => {
    const creditToDollarsValue =
      currentUser &&
      currentUser.currentCompany &&
      currentUser.currentCompany.activePlan &&
      currentUser.currentCompany.activePlan.features &&
      currentUser.currentCompany.activePlan.features.credit_to_dollars;

    if (creditToDollarsValue) {
      setCreditToDollars(creditToDollarsValue);
      setDollarInput(creditToDollarsValue);
      setDollarValue(creditToDollarsValue);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const closeModal = () => {
    onCancel(false);
  };

  const afterCloseModal = () => {
    fetchData();
    setSubmitted(false);
  };

  const handleCreditNumberChange = (e) => {
    const value = e.target.value.trim();

    if (!isNaN(value)) {
      const numericValue = Number(value);

      const newValue = value === '0' ? '' : numericValue;
      const convertedValue = newValue * dollarValue;

      setCredit(newValue);
      setCreditInput(newValue);
      setCreditToDollars(convertedValue);
      setDollarInput(convertedValue);
    }
  };

  const handleUsdNumberChange = (e) => {
    const value = e.target.value.trim();

    if (!isNaN(value)) {
      const numericValue = Number(value);
      const newValue = value === '0' ? '' : numericValue;
      let creditConvertedValue = newValue / dollarValue;
      creditConvertedValue =
        creditConvertedValue % 1 === 0
          ? creditConvertedValue.toFixed(0)
          : creditConvertedValue.toFixed(2);

      const dollarsInput = parseFloat(value);

      const credits = Math.floor(dollarsInput / dollarValue);

      const roundedDollars = credits * dollarValue;

      if (parseFloat(value) > companyCurrentBalance) {
        setBalanceError(t('budget.ptaas.converter.balance-error'));
      } else {
        setBalanceError(null);
      }

      setDollarInput(value);
      setCreditInput(creditConvertedValue);
      setCreditToDollars(roundedDollars);
      setCredit(credits);
    }
  };

  const validateConversion = () => {
    setSubmitted(true);
  };

  const handleConverter = () => {
    const attributes = { from_value: creditToDollars, to_value: credit };

    dispatch(getConversion(attributes)).then(() => {
      if (hasError) {
        openNotification('error', 'Error', errorMessage);
      } else {
        openNotification(
          'success',
          t('notifications.finance.success'),
          t('notifications.finance.conversion-message')
        );
        if (!isFetchingConversion) {
          setTimeout(() => {
            dispatch(subscribeFinancePrograms());
            closeModal();
          }, 500);
        }
      }
    });
  };

  const balance = companyCurrentBalance - creditToDollars;

  return (
    <Modal
      title={
        submitted
          ? t('budget.ptaas.converter.detail')
          : t('budget.ptaas.converter.title')
      }
      visible={modalVisible}
      onCancel={closeModal}
      afterClose={afterCloseModal}
      footer={null}
    >
      {submitted ? (
        <div
          style={{
            padding: '2%',
            textAlign: 'left',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <strong
              style={{
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              {t('budget.ptaas.converter.balance-actual')}:
            </strong>
            <div>
              <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                <NumberDisplay number={companyCurrentBalance} />
              </strong>
              <span style={{ fontSize: '11px', textTransform: 'uppercase' }}>
                {' '}
                USD
              </span>
            </div>
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <strong
              style={{
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              {t('budget.ptaas.converter.amount-convert')}:
            </strong>
            <div>
              <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                <NumberDisplay number={creditToDollars} />
              </strong>
              <span style={{ fontSize: '11px', textTransform: 'uppercase' }}>
                {' '}
                USD
              </span>
            </div>
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <strong
              style={{
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              {t('budget.ptaas.converter.amount-obtain')}:
            </strong>
            <div>
              <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                {credit}{' '}
              </strong>
              <span style={{ fontSize: '11px', textTransform: 'uppercase' }}>
                {credit === 1
                  ? t('budget.ptaas.converter.credit')
                  : t('budget.ptaas.converter.credits')}
              </span>
            </div>
          </div>
          <hr />
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <strong
              style={{
                marginRight: '10px',
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              {t('budget.ptaas.converter.remaining-wallet-balance')}:
            </strong>
            <div>
              <strong style={{ textAlign: 'right', fontSize: '16px' }}>
                <NumberDisplay
                  number={companyCurrentBalance - creditToDollars}
                />
              </strong>
              <span style={{ fontSize: '11px', textTransform: 'uppercase' }}>
                {' '}
                USD
              </span>
            </div>
          </div>
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <Button
              size="large"
              style={{
                width: '48%',
                height: '40px',
                background: '#717982',
              }}
              onClick={() => setSubmitted(false)}
            >
              <span className="p-button">
                {t('budget.funds.cancel-button')}
              </span>
            </Button>
            <Button
              size="large"
              className="btn-company"
              style={{
                width: '48%',
                height: '40px',
              }}
              onClick={handleConverter}
            >
              {isFetchingConversion ? (
                <Spin>
                  <span className="p-button">
                    {t('budget.ptaas.converter.converting')}
                  </span>
                </Spin>
              ) : (
                <span className="p-button">
                  {t('budget.ptaas.converter.converter-button')}
                </span>
              )}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{ textAlign: 'left', marginLeft: '30px', marginTop: '2vh' }}
          >
            {t('budget.ptaas.converter.amount-convert')}:
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '90%',
            }}
          >
            <Input
              style={{
                marginTop: '10px',
                flex: '1',
                marginLeft: '29px',
                height: '36px',
                fontSize: '11px',
                color: 'black',
              }}
              value={dollarInput}
              onChange={handleUsdNumberChange}
              suffix="USD"
            />
          </div>
          {balanceError && (
            <div
              style={{
                color: 'red',
                textAlign: 'left',
                marginLeft: '30px',
                marginTop: '2vh',
              }}
            >
              {balanceError}
            </div>
          )}
          <div style={{ margin: '0', textAlign: 'center', marginTop: '15px' }}>
            <strong style={{ fontSize: '16px' }}>1 </strong>
            <span
              style={{
                fontSize: '11px',
                textTransform: 'uppercase',
                marginRight: '2vh',
              }}
            >
              {t('budget.ptaas.converter.credit')}
            </span>
            <Image src="/images/money.png" width={'25px'} preview={false} />
            <strong style={{ fontSize: '16px', marginLeft: '2vh' }}>
              {dollarValue}
            </strong>
            <span style={{ fontSize: '11px', textTransform: 'uppercase' }}>
              {' '}
              USD
            </span>
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '3vh',
              marginBottom: '1vh',
            }}
          >
            <SwapOutlined
              style={{ fontSize: '30px', transform: 'rotate(90deg)' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '90%',
            }}
          >
            <Input
              style={{
                marginTop: '10px',
                flex: '1',
                marginLeft: '29px',
                height: '36px',
                fontSize: '11px',
                color: 'black',
              }}
              value={creditInput}
              onChange={handleCreditNumberChange}
              suffix={t('budget.ptaas.credits')}
            />
          </div>
          <div
            style={{
              margin: '0',
              textAlign: 'center',
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Card
              style={{
                marginBottom: '10px',
                marginTop: '2vh',
                textAlign: 'center',
                width: '83%',
                marginRight: '10px',
                height: '12vh',
                transition: 'box-shadow 0.3s, transform 0.3s',
              }}
            >
              <strong
                style={{
                  fontSize: '16px',
                  marginBottom: '2vh',
                  marginTop: '2vh',
                }}
              >
                Se convertirá
              </strong>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <strong style={{ marginLeft: '4vh', fontSize: '16px' }}>
                    <NumberDisplay number={creditToDollars} />
                  </strong>
                  <span style={{ fontSize: '11px' }}>{' USD'}</span>
                </div>

                <SwapOutlined style={{ fontSize: '30px' }} />
                <div>
                  <strong style={{ fontSize: '16px' }}>
                    <NumberDisplay number={credit} />
                  </strong>
                  <span
                    style={{
                      fontSize: '11px',
                      textTransform: 'uppercase',
                      marginRight: '4vh',
                    }}
                  >
                    {credit === 1
                      ? ' ' + t('budget.ptaas.converter.credit')
                      : ' ' + t('budget.ptaas.converter.credits')}
                  </span>
                </div>
              </div>
            </Card>
            <Button
              key="convert"
              size="large"
              className="btn-company"
              style={{
                width: '83%',
                marginRight: '7px',
                height: '40px',
                marginBottom: '10px',
                marginTop: '2vh',
              }}
              onClick={validateConversion}
              disabled={balance < 0 || dollarInput < dollarValue}
            >
              <span className="p-button center">
                {t('budget.ptaas.converter.continue-button')}
              </span>
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isFetchingConversion: state.wallet.isFetching,
  hasError: state.wallet.hasError,
  errorMessage: state.wallet.errorMessage,
});

export default connect(mapStateToProps)(ModalConverter);
